import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: 500,
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
}));

function Results({
  className,
  vacationRequests,
  page,
  setPage,
  limit,
  setLimit,
  requestUser,
  totalVacationRequests,
  ...rest
}) {
  const classes = useStyles();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Fecha inicio
                </TableCell>
                <TableCell>
                  Fecha fin
                </TableCell>
                <TableCell>
                  Días legales
                </TableCell>
                <TableCell>
                  Días adicionales
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vacationRequests.length > 0 && vacationRequests.map((vacation) => {
                // console.log(vacation, 'vacation');
                const vacationRequestDays = vacation.developer.vacationDays
                  .filter((v) => v.vacationRequestId === vacation.id);
                const { creditDays, legalDays } = vacationRequestDays.reduce((acc, curr) => {
                  if (curr.isExtra) {
                    acc.creditDays += curr.vacationCharge;
                  } else {
                    acc.legalDays += curr.vacationCharge;
                  }
                  return acc;
                }, { creditDays: 0, legalDays: 0 });

                return (
                  <TableRow
                    hover
                    key={vacation.id}
                  >
                    <TableCell>
                      {vacation.startDate}
                    </TableCell>
                    <TableCell>
                      {vacation.endDate}
                    </TableCell>
                    <TableCell>
                      {legalDays}
                    </TableCell>
                    <TableCell>
                      {creditDays}
                    </TableCell>
                    <TableCell>
                      {vacation.status}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalVacationRequests}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  vacationRequests: PropTypes.array,
  page: PropTypes.number,
  setPage: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  requestUser: PropTypes.object,
  totalVacationRequests: PropTypes.number,
};

Results.defaultProps = {
  vacationRequests: [],
};

export default Results;
