import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import useStyles from './styles';

const DateFilters = ({
  filters,
  setFilters,
  clients,
}) => {
  const classes = useStyles();

  const handleFiltersChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  if (clients.length === 0) {
    return null;
  }
  return (
    <div className={classes.chartHeader}>
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item xs={12} sm={6}>
      <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={clients}
            defaultValue={null}
            getOptionLabel={(option) => (option ? `${option.name}` : '')}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onChange={(e, value) => handleFiltersChange('client', value || '')}
            value={filters.client}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Buscar Cliente"
                variant="outlined"
              />
            )}
          />
      </Grid>
      <Grid item xs={12} sm={6} container spacing={2} className={classes.dataPickersGrid}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
            <DatePicker
              inputFormat="MM-yyyy"
              views={['year', 'month']}
              label="Mes y Año"
              minDate={new Date('2020-02-01')}
              maxDate={new Date('2030-01-01')}
              value={filters.startMonth}
              onChange={(value) => handleFiltersChange('startMonth', value || null)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" className={classes.datePicker} />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
            <DatePicker
              inputFormat="MM-yyyy"
              views={['year', 'month']}
              label="Mes y Año"
              minDate={new Date('2020-02-01')}
              maxDate={new Date('2030-01-01')}
              value={filters.endMonth}
              onChange={(value) => handleFiltersChange('endMonth', value || null)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" className={classes.datePicker} />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Grid>
    </div>
  );
};

DateFilters.propTypes = {
  clients: PropTypes.array,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default DateFilters;
