const CLP = 'CLP';
const USD = 'USD';

const currencyList = [
  CLP,
  USD,
];

module.exports = {
  CLP,
  USD,
  currencyList,
};
