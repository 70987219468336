import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box, Card, Divider, Button, Grid,
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DataGraph from './Graphic';
import { firstDayOfCurrentMonth } from '../../utils/date';
import useStyles from './styles';

function Results({
  className,
  data,
  categories,
  month,
  setMonth,
  setProjectIndex,
  setDeveloperIndex,
  graphic,
  setGraphic,
  ...rest
}) {
  const classes = useStyles();

  const handleMonthOnChange = (date) => {
    if (date && date.getFullYear() > 2020) {
      const Month = date.getMonth();
      const Year = date.getFullYear();
      const firstDayOfTheMonth = new Date(Year, Month, 1);
      const valid = Date.parse(firstDayOfTheMonth);
      if (!Number.isNaN(valid)) {
        setMonth(firstDayOfTheMonth.toISOString());
      }
    } else {
      setMonth(firstDayOfCurrentMonth(new Date()));
    }
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(month);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setMonth(prevMonth.toISOString());
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(month);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setMonth(nextMonth.toISOString());
  };

  return (
    <div>
      <Card className={clsx(classes.rootResults, className)} {...rest}>
        <Divider />
        <Box
          p={2}
          minHeight={56}
          display="flex"
          alignItems="center"
          flexwrap="wrap"
          spacing={3}
        >
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <Button
                color="secondary"
                className={classes.action}
                variant="contained"
                onClick={() => setGraphic(1)}
              >
                Gráfico Proyectos
              </Button>
            </Grid>
            <Grid item md={4} xs={12}>
              <Button
                color="secondary"
                className={classes.action}
                variant="contained"
                onClick={() => setGraphic(0)}
              >
                Gráfico Developers
              </Button>
            </Grid>
            <Grid item md={4} xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esLocale}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={handlePrevMonth}
                    color="primary"
                    className={classes.dateButtons}

                  >
                    <NavigateBeforeIcon />
                  </Button>
                  <DesktopDatePicker
                    inputFormat="MM-yyyy"
                    views={['year', 'month']}
                    label="Mes y Año"
                    minDate={new Date('2022-01-02')}
                    maxDate={new Date('2024-01-01')}
                    value={month}
                    onChange={(e) => handleMonthOnChange(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Button
                    onClick={handleNextMonth}
                    color="primary"
                    className={classes.dateButtons}
                  >
                    <NavigateNextIcon />
                  </Button>
                </div>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        {graphic ? (
          <DataGraph
            categories={categories}
            data={data}
            title={'Control de avance por proyectos'}
            graphic={graphic}
            setProjectIndex={setProjectIndex}
            setDeveloperIndex={setDeveloperIndex}
          />
        ) : (
          <DataGraph
            categories={categories}
            data={data}
            title={'Control de avance por developer'}
            graphic={graphic}
            setProjectIndex={setProjectIndex}
            setDeveloperIndex={setDeveloperIndex}
          />
        )}
      </Card>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  month: PropTypes.any,
  setMonth: PropTypes.func,
  categories: PropTypes.array,
  setProjectIndex: PropTypes.func,
  setDeveloperIndex: PropTypes.func,
  graphic: PropTypes.number,
  setGraphic: PropTypes.func,
};

export default Results;
