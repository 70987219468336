import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import useStyles from './styles';
import Page from '../../../components/Layout/Page';
import InfoCard from './InfoCard';
import billRequests from '../../../requests/api/bill';
import Chart from './Chart';
import { formatMonthAndYearMoment } from '../../../utils/date';
import DateFilters from './DateFilters';
import { CANCELED } from '../../../utils/const/billStatus';
import clientsRequest from '../../../requests/api/client';
import billsFiltersActions from '../../../actions/billActions';

function BillsChart() {
  const classes = useStyles();
  const [bills, setBills] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const [clients, setClients] = useState([]);

  const [filters, setFilters] = useState({
    client: '',
    startMonth: new Date(currentYear, 0, 1).toISOString(),
    endMonth: new Date(currentYear, currentMonth, 1).toISOString(),
  });

  const billsRedux = useSelector((state) => state.bill);
  const [reduxFilters, setReduxFilters] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const setReduxInfo = async () => {
      if (Object.keys(billsRedux.billsChartFilters).length > 0) {
        setFilters({ ...filters, ...billsRedux.billsChartFilters });
      }
      setReduxFilters(true);
    };
    setReduxInfo();
  }, []);

  useEffect(() => {
    const getBills = async () => {
      const response = await billRequests.getBillsPerPage({
        page: 0,
        limit: 10000,
        searchValue: '',
        searchStartMonth: filters.startMonth,
        searchEndMonth: filters.endMonth,
        searchClient: filters.client.name || '',
        searchStatus: '',
        searchOrder: 'DESC',
      });
      if (response.success) {
        const data = await response.data.data.bills;
        const arrayData = Object.values(data);
        setBills(arrayData);
      }
    };

    const setBillsFilters = async () => {
      dispatch(billsFiltersActions.setBillsChartFilters(filters));
    };

    if (clients.length > 0 && reduxFilters) {
      setBillsFilters();
      getBills();
    }
  }, [filters, clients]);

  useEffect(() => {
    const getClients = async () => {
      const response = await clientsRequest.getAllClients();
      if (response.success) {
        const data = await response.data.data.clients;
        const arrayData = Object.values(data);
        setClients(arrayData);
      }
    };
    getClients();
  }, []);

  useEffect(() => {
    const getChartData = () => {
      const data = {
        'Total Facturado': [],
        'Total Ingreso': [],
        'Total Servicios': [],
        categories: [],
        'Total Facturado Periodo De Tiempo': [],
        'Total Ingreso Periodo De Tiempo': [],
        'Total Servicios Periodo De Tiempo': [],
      };

      const initialDate = new Date(filters.startMonth);
      const finalDate = new Date(filters.endMonth);

      const diffInMonths = (finalDate.getFullYear() - initialDate.getFullYear()) * 12
        + (finalDate.getMonth() - initialDate.getMonth());

      let totalFacturadoPeriodoDeTiempo = 0;
      let totalIngresoPeriodoDeTiempo = 0;
      let totalServiciosPeriodoDeTiempo = 0;

      for (let i = 0; i <= diffInMonths; i += 1) {
        const date = new Date(
          initialDate.getFullYear(),
          initialDate.getMonth() + i,
          1,
        );
        const billsEmitidasOPagadas = bills.filter(
          (bill) => formatMonthAndYearMoment(bill.issueDate)
              === formatMonthAndYearMoment(date) && bill.status !== CANCELED,
        );
        const totalFacturado = billsEmitidasOPagadas.reduce(
          (acc, bill) => acc + bill.amount,
          0,
        );
        totalFacturadoPeriodoDeTiempo += totalFacturado;

        // TODO:Preguntar por caso de facturas pagadas sin fecha de pago
        const billsPagadas = bills.filter(
          (bill) => formatMonthAndYearMoment(bill.paymentDate)
              === formatMonthAndYearMoment(date) && bill.status !== CANCELED,
        );
        const totalIngreso = billsPagadas.reduce(
          (acc, bill) => acc + bill.amount,
          0,
        );

        totalIngresoPeriodoDeTiempo += totalIngreso;

        const billsEmitidas = bills.filter(
          (bill) => formatMonthAndYearMoment(bill.serviceMonth)
              === formatMonthAndYearMoment(date) && bill.status !== CANCELED,
        );
        const totalServicios = billsEmitidas.reduce(
          (acc, bill) => acc + bill.amount,
          0,
        );

        totalServiciosPeriodoDeTiempo += totalServicios;

        data['Total Facturado'].push(totalFacturado);
        data['Total Ingreso'].push(totalIngreso);
        data['Total Servicios'].push(totalServicios);
        data.categories.push(formatMonthAndYearMoment(date));
      }

      data['Total Facturado Periodo De Tiempo'].push(
        totalFacturadoPeriodoDeTiempo,
      );
      data['Total Ingreso Periodo De Tiempo'].push(totalIngresoPeriodoDeTiempo);
      data['Total Servicios Periodo De Tiempo'].push(
        totalServiciosPeriodoDeTiempo,
      );

      setDataChart(data);
    };
    getChartData();
  }, [bills]);

  return (
    <Page className={classes.root} title="TubeOps | Gráfico facturas">
      <Container maxWidth={false}>
        <div className={classes.headerDiv}>
          <Header />
        </div>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard data={dataChart} card={0} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard data={dataChart} card={1} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard data={dataChart} card={2} />
          </Grid>
        </Grid>
        <div className={classes.chartDiv}>
          <DateFilters
            filters={filters}
            setFilters={setFilters}
            clients={clients}
          />
          <Chart data={dataChart} />
        </div>
      </Container>
    </Page>
  );
}

export default BillsChart;
