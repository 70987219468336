const SET_INSTANCE = '@instance/set-instance';
const REFRESH = '@instance/refresh';

const setInstance = (instance) => (dispatch) => dispatch({
  type: SET_INSTANCE,
  payload: {
    instance,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setInstance,
  SET_INSTANCE,
  refresh,
  REFRESH,
};
