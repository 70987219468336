import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-head': {
      fontWeight: 700,
      textAlign: 'center',
    },
  },
  queryField: {
    width: 50,
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
}));

function CommentsResults({
  className,
  comments,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  searchValue,
  setSearchValue,
  totalComments,
  ...rest
}) {
  const classes = useStyles();
  const [currentComments, setCurrentComments] = useState(comments);

  useEffect(() => {
    setCurrentComments(comments);
  }, [comments]);

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Divider />
      <Box
        display="flex"
        alignItems="center"
      >
      </Box>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  Tipo
                </TableCell>
                <TableCell align='center'>
                  Fecha
                </TableCell>
                <TableCell align='center'>
                  Mensaje
                </TableCell>
                <TableCell align='center'>
                  Escrito por
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentComments.map((comment) => (
                <TableRow
                  hover
                  key={comment.id}
                >
                  <TableCell align='center'>
                    <Box display="flex" alignContent='content' justifyContent='center'>
                      <Typography
                        color="inherit"
                        variant="h6"
                      >
                        {comment.isEvaluationComment ? 'Evaluación' : 'General'}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align='center'>
                    <Box display="flex" alignContent='content' justifyContent='center'>
                      <Typography
                        color="inherit"
                        variant="h6"
                      >
                        {comment.createdAt ? moment(comment.createdAt).format('DD/MM/YYYY') : 'No hay fecha'}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align='center'>
                    <Box display="flex" alignContent='content' justifyContent='center'>
                      <Typography
                        color="inherit"
                        variant="h6"
                      >
                        {comment.comment}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align='center'>
                    <Box display="flex" alignContent='content' justifyContent='center'>
                      <Typography
                        color="inherit"
                        variant="h6"
                      >
                        {`${comment.developer.user.name} ${comment.developer.user.lastname}`}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalComments}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

CommentsResults.propTypes = {
  className: PropTypes.string,
  comments: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  totalComments: PropTypes.number,
};

CommentsResults.defaultProps = {
  comments: [],
};

export default CommentsResults;
