/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Grid,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Edit as EditIcon, Search as SearchIcon } from 'react-feather';
import { useSnackbar } from 'notistack';
import dimensionRequest from '../../../requests/api/dimension';
import useStyles from './styles';
import getInitials from '../../../utils/getInitials';
import { SUPER_ADMIN, SYSTEM_ADMIN } from '../../../utils/const/user_types';

function Results({
  className,
  dimensions,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  filters,
  setFilters,
  totalDimensions,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectDimension, setSelectDimension] = useState([]);
  const [currentDimension, setCurrentDimension] = useState(dimensions);

  useEffect(() => {
    setCurrentDimension(dimensions);
  }, [dimensions]);

  const handleFiltersChange = (name, event) => {
    event.persist();
    let value = null;
    value = event.target.value;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectAllDimension = (event) => {
    setSelectDimension(
      event.target.checked ? currentDimension.map((e) => e.id) : [],
    );
  };

  const handleSelectOneDimension = (event, technologyId) => {
    if (!selectDimension.includes(technologyId)) {
      setSelectDimension((prevSelected) => [...prevSelected, technologyId]);
    } else {
      setSelectDimension((prevSelected) => prevSelected.filter((id) => id !== technologyId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectDimension.length > 0;
  const selectSomeDimensions = selectDimension.length > 0
    && selectDimension.length < currentDimension.length;
  const selectedAllDimensions = selectDimension.length === currentDimension.length;
  const account = useSelector((state) => state.account);

  const deleteDimensions = async () => {
    if (account.user.role.name !== SUPER_ADMIN && account.user.role.name !== SYSTEM_ADMIN) {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectDimension.length; a += 1) {
        dimensionRequest.deleteDimension(selectDimension[a]);
        enqueueSnackbar('Dimension eliminadas!', {
          variant: 'success',
        });
      }
      setCurrentDimension(
        currentDimension.filter((e) => !selectDimension.includes(e.id)),
      );
      setSelectDimension([]);
    }
  };

  return (
    <Card className={clsx(classes.cardMargin, className)} {...rest}>
      <Divider />
      <Grid
          container
          spacing={3}
          className={classes.filtersContainer}
          display="flex"
          alignItems="center"
          flexwrap="wrap"
        >
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFiltersChange('dimension', e)}
              placeholder="Buscar dimensión"
              value={filters.dimension}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFiltersChange('category', e)}
              placeholder="Buscar categoría"
              value={filters.category}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFiltersChange('subCategory', e)}
              placeholder="Buscar sub-categoría"
              value={filters.subCategory}
              variant="outlined"
              fullWidth
            />
          </Grid>
          </Grid>

      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllDimensions}
              indeterminate={selectSomeDimensions}
              onChange={handleSelectAllDimension}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteDimensions()}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllDimensions}
                    indeterminate={selectSomeDimensions}
                    onChange={handleSelectAllDimension}
                  />
                </TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Categoría</TableCell>
                <TableCell>Sub-Categoría</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentDimension.map((element) => {
                const isDimensionSelected = selectDimension.includes(
                  element.id,
                );
                return (
                  <TableRow
                    hover
                    key={element.id}
                    selected={isDimensionSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isDimensionSelected}
                        onChange={(event) => handleSelectOneDimension(event, element.id)
                        }
                        value={isDimensionSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatar} src={element.avatar}>
                          {getInitials(element.name)}
                        </Avatar>
                        <div>
                          <Typography color="inherit" variant="h6">
                            {element.name}
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>{element.sub_category.category.name}</TableCell>
                    <TableCell>{element.sub_category.name}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/administracion/dimensiones/${element.id}/editar`}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalDimensions}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  dimensions: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  filters: PropTypes.string,
  setFilters: PropTypes.func,
  totalDimensions: PropTypes.number,
};

Results.defaultProps = {};

export default Results;
