import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsCustomEvents from 'highcharts-custom-events';
import highchartsMore from 'highcharts/highcharts-more';

HighchartsCustomEvents(Highcharts);

highchartsMore(Highcharts);

function DataGraphic({ categories, data }) {
  const options = {
    chart: {
      type: 'column',
      zoomType: 'x',
    },
    title: {
      text: 'Demanda',
    },
    xAxis: {
      categories,
      title: {
        text: 'Meses',
      },
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: 'Horas',
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        fontSize: '12px',
      },
    },
    series: data,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

DataGraphic.propTypes = {
  data: PropTypes.array,
  categories: PropTypes.array,
};

export default DataGraphic;
