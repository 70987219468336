/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Grid,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Edit as EditIcon, Search as SearchIcon } from 'react-feather';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import projectCellRequest from '../../../requests/api/project_cell';
import useStyles from './styles';
import projectGraphicActions from '../../../actions/projectGraphicActions';
import projectCellActions from '../../../actions/projectCellActions';

function Results({
  className,
  projectCells,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  filters,
  setFilters,
  totalProjectCells,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedProjectCell, setSelectedProjectCell] = useState([]);
  const [currentProjectCell, setCurrentProjectCell] = useState(projectCells);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentProjectCell(projectCells);
  }, [projectCells]);

  const handleFiltersChange = (name, event) => {
    event.persist();
    let value = null;
    value = event.target.value;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectAllAssignments = (event) => {
    setSelectedProjectCell(
      event.target.checked
        ? currentProjectCell.map((assignment) => assignment.id)
        : [],
    );
  };

  const handleSelectOneAssignment = (event, assignmentId) => {
    if (!selectedProjectCell.includes(assignmentId)) {
      setSelectedProjectCell((prevSelected) => [...prevSelected, assignmentId]);
    } else {
      setSelectedProjectCell((prevSelected) => prevSelected.filter((id) => id !== assignmentId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectedProjectCell.length > 0;
  const selectedSomeAssignments = selectedProjectCell.length > 0
    && selectedProjectCell.length < currentProjectCell.length;
  const selectedAllAssignments = selectedProjectCell.length === currentProjectCell.length;
  const account = useSelector((state) => state.account);

  const deleteAssignments = async () => {
    if (account.user.role.name.toLowerCase() !== 'superadmin') {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectedProjectCell.length; a += 1) {
        // eslint-disable-next-line no-await-in-loop
        const response = await projectCellRequest.deleteProjectCell(selectedProjectCell[a]);
        if (response.success) {
          enqueueSnackbar('Célula de proyecto eliminadas!', {
            variant: 'success',
          });
          setCurrentProjectCell(
            currentProjectCell.filter((e) => !selectedProjectCell.includes(e.id)),
          );
        } else {
          enqueueSnackbar(response.message, {
            variant: 'error',
          });
        }
        setSelectedProjectCell([]);
      }
    }
  };

  const getMonth = (date) => {
    const dateFormat = new Date(date);
    const Month = dateFormat.toLocaleString('es-es', { month: 'long' });
    return Month;
  };
  const handleMonthOnChange = (date, name) => {
    if (date && (date.getFullYear() > 2020)) {
      const Month = date.getMonth();
      const Year = date.getFullYear();
      const firstDayOfTheMonth = new Date(Year, Month, 1);
      const valid = Date.parse(firstDayOfTheMonth);
      if (!Number.isNaN(valid)) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: firstDayOfTheMonth.toISOString(),
        }));
      }
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: '',
      }));
    }
  };

  const handleRedirectToGraphicProject = (projectId, month) => {
    dispatch(projectGraphicActions.setProjectGraphic({ projectId, month }));
    history.push('/administracion/proyecto/grafico');
  };

  const handleRedirectToProjectCell = (projectId, month) => {
    dispatch(projectCellActions.setProjectCell({ projectId, month }));
    history.push('/administracion/celulas-de-proyecto/panel');
  };

  return (
    <Card className={clsx(classes.cardMargin, className)} {...rest}>
      {(
        <Grid
          container
          spacing={4}
          className={classes.filtersContainer}
          display="flex"
          alignItems="center"
          flexwrap="wrap"
        >

          <Grid item md={3} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFiltersChange('project', e)}
              placeholder="Buscar proyecto"
              value={filters.project}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFiltersChange('client', e)}
              placeholder="Buscar cliente"
              value={filters.client}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DatePicker
                inputFormat="MM-yyyy"
                views={['year', 'month']}
                label="Mes inicio"
                minDate={new Date('2022-02-01')}
                maxDate={new Date('2024-01-01')}
                value={filters.startMonth}
                onChange={(e) => handleMonthOnChange(e, 'startMonth')}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" error={false} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={3} xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DatePicker
                inputFormat="MM-yyyy"
                views={['year', 'month']}
                label="Mes termino"
                minDate={new Date('2022-02-01')}
                maxDate={new Date('2024-01-01')}
                value={filters.endMonth}
                onChange={(e) => handleMonthOnChange(e, 'endMonth')}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" error={false} />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      )}

      <Divider />
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllAssignments}
              indeterminate={selectedSomeAssignments}
              onChange={handleSelectAllAssignments}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteAssignments()}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllAssignments}
                    indeterminate={selectedSomeAssignments}
                    onChange={handleSelectAllAssignments}
                  />
                </TableCell>
                <TableCell>Proyecto</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Mes</TableCell>
                <TableCell>Numero de developers</TableCell>
                <TableCell>Horas Asignadas</TableCell>
                <TableCell>Costo Asignado</TableCell>
                <TableCell>Costo Real</TableCell>
                <TableCell>Horas Reales</TableCell>
                <TableCell>Afinidad</TableCell>
                <TableCell>Gráfico</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProjectCell.map((projectCell) => {
                const isAssignmentSelected = selectedProjectCell.includes(
                  projectCell.id,
                );
                const numberOfDevelopers = projectCell.project_developers.length;
                const assignedHours = projectCell.project_developers.map((item) => item.assignedHours).reduce((prev, curr) => prev + curr, 0);
                const assignedCost = projectCell.project_developers.map((item) => (item.assignation.developer.costPerHour * item.assignedHours)).reduce((prev, curr) => prev + curr, 0);
                const realCost = projectCell.project_developers.map((item) => (item.assignation.developer.costPerHour * item.realHours)).reduce((prev, curr) => prev + curr, 0);
                const realHours = projectCell.project_developers.map((item) => +item.realHours).reduce((prev, curr) => prev + curr, 0).toFixed(1);
                return (
                  <TableRow
                    hover
                    key={projectCell.id}
                    selected={isAssignmentSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isAssignmentSelected}
                        onChange={(event) => handleSelectOneAssignment(event, projectCell.id)
                        }
                        value={isAssignmentSelected}
                      />
                    </TableCell>
                    <TableCell>
                      {projectCell.project.name}
                    </TableCell>
                    <TableCell>
                      {projectCell.project.client.name}
                    </TableCell>
                    <TableCell>{getMonth(projectCell.month)}</TableCell>
                    <TableCell>{numberOfDevelopers}</TableCell>
                    <TableCell>{assignedHours}</TableCell>
                    <TableCell>{assignedCost}</TableCell>
                    <TableCell>{realCost}</TableCell>
                     <TableCell>{realHours}</TableCell>
                    <TableCell>{projectCell.affinity}</TableCell>
                    <TableCell>
                    <IconButton
                        onClick={() => handleRedirectToGraphicProject(projectCell.project.id, projectCell.month)}
                      >
                        <SvgIcon fontSize="medium">
                          <EqualizerIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleRedirectToProjectCell(projectCell.project.id, projectCell.month)}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
                // ));
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalProjectCells}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  projectCells: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  totalProjectCells: PropTypes.number,
};

Results.defaultProps = {
};

export default Results;
