import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../constants/http';

const postCreateSurvey = async ({
  name,
  googleFormId,
  link,
  expirationDate,
  mandatory,
}) => {
  try {
    const { data } = await axios.post('/api/survey', {
      name,
      googleFormId,
      link,
      expirationDate,
      mandatory,
    });

    return data;
  } catch (err) {
    const errStatus = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      errStatus,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getSurveyById = async (id) => {
  try {
    const { data } = await axios.get(`/api/survey/${id}`);

    return data;
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putSurveyById = async ({
  id,
  name,
  googleFormId,
  link,
  expirationDate,
  mandatory,
}) => {
  try {
    const { data } = await axios.put(`/api/survey/${id}`, {
      name,
      googleFormId,
      link,
      expirationDate,
      mandatory,
    });

    return data;
  } catch (err) {
    const errStatus = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      errStatus,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getSurveysPerPage = async (page, limit, query, sort) => {
  try {
    const { data } = await axios.get(`/api/survey?page=${page}&limit=${limit}&query=${query}&sort=${sort}`);

    return data;
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteSurveys = async (surveyIds) => {
  try {
    const { data } = await axios.delete(`/api/survey/${surveyIds}`);

    return data;
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getSurveyResponses = async (formId) => {
  try {
    const { data } = await axios.get(`/api/survey/responses/${formId}`);

    return data;
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  postCreateSurvey,
  getSurveyById,
  putSurveyById,
  getSurveysPerPage,
  deleteSurveys,
  getSurveyResponses,
};
