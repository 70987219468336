import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { Redirect } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import Request from '../../../requests/api/availability';
import useStyles from './styles';

function AvailabilityCreateForm({ className, developers, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/availability" />;
  }

  return (
    <Formik
      initialValues={{
        developer: '',
        month: '',
        minHours: '',
        maxHours: '',
      }}
      validationSchema={Yup.object().shape({
        developer: Yup.object().required('Debe ingresar un desarrollador'),
        month: Yup.date().required('Debe ingresar un mes y un año'),
        minHours: Yup.number().required('Debe ingresar la cantidad minima de horas'),
        maxHours: Yup.number().required('Debe ingresar la cantidad maxima de horas'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // const month = values.date.toLocaleString('es-es', { month: 'long' });
          const getMonth = values.month.getMonth();
          const year = values.month.getFullYear();
          const firstDayOfTheMonth = new Date(year, getMonth, 1);
          const data = {
            developerId: values.developer.id,
            month: firstDayOfTheMonth,
            minHours: values.minHours,
            maxHours: values.maxHours,
          };
          const response = await Request.postCreateAvailability(data);
          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: (
                <Button href="/administracion/availability">
                  Volver a Disponibilidad
                </Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    id="combo-box-demo"
                    options={developers}
                    getOptionLabel={(option) => `${option.user.name} ${option.user.lastname}`
                    }
                    onChange={(e, value) => {
                      if (value === null) {
                        // eslint-disable-next-line no-param-reassign
                        value = '';
                      }
                      setFieldValue('developer', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.developer && errors.developer)}
                        helperText={touched.developer && errors.developer}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        variant="outlined"
                        label="Nombre desarrollador"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                      <DatePicker
                        inputFormat="MM-yyyy"
                        views={['year', 'month']}
                        label="Mes y Año"
                        minDate={new Date('2020-02-01')}
                        maxDate={new Date('2030-01-01')}
                        value={values.month}
                        onChange={(value) => setFieldValue('month', value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            error={Boolean(touched.month && errors.month)}
                            helperText={touched.month && errors.month}
                            onBlur={handleBlur}
                          />
                        )}
                      />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.minHours && errors.minHours)}
                    fullWidth
                    helperText={touched.minHours && errors.minHours}
                    label="Horas Mínimas"
                    name="minHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.minHours}
                    variant="outlined"
                    margin="normal"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 200 },
                      endAdornment: (
                        <InputAdornment position="start">hr</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                <TextField
                    error={Boolean(touched.maxHours && errors.maxHours)}
                    fullWidth
                    helperText={touched.maxHours && errors.maxHours}
                    label="Horas Maximas"
                    name="maxHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // required
                    value={values.maxHours}
                    variant="outlined"
                    margin="normal"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 200 },
                      endAdornment: (
                        <InputAdornment position="start">hr</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item />
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

AvailabilityCreateForm.propTypes = {
  className: PropTypes.string,
  developers: PropTypes.array,
};

export default AvailabilityCreateForm;
