import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import useStyles from './styles';

function Header({
  className,
  setCycle,
  cycle,
  cycles,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.emptyRoot, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item className={classes.headerTitle}>
        <BarChartIcon className={classes.headerIcon} />
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Performance proyectos
        </Typography>
      </Grid>
      <Grid item md={3} xs={12}>
        <div style={{
          display: 'flex', alignItems: 'center',
        }}>
          <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={cycles}
            value={cycle}
            getOptionLabel={(option) => option.name || 'N/A'}
            onChange={(e, value) => {
              if (value === null) return;
              setCycle(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Ciclo"
              />
            )} />
        </div>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  cycle: PropTypes.any,
  setCycle: PropTypes.func,
  cycles: PropTypes.array,
};

export default Header;
