import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  emptyRoot: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  filtersContainer: {
    padding: '20px 10px',
  },
  close: {
    borderRadius: '8px',
    backgroundColor: theme.palette.primary.close,
    padding: '2px 10px',
  },
  open: {
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.open,
    padding: '2px 10px',
  },
  fullTableRow: {
    display: 'table-row !important',
  },
  pendingIcon: {
    color: 'rgba(217, 201, 104, 0.8)',
  },
  checkIcon: {
    color: 'rgba(82, 186, 129, 0.8)',
  },
  emailCell: {
    paddingLeft: '40%',
    '& svg': {
      marginRight: '10px',
      width: '17px',
      height: '17px',
    },
  },
}));

export default useStyles;
