import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ActionsCreateForm from './ActionsCreateForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function ActionsCreateView() {
  const classes = useStyles();
  const performanceCycleInstanceId = window.location.pathname.split('/')[3];
  const objectiveId = window.location.pathname.split('/')[5];

  return (
    <Page
      className={classes.root}
      title="TubeOps | Agregar acción"
    >
      <Container maxWidth={false}>
        <Header
          performanceCycleInstanceId={performanceCycleInstanceId}
          objectiveId={objectiveId}
        />
        <ActionsCreateForm />
      </Container>
    </Page>
  );
}

export default ActionsCreateView;
