import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Modal,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Rating, TextField } from '@mui/material';
import objectiveRequest from '../../../requests/api/objectives';
import instanceActions from '../../../actions/instanceActions';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    maxWidth: '775px',
    minHeight: '90%',
    marginTop: '8%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

export default function EvaluationModal({
  openEvaluationModal,
  setOpenEvaluationModal,
  setEvaluations,
  objective,
  setReload,
  selectedInstance,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((s) => s.account.user);

  const { enqueueSnackbar } = useSnackbar();
  const [objectiveDetails, setObjectiveDetails] = useState(null);
  const [objectiveComment, setObjectiveComment] = useState('');

  useEffect(() => {
    const getObjectiveDetails = async () => {
      const response = await objectiveRequest.getObjectiveById(objective.id);
      if (response.success) {
        setObjectiveDetails(response.data.data.objective);
      }
    };
    if (objective.id) getObjectiveDetails();
  }, [objective]);

  const handleEditObjectiveEvaluation = async () => {
    const response = await objectiveRequest.putObjectiveById({
      objectiveId: objectiveDetails.id,
      expectedResults: objectiveDetails.expectedResults,
      monthlyLoad: objectiveDetails.monthlyLoad,
      bonusPercentage: objectiveDetails.bonusPercentage,
      active: objectiveDetails.active,
      evaluation: objective.evaluation,
    });
    if (response.success) {
      dispatch(instanceActions.setInstance(response.data.data.updatedInstance));
      if (objectiveComment.length) {
        await objectiveRequest.postCreateObjectiveComment({
          objectiveId: objectiveDetails.id,
          commentingDeveloperId: user.developer.id,
          isEvaluationComment: true,
          comment: objectiveComment,
        });
      }
      enqueueSnackbar('Cambios guardados', {
        variant: 'success',
      });
    }
    setObjectiveComment('');
    setOpenEvaluationModal(false);
    setReload((prev) => !prev);
  };

  const handleCloseModal = () => {
    dispatch(instanceActions.setInstance(selectedInstance));
    setEvaluations((prev) => ({ ...prev, [objective.index]: objectiveDetails.evaluation }));
    setOpenEvaluationModal(false);
    setReload((prev) => !prev);
    setObjectiveComment('');
  };

  if (!objectiveDetails) return <></>;

  return (
    <Box
      mt={2}
      display='flex'
      flexDirection='column'
      gridGap={10}
    >
      <Modal
        open={openEvaluationModal}
        onClose={() => setOpenEvaluationModal(false)}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='75%'
        >
          <Card className={classes.modal}>
            <CardContent className={classes.modalContent}>
              <Box display='flex' flexDirection='column' flex={1}>
                <Box display='flex' flexDirection='column' flex={1}>
                  <Box display='flex' mb={2}>
                    <Typography variant='h3'>
                      Evaluar objetivo
                    </Typography>
                  </Box>
                  <Divider />
                  <Box display='flex' flexDirection='column'>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='start'
                      ml={'5%'}
                      mt={'5%'}
                    >
                      <Box minWidth={'49%'}>
                        <Typography variant='h5'>
                          Nombre developer
                        </Typography>
                        <Typography variant='h4'>
                          {`${objectiveDetails && objectiveDetails.performance_cycle_instance.collaborator.user.name} ${objectiveDetails.performance_cycle_instance.collaborator.user.lastname}`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant='h5'>
                          Objetivo
                        </Typography>
                        <Typography variant='h4'>
                          {objectiveDetails.expectedResults}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='start'
                      ml={'5%'}
                      mt={'5%'}
                    >
                      <Box minWidth={'49%'}>
                        <Typography variant='h5'>
                          Tipo
                        </Typography>
                        <Typography variant='h4'>
                          {objectiveDetails.type}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant='h5'>
                          Descripción
                        </Typography>
                        <Typography variant='h4'>
                          {objectiveDetails.description}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='start'
                      ml={'5%'}
                      mt={'5%'}
                    >
                      <Box minWidth={'49%'}>
                        <Typography variant='h5'>
                          Carga
                        </Typography>
                        <Typography variant='h4'>
                          {objectiveDetails.monthlyLoad}
                        </Typography>
                      </Box>
                      <Box minWidth={'49%'}>
                        <Typography variant='h5'>
                          Porcentaje de bono
                        </Typography>
                        <Typography variant='h4'>
                          {`${objectiveDetails.bonusPercentage} %`}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='start'
                      ml={'5%'}
                      mt={'5%'}
                      mb={'2%'}
                    >
                      <Box minWidth={'49%'}>
                        <Typography variant='h5'>
                          Evaluación
                        </Typography>
                        <Box>
                          <Rating
                            name="evaluation"
                            value={objective.evaluation}
                            max={4}
                            emptyIcon={<StarBorderIcon fontSize="large" />}
                            icon={<StarIcon fontSize="large" style={{ color: '#00D293' }} />}
                            readOnly
                          />
                        </Box>
                      </Box>
                      <Box minWidth={'49%'}>
                        <Typography variant='h5'>
                          Comentario
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          minRows={2}
                          name="comment"
                          onChange={(e) => setObjectiveComment(e.target.value)}
                          required
                          value={objectiveComment}
                          variant="outlined"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                display='flex'
                padding='10px 24px'
              >
                <Box minWidth={'49%'} mr={2}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={handleCloseModal}
                    variant='contained'
                  >
                    Cancelar
                  </Button>
                </Box>
                <Box minWidth={'49%'}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={handleEditObjectiveEvaluation}
                    variant='contained'
                  >
                    Confirmar y evaluar
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
}

EvaluationModal.propTypes = {
  openEvaluationModal: PropTypes.bool,
  setOpenEvaluationModal: PropTypes.func,
  objective: PropTypes.object,
  setEvaluations: PropTypes.func,
  setReload: PropTypes.func,
  selectedInstance: PropTypes.object,
};
