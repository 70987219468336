import axios from '../../utils/axios';
// import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';
import { INTERNAL_SERVER_ERROR } from '../../constants/http';

const getProjectDimensionPerPage = async ({
  page, limit, searchDimension, searchCategory, searchProject, searchClient,
}) => {
  try {
    const { data } = await axios.get(
      `/api/project-dimensions?page=${page}&limit=${limit}&searchDimension=${searchDimension}&searchCategory=${searchCategory}&searchProject=${searchProject}&searchClient=${searchClient}`,
    );
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

// const postCreateDimension = async (
//   {
//     subCategoryId,
//     name,
//   },
// ) => {
//   try {
//     const response = await axios.post('/api/dimension/create', {
//       subCategoryId,
//       name,
//     });
//     if (response.data.success) {
//       return {
//         success: true,
//         status: OK.status,
//         message: OK.message,
//         data: response.data,
//       };
//     }

//     return {
//       success: false,
//       status: BAD_REQUEST.status,
//       message: response.data.data.errorMessage,
//       data: {},
//     };
//   } catch (err) {
//     const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
//     const message = err.response
//       ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

//     return {
//       success: false,
//       status,
//       message,
//       data: {
//         errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
//       },
//     };
//   }
// };

// const getDeveloperDimensionById = async (id) => {
//   try {
//     const { data } = await axios.get(`/api/developer-dimension/${id}`);

//     return {
//       success: data.success,
//       status: data.status,
//       message: data.message,
//       data,
//     };
//   } catch (err) {
//     const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
//     const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
//     return {
//       success: false,
//       status,
//       message,
//       data: {
//         errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
//       },
//     };
//   }
// };

// const putDeveloperDimensionById = async (
//   {
//     id, experience, interest,
//   },
// ) => {
//   try {
//     const response = await axios.put('/api/developer-dimension/edit', {
//       id, experience, interest,
//     });
//     if (response.data.success) {
//       return {
//         success: true,
//         status: OK.status,
//         message: OK.message,
//         data: response.data,
//       };
//     }

//     return {
//       success: false,
//       status: BAD_REQUEST.status,
//       message: response.data.data.errorMessage,
//       data: {},
//     };
//   } catch (err) {
//     const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
//     const message = err.response
//       ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

//     return {
//       success: false,
//       status,
//       message,
//       data: {
//         errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
//       },
//     };
//   }
// };

// const deleteDeveloperDimension = async (id) => {
//   try {
//     const { data } = await axios.delete(`/api/developer-dimension/${id}`);

//     return {
//       success: data.success,
//       status: data.status,
//       message: data.message,
//       data,
//     };
//   } catch (err) {
//     const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
//     const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
//     return {
//       success: false,
//       status,
//       message,
//       data: {
//         errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
//       },
//     };
//   }
// };

export default {
  getProjectDimensionPerPage,
  // postCreateDimension,
  // getDeveloperDimensionById,
  // putDeveloperDimensionById,
  // deleteDeveloperDimension,
};
