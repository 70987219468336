import React, {
  useEffect,
  useState,
} from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import cycleRequest from '../../../requests/api/performance_cycles';
import Objectives from './Objectives';
import ObjectivesRequest from '../../../requests/api/objectives';
import InstancesRequest from '../../../requests/api/performance_cycle_instances';
import SplashScreen from '../../../components/Loading/SplashScreen';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import Evaluations from './Evaluations';
import InstanceDetails from './InstanceDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function PerformanceCycleInstancesDetailView() {
  const classes = useStyles();
  const params = useParams();
  const user = useSelector((s) => s.account.user);
  const reduxInstance = useSelector((s) => s.instance.instance);

  const [reload, setReload] = useState(false);
  const fixedParamsId = parseInt(params.id, 10) || 0;

  const [objectives, setObjectives] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [instances, setInstances] = useState([]);
  const [cycles, setCycles] = useState([]);
  const [selectedCycle, setSelectedCycle] = useState({ name: '' });
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [objectivesSort, setObjectivesSort] = useState('');
  const [selectedInstance, setSelectedInstance] = useState({
    id: '',
  });
  const [totalObjectives, setTotalObjectives] = useState(0);

  useEffect(() => {
    const getCycles = async () => {
      const cyclePage = '';
      const cycleLimit = '';
      const response = await cycleRequest.getPerformanceCyclesPerPage(cyclePage, cycleLimit);
      if (response.success) {
        const data = await response.data.data.performanceCycles;
        setCycles(data);
        setSelectedCycle(data[0]);
      }
    };
    getCycles();
  }, []);

  useEffect(() => {
    const getInstance = async () => {
      const response = await InstancesRequest
        .getPerformanceCycleInstanceByDeveloperId(user.developer.id, selectedCycle.id);
      if (response.success) {
        const devInstance = response.data.data.performance_cycle_instance;
        setSelectedInstance(devInstance);
      }
    };

    if (selectedCycle.id && user.role.name !== SUPER_ADMIN) getInstance();
  }, [selectedCycle]);

  useEffect(() => {
    const getInstancesForCycle = async () => {
      const instancePage = '';
      const instanceLimit = '';
      const instanceSearchValue = '';
      const response = await InstancesRequest
        .getPerformanceCycleInstancesPerPage(
          selectedCycle.id, instancePage, instanceLimit, instanceSearchValue,
        );
      if (response.success) {
        const cycleInstances = response.data.data.performanceCycleInstances;
        setInstances(cycleInstances);
      }
    };

    if (selectedCycle.id && user.role.name === SUPER_ADMIN) getInstancesForCycle();
  }, [selectedCycle, user, reload]);

  useEffect(() => {
    setSelectedInstance((prevInstance) => {
      let newInstance = instances.length > 0 ? instances[0] : {};
      if (prevInstance && prevInstance.collaborator) {
        const collabInstance = instances.find(
          (ci) => ci.collaborator.id === prevInstance.collaborator.id,
        );

        if (collabInstance) newInstance = collabInstance;
      }

      return newInstance;
    });
  }, [instances]);

  useEffect(() => {
    const getInstanceObjetctives = async () => {
      const response = await ObjectivesRequest.getObjectivesPerPage(
        parseInt(selectedInstance.id, 10),
        pageOfTable,
        limit,
        searchValue,
        objectivesSort,
      );
      if (response.success) {
        const {
          performanceCycleInstanceObjectives, totalPerformanceCycleInstanceObjectives,
        } = response.data.data;
        setObjectives(performanceCycleInstanceObjectives);
        setTotalObjectives(totalPerformanceCycleInstanceObjectives);
      }
    };

    if (selectedInstance.id) getInstanceObjetctives();
  }, [selectedInstance]);

  useEffect(() => {
    if (reduxInstance && reduxInstance.id
      && user.role.name === SUPER_ADMIN) setSelectedInstance(reduxInstance);
  }, [reduxInstance]);

  if (!selectedInstance.id) return <SplashScreen />;
  if (user.role.name !== SUPER_ADMIN
    && (fixedParamsId === 0 || user.developer.id !== fixedParamsId)) {
    return <Redirect to='/' />;
  }

  return (
    <Page
      className={classes.root}
      title="TubeOps | Objetivos por developer"
    >
      <Container maxWidth={false}>
        <Header
          cycles={cycles}
          selectedCycle={selectedCycle}
          setSelectedCycle={setSelectedCycle}
          instances={instances}
          selectedInstance={selectedInstance}
          setSelectedInstance={setSelectedInstance}
          user={user}
          setReload={setReload}
        />
        <InstanceDetails
          instance={selectedInstance}
          objectives={objectives}
          setReload={setReload}
        />
        <Evaluations
          instance={selectedInstance}
        />
        <Box mt={3} ml={1}>
          <Typography variant='h4'>
            Listado de objetivos
          </Typography>
        </Box>
        <Box mt={1}>
          <Objectives
            objectives={objectives}
            pageOfTable={pageOfTable}
            setPageOfTable={setPageOfTable}
            limit={limit}
            setLimit={setLimit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            totalObjectives={totalObjectives}
            selectedInstance={selectedInstance}
            performanceCycleInstanceId={selectedInstance.id}
            setReload={setReload}
            user={user}
            setObjectivesSort={setObjectivesSort}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default PerformanceCycleInstancesDetailView;
