import React, {
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import vacationsCalculator from '../../../utils/vacationDaysCalculator';
import { APPROVED } from '../../../utils/const/vacationRequestStatus';

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: 500,
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
}));

function DevelopersList({
  className,
  developersWithVacations,
  developerPage,
  setDeveloperPage,
  developerLimit,
  setDeveloperLimit,
  totalDevelopers,
  vacationRequests,
  ...rest
}) {
  const classes = useStyles();
  const [organizedDevelopers, setOrganizedDevelopers] = useState([]);

  const handlePageChange = (event, newPage) => {
    setDeveloperPage(newPage);
  };

  const handleLimitChange = (event) => {
    setDeveloperLimit(event.target.value);
  };

  useEffect(() => {
    const organizeDevelopersData = () => {
      const vacationByDeveloper = developersWithVacations.reduce((acc, obj) => {
        const {
          id, user, vacationDays, allVacationDays,
        } = obj;
        const devRequests = vacationRequests
          .filter((v) => v.developerId === id && v.status === APPROVED);
        if (!acc[id]) {
          const {
            twoYearsAgoDeposit,
            twoYearsAgoCharge,
            creditDaysTaken,
            creditDaysAvailable,
            creditDaysDeposit,
          } = vacationsCalculator(vacationDays);

          const allExtraDaysTaken = allVacationDays
            .reduce((total, vd) => {
              if (vd.isExtra) {
                return total + vd.vacationCharge;
              }

              return total;
            }, 0);

          const allNormalDaysTaken = allVacationDays
            .reduce((total, vd) => {
              if (!vd.isExtra) {
                return total + vd.vacationCharge;
              }

              return total;
            }, 0);

          acc[id] = {
            id,
            name: user.name,
            lastname: user.lastname,
            contractDate: obj.contractStartDate,
            lastVacationDuration: devRequests.length > 0 && devRequests[0].daysRequested,
            vacationDays: {
              twoYearsAgoDeposit,
              twoYearsAgoCharge,
              creditDaysTaken,
              creditDaysAvailable,
              creditDaysDeposit,
              allExtraDaysTaken,
              allNormalDaysTaken,
              requestedDays: devRequests.reduce(
                (totalDays, request) => totalDays + request.daysRequested, 0,
              ),
              lastVacationsDate: devRequests.length > 0 && devRequests[0].startDate,
            },
          };
        }
        return acc;
      }, {});
      if (Object.keys(vacationByDeveloper).length) {
        setOrganizedDevelopers(Object.values(vacationByDeveloper));
      }
    };
    organizeDevelopersData();
  }, [developersWithVacations]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  Nombre developer
                </TableCell>
                <TableCell align='center'>
                  Fecha inicio de contrato
                </TableCell>
                <TableCell align='center'>
                  Días legales tomados
                </TableCell>
                <TableCell align='center'>
                  Días adicionales tomados
                </TableCell>
                <TableCell align='center'>
                  Días legales disponibles
                </TableCell>
                <TableCell align='center'>
                  Días adicionales disponibles
                </TableCell>
                <TableCell align='center'>
                  Fecha últimas vacaciones
                </TableCell>
                <TableCell align='center'>
                  Duración de últimas vacaciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizedDevelopers.length > 0
                && organizedDevelopers.map((developer, index) => (
                    <TableRow
                      hover
                      key={index}
                    >
                      <TableCell align='center'>
                        {`${developer.name} ${developer.lastname}`}
                      </TableCell>
                      <TableCell align='center'>
                        {developer.contractDate}
                      </TableCell>
                      <TableCell align='center'>
                        {developer.vacationDays.allNormalDaysTaken}
                      </TableCell>
                      <TableCell align='center'>
                        {developer.vacationDays.allExtraDaysTaken}
                      </TableCell>
                      <TableCell align='center'>
                        {developer.vacationDays.twoYearsAgoDeposit
                          - developer.vacationDays.twoYearsAgoCharge}
                      </TableCell>
                      <TableCell align='center'>
                        {developer.vacationDays.creditDaysAvailable < 0
                          ? 0
                          : developer.vacationDays.creditDaysAvailable}
                      </TableCell>
                      <TableCell align='center'>
                        {developer.vacationDays.lastVacationsDate ? moment(developer.vacationDays.lastVacationsDate).format('DD-MM-YYYY') : '-'}
                      </TableCell>
                      <TableCell align='center'>
                        {developer.lastVacationDuration ? `${developer.lastVacationDuration} días` : '-'}
                      </TableCell>
                    </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalDevelopers}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={developerPage}
        rowsPerPage={developerLimit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

DevelopersList.propTypes = {
  className: PropTypes.string,
  developersWithVacations: PropTypes.array,
  developerPage: PropTypes.number,
  setDeveloperPage: PropTypes.func,
  developerLimit: PropTypes.number,
  setDeveloperLimit: PropTypes.func,
  requestUser: PropTypes.object,
  totalDevelopers: PropTypes.number,
  vacationRequests: PropTypes.array,
};

DevelopersList.defaultProps = {
  developersWithVacations: [],
};

export default DevelopersList;
