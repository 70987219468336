const SET_BILLS_FILTERS = '@account/set-bills-filters';
const SET_BILLS_CHART_FILTERS = '@account/set-bills-chart-filters';
const SET_BILLS_RECEIVABLES_FILTERS = '@account/set-bills-receivables-filters';
const REFRESH = '@account/refresh';

const setBillsFilters = (billsFilters) => (dispatch) => dispatch({
  type: SET_BILLS_FILTERS,
  payload: {
    billsFilters,
  },
});

const setBillsChartFilters = (billsChartFilters) => (dispatch) => dispatch({
  type: SET_BILLS_CHART_FILTERS,
  payload: {
    billsChartFilters,
  },
});

const setBillsReceivablesFilters = (billsReceivablesFilters) => (dispatch) => dispatch({
  type: SET_BILLS_RECEIVABLES_FILTERS,
  payload: {
    billsReceivablesFilters,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setBillsFilters,
  SET_BILLS_FILTERS,
  setBillsChartFilters,
  SET_BILLS_CHART_FILTERS,
  setBillsReceivablesFilters,
  SET_BILLS_RECEIVABLES_FILTERS,
  refresh,
  REFRESH,
};
