import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  emptyRoot: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    width: 500,
  },
  modal: {
    maxWidth: '600px',
    marginTop: '8%',
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  containerWithShadow: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    padding: '2%',
  },
}));

export default useStyles;
