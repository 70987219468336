/* eslint-disable max-len */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import { formatMonthAndYearMoment } from '../../utils/date';

const Chart = ({
  selectChart,
  data,
  startMonth,
  endMonth,
}) => {
  if (Object.keys(data).length === 0) {
    return null;
  }

  const categories = [];
  const minDemands = [];
  const maxDemands = [];
  const minAvailabilities = [];
  const maxAvailabilities = [];
  const assignedHours = [];
  const realHours = [];
  let seriesData = [];

  const date = new Date(startMonth);
  const dateEnd = new Date(endMonth);

  while (date.getTime() <= dateEnd.getTime()) {
    const month = formatMonthAndYearMoment(date);
    const findMonthDemand = data.demands.find(
      (demand) => demand.month === month,
    );
    const findMonthAvailability = data.availabilities.find(
      (availability) => availability.month === month,
    );
    const findMonthProjectDeveloper = data.projectDevelopers.find(
      (projectDeveloper) => projectDeveloper.month === month,
    );

    const minDemand = findMonthDemand?.minDemand || 0;
    const maxDemand = findMonthDemand?.maxDemand - minDemand || 0;
    const minAvailability = findMonthAvailability?.minAvailability || 0;
    const maxAvailability = findMonthAvailability?.maxAvailability - minAvailability || 0;

    categories.push(month);
    minDemands.push(minDemand);
    maxDemands.push(maxDemand);
    minAvailabilities.push(minAvailability);
    maxAvailabilities.push(maxAvailability);
    assignedHours.push(findMonthProjectDeveloper?.assignedHours || 0);
    realHours.push(findMonthProjectDeveloper?.realHours || 0);
    date.setMonth(date.getMonth() + 1);
  }

  if (selectChart === 0) {
    seriesData = [
      {
        name: 'Demanda Máxima',
        data: maxDemands,
        stack: 'stack1',
        color: '#BDBDBD',
      },
      {
        name: 'Demanda Mínima',
        data: minDemands,
        stack: 'stack1',
        color: '#24335E',
      },
      {
        name: 'Disponibilidad Máxima',
        data: maxAvailabilities,
        stack: 'stack2',
        color: '#BDBDBD',
      },
      {
        name: 'Disponibilidad Mínima',
        data: minAvailabilities,
        stack: 'stack2',
        color: '#5F696B',
      },
      {
        name: 'Horas Reales',
        data: realHours,
        stack: 'stack3',
        color: '#00D293',
      },
    ];
  } else if (selectChart === 1) {
    seriesData = [
      {
        name: 'Disponibilidad Máxima',
        data: maxAvailabilities,
        stack: 'stack1',
        color: '#BDBDBD',
      },
      {
        name: 'Disponibilidad Mínima',
        data: minAvailabilities,
        stack: 'stack1',
        color: '#24335E',
      },
      {
        name: 'Horas Asignadas',
        data: assignedHours,
        stack: 'stack2',
        color: '#5F696B',
      },
      {
        name: 'Horas Reales',
        data: realHours,
        stack: 'stack3',
        color: '#00D293',
      },
    ];
  } else if (selectChart === 2) {
    seriesData = [
      {
        name: 'Demanda Máxima',
        data: maxDemands,
        stack: 'stack1',
        color: '#BDBDBD',
      },
      {
        name: 'Demanda Mínima',
        data: minDemands,
        stack: 'stack1',
        color: '#24335E',
      },
      {
        name: 'Horas Asignadas',
        data: assignedHours,
        stack: 'stack2',
        color: '#5F696B',
      },
      {
        name: 'Horas Reales',
        data: realHours,
        stack: 'stack3',
        color: '#00D293',
      },
    ];
  }

  const chartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        style: {
          color: '#24335E',
          fontFamily: 'Inter, sans-serif',
          textTransform: 'capitalize', // Primera letra en mayúscula
          fontSize: '16px',
          fontWeight: 700,
        },
      },
    },
    yAxis: {
      stackLabels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    legend: {
      verticalAlign: 'top',
      enabled: true,
      itemStyle: {
        fontSize: '12px',
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
      column: {
        borderWidth: 0,
        shadow: false,
      },
    },
    tooltip: {
      backgroundColor: '#D9D9D9',
      borderWidth: 1,
      borderRadius: 5,
      useHTML: true,
      style: {
        fontFamily: 'Inter, sans-serif',
        color: '#24335E',
      },

      formatter() {
        const { series } = this.series.chart;
        const seriesIndex = this.series.index;
        const categoryIndex = this.point.index;
        const label = series[seriesIndex].name;
        const value = series[seriesIndex].yData[categoryIndex];
        let minDemand;
        let maxDemand;
        let minAvailability;
        let maxAvailability;
        if (selectChart === 0) {
          minDemand = series[1].yData[categoryIndex];
          maxDemand = minDemand + series[0].yData[categoryIndex];
          minAvailability = series[3].yData[categoryIndex];
          maxAvailability = minAvailability + series[2].yData[categoryIndex];
        } else if (selectChart === 1) {
          minAvailability = series[1].yData[categoryIndex];
          maxAvailability = minAvailability + series[0].yData[categoryIndex];
        } else if (selectChart === 2) {
          minDemand = series[1].yData[categoryIndex];
          maxDemand = minDemand + series[0].yData[categoryIndex];
        }

        if (label === 'Demanda Mínima' || label === 'Demanda Máxima') {
          return `<div style="text-align: center;"><b style="font-weight: 700">Demanda</b><br/>Demanda Máxima: ${maxDemand} horas<br/>Demanda Mínima: ${minDemand} horas </div>`;
        }
        if (
          label === 'Disponibilidad Mínima'
          || label === 'Disponibilidad Máxima'
        ) {
          return `<div style="text-align: center;"><b style="font-weight: 700">Disponibilidad</b><br/>Disponibilidad Máxima: ${maxAvailability} horas <br/>Disponibilidad Mínima: ${minAvailability} horas </div>`;
        }
        if (label === 'Horas Reales') {
          return `<div style="text-align: center;"><b style="font-weight: 700">Horas Reales</b><br/>Horas Reales: ${value.toFixed(2)} horas</div>`;
        }
        if (label === 'Horas Asignadas') {
          return `<div style="text-align: center;"><b style="font-weight: 700">Horas Asignadas</b><br/>Horas Asignadas: ${value.toFixed(2)} horas</div>`;
        }
        return null;
      },
    },
    series: seriesData,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

Chart.propTypes = {
  selectChart: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  startMonth: PropTypes.string,
  endMonth: PropTypes.string,
};

export default Chart;
