const SET_CONTROL_PANEL_GRAPHIC = '@account/set-control-panel-graphic';
const REFRESH = '@account/refresh';

const setControlPanelGraphic = (controlPanelGraphic) => (dispatch) => dispatch({
  type: SET_CONTROL_PANEL_GRAPHIC,
  payload: {
    controlPanelGraphic,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setControlPanelGraphic,
  SET_CONTROL_PANEL_GRAPHIC,
  refresh,
  REFRESH,
};
