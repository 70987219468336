import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

const DemandChart = ({ demandData, categories }) => {
  const chartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      stackLabels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    legend: {
      verticalAlign: 'top',
      enabled: true,
      itemStyle: {
        fontSize: '12px',
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
      column: {
        borderWidth: 0,
        shadow: false,
      },
    },
    tooltip: {
      backgroundColor: '#D9D9D9',
      borderWidth: 1,
      borderRadius: 5,
      useHTML: true,
      style: {
        fontFamily: 'Inter, sans-serif',
        color: '#24335E',
      },

      formatter() {
        const { series } = this.series.chart;
        const seriesIndex = this.series.index;
        const categoryIndex = this.point.index;
        const label = series[seriesIndex].name;
        const value = series[seriesIndex].yData[categoryIndex];

        if (seriesIndex === 0 || seriesIndex === 1) {
          const minDemand = series[1].yData[categoryIndex];
          const maxDemand = minDemand + series[0].yData[categoryIndex];
          if (label === 'Demanda Mínima' || label === 'Demanda Máxima') {
            return `<div style="text-align: center;"><b style="font-weight: 700">Demanda</b><br/>Demanda Máxima: ${maxDemand} horas<br/>Demanda Mínima: ${minDemand} horas </div>`;
          }
        } else if (seriesIndex === 2 && label === 'Horas Asignadas') {
          return `<div style="text-align: center;"><b style="font-weight: 700">${label}</b><br/>Asignadas: ${value} horas</div>`;
        } else if (seriesIndex === 3 && label === 'Horas Reales') {
          return `<div style="text-align: center;"><b style="font-weight: 700">${label}</b><br/>Reales: ${value} horas</div>`;
        }
        return null;
      },
    },
    series: [
      {
        name: 'Demanda Máxima',
        data: demandData.maxDemand,
        stack: 'stack1',
        color: '#BDBDBD',
      },
      {
        name: 'Demanda Mínima',
        data: demandData.minDemand,
        stack: 'stack1',
        color: '#24335E',
      },
      {
        name: 'Horas Asignadas',
        data: demandData.assignedHours,
        stack: 'stack2',
        color: '#5F696B',
      },
      {
        name: 'Horas Reales',
        data: demandData.realHours,
        stack: 'stack3',
        color: '#00D293',
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

DemandChart.propTypes = {
  demandData: PropTypes.shape({
    minDemand: PropTypes.arrayOf(PropTypes.number),
    maxDemand: PropTypes.arrayOf(PropTypes.number),
    assignedHours: PropTypes.arrayOf(PropTypes.number),
    realHours: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,

  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DemandChart;
