const SET_ASSIGNATION = '@account/set-assignation';
const REFRESH = '@account/refresh';

const setAssignation = (assignation) => (dispatch) => dispatch({
  type: SET_ASSIGNATION,
  payload: {
    assignation,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setAssignation,
  SET_ASSIGNATION,
  refresh,
  REFRESH,
};
