import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import assignmentRequest from '../../../requests/api/assignation';
import { firstDayOfCurrentMonth } from '../../../utils/date';

import useStyles from './styles';

function AssignmentCreateForm({ className, developers, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/asignaciones" />;
  }

  return (
    <div>
      <Formik
        initialValues={{
          developer: '',
          month: '',
        }}
        validationSchema={Yup.object().shape({
          developer: Yup.object().required('Debe ingresar un desarrollador'),
          month: Yup.date().required('Debe ingresar un mes'),
        })}
        onSubmit={async (
          values,
          {
            setErrors, setStatus, setSubmitting, resetForm,
          },
        ) => {
          try {
            const data = {
              developerId: values.developer.id,
              month: firstDayOfCurrentMonth(values.month),
            };

            const response = await assignmentRequest.postCreateAssignation(
              data,
            );
            const res = response.data;
            if (res.success) {
              resetForm({ values: '' });
              setSubmitSuccess(true);
              setStatus({ success: true });
              setSubmitting(false);
              enqueueSnackbar('Cambios guardados', {
                variant: 'success',
              });
            } else {
              setStatus({ success: false });
              setErrors({ submit: response.message });
              enqueueSnackbar(response.message, {
                variant: 'error',
                action: (
                  <Button href="/administracion/asignaciones">
                    Volver a asignaciones
                  </Button>
                ),
              });
            }
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form
            className={clsx(classes.emptyRoot, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="combo-box-demo"
                      options={developers}
                      getOptionLabel={(option) => `${option.user.name} ${option.user.lastname}`
                      }
                      onChange={(e, value) => {
                        if (value === null) {
                          // eslint-disable-next-line no-param-reassign
                          value = '';
                        }
                        setFieldValue('developer', value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.developer && errors.developer)}
                          helperText={touched.developer && errors.developer}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // required
                          variant="outlined"
                          label="Nombre desarrollador"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={esLocale}
                    >
                      <DatePicker
                        inputFormat="MM-yyyy"
                        views={['year', 'month']}
                        label="Mes y Año"
                        minDate={new Date('2020-02-01')}
                        maxDate={new Date('2030-01-01')}
                        value={values.month}
                        onChange={(value) => setFieldValue('month', value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            error={Boolean(touched.month && errors.month)}
                            helperText={touched.month && errors.month}
                            onBlur={handleBlur}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Guardar
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
}

AssignmentCreateForm.propTypes = {
  className: PropTypes.string,
  developers: PropTypes.array,
};

export default AssignmentCreateForm;
