import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import DimensionCreateForm from './DimensionCreateForm';
import categoryRequest from '../../../requests/api/category';
import useStyles from './styles';

function DimensionCreateView() {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getDimensions = async () => {
      const response = await categoryRequest.getCategoriesPerPage({
        page: 0,
        limit: 200,
        searchValue: '',
      });
      if (response.success) {
        const data = await response.data.data.categories;
        const arrayData = Object.values(data);
        setCategories(arrayData);
      }
    };
    getDimensions();
  }, []);

  return (
    <Page className={classes.root} title="Agregar Dimensión">
      <Container maxWidth={false}>
        <Header />
        <DimensionCreateForm categories={categories}/>
      </Container>
    </Page>
  );
}

export default DimensionCreateView;
