/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import developerRequest from '../../../requests/api/developer';
import useStyles from './styles';
import { firstDayOfCurrentMonth } from '../../../utils/date';
import projectGraphicActions from '../../../actions/projectGraphicActions';

function DeveloperGraphicView() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [month, setMonth] = useState(null);
  const [developerId, setDeveloperId] = useState(null);
  const [developers, setDevelopers] = useState([]);
  const [autocompleteDefaultValue, setAutocompleteDefaultValue] = useState([]);
  const [ids, setIds] = useState([]);
  const [projectIndex, setProjectIndex] = useState(-1);
  const history = useHistory();
  const developerGraphicReduxInfo = useSelector((state) => state.developerGraphic);
  const dispatch = useDispatch();

  useEffect(() => {
    if (developerGraphicReduxInfo.developerGraphic.month) {
      setMonth(developerGraphicReduxInfo.developerGraphic.month);
    } else {
      setMonth(firstDayOfCurrentMonth(new Date()));
    }
    if (developerGraphicReduxInfo.developerGraphic.developerId) {
      const reduxDeveloperId = developerGraphicReduxInfo.developerGraphic.developerId;
      setDeveloperId(reduxDeveloperId);
    } else {
      setDeveloperId(0);
    }
  }, []);

  useEffect(() => {
    const getDeveloper = async () => {
      const response = await developerRequest.getDevelopersPerPage(0, 0, '', month);
      if (response.success) {
        const developersData = await response.data.data.developers;
        const arrayData = Object.values(developersData);
        setDevelopers(arrayData);
        if (!developerId) {
          setDeveloperId(arrayData[0].id);
          setAutocompleteDefaultValue(arrayData.filter((e) => e.id === arrayData[0].id));
        } else {
          setAutocompleteDefaultValue(arrayData.filter((e) => e.id === developerId));
        }
      }
    };

    if (month && developerId >= 0) {
      getDeveloper();
    }
  }, [month, developerId]);

  useEffect(() => {
    const getDeveloperProjectsPerMonth = async () => {
      const response = await developerRequest.getDeveloperGraphic({
        id: developerId,
        month,
      });
      if (response.success) {
        const developerData = await response.data.data.totalProjectDevelopers;
        if (developerData.assignedHours) {
          const dataArray = [];
          dataArray.push({
            name: 'Horas Asignadas',
            data: developerData.assignedHours,
          });
          dataArray.push({
            name: 'Horas Proyectadas',
            data: developerData.projectedHours,
          });
          dataArray.push({ name: 'Horas Reales', data: developerData.realHours });

          setIds(developerData.projectsIds);
          setData(dataArray);
          setCategories(developerData.projectsName);
        } else {
          setIds([]);
          setData([]);
          setCategories([]);
        }
      }
    };
    if (developerId && month) getDeveloperProjectsPerMonth();
  }, [month, developerId]);

  useEffect(() => {
    const getDeveloperProjectsPerMonth = async () => {
      const response = await developerRequest.getDeveloperGraphic({
        id: developerId,
        month,
      });
      if (response.success) {
        const developerData = await response.data.data.totalProjectDevelopers;
        if (developerData.assignedHours) {
          const dataArray = [];
          dataArray.push({
            name: 'Horas Asignadas',
            data: developerData.assignedHours,
          });
          dataArray.push({
            name: 'Horas Proyectadas',
            data: developerData.projectedHours,
          });
          dataArray.push({ name: 'Horas Reales', data: developerData.realHours });

          setIds(developerData.projectsIds);
          setData(dataArray);
          setCategories(developerData.projectsName);
        } else {
          setIds([]);
          setData([]);
          setCategories([]);
        }
      }
    };
    if (developerId && month) getDeveloperProjectsPerMonth();
  }, [month, developerId]);

  useEffect(() => {

  }, []);

  useEffect(() => {
    const redirectProjectGraphicView = async () => {
      if (projectIndex >= 0) {
        const id = ids[projectIndex];
        dispatch(projectGraphicActions.setProjectGraphic({ projectId: id, month }));
        history.push('/administracion/proyecto/grafico');
      }
    };
    redirectProjectGraphicView();
  }, [projectIndex]);

  return (
    <Page className={classes.root} title="TubeOps | Developers">
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
        {autocompleteDefaultValue.length
          ? <Results
            categories={categories}
            data={data}
            month={month}
            setMonth={setMonth}
            developers={developers}
            developerId={developerId}
            setDeveloperId={setDeveloperId}
            autocompleteDefaultValue={autocompleteDefaultValue}
            setProjectIndex={setProjectIndex}
          /> : null }
        </Box>
      </Container>
    </Page>
  );
}

DeveloperGraphicView.propTypes = {
  match: PropTypes.any,
};

export default DeveloperGraphicView;
