/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import getInitials from '../../../utils/getInitials';
import useStyles from './styles';
import Request from '../../../requests/api/developer';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import developerGraphicActions from '../../../actions/developerGraphicActions';

const sortOptions = [
  {
    value: 'updated_at|desc',
    label: 'Más nuevo primero',
  },
  {
    value: 'updated_at|asc',
    label: 'Más antiguo primero',
  },
];

function Results({
  className,
  developers,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  searchValue,
  setSearchValue,
  totalDevelopers,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedDevelopers, setSelectedDevelopers] = useState([]);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [currentDevelopers, setCurrentDevelopers] = useState(developers);
  // eslint-disable-next-line no-unused-vars
  const [selectedProjectDeveloper, setSelectedProjectDeveloper] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => { setCurrentDevelopers(developers); }, [developers]);

  const handleQueryChange = (event) => {
    event.persist();
    setSearchValue(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllDevelopers = (event) => {
    setSelectedDevelopers(event.target.checked
      ? currentDevelopers.map((developer) => developer.id)
      : []);
    setSelectedProjectDeveloper(event.target.checked
      ? currentDevelopers.map((developer) => developer)
      : []);
  };

  const handleSelectOneDeveloper = (event, developer) => {
    if (!selectedDevelopers.includes(developer.id)) {
      setSelectedDevelopers((prevSelected) => [...prevSelected, developer.id]);
      setSelectedProjectDeveloper((prevSelected) => [...prevSelected, developer]);
    } else {
      setSelectedDevelopers((prevSelected) => prevSelected.filter((id) => id !== developer.id));
      setSelectedProjectDeveloper((prevSelected) => prevSelected.filter((dev) => dev.id !== developer.id));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectedDevelopers.length > 0;
  const selectedSomeDevelopers = selectedDevelopers.length > 0 && selectedDevelopers.length < currentDevelopers.length;
  const selectedAllDevelopers = selectedDevelopers.length === currentDevelopers.length;
  const account = useSelector((state) => state.account);
  const deleteDevelopers = async () => {
    if (account.user.role !== SUPER_ADMIN) {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectedDevelopers.length; a += 1) {
        Request.deleteDeveloper(selectedDevelopers[a])
          .then((r) => {
            if (r.success) {
              enqueueSnackbar('Developers eliminados/as!', {
                variant: 'success',
              });
              setCurrentDevelopers(currentDevelopers.filter((e) => !selectedDevelopers.includes(e.id)));
            } else if (!r.success) {
              enqueueSnackbar('Debe eliminar asignación antes de eliminar developer!', {
                variant: 'error',
              });
            }
          });
      }
      setSelectedDevelopers([]);
      setSelectedProjectDeveloper([]);
    }
  };

  const handleRedirectToGraphicDeveloper = (developerId) => {
    dispatch(developerGraphicActions.setDeveloperGraphic({ developerId }));
    history.push('/administracion/developers/grafico');
  };

  return (
    <Card
      className={clsx(classes.emptyRoot, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Buscar developer"
          value={searchValue}
          variant="outlined"
        />
        <Box flexGrow={1} />
        <TextField
          label="Orden"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllDevelopers}
              indeterminate={selectedSomeDevelopers}
              onChange={handleSelectAllDevelopers}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteDevelopers()}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllDevelopers}
                    indeterminate={selectedSomeDevelopers}
                    onChange={handleSelectAllDevelopers}
                  />
                </TableCell>
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell>
                  Cargo
                </TableCell>
                <TableCell>
                  Pais
                </TableCell>
                <TableCell>
                  Costo HH
                </TableCell>
                <TableCell align="right">
                  Gráfico
                </TableCell>
                <TableCell align="right">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentDevelopers.map((developer) => {
                const isDeveloperSelected = selectedDevelopers.includes(developer.id);
                return (
                  <TableRow
                    hover
                    key={developer.id}
                    selected={isDeveloperSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isDeveloperSelected}
                        onChange={(event) => handleSelectOneDeveloper(event, developer)}
                        value={isDeveloperSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={developer.avatar}
                        >
                          {getInitials(developer.user.name)}
                        </Avatar>
                        <div>
                          <Typography
                            color="inherit"
                            variant="h6"
                          >
                            {developer.user.name} {' '} {developer.user.lastname}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {developer.user.email}
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {developer.jobTitle}
                    </TableCell>
                    <TableCell>
                      {developer.user.country}
                    </TableCell>
                    <TableCell>
                      {developer.costPerHour}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleRedirectToGraphicDeveloper(developer.id)}
                      >
                        <SvgIcon fontSize="medium">
                          <EqualizerIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/administracion/developers/${developer.id}/editar`}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalDevelopers}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  developers: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  totalDevelopers: PropTypes.number,
};

Results.defaultProps = {
  developers: [],
};

export default Results;
