import produce from 'immer';
import assignationActions from '../actions/assignationActions';

const initialState = {
  assignation: {},
};

const assignationReducer = (state = initialState, action) => {
  switch (action.type) {
    case assignationActions.SET_ASSIGNATION: {
      const { assignation } = action.payload;

      return produce(state, (draft) => {
        draft.assignation = assignation;
      });
    }

    default: {
      return state;
    }
  }
};

export const resetState = () => initialState;

export default assignationReducer;
