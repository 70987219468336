import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

function InfoCard({ totalHours, card, totalBillableHours }) {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [horas, setHoras] = useState('');
  const [percentage, setPercentage] = useState('');

  useEffect(() => {
    if (totalBillableHours > 0) {
      if (card === 0) {
        setTitle('Total asignado');
      }
      if (card === 1) {
        setTitle('Total proyectado');
      }
      if (card === 2) {
        setTitle('Total realizado');
      }
      setHoras(`${totalHours.toFixed(0)} horas`);
      setPercentage(
        `${((totalBillableHours / totalHours) * 100).toFixed(0) || 0}% horas facturables`,
      );
    }
  }, [totalBillableHours]);

  return (
    <div>
      <Card className={clsx(classes.infoCard)}>
        <Typography className={classes.infoCardtitle}>{title}</Typography>
        <Typography
          className={clsx(classes.infoCardNumber, {
            [classes.blue]: card === 0,
            [classes.grey]: card === 1,
            [classes.green]: card === 2,
          })}
        >{`${horas}`}</Typography>
        <Typography
          className={clsx(classes.infoCardBottomText, {
            [classes.blue]: card === 0,
            [classes.grey]: card === 1,
            [classes.green]: card === 2,
          })}
        >{`${percentage}`}</Typography>
      </Card>
    </div>
  );
}

InfoCard.propTypes = {
  card: PropTypes.number.isRequired,
  totalHours: PropTypes.number.isRequired,
  totalBillableHours: PropTypes.number.isRequired,
};

export default InfoCard;
