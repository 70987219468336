import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import ProjectEditForm from './ProjectEditForm';
import ProjectDimensionEditForm from './ProjectDimensionEditForm';
import Header from './Header';
import projectRequest from '../../../requests/api/project';
import categoryRequest from '../../../requests/api/category';
import useStyles from './styles';

function ProjectEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [project, setProject] = useState();
  const [projectDimensions, setProjectDimensions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesDimensions, setCategoriesDimensions] = useState([]);
  const [pageOfTable] = useState(0);
  const [loading, setLoading] = useState(true);
  const [limit] = useState(100);

  useEffect(() => {
    const getProjectAndCategoriesDimensions = async () => {
      const res = await projectRequest.getProyectById(props.match.params.id);
      const response = res.data;
      let dimensions = [];
      const newArray = [];
      if (isMountedRef.current) {
        setProject(response.data.project);
        dimensions = response.data.project.dimensions;
      }
      const categoriesResponse = await categoryRequest.getCategoriesDimensions();
      if (categoriesResponse.success) {
        const data = await categoriesResponse.data.data.categories_dimensions;
        const arrayData = Object.values(data);
        setCategoriesDimensions(arrayData);
        for (let i = 0; i < arrayData.length; i += 1) {
          if (dimensions.find((e) => e.id === arrayData[i].dimension_id)) {
            newArray.push(arrayData[i]);
          }
        }
        setProjectDimensions(newArray);
        setLoading(false);
      }
    };

    getProjectAndCategoriesDimensions();
    const getCategories = async () => {
      const response = await categoryRequest.getCategoriesPerPage({
        page: pageOfTable,
        limit,
        searchValue: '',
      });
      if (response.success) {
        const data = await response.data.data.categories;
        const arrayData = Object.values(data);
        setCategories(arrayData);
      }
    };
    getCategories();
  }, [props]);

  if (!project) {
    return null;
  }

  return (
    <Page className={classes.root} title="Editar Proyecto">
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <ProjectEditForm project={project} />
          {!loading ? (
            <ProjectDimensionEditForm
              projectId={props.match.params.id}
              categories={categories}
              categoriesDimensions={categoriesDimensions}
              projectDimensions={projectDimensions}
            />
          ) : null}
        </Box>
      </Container>
    </Page>
  );
}

ProjectEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ProjectEditView);
