import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import ReportModal from './ReportModal';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  customAutocomplete: {
    minWidth: 'fit-content', // Set the minWidth for the custom Autocomplete
  },
}));

function Header({
  className, cycles, selectedCycle, setSelectedCycle, instances,
  selectedInstance, setSelectedInstance, user, setReload, ...rest
}) {
  const classes = useStyles();
  const [openReportModal, setOpenReportModal] = useState(false);

  const handleOpenReportModal = () => setOpenReportModal(true);

  const handleOpenReport = () => {
    if (selectedInstance.cycleReportUrl) window.open(`${selectedInstance.cycleReportUrl}`);
  };

  return (
    <Box>
      <Grid
        container
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Grid item>
          <Typography
            variant='h3'
            color='textPrimary'
          >
            Objetivos por developer
          </Typography>
        </Grid>
        <Grid item md={8} lg={7}>
          <Grid
            container
            className={clsx(classes.root, className)}
            spacing={1}
            justifyContent="space-between"
          >
            {user.role.name === SUPER_ADMIN && (
              <Grid item md={3} lg={4}>
                <Button
                  color='secondary'
                  fullWidth
                  onClick={() => handleOpenReportModal()}
                  variant='contained'
                >
                  {selectedInstance.cycleReportUrl ? 'Actualizar reporte' : 'Adjuntar reporte'}
                </Button>
              </Grid>
            )}
            <Grid item md={2} lg={3}>
              <Button
                fullWidth
                color='secondary'
                onClick={() => handleOpenReport()}
                disabled={!selectedInstance.cycleReportUrl}
                variant='contained'
              >
                Ver reporte
              </Button>
            </Grid>
            <Grid item md={3} lg={2}>
              <Autocomplete
                freeSolo
                disablePortal
                fullWidth
                id='combo-box-demo'
                options={cycles}
                value={selectedCycle}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  if (value === null) return;
                  setSelectedCycle(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant='outlined'
                    label='Ciclo'
                  />
                )} />
            </Grid>
            <Grid item md={4} lg={3}>
              {user.role.name === SUPER_ADMIN && (
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disablePortal
                  fullWidth
                  freeSolo
                  id='combo-box-demo'
                  options={instances}
                  value={selectedInstance}
                  getOptionLabel={(option) => `${option.collaborator.user.name} ${option.collaborator.user.lastname}`}
                  onChange={(e, value) => {
                    if (value === null) return;
                    setSelectedInstance(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Developer'
                    />
                  )} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ReportModal
        instance={selectedInstance}
        openReportModal={openReportModal}
        setOpenReportModal={setOpenReportModal}
        setReload={setReload}
      />
    </Box >
  );
}

Header.propTypes = {
  className: PropTypes.string,
  cycles: PropTypes.array,
  selectedCycle: PropTypes.object,
  setSelectedCycle: PropTypes.func,
  instances: PropTypes.array,
  selectedInstance: PropTypes.object,
  setSelectedInstance: PropTypes.func,
  user: PropTypes.object,
  setReload: PropTypes.func,
};

export default Header;
