import moment from 'moment';

const formatDateTime = (time) => {
  const opciones = {
    year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit',
  };
  const formatedDate = new Date(time)
    .toLocaleDateString('es', opciones);
  return formatedDate;
};

const formatDate = (time) => {
  const opciones = {
    year: 'numeric', month: '2-digit', day: '2-digit',
  };
  const formatedDate = new Date(`'${time}'`)
    .toLocaleDateString('es', opciones);
  return formatedDate;
};

const firstDayOfCurrentMonth = (date) => {
  const newDate = new Date(date);
  const month = newDate.getMonth();
  const year = newDate.getFullYear();
  const firstDay = new Date(year, month, 2);
  return firstDay.toISOString();
};

const firstDayOfNextMonth = (date) => {
  const newDate = new Date(date);
  const month = newDate.getMonth();
  const year = newDate.getFullYear();
  const firstDay = new Date(year, month + 1, 2);
  return firstDay.toISOString();
};

const lastMonth = (date) => {
  const dateLastMonth = new Date(date);
  dateLastMonth.setMonth(dateLastMonth.getMonth() - 1);
  return dateLastMonth.toISOString();
};

const getNextMonth = (date) => {
  const dateNextMonth = new Date(date);
  dateNextMonth.setMonth(dateNextMonth.getMonth() + 1);
  return dateNextMonth.toISOString();
};

const formatMonthAndYearMoment = (date) => {
  const formatedDate = moment(date).format('MM-YYYY');
  return formatedDate;
};

const formatDayMonthAndYearMoment = (date) => {
  const formatedDate = moment(date).format('DD-MM-YYYY');
  return formatedDate;
};

const compareDateMonths = (date1, date2) => {
  const stringDate1 = moment(date1).format('yyyy-MM');
  const stringDate2 = moment(date2).format('yyyy-MM');

  return stringDate1 === stringDate2;
};

export {
  formatDateTime,
  formatDate,
  firstDayOfCurrentMonth,
  lastMonth,
  getNextMonth,
  formatMonthAndYearMoment,
  formatDayMonthAndYearMoment,
  firstDayOfNextMonth,
  compareDateMonths,
};
