import { INTERNAL_SERVER_ERROR } from '../../constants/http';
import axios from '../../utils/axios';

const getBlogs = async ({ page, limit, searchValue }) => {
  try {
    const { data } = await axios.get(
      `/api/blog?page=${page}&limit=${limit}&searchValue=${searchValue}`,
    );
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getBlog = async ({ id }) => {
  try {
    const { data } = await axios.get(
      `/api/blog/${id}`,
    );
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postBlogCreate = async ({ blog }) => {
  try {
    const { data } = await axios.post(
      '/api/blog/create',
      blog,
    );
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putBlogEdit = async ({ id, blog }) => {
  try {
    const { data } = await axios.put(
      `/api/blog/${id}`,
      blog,
    );
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postBlogPublish = async ({ blogs }) => {
  try {
    const { data } = await axios.post(
      '/api/blog/publish',
      blogs,
    );
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  getBlogs,
  getBlog,
  postBlogCreate,
  putBlogEdit,
  postBlogPublish,
};
