import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import Request from '../../../requests/api/performance_cycles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function PerformanceCyclesListView() {
  const classes = useStyles();
  const [cycles, setCycles] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCycles, setTotalCycles] = useState(0);

  useEffect(() => {
    const getCycles = async () => {
      const response = await Request.getPerformanceCyclesPerPage(pageOfTable, limit);
      if (response.success) {
        const data = await response.data.data.performanceCycles;
        setCycles(data);
        setTotalCycles(response.data.data.totalPerformanceCycles);
      }
    };
    getCycles();
  }, [pageOfTable, limit]);

  return (
    <Page
      className={classes.root}
      title="TubeOps | Ciclos de performance"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results
            cycles={cycles}
            pageOfTable={pageOfTable}
            setPageOfTable={setPageOfTable}
            limit={limit}
            setLimit={setLimit}
            totalCycles={totalCycles}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default PerformanceCyclesListView;
