import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  Edit as EditIcon,
  Search as SearchIcon,
  Eye as EyeIcon,
  PlusCircle as PlusCircleIcon,
} from 'react-feather';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Rating, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import EvaluationModal from './EditEvaluationModal';
import objectiveRequest from '../../../requests/api/objectives';
import instanceActions from '../../../actions/instanceActions';

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
}));

const objectiveTypesOptions = [
  {
    value: 'Generación de valor',
    label: 'Generación de valor',
  },
  {
    value: 'Desarrollo profesional',
    label: 'Desarrollo profesional',
  },
  {
    value: 'Todos',
    label: 'Todos',
  },
];
function Objectives({
  className,
  objectives,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  searchValue,
  setSearchValue,
  totalObjectives,
  performanceCycleInstanceId,
  allObjectives,
  setReload,
  setObjectivesSort,
  selectedInstance,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((s) => s.account.user);

  const [sort, setSort] = useState(objectiveTypesOptions[0].value);
  const [currentObjectives, setCurrentObjectives] = useState(objectives);
  const [evaluations, setEvaluations] = useState({});
  const [selectedObjective, setSelectedObjective] = useState({});
  const [openEvaluationModal, setOpenEvaluationModal] = useState(false);

  useEffect(() => {
    if (sort !== 'Todos') setObjectivesSort(sort);
    else setObjectivesSort('');
  }, [sort]);

  useEffect(() => {
    objectives.map((obj, i) => setEvaluations((prev) => ({ ...prev, [i]: obj.evaluation })));
    setCurrentObjectives(objectives);
  }, [objectives]);

  const handleQueryChange = (event) => {
    event.persist();
    setSearchValue(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleOpenEvaluationModal = (objective, index, evaluation) => {
    setSelectedObjective({ ...objective, evaluation, index });
    setEvaluations((prev) => ({ ...prev, [index]: evaluation }));
    setOpenEvaluationModal(true);
  };

  const handleApproveObjective = async (objective) => {
    const response = await objectiveRequest.putObjectiveById({
      objectiveId: objective.id,
      expectedResults: objective.expectedResults,
      monthlyLoad: objective.monthlyLoad,
      bonusPercentage: objective.bonusPercentage,
      active: objective.active,
      evaluation: objective.evaluation,
      approved: !objective.approved,
    });
    if (response.success) {
      dispatch(instanceActions.setInstance(response.data.data.updatedInstance));
      enqueueSnackbar('Cambios guardados', {
        variant: 'success',
      });
      setReload((prev) => !prev);
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        justifyContent='space-between'
        alignItems="center"
      >
        <Box>
          {!allObjectives && user.role.name === SUPER_ADMIN
            && <Button
              color="secondary"
              variant="contained"
              className={classes.action}
              onClick={() => {
                dispatch(instanceActions.setInstance(selectedInstance));
                history.push(`/administracion/performance-cycle-instances/${performanceCycleInstanceId}/objectives/crear`);
              }}
            >
              <SvgIcon fontSize="small" className={classes.actionIcon}>
                <PlusCircleIcon />
              </SvgIcon>
              Agregar objetivo
            </Button>}
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          width={allObjectives ? '100%' : '40%'}
        >
          <Box />
          <Box
            display='flex'
            justifyContent='space-between'
            width={allObjectives ? '40%' : '100%'}
          >
            <Box>
              {allObjectives && (
                <TextField
                  label={'Tipo de objetivo'}
                  name="sort"
                  onChange={handleSortChange}
                  select
                  SelectProps={{ native: true }}
                  value={sort}
                  variant="outlined"
                >
                  {objectiveTypesOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              )}
            </Box>
            <Box>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryChange}
                placeholder="Buscar objetivo"
                value={searchValue}
                variant="outlined"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <PerfectScrollbar>
        <Box p={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre developer</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Carga mensual</TableCell>
                <TableCell>Porcentaje bono</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Evaluación</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentObjectives.map((objective, index) => (
                <TableRow hover key={objective.id}>
                  <TableCell>{`${objective.performance_cycle_instance.collaborator.user.name} ${objective.performance_cycle_instance.collaborator.user.lastname}`}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography color="inherit" variant="h6">
                        {objective.type}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography color="inherit" variant="h6">
                        {objective.description}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography color="inherit" variant="h6">
                        {objective.monthlyLoad}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography color="inherit" variant="h6">
                        {objective.bonusPercentage}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Tooltip title={user.role.name === SUPER_ADMIN
                        ? `${objective.approved ? 'Desaprobar' : 'Aprobar'} objetivo`
                        : `Objetivo ${objective.approved ? 'aprobado' : 'desaprobado'}`}>
                        <Typography color="inherit" variant="h6">
                          <IconButton
                            onClick={() => handleApproveObjective(objective)}
                            disabled={user.role.name !== SUPER_ADMIN}
                          >
                            <SvgIcon>
                              {<CheckCircleIcon sx={{ color: objective.approved && '#00D293' }} />}
                            </SvgIcon>
                          </IconButton>
                        </Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography color="inherit" variant="h6">
                        <Rating
                          name="evaluation"
                          value={evaluations[index]}
                          max={4}
                          emptyIcon={<StarBorderIcon fontSize="inherit" />}
                          icon={<StarIcon fontSize="inherit" style={{ color: '#00D293' }} />}
                          readOnly={user.role.name !== SUPER_ADMIN}
                          onChange={(e, newValue) => {
                            handleOpenEvaluationModal(objective, index, newValue);
                          }}
                        />
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {user.role.name === SUPER_ADMIN ? (
                      <IconButton
                        onClick={() => {
                          dispatch(instanceActions.setInstance(selectedInstance));
                          history.push(`/administracion/performance-cycle-instances/${objective.performanceCycleInstanceId}/objectives/${objective.id}/editar`);
                        }}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>) : null}
                    <IconButton
                      onClick={() => {
                        dispatch(instanceActions.setInstance(selectedInstance));
                        history.push(`/administracion/performance-cycle-instances/${objective.performanceCycleInstanceId}/objectives/${objective.id}`);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <EyeIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalObjectives}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
      <EvaluationModal
        openEvaluationModal={openEvaluationModal}
        setOpenEvaluationModal={setOpenEvaluationModal}
        objective={selectedObjective}
        setEvaluations={setEvaluations}
        setReload={setReload}
        user={user}
        selectedInstance={selectedInstance}
      />
    </Card>
  );
}

Objectives.propTypes = {
  className: PropTypes.string,
  objectives: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  totalObjectives: PropTypes.number,
  performanceCycleInstanceId: PropTypes.number,
  allObjectives: PropTypes.bool,
  setReload: PropTypes.func,
  setObjectivesSort: PropTypes.func,
  selectedInstance: PropTypes.object,
};

Objectives.defaultProps = {
  objectives: [],
};

export default Objectives;
