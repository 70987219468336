import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 8,
  },
  title: {
    paddingRight: '15px',
  },
  tooltip: {
    backgroundColor: 'white',
    '& .MuiTooltip-arrow': {
      color: '#006E90',
    },
    color: '#006E90',
    border: '1px solid',
    borderColor: '#006E90',
    fontSize: '17px',
  },
}));

function Evaluations({ evaluationAvgs, avgEvaluation }) {
  const classes = useStyles();

  return (
    <Box mt={2}>
      <Card className={classes.root}>
        <Box
          display='flex'
          // flexWrap={'wrap'}
          height={130}
        >
          {evaluationAvgs.map((e, i) => (
            <React.Fragment key={i}>
              <Box
                key={i}
                width='25%'
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
              >
                <Typography variant='h6'>
                  {e.name}
                </Typography>
                <Typography variant='body2'>
                  {e.roles.join(' & ')}
                </Typography>
                <Box
                  display='flex'
                  alignItems='end'
                  justifyContent='space-between'
                >
                  <Typography variant='h2'>
                    {e.evaluationAverage.toFixed(1)}
                  </Typography>
                  <Typography variant='h4'>
                    /4
                  </Typography>
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem />
            </React.Fragment>
          ))}
          <Box
            key={-1}
            width='25%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='h6'>
              Nota final
            </Typography>
            <Box
              display='flex'
              alignItems='end'
              justifyContent='space-between'
              style={{ color: '#00D293' }}
            >
              <Typography variant='h2'>
                {avgEvaluation.toFixed(2)}
              </Typography>
              <Typography variant='h4'>
                /4.0
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

Evaluations.propTypes = {
  evaluationAvgs: PropTypes.array.isRequired,
  avgEvaluation: PropTypes.number.isRequired,
};

export default Evaluations;
