import React from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Modal,
  Typography,
} from '@material-ui/core';
import vacationRequest from '../../../requests/api/vacationRequest';
import { THEMES } from '../../../constants';
import { formatDate } from '../../../utils/date';
import { CANCELLED } from '../../../utils/const/vacationRequestStatus';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    ...theme.name === THEMES.LIGHT ? {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:disabled': {
        color: 'white',
        backgroundColor: '#9e9e9e',
      },
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    } : {},
    height: 60,
  },
  modal: {
    maxWidth: '600px',
    marginTop: '8%',
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

export default function CancelVacationsModal({ openCancelModal, setOpenCancelModal, request }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleCancelVacation = async () => {
    try {
      const emailStartDate = request.startDate.split('-').reverse().join('/');
      const emailEndDate = request.endDate.split('-').reverse().join('/');
      const response = await vacationRequest.putVacationRequestById({
        id: request.id,
        developerId: request.developerId,
        developerEmail: `${request.developer.user.email}`,
        developerName: `${request.developer.user.name} ${request.developer.user.lastname}`,
        supervisorId: request.supervisorId,
        status: CANCELLED,
        daysRequested: request.daysRequested,
        startDate: request.startDate,
        endDate: request.endDate,
        emailStartDate,
        emailEndDate,
      });
      if (response.success) {
        enqueueSnackbar('Vacaciones canceladas', { variant: 'success' });
        window.location.reload();
      }
    } catch (err) {
      enqueueSnackbar(err, { variant: 'error' });
    }
  };

  return (
    <Box
      mt={2}
      display='flex'
      flexDirection='column'
      gridGap={10}
    >
      {openCancelModal && (
        <Modal
          open={openCancelModal}
          onClose={() => setOpenCancelModal(false)}
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='75%'
          >
            <Card className={classes.modal}>
              <CardContent className={classes.modalContent}>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    flex={1}
                  >
                    <Box
                      display='flex'
                      mb={2}
                    >
                      <Typography
                        variant='h3'
                      >
                        Cancelar vacaciones
                      </Typography>
                    </Box>
                    <Divider />
                    <Box m='auto'>
                      <Box m='auto' width='70%'>
                        <Typography
                          variant='h4'
                        >
                          ¿Estás seguro de que quieres cancelar las vacaciones del
                          <span style={{ fontWeight: 'bold' }}>{` ${formatDate(request.startDate)} hasta el ${formatDate(request.endDate)}`}</span> que solicitó
                          <span style={{ fontWeight: 'bold' }}>{` ${request.developer.user.name} ${request.developer.user.lastname}?`}</span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display='flex'
                  padding='10px 24px'
                >
                  <Box width={'49%'} mr={2}>
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={() => setOpenCancelModal(false)}
                      variant='contained'
                    >
                      Volver
                    </Button>
                  </Box>
                  <Box width={'49%'}>
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={handleCancelVacation}
                      variant='contained'
                    >
                      Confirmar
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Modal>
      )}
    </Box>
  );
}

CancelVacationsModal.propTypes = {
  request: PropTypes.object,
  openCancelModal: PropTypes.boolean,
  setOpenCancelModal: PropTypes.func,
};
