import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import {
  Button,
  Grid,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import {
  PlusCircle as PlusCircleIcon,
} from 'react-feather';
import useStyles from './styles';
import { DEVELOPER } from '../../../utils/const/user_types';

function Header({ className, ...rest }) {
  const classes = useStyles();
  const user = useSelector((state) => state.account.user);

  return (
    <Grid
      className={clsx(classes.emptyRoot, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Ciclos de performance
        </Typography>
      </Grid>
      {user.role?.name !== DEVELOPER
        ? (<Grid item>
          <Button
            color="secondary"
            variant="contained"
            className={classes.action}
            href="/administracion/performance-cycles/crear"
          >
            <SvgIcon
              fontSize="small"
              className={classes.actionIcon}
            >
              <PlusCircleIcon />
            </SvgIcon>
            Agregar ciclo de performance
          </Button>
        </Grid>) : null}
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
