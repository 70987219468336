import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Request from '../../../requests/api/blog';
import EditForm from './EditForm';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function EditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [item, setItem] = useState();

  useEffect(() => {
    const getItem = async () => {
      const response = await Request.getBlog({ id: props.match.params.id });
      if (isMountedRef.current) {
        setItem(response.data.data);
      }
    };

    getItem();
  }, []);

  if (!item) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Editar blog"
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
        <EditForm item={item} />
        </Box>
      </Container>
    </Page>
  );
}

EditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(EditView);
