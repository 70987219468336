/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import projectCellRequest from '../../../requests/api/project_cell';
import useStyles from './styles';

const getSearchCache = () => {
  const searchProject = '';
  const searchClient = '';
  const searchStartMonth = '';
  const searchEndMonth = '';

  return {
    searchProject,
    searchClient,
    searchStartMonth,
    searchEndMonth,
  };
};

function ProjectCellsListView() {
  const classes = useStyles();
  const [projectCells, setProjectCells] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const {
    searchProject, searchClient, searchStartMonth, searchEndMonth,
  } = getSearchCache();
  const [totalProjectCells, setTotalProjectCells] = useState(0);
  const [filters, setFilters] = useState({
    project: searchProject,
    client: searchClient,
    startMonth: searchStartMonth,
    endMonth: searchEndMonth,
  });

  useEffect(() => {
    const getProjectCells = async () => {
      const response = await projectCellRequest.getProjectCellsPerPage({
        pageOfTable,
        limit,
        searchProject: filters.project,
        searchClient: filters.client,
        searchStartMonth: filters.startMonth,
        searchEndMonth: filters.endMonth,

      });

      if (response.success) {
        const data = await response.data.data.projectCells;
        const arrayData = Object.values(data);
        setProjectCells(arrayData);
        setTotalProjectCells(response.data.data.totalProjectCells);
      }
    };
    getProjectCells();
  }, [pageOfTable, limit, filters]);

  return (
    <Page className={classes.root} title="TubeOps | Células">
      <Container maxWidth={false}>
        <Header />
        {projectCells && (
          <Box mt={3}>
            <Results
              projectCells={projectCells}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              totalProjectCells={totalProjectCells}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default ProjectCellsListView;
