/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsCustomEvents from 'highcharts-custom-events';
import highchartsMore from 'highcharts/highcharts-more';

HighchartsCustomEvents(Highcharts);
highchartsMore(Highcharts);

function DataGraphic({
  categories, data, title, graphic, setProjectIndex, setDeveloperIndex,
}) {
  const onClickEvent = (index) => {
    if (graphic) {
      setProjectIndex(index);
    } else {
      setDeveloperIndex(index);
    }
  };
  const options = {
    chart: {
      type: 'bar',
      zoomType: 'x',
    },
    title: {
      text: title,
    },
    xAxis: {
      categories,
      labels: {
        events: {
          click() {
            onClickEvent(this.chart.series[0].points[this.pos].index);
          },
        },
        style: {
          cursor: 'pointer',
        },
      },
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: 'Horas',
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        fontSize: '12px',
      },
    },
    series: data,
  };

  return (
  <HighchartsReact
    containerProps={{ style: { height: '1000px', width: '100%' } }}
    highcharts={Highcharts}
    options={options}
  />
  );
}

DataGraphic.propTypes = {
  data: PropTypes.array,
  categories: PropTypes.array,
  title: PropTypes.string,
  graphic: PropTypes.number,
  setProjectIndex: PropTypes.func,
  setDeveloperIndex: PropTypes.func,
};

export default DataGraphic;
