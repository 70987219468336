/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Grid,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Edit as EditIcon, Search as SearchIcon } from 'react-feather';
import { useSnackbar } from 'notistack';
import projectRoleRequest from '../../../requests/api/project_role';
import useStyles from './styles';

function Results({
  className,
  projectRoles,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  filters,
  setFilters,
  totalProjectRoles,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectProjectRole, setSelectProjectRole] = useState([]);
  const [currentProjectRole, setCurrentProjectRole] = useState(projectRoles);
  useEffect(() => {
    setCurrentProjectRole(projectRoles);
  }, [projectRoles]);

  const handleFiltersChange = (name, event) => {
    event.persist();
    let value = null;
    value = event.target.value;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectAllProjectRole = (event) => {
    setSelectProjectRole(
      event.target.checked ? currentProjectRole.map((e) => e.id) : [],
    );
  };

  const handleSelectOneProjectRole = (event, projectRoleId) => {
    if (!selectProjectRole.includes(projectRoleId)) {
      setSelectProjectRole((prevSelected) => [...prevSelected, projectRoleId]);
    } else {
      setSelectProjectRole((prevSelected) => prevSelected.filter((id) => id !== projectRoleId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectProjectRole.length > 0;
  const selectSomeProjectRoles = selectProjectRole.length > 0
    && selectProjectRole.length < currentProjectRole.length;
  const selectedAllProjectRoles = selectProjectRole.length === currentProjectRole.length;
  const account = useSelector((state) => state.account);

  const deleteProjectRoles = async () => {
    if (account.user.role.name.toLowerCase() !== 'superadmin') {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectProjectRole.length; a += 1) {
        projectRoleRequest.deleteProjectRole(selectProjectRole[a]);
        enqueueSnackbar('Roles de proyecto eliminados!', {
          variant: 'success',
        });
      }
      setCurrentProjectRole(
        currentProjectRole.filter((e) => !selectProjectRole.includes(e.id)),
      );
      setSelectProjectRole([]);
    }
  };

  return (
    <Card className={clsx(classes.cardMargin, className)} {...rest}>
      <Divider />
      <Grid
        container
        spacing={3}
        className={classes.filtersContainer}
        display="flex"
        alignItems="center"
        flexwrap="wrap"
      >
        <Grid item md={4} xs={12}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleFiltersChange('role', e)}
            placeholder="Buscar Rol"
            value={filters.role}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>

      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllProjectRoles}
              indeterminate={selectSomeProjectRoles}
              onChange={handleSelectAllProjectRole}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteProjectRoles()}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllProjectRoles}
                    indeterminate={selectSomeProjectRoles}
                    onChange={handleSelectAllProjectRole}
                  />
                </TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Nivel Minimo</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProjectRole.length
                ? currentProjectRole.map((element) => {
                  const isProjectRoleSelected = selectProjectRole.includes(
                    element.id,
                  );
                  return (
                      <TableRow
                        hover
                        key={element.id}
                        selected={isProjectRoleSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isProjectRoleSelected}
                            onChange={(event) => handleSelectOneProjectRole(event, element.id)
                            }
                            value={isProjectRoleSelected}
                          />
                        </TableCell>

                        <TableCell>{element.name}</TableCell>
                        <TableCell>{element.minimumLevel}</TableCell>

                        <TableCell align="right">
                          <IconButton
                            component={RouterLink}
                            to={`/administracion/proyecto-roles/${element.id}/editar`}
                          >
                            <SvgIcon fontSize="small">
                              <EditIcon />
                            </SvgIcon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                  );
                })
                : null}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalProjectRoles}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  projectRoles: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  totalProjectRoles: PropTypes.number,
};

Results.defaultProps = {};

export default Results;
