import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import Request from '../../../requests/api/blog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function ListView() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getList = async () => {
      const response = await Request.getBlogs({ page: pageOfTable, limit, searchValue });
      if (response.success) {
        const data = response.data.data.rows;
        setRows(data);
        setCount(response.data.data.count);
      }
    };
    getList();
  }, [pageOfTable, limit, searchValue]);

  return (
    <Page
      className={classes.root}
      title="TubeOps | Blogs"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results
            rows={rows}
            pageOfTable={pageOfTable}
            setPageOfTable={setPageOfTable}
            limit={limit}
            setLimit={setLimit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            count={count}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default ListView;
