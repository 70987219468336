import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import availabilityRequest from '../../../requests/api/availability';
import assignationRequest from '../../../requests/api/assignation';
import useStyles from './styles';
import Header from './Header';
import AvailabilitiesList from './AvailabilitiesList';
import DefaultAvailability from './DefaultAvailabilityForm';
import devRequest from '../../../requests/api/developer';
import DataGraphic from './Graphic';

function AvailabilitiesManagementView() {
  const classes = useStyles();
  const user = useSelector((s) => s.account.user);

  const [months, setMonths] = useState([]);
  const [graphicData, setGraphicData] = useState([]);
  const [availabilitiesForGraphic, setAvailabilitiesForGraphic] = useState([]);

  const [futureAvailabilities, setFutureAvailabilities] = useState([]);
  const [developer, setDeveloper] = useState({});

  const [reloadAvailabilities, setReloadAvailabilities] = useState(false);

  if (reloadAvailabilities) window.location.reload();

  useEffect(() => {
    const getAvailabilities = async () => {
      const response = await availabilityRequest.get12AvailabilitiesPerDev({
        developerId: user.developer.id,
        onlyFuture: false,
      });

      if (response.success) {
        const availabilityMonths = [];
        const availabilitySeries = response.data.data.availabilities.reverse()
          .map(({
            month, minHours, maxHours, vacationHours,
          }) => {
            const min = minHours - vacationHours;
            const max = maxHours - vacationHours;
            availabilityMonths.push(new Date(month).toLocaleString('default', { month: 'long' }));
            return {
              name: new Date(month).toLocaleString('default', { month: 'long' }),
              data: [min, max],
            };
          });
        setMonths(availabilityMonths);
        setAvailabilitiesForGraphic([{
          name: 'Disponibilidad máxima',
          color: '#BDBDBD',
          data: availabilitySeries.map(({ data }) => data[1] - data[0]),
          stack: 'stack1',
        },
        {
          name: 'Disponibilidad mínima',
          color: '#24335E',
          data: availabilitySeries.map(({ data }) => data[0]),
          stack: 'stack1',
        }]);
        const today = moment().utc();
        setFutureAvailabilities(response.data.data.availabilities
          .filter((el) => today.isBefore(moment(el.month), 'month')));
      }
    };

    const getDeveloperById = async () => {
      const response = await devRequest.getDeveloperById(user.developer.id);
      if (response.success) setDeveloper(response.data.data.developer);
    };
    if (user.developer.id) {
      getAvailabilities();
      getDeveloperById();
    }
  }, [user]);

  useEffect(() => {
    const getAssignations = async () => {
      const response = await assignationRequest.get12AssignationsByDev({
        developerId: user.developer.id,
      });
      if (response.success) {
        const assignationHours = response.data.data.assignations.reverse().map((a) => {
          const hours = a.project_developers.reduce((acc, curr) => {
            acc.assignedHours += curr.assignedHours;
            acc.realHours += curr.realHours;
            acc.month = new Date(a.month).toLocaleString('default', { month: 'long' });
            return acc;
          }, { assignedHours: 0, realHours: 0 });
          return hours;
        });
        const assignationSeries = assignationHours.map(({ month, assignedHours, realHours }) => ({
          name: month,
          data: [assignedHours, realHours],
        }));

        setGraphicData([
          ...availabilitiesForGraphic,
          {
            name: 'Asignación',
            color: '#5F696B',
            data: assignationSeries.map(({ data }) => data[0]),
            stack: 'stack2',
          },
          {
            name: 'Real',
            color: '#00D293',
            data: assignationSeries.map(({ data }) => data[1]),
            stack: 'stack3',
          },
        ]);
      }
    };
    getAssignations();
  }, [availabilitiesForGraphic]);

  return (
    <Page className={classes.root} title="TubeOps | Disponibilidad">
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <DefaultAvailability
            user={user}
            developer={developer}
            setReloadAvailabilities={setReloadAvailabilities}
          />
        </Box>
        <Box mt={3}>
          <DataGraphic
            categories={months}
            data={graphicData.length === 4 ? graphicData : []}
          />
        </Box>
        <Box mt={3}>
          <AvailabilitiesList
            availabilities={futureAvailabilities}
            totalAvailabilities={futureAvailabilities.length}
            setReloadAvailabilities={setReloadAvailabilities}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default AvailabilitiesManagementView;
