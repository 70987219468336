const SET_PROJECT_GRAPHIC = '@account/set-project-graphic';
const REFRESH = '@account/refresh';

const setProjectGraphic = (projectGraphic) => (dispatch) => dispatch({
  type: SET_PROJECT_GRAPHIC,
  payload: {
    projectGraphic,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setProjectGraphic,
  SET_PROJECT_GRAPHIC,
  refresh,
  REFRESH,
};
