import axios from '../../utils/axios';
import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';

const getProjectsPerPage = async ({
  page, limit, searchProject, searchClient, month, status,
}) => {
  try {
    const { data } = await axios.get(
      `/api/projects?page=${page}&limit=${limit}&searchProject=${searchProject}&searchClient=${searchClient}&month=${month}&status=${status}`,
    );

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const errStatus = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status: errStatus,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postCreateProject = async (
  {
    clientId,
    name,
    description,
    estimatedHours,
    fixedBudget,
    status,
    kickOffDate,
    closeDate,
    dimensions,
    notionDatabaseId,
  },
) => {
  try {
    const response = await axios.post('/api/project/create', {
      clientId,
      name,
      description,
      estimatedHours,
      fixedBudget,
      status,
      kickOffDate,
      closeDate,
      dimensions,
      notionDatabaseId,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }
    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const errStatus = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      errStatus,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProyectById = async (id) => {
  try {
    const { data } = await axios.get(`/api/project/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putProjectById = async (
  {
    id,
    clientId,
    name,
    description,
    estimatedHours,
    fixedBudget,
    status,
    kickOffDate,
    closeDate,
    dimensions,
    notionDatabaseId,
  },
) => {
  try {
    const response = await axios.put('/api/project/edit', {
      id,
      clientId,
      name,
      description,
      estimatedHours,
      fixedBudget,
      status,
      kickOffDate,
      closeDate,
      dimensions,
      notionDatabaseId,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const errStatus = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      errStatus,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteProject = async (id) => {
  try {
    const { data } = await axios.delete(`/api/project/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProjectDevelopersPerMonth = async ({ id, month }) => {
  try {
    const { data } = await axios.get(`/api/project-developers/${id}?month=${month}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const updateProjectDimensions = async (
  {
    projectId,
    dimensions,
  },
) => {
  try {
    const response = await axios.put('/api/project-dimensions/edit', {
      projectId,
      dimensions,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const errStatus = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      errStatus,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAllProjectsGraphic = async ({ month }) => {
  try {
    const { data } = await axios.get(`/api/projects/all-projects-graphic?month=${month}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProjectGraphic = async ({ id, month }) => {
  try {
    const { data } = await axios.get(`/api/project/project-graphic/${id}?month=${month}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  getProjectsPerPage,
  postCreateProject,
  getProyectById,
  putProjectById,
  deleteProject,
  getProjectDevelopersPerMonth,
  updateProjectDimensions,
  getProjectGraphic,
  getAllProjectsGraphic,
};
