import produce from 'immer';
import billsFiltersActions from '../actions/billActions';

const initialState = {
  billsFilters: {},
  billsChartFilters: {},
  billsReceivablesFilters: {},
};

const billsReducer = (state = initialState, action) => {
  switch (action.type) {
    case billsFiltersActions.SET_BILLS_FILTERS: {
      const { billsFilters } = action.payload;

      return produce(state, (draft) => {
        draft.billsFilters = billsFilters;
      });
    }

    case billsFiltersActions.SET_BILLS_CHART_FILTERS: {
      const { billsChartFilters } = action.payload;

      return produce(state, (draft) => {
        draft.billsChartFilters = billsChartFilters;
      });
    }

    case billsFiltersActions.SET_BILLS_RECEIVABLES_FILTERS: {
      const { billsReceivablesFilters } = action.payload;

      return produce(state, (draft) => {
        draft.billsReceivablesFilters = billsReceivablesFilters;
      });
    }

    default: {
      return state;
    }
  }
};

export const resetState = () => initialState;

export default billsReducer;
