/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  Edit as EditIcon,
  Eye as EyeIcon,
  EyeOff as EyeOffIcon,
  Search as SearchIcon,
  AlertTriangle as AlertIcon,
  CheckCircle as CheckIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';

import Request from '../../../requests/api/survey';
import useStyles from './styles';
import { formatDateTime } from '../../../utils/date';

const sortOptions = [
  {
    value: 'updated_at|desc',
    label: 'Más nuevo primero',
  },
  {
    value: 'updated_at|asc',
    label: 'Más antiguo primero',
  },
];

function Results({
  className,
  surveys,
  page,
  setPage,
  limit,
  setLimit,
  searchValue,
  setSearchValue,
  sort,
  setSort,
  totalSurveys,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [currentSurveys, setCurrentSurveys] = useState(surveys);
  const [googleForm, setGoogleForm] = useState(null);
  const [responses, setResponses] = useState([]);
  const [pendingResponses, setPendingResponses] = useState([]);

  useEffect(() => { setCurrentSurveys(surveys); }, [surveys]);

  const handleQueryChange = (event) => {
    event.persist();
    setSearchValue(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllSurveys = (event) => {
    setSelectedSurveys(event.target.checked
      ? currentSurveys.map((survey) => survey.id)
      : []);
  };

  const handleSelectOneDeveloper = (event, survey) => {
    if (!selectedSurveys.includes(survey.id)) {
      setSelectedSurveys((prevSelected) => [...prevSelected, survey.id]);
    } else {
      setSelectedSurveys((prevSelected) => prevSelected.filter((id) => id !== survey.id));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const enableBulkOperations = selectedSurveys.length > 0;
  const selectedSomeSurveys = selectedSurveys.length > 0 && selectedSurveys.length < currentSurveys.length;
  const selectedAllSurveys = selectedSurveys.length === currentSurveys.length;
  const account = useSelector((state) => state.account);

  const deleteSelectedSurveys = async () => {
    if (account.user.role.toLowerCase() !== 'superadmin') {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      try {
        await Request.deleteSurveys(selectedSurveys);
        enqueueSnackbar('Encuestas eliminados!', {
          variant: 'success',
        });
        setCurrentSurveys(currentSurveys.filter((e) => (!selectedSurveys.includes(e.id))));
        setSelectedSurveys([]);
      } catch (err) {
        enqueueSnackbar('Error al eliminar las encuestas!', {
          variant: 'error',
        });
      }
    }
  };

  const isOpen = (datetime) => {
    const now = new Date();
    if (now > new Date(datetime)) {
      return <p className={classes.close}>Cerrado</p>;
    }

    return <p className={classes.open}>Abierto</p>;
  };

  const loadResponses = async (formId, hide) => {
    setPendingResponses([]);
    setResponses([]);

    if (hide) {
      setGoogleForm(null);
      return;
    }

    const surveyResponses = await Request.getSurveyResponses(formId);

    setGoogleForm(formId);
    setPendingResponses(surveyResponses.data.pendingUsers);
    setResponses(surveyResponses.data.respondentEmails);
  };

  return (
    <Card
      className={clsx(classes.emptyRoot, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Buscar encuesta"
          value={searchValue}
          variant="outlined"
        />
        <Box flexGrow={1} />
        <TextField
          label="Orden"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllSurveys}
              indeterminate={selectedSomeSurveys}
              onChange={handleSelectAllSurveys}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteSelectedSurveys()}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllSurveys}
                    indeterminate={selectedSomeSurveys}
                    onChange={handleSelectAllSurveys}
                  />
                </TableCell>
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell>
                  Obligatorio
                </TableCell>
                <TableCell>
                  Google Form ID
                </TableCell>
                <TableCell>
                  Link
                </TableCell>
                <TableCell>
                  Fecha de término
                </TableCell>
                <TableCell align="center">
                  Estado
                </TableCell>
                <TableCell align="right">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentSurveys.map((survey) => {
                const isSurveySelected = selectedSurveys.includes(survey.id);
                return (
                  <>
                  <TableRow
                    hover
                    key={survey.id}
                    selected={isSurveySelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSurveySelected}
                        onChange={(event) => handleSelectOneDeveloper(event, survey)}
                        value={isSurveySelected}
                      />
                    </TableCell>
                    <TableCell>
                      {survey.name}
                    </TableCell>
                    <TableCell align='center'>
                      <Checkbox
                        checked={survey.mandatory}
                      />
                    </TableCell>
                    <TableCell>
                      {survey.googleFormId}
                    </TableCell>
                    <TableCell>
                      {survey.link}
                    </TableCell>
                    <TableCell>
                      {formatDateTime(survey.expirationDate)}
                    </TableCell>
                    <TableCell align="center">
                      {isOpen(survey.expirationDate)}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => loadResponses(survey.googleFormId, googleForm === survey.googleFormId)}
                      >
                        <SvgIcon fontSize="small">
                          {googleForm === survey.googleFormId ? <EyeOffIcon /> : <EyeIcon />}
                        </SvgIcon>
                      </IconButton>
                      <IconButton
                        component={RouterLink}
                        to={`/administracion/encuestas/${survey.id}/editar`}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {googleForm === survey.googleFormId && pendingResponses.length > 0 ? pendingResponses.map((pendingResponse) => (
                      <TableRow
                        key={pendingResponse.email}
                        style={{ display: googleForm === survey.googleFormId ? 'block' : 'none' }}
                        className={classes.fullTableRow}
                      >
                        <TableCell className={classes.emailCell} colSpan={8} align="left">
                          <Typography><AlertIcon className={classes.pendingIcon} />{pendingResponse.email}</Typography>
                        </TableCell>
                      </TableRow>
                  )) : null}
                  {googleForm === survey.googleFormId && responses.length > 0 ? responses.map((response) => (
                      <TableRow
                        key={response}
                        style={{ display: googleForm === survey.googleFormId ? 'block' : 'none' }}
                        className={classes.fullTableRow}
                      >
                        <TableCell className={classes.emailCell} colSpan={8} align="left">
                          <Typography><CheckIcon className={classes.checkIcon} />{response}</Typography>
                        </TableCell>
                      </TableRow>
                  )) : null}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalSurveys}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  surveys: PropTypes.array,
  page: PropTypes.number,
  setPage: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  sort: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
  totalSurveys: PropTypes.number,
};

Results.defaultProps = {
  surveys: [],
};

export default Results;
