/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  // InputAdornment,
  Grid,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Edit as EditIcon } from 'react-feather';
import { useSnackbar } from 'notistack';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useStyles from './styles';
import billRequest from '../../../requests/api/bill';
import UploadBillsModal from './UploadBillsModal';
import pesoChileno from '../../../utils/moneyFormat';
import {
  ISSUED,
  PAID,
  CANCELED,
} from '../../../utils/const/billStatus';

const orderStatusOptions = [
  { value: ISSUED },
  { value: PAID },
  { value: CANCELED },
];

function Results({
  className,
  bills,
  totalBills,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  filters,
  setFilters,
  clients,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectBill, setSelectBill] = useState([]);
  const [currentBill, setCurrentBill] = useState(bills);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCurrentBill(bills);
  }, [bills]);

  const handleFiltersChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectAllBills = (event) => {
    setSelectBill(event.target.checked ? currentBill.map((e) => e.id) : []);
  };

  const handleSelectAllBill = (event, billId) => {
    if (!selectBill.includes(billId)) {
      setSelectBill((prevSelected) => [...prevSelected, billId]);
    } else {
      setSelectBill((prevSelected) => prevSelected.filter((id) => id !== billId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleUploadBills = () => {
    setOpen(true);
  };

  const handleCloseUploadBills = () => {
    setOpen(false);
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectBill.length > 0;
  const selectSomeBills = selectBill.length > 0 && selectBill.length < currentBill.length;
  const selectedAllBills = selectBill.length === currentBill.length;
  const account = useSelector((state) => state.account);

  const deleteBills = async () => {
    if (account.user.role.name.toLowerCase() !== 'superadmin') {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectBill.length; a += 1) {
        billRequest.deleteBill(selectBill[a]);
        enqueueSnackbar('Facturas eliminadas!', {
          variant: 'success',
        });
      }
      setCurrentBill(currentBill.filter((e) => !selectBill.includes(e.id)));
      setSelectBill([]);
    }
  };

  return (
    <Card className={clsx(classes.cardMargin, className)} {...rest}>
      <Divider />
      <Grid
        container
        spacing={3}
        className={classes.filtersContainer}
        display="flex"
        alignItems="center"
        flexwrap="wrap"
      >
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth
            label="Número Factura"
            name="search"
            onChange={(e) => handleFiltersChange('search', e.target.value || '')
            }
            value={filters.search}
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={clients}
            defaultValue={null}
            getOptionLabel={(option) => (option ? `${option.name}` : '')}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onChange={(e, value) => handleFiltersChange('client', value || '')}
            value={filters.client}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Buscar Cliente"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Autocomplete
            multiple
            options={orderStatusOptions}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.value === value.value
            }
            value={filters.status}
            onChange={(event, value) => handleFiltersChange('status', value)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder="Estado" />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
          >
            <DatePicker
              inputFormat="MM-yyyy"
              views={['year', 'month']}
              label="Fecha Emisión"
              minDate={new Date('2020-02-01')}
              maxDate={new Date('2030-01-01')}
              value={filters.month}
              onChange={(value) => handleFiltersChange('month', value || null)}
              renderInput={(params) => (
                <TextField {...params} fullWidth variant="outlined" />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item md={4} xs={12}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleUploadBills()}
          >
            Cargar Facturas
          </Button>
          <UploadBillsModal
            open={open}
            handleClose={() => handleCloseUploadBills()}
          />
        </Grid>
      </Grid>

      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllBills}
              indeterminate={selectSomeBills}
              onChange={handleSelectAllBills}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteBills()}
            >
              Eliminar
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllBills}
                    indeterminate={selectSomeBills}
                    onChange={handleSelectAllBills}
                  />
                </TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Numero</TableCell>
                <TableCell>Fecha Emisión</TableCell>
                <TableCell>Fecha Vencimiento</TableCell>
                <TableCell>Fecha Pago</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Moneda</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell align="center">Link Factura</TableCell>
                <TableCell align="center">Nota de crédito</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentBill.length
                ? currentBill.map((element) => {
                  const isBillSelected = selectBill.includes(element.id);
                  return (
                      <TableRow
                        hover
                        key={element.id}
                        selected={isBillSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isBillSelected}
                            onChange={(event) => handleSelectAllBill(event, element.id)
                            }
                            value={isBillSelected}
                          />
                        </TableCell>
                        <TableCell>{element.client.name}</TableCell>
                        <TableCell>{element.type}</TableCell>
                        <TableCell>{element.number}</TableCell>
                        <TableCell>{element.issueDate}</TableCell>
                        <TableCell>{element.dueDate}</TableCell>
                        <TableCell>{element.paymentDate}</TableCell>
                        <TableCell>{pesoChileno(element.amount)}</TableCell>
                        <TableCell>{element.currency}</TableCell>
                        <TableCell>{element.status}</TableCell>
                        <TableCell align="center">
                          <a
                            href={element.billUrl || null}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <VisibilityIcon className={classes.eyeIcon} />
                          </a>
                        </TableCell>
                        <TableCell align="center">
                          <a
                            href={element.creditNoteUrl || null}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <VisibilityIcon className={classes.eyeIcon} />
                          </a>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            component={RouterLink}
                            to={`/administracion/facturas/${element.id}/editar`}
                          >
                            <SvgIcon fontSize="small">
                              <EditIcon />
                            </SvgIcon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                  );
                })
                : null}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalBills}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  bills: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  totalBills: PropTypes.number,
  clients: PropTypes.array,
};

Results.defaultProps = {};

export default Results;
