import React from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ProjectRoleCreateForm from './ProjectRoleCreateForm';
import useStyles from './styles';

function ProjectRoleCreateView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Agregar Rol de proyecto">
      <Container maxWidth={false}>
        <Header />
        <ProjectRoleCreateForm />
      </Container>
    </Page>
  );
}

export default ProjectRoleCreateView;
