import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import projectDimensionRequest from '../../../requests/api/project_dimension';
import useStyles from './styles';

const getSearchCache = () => {
  const searchDimension = '';
  const searchCategory = '';
  const searchProject = '';
  const searchClient = '';

  return {
    searchDimension,
    searchCategory,
    searchProject,
    searchClient,
  };
};

function ProjectDimensionListView() {
  const classes = useStyles();
  const [projectDimensions, setProjectDimensions] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalProjectDimensions, setTotalProjectDimensions] = useState(0);
  const {
    searchDimension, searchCategory, searchProject, searchClient,
  } = getSearchCache();
  const [filters, setFilters] = useState({
    dimension: searchDimension,
    category: searchCategory,
    project: searchProject,
    client: searchClient,
  });
  useEffect(() => {
    const getDeveloperDimension = async () => {
      const response = await projectDimensionRequest.getProjectDimensionPerPage({
        page: pageOfTable,
        limit,
        searchDimension: filters.dimension,
        searchCategory: filters.category,
        searchProject: filters.project,
        searchClient: filters.client,
      });
      if (response.success) {
        const data = await response.data.data.project_dimensions;
        const arrayData = Object.values(data);
        setProjectDimensions(arrayData);
        setTotalProjectDimensions(response.data.data.totalProjectDimensions);
      }
    };
    getDeveloperDimension();
  }, [pageOfTable, limit, filters]);

  return (
    <Page className={classes.root} title="TubeOps | Características">
      <Container maxWidth={false}>
        <Header />
        {projectDimensions && (
          <Box mt={3}>
            <Results
              projectDimensions={projectDimensions}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              totalProjectDimensions={totalProjectDimensions}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default ProjectDimensionListView;
