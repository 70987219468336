import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import assignmentRequest from '../../../requests/api/assignation';
import useStyles from './styles';

const getSearchCache = () => {
  const searchDeveloper = '';
  const searchStartMonth = '';
  const searchEndMonth = '';

  return {
    searchDeveloper,
    searchStartMonth,
    searchEndMonth,
  };
};

function AssignmentsListView() {
  const classes = useStyles();
  const [assignments, setAssignments] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const {
    searchDeveloper, searchStartMonth, searchEndMonth,
  } = getSearchCache();
  const [totalAssignments, setTotalAssignments] = useState(0);
  const [filters, setFilters] = useState({
    developer: searchDeveloper,
    startMonth: searchStartMonth,
    endMonth: searchEndMonth,
  });

  useEffect(() => {
    const getAssignments = async () => {
      const response = await assignmentRequest.getAssignationsPerPage({
        pageOfTable,
        limit,
        searchDeveloper: filters.developer,
        searchStartMonth: filters.startMonth,
        searchEndMonth: filters.endMonth,

      });

      if (response.success) {
        const data = await response.data.data.assignations;
        const arrayData = Object.values(data);
        setAssignments(arrayData);
        setTotalAssignments(response.data.data.totalAssignations);
      }
    };
    getAssignments();
  }, [pageOfTable, limit, filters]);

  return (
    <Page className={classes.root} title="TubeOps | Asignaciones">
      <Container maxWidth={false}>
        <Header />
        {assignments && (
          <Box mt={3}>
            <Results
              assignments={assignments}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              totalAssignments={totalAssignments}
              table={1}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default AssignmentsListView;
