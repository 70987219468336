/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
// import { Editor } from '@tinymce/tinymce-react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Request from '../../../requests/api/blog';
import { SUPER_ADMIN } from '../../../utils/const/user_types';

const useStyles = makeStyles(() => ({
  root: {},
}));

function CreateForm({ className, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [published, setPublished] = useState(false);
  const [isMarkdown, setIsMarkdown] = useState(false);
  const [blogContent, setBlogContent] = useState('');

  const account = useSelector((state) => state.account);

  const quillModules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  const handleRadioChange = (event) => {
    setIsMarkdown(event.target.value === 'markdown');
  };

  if (submitSuccess) {
    return <Redirect to="/administracion/blog" />;
  }

  return (
    <Formik
      initialValues={{
        userId: account.user.id,
        title: '',
        excerpt: '',
        slug: '',
        content: '',
        meta: '',
        tags: '',
        thumbnail: '',
        published,
        isMarkdown,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required('Debe ingresar un título'),
        excerpt: Yup.string().required('Debe ingresar un extracto'),
        slug: Yup.string().max(255).required('Debe ingresar un slug'),
        meta: Yup.string().required('Debe ingresar meta keywords'),
        tags: Yup.string().required('Debe ingresar al menos un tag'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            userId: account.user.id,
            title: values.title,
            excerpt: values.excerpt,
            slug: values.slug,
            content: isMarkdown ? values.content : blogContent,
            meta: values.meta,
            tags: values.tags,
            thumbnail: values.thumbnail,
            published,
            isMarkdown,
          };
          const response = await Request.postBlogCreate({ blog: data });

          const res = response.data;
          if (res.success) {
            setStatus({ success: true });
            setSubmitting(false);
            setSubmitSuccess(true);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: <Button href="/administracion/blogs">Volver a blogs</Button>,
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    helperText={touched.title && errors.title}
                    label="Título"
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.title}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.excerpt && errors.excerpt)}
                    fullWidth
                    helperText={touched.excerpt && errors.excerpt}
                    label="Extracto"
                    name="excerpt"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.excerpt}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.slug && errors.slug)}
                    fullWidth
                    helperText={touched.slug && errors.slug}
                    label="Slug"
                    name="slug"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.slug}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <FormControl>
                    <FormLabel id="radio-blog-format">Formato</FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-blog-format"
                      defaultValue="editor"
                      name="radio-blog-group"
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel value="editor" control={<Radio />} label="Editor" />
                      <FormControlLabel value="markdown" control={<Radio />} label="Markdown" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  {isMarkdown
                    ? (
                      <TextField
                        error={Boolean(touched.content && errors.content)}
                        fullWidth
                        helperText={touched.content && errors.content}
                        label="Contenido"
                        name="content"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        multiline
                        value={values.content}
                        variant="outlined"
                      />
                    ) : (
                      <ReactQuill
                        theme="snow"
                        modules={quillModules}
                        value={blogContent}
                        onChange={setBlogContent}
                      />
                    )}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.meta && errors.meta)}
                    fullWidth
                    helperText={touched.meta && errors.meta}
                    label="Meta keywords"
                    name="meta"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.meta}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.tags && errors.tags)}
                    fullWidth
                    helperText={touched.tags && errors.tags}
                    label="Tags"
                    name="tags"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.tags}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.thumbnail && errors.thumbnail)}
                    fullWidth
                    helperText={touched.thumbnail && errors.thumbnail}
                    label="Imagen de portada"
                    name="thumbnail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.thumbnail}
                    variant="outlined"
                  />
                </Grid>
                {account.user.role.name === SUPER_ADMIN
                  ? (
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Publicar</FormLabel>
                        <Switch
                          checked={published}
                          onChange={() => setPublished(!published)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </FormControl>
                    </Grid>
                  ) : null}
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

CreateForm.propTypes = {
  className: PropTypes.string,
};

export default CreateForm;
