/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import DemandChart from './DemandChart';
import Request from '../../../requests/api/demands';
import projectRequest from '../../../requests/api/project';
import projectDemandGraphicActions from '../../../actions/projectDemandGraphicActions';
import Results from './Results';
import useStyles from './styles';
import ProjectDemandCreateForm from './ProjectDemandCreateForm';
import { compareDateMonths, firstDayOfCurrentMonth, firstDayOfNextMonth } from '../../../utils/date';
import ClientProjectsView from './ClientProjectsView';
import BarChart from './BarChart';
import availabilityRequest from '../../../requests/api/availability';
import ProgressBar from './ProgressBar';
import SearchBar from './SearchBar';
import SearchMonth from './SearchMonth';

function ProjectDemandsListView() {
  const classes = useStyles();
  const [projectId, setProjectId] = useState(-1);
  const [projects, setProjects] = useState([]);
  const [selectProjectInfo, setSelectProjectInfo] = useState({});
  const [demandData, setDemandData] = useState({});
  const [categories, setCategories] = useState([]);
  const [refreshCurrentAssignment, setRefreshCurrentAssignment] = useState(false);
  const [clientProjectsInfo, setClientProjectsInfo] = useState([]);
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const month = (currentDay >= 0 && currentDay <= 15) ? firstDayOfCurrentMonth(currentDate) : firstDayOfNextMonth(currentDate);
  const [nextMonth, setNextMonth] = useState(month);
  const [nextMonthDemandChartInfo, setNextMonthDemandChartInfo] = useState([]);
  const [nextMonthAvailabilityChartInfo, setNextMonthAvailabilityChartInfo] = useState([]);
  const projectDemandGraphicReduxInfo = useSelector(
    (state) => state.projectDemandGraphic,
  );
  const [
    numberOfProjectsWithNextMonthDemand,
    setNumberOfProjectsWithNextMonthDemand,
  ] = useState(0);
  const [searchClient, setSearchClient] = useState('');
  const dispatch = useDispatch();

  // Carga de datos del redux
  useEffect(() => {
    const setReduxInfo = async () => {
      if (projectDemandGraphicReduxInfo.projectDemandGraphic.projectId) {
        setProjectId(
          projectDemandGraphicReduxInfo.projectDemandGraphic.projectId,
        );
      } else {
        setProjectId(0);
      }
    };
    setReduxInfo();
  }, []);

  useEffect(() => {
    const getProjects = async () => {
      const response = await projectRequest.getProjectsPerPage({
        page: 0,
        limit: 100,
        searchClient: '',
        searchProject: '',
        month: '',
        status: 'Activo',
      });
      if (response.success) {
        const projectsData = await response.data.data.projects;
        const arrayData = Object.values(projectsData);
        setProjects(arrayData);
        setNumberOfProjectsWithNextMonthDemand(0);

        // Informacion de Clientes con sus proyectos
        const clients = arrayData.reduce((acc, project) => {
          const { client } = project;
          const findClient = acc.find((e) => e.id === client.id);
          const projectDemandNextMonth = project.demands.find((demand) => compareDateMonths(demand.month, nextMonth))
            ?.blocked === true;

          // Cuenta cuantos proyectos sin asignar hay el prox mes
          if (projectDemandNextMonth) {
            setNumberOfProjectsWithNextMonthDemand(
              (prevState) => prevState + 1,
            );
          }
          const blocked = projectDemandNextMonth;
          project.blocked = blocked;

          if (findClient) {
            findClient.projects.push(project);
            findClient.blocked = blocked;
          } else {
            acc.push({
              id: client.id,
              name: client.name,
              projects: [project],
              blocked,
            });
          }
          return acc;
        }, []);

        // se añaden las horas minimas y maximas totales de cada cliente
        const updatedClientProjects = clients.map((client) => {
          const clientProjects = client.projects;
          const projectDemands = clientProjects.map(
            (project) => project.demands.find((demand) => compareDateMonths(demand.month, nextMonth)) || {
              minHours: 0,
              maxHours: 0,
            },
          );
          const clientMinDemand = projectDemands.reduce(
            (acc, cur) => acc + cur.minHours,
            0,
          );
          const clientMaxDemand = projectDemands.reduce(
            (acc, cur) => acc + cur.maxHours,
            0,
          );
          return {
            ...client,
            minDemand: clientMinDemand,
            maxDemand: clientMaxDemand,
          };
        });

        const clientsArray = updatedClientProjects.find(
          (client) => client.name === searchClient,
        ) !== undefined
          ? updatedClientProjects.filter(
            (client) => client.name === searchClient,
          )
          : updatedClientProjects;
        setClientProjectsInfo(clientsArray);

        let project = {};
        project = arrayData.find((p) => p.id === projectId);

        // Ya hay un proyecto seleccionado
        if (project) {
          dispatch(
            projectDemandGraphicActions.setProjectDemandGraphic({
              projectId,
            }),
          );
        } else {
          // No hay un proyecto seleccionado
          [project] = arrayData;
          setProjectId(project.id);
          dispatch(
            projectDemandGraphicActions.setProjectDemandGraphic({
              projectId: project.id,
            }),
          );
        }
        setSelectProjectInfo(project);
      }
    };
    if (projectId >= 0) {
      getProjects();
    }
  }, [projectId, refreshCurrentAssignment, nextMonth, searchClient]);

  // useEffect que obtiene la disponibilidad de un proyecto en especifico
  useEffect(() => {
    const getAvailabilities = async () => {
      const response = await availabilityRequest.getAvailabilitiesPerPage(
        0,
        100,
        '',
        nextMonth,
      );
      if (response.success) {
        const availabilitiesResponse = await response.data.data.availabilities;
        const arrayData = Object.values(availabilitiesResponse);

        // Suma de horas minimas y maximas de la disponibilidades del proyectos para el prox mes
        const nextMonthTotalMinAvailability = arrayData.reduce(
          (acc, cur) => acc + Math.max(cur.minHours - cur.vacationHours, 0),
          0,
        );
        const nextMonthTotalMaxAvailability = arrayData.reduce(
          (acc, cur) => acc + Math.max(cur.maxHours - cur.vacationHours, 0),
          0,
        );

        if (arrayData.length > 0) {
          setNextMonthAvailabilityChartInfo([
            {
              name: 'Disponibilidad Maxima',
              value: nextMonthTotalMaxAvailability,
            },
            {
              name: 'Disponibilidad Minima',
              value: nextMonthTotalMinAvailability,
            },
          ]);
        }
      }
    };
    const getDemands = async () => {
      const response = await Request.getDemandsPerPage({
        page: 0,
        limit: 100,
        searchProject: '',
        searchClient: '',
        searchMonth: nextMonth,
      });
      if (response.success) {
        const demandsResponse = await response.data.data.Demands;
        const arrayData = Object.values(demandsResponse);

        // Suma de horas minimas y maximas de la demanda de los  proyectos para el prox mes
        const nextMonthTotalMinDemand = arrayData.reduce(
          (acc, cur) => acc + cur.minHours,
          0,
        );
        const nextMonthTotalMaxDemand = arrayData.reduce(
          (acc, cur) => acc + cur.maxHours,
          0,
        );

        if (arrayData.length > 0) {
          setNextMonthDemandChartInfo([
            {
              name: 'Demanda Maxima',
              value: nextMonthTotalMaxDemand,
            },
            {
              name: 'Demanda Minima',
              value: nextMonthTotalMinDemand,
            },
          ]);
        }
      }
    };
    getDemands();
    getAvailabilities();
  }, [nextMonth, refreshCurrentAssignment]);

  // useEffect que obtiene los datos del grafico
  useEffect(() => {
    const getDemandsGraphic = async () => {
      const response = await Request.getDemandsGraphic({
        projectId,
        startMonth: '',
        endMonth: '',
      });
      if (response.success) {
        const demandsGraphicResponse = await response.data.data.totalDemands;
        setDemandData(demandsGraphicResponse);
        setCategories(demandsGraphicResponse.month);
      }
    };

    if (projectId > 0) {
      getDemandsGraphic();
    }
  }, [projectId, refreshCurrentAssignment, nextMonth]);

  return (
    <Page className={classes.root} title="TubeOps | Proyecciones Demanda">
      <Container maxWidth={false}>
        <div className={classes.headerDiv}>
          <Header />
          <SearchMonth nextMonth={nextMonth} setNextMonth={setNextMonth} />
        </div>
        {projects.length ? (
          <div>
            <div className={classes.mainSquare}>
              <div className={classes.leftColumn}>
                <SearchBar
                  clientProjectsInfo={clientProjectsInfo}
                  setProjectId={setProjectId}
                  searchClient={searchClient}
                  setSearchClient={setSearchClient}
                  setRefreshCurrentAssignment={setRefreshCurrentAssignment}
                />
                <ClientProjectsView
                  clientProjectsInfo={clientProjectsInfo}
                  month={nextMonth}
                  projectId={projectId}
                  setProjectId={setProjectId}
                  setRefreshCurrentAssignment={setRefreshCurrentAssignment}
                />
                <ProgressBar
                  totalProjects={projects.length}
                  numberOfProjectsWithNextMonthDemand={
                    numberOfProjectsWithNextMonthDemand
                  }
                />
              </div>
              <div className={classes.rightColumn}>
                <div className={classes.topRow}>
                  {nextMonthDemandChartInfo.length ? (
                    <BarChart
                      title={'Demanda'}
                      data={nextMonthDemandChartInfo}
                    />
                  ) : null}
                  {nextMonthAvailabilityChartInfo.length ? (
                    <BarChart
                      title={'Disponibilidad'}
                      data={nextMonthAvailabilityChartInfo}
                    />
                  ) : null}
                </div>

                <div className={classes.bottomRow}>
                  <DemandChart
                    categories={categories}
                    demandData={demandData}
                  />
                </div>
              </div>
            </div>
            <div className={classes.createDemandDiv}>
              <ProjectDemandCreateForm
                setRefreshCurrentAssignment={setRefreshCurrentAssignment}
                selectProjectInfo={selectProjectInfo}
                nextMonth={nextMonth}
              />
            </div>

            <Results
              month={month}
              nextMonth={nextMonth}
              selectProjectInfo={selectProjectInfo}
              setRefreshCurrentAssignment={setRefreshCurrentAssignment}
            />
          </div>
        ) : null}
      </Container>
    </Page>
  );
}

export default ProjectDemandsListView;
