import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import { DatePicker } from '@mui/x-date-pickers';
import Request from '../../../requests/api/performance_cycles';

const useStyles = makeStyles(() => ({
  root: {},
}));

function PerformanceCycleEditForm({
  className,
  performanceCycle,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/performance-cycles" />;
  }

  return (
    <Formik
      initialValues={{
        name: performanceCycle.name || '',
        startDate: performanceCycle.startDate || '',
        endDate: performanceCycle.endDate || '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        startDate: Yup.date().required('Debe ingresar una fecha de inicio'),
        endDate: Yup.date().required('Debe ingresar una fecha de término'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            id: performanceCycle.id,
            name: values.name,
            startDate: values.startDate,
            endDate: values.endDate,
          };
          const response = await Request.putPerformanceCycleById(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: <Button href="/administracion/performance-cycles">Volver a ciclos de performance</Button>,
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      views={['year', 'month', 'day']}
                      label="Fecha de inicio"
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      value={values.startDate}
                      onChange={(value) => setFieldValue('startDate', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          error={Boolean(touched.startDate && errors.startDate)}
                          helperText={touched.startDate && errors.startDate}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      views={['year', 'month', 'day']}
                      label="Fecha de término"
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      value={values.endDate}
                      onChange={(value) => setFieldValue('endDate', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          error={Boolean(touched.endDate && errors.endDate)}
                          helperText={touched.endDate && errors.endDate}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

PerformanceCycleEditForm.propTypes = {
  className: PropTypes.string,
  performanceCycle: PropTypes.object.isRequired,
};

export default PerformanceCycleEditForm;
