/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { formatMonthAndYearMoment } from '../../utils/date';
import useStyles from './styles';

function InfoCard({
  chart, selectChart, setSelectChart, data,
}) {
  const classes = useStyles();
  let title = '';
  let leftText = '';
  let rightText = '';
  const [currentMonthPercentage, setCurrentMonthPercentage] = useState(0);
  const [nextMonthPercentage, setNextMonthPercentage] = useState(0);
  const currentMonth = new Date();
  const firstDayCurrentMonth = formatMonthAndYearMoment(
    new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1),
  );
  const firtsDayOfNextMonth = formatMonthAndYearMoment(
    new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1),
  );

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      const currentMonthDemand = data.demands.find(
        (item) => item.month === firstDayCurrentMonth,
      );
      const currentMonthAvailability = data.availabilities.find(
        (item) => item.month === firstDayCurrentMonth,
      );
      const currentMonthProjectDevelopers = data.projectDevelopers.find(
        (item) => item.month === firstDayCurrentMonth,
      );
      const nextMonthDemand = data.demands.find(
        (item) => item.month === firtsDayOfNextMonth,
      );
      const nextMonthAvailability = data.availabilities.find(
        (item) => item.month === firtsDayOfNextMonth,
      );
      const nextMonthProjectDevelopers = data.projectDevelopers.find(
        (item) => item.month === firtsDayOfNextMonth,
      );
      const currentMonthMinDemand = currentMonthDemand?.minDemand || 0;
      const currentMonthMaxDemand = currentMonthDemand?.maxDemand || 0;
      const nextMonthMinDemand = nextMonthDemand?.minDemand || 0;
      const nextMonthMaxDemand = nextMonthDemand?.maxDemand || 0;
      const currentMonthMinAvailability = currentMonthAvailability?.minAvailability || 0;
      const currentMonthMaxAvailability = currentMonthAvailability?.maxAvailability || 0;
      const nextMonthMinAvailability = nextMonthAvailability?.minAvailability || 0;
      const nextMonthMaxAvailability = nextMonthAvailability?.maxAvailability || 0;
      const assignationCurrentMonth = currentMonthProjectDevelopers?.assignedHours || 0;
      const assignationNextMonth = nextMonthProjectDevelopers?.assignedHours || 0;

      const meanDemandCurrentMonth = (currentMonthMinDemand + currentMonthMaxDemand) / 2;
      const meanDemandNextMonth = (nextMonthMinDemand + nextMonthMaxDemand) / 2;
      const meanAvailabilityCurrentMonth = (currentMonthMinAvailability + currentMonthMaxAvailability) / 2;
      const meanAvailabilityNextMonth = (nextMonthMinAvailability + nextMonthMaxAvailability) / 2;
      if (chart === 0) {
        const firstPercentage = meanAvailabilityCurrentMonth !== 0
          ? (
            (meanDemandCurrentMonth / meanAvailabilityCurrentMonth)
                * 100
          ).toFixed(1)
          : 0;
        const secondPercentage = meanAvailabilityNextMonth !== 0
          ? ((meanDemandNextMonth / meanAvailabilityNextMonth) * 100).toFixed(
            1,
          )
          : 0;
        setCurrentMonthPercentage(firstPercentage);
        setNextMonthPercentage(secondPercentage);
      } else if (chart === 1) {
        const firstPercentage = meanAvailabilityCurrentMonth !== 0
          ? (
            (assignationCurrentMonth / meanAvailabilityCurrentMonth)
                * 100
          ).toFixed(1)
          : 0;
        const secondPercentage = meanAvailabilityNextMonth !== 0
          ? (
            (assignationNextMonth / meanAvailabilityNextMonth)
                * 100
          ).toFixed(1)
          : 0;
        setCurrentMonthPercentage(firstPercentage);
        setNextMonthPercentage(secondPercentage);
      } else if (chart === 2) {
        const firstPercentage = meanDemandCurrentMonth !== 0
          ? (
            (assignationCurrentMonth / meanDemandCurrentMonth)
                * 100
          ).toFixed(1)
          : 0;
        const secondPercentage = meanDemandNextMonth !== 0
          ? ((assignationNextMonth / meanDemandNextMonth) * 100).toFixed(1)
          : 0;
        setCurrentMonthPercentage(firstPercentage);
        setNextMonthPercentage(secondPercentage);
      }
    }
  }, [data]);

  if (chart === 0) {
    title = 'CAPACIDAD DEL EQUIPO';
    leftText = 'Demanda / Disponibilidad mes actual:';
    rightText = 'Demanda / Disponibilidad mes siguiente:';
  } else if (chart === 1) {
    title = 'USO DE DISPONIBILIDAD';
    leftText = 'Asignación / Disponibilidad mes actual:';
    rightText = 'Asignación / Disponibilidad mes siguiente:';
  } else if (chart === 2) {
    title = 'SATISFACCIÓN DE DEMANDA';
    leftText = 'Asignación / Demanda mes actual:';
    rightText = 'Asignación / Demanda mes siguiente:';
  }

  const handleCardClick = () => {
    setSelectChart(chart);
  };

  if (Object.keys(data).length === 0) {
    return null;
  }

  return (
    <div>
      <Card
        className={clsx(classes.infoCard, {
          [classes.infoCardSelected]: selectChart === chart,
          [classes.infoCardHover]: selectChart !== chart,
        })}
        onClick={() => handleCardClick()}
      >
        <div className={classes.cardFirstPart}>
          <Typography className={classes.infoCardtitle}>{title}</Typography>
        </div>

        <Divider
          className={clsx(classes.infoCardDivider, {
            [classes.infoCardDividerSelected]: selectChart === chart,
          })}
        />

        <div className={classes.cardSecondPart}>
          <div className={classes.infoCardSecondPartLeftRight}>
            <Typography className={classes.infoCardText}>{leftText}</Typography>
            <Typography
              className={classes.infoCardNumber}
            >{`${currentMonthPercentage}%`}</Typography>
          </div>

          <div className={classes.infoCardSecondPartLeftRight}>
            <Typography className={classes.infoCardText}>
              {rightText}
            </Typography>
            <Typography
              className={classes.infoCardNumber}
            >{`${nextMonthPercentage}%`}</Typography>
          </div>
        </div>
      </Card>
    </div>
  );
}

InfoCard.propTypes = {
  chart: PropTypes.number.isRequired,
  selectChart: PropTypes.number.isRequired,
  setSelectChart: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default InfoCard;
