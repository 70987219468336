/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
  Switch,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Request from '../../../requests/api/blog';
import { SUPER_ADMIN } from '../../../utils/const/user_types';

const sortOptions = [
  {
    value: 'updated_at|desc',
    label: 'Más nuevo primero',
  },
  {
    value: 'updated_at|asc',
    label: 'Más antiguo primero',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
}));

function Results({
  className,
  rows,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  searchValue,
  setSearchValue,
  count,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = useState([]);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [currentRows, setCurrentRows] = useState(rows);

  useEffect(() => { setCurrentRows(rows); }, [rows]);

  const handleQueryChange = (event) => {
    event.persist();
    setSearchValue(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllRows = (event) => {
    setSelectedRows(event.target.checked
      ? currentRows.map((row) => row.id)
      : []);
  };

  const handleSelectOneRow = (event, rowId) => {
    if (!selectedRows.includes(rowId)) {
      setSelectedRows((prevSelected) => [...prevSelected, rowId]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter((id) => id !== rowId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePublishedChange = async (blogId) => {
    const newBlogs = [...currentRows];
    const blog = newBlogs.find((l) => l.id === blogId) || {};
    if (blog.published) {
      const resp = await Request.postBlogPublish({ blogs: [{ id: blog.id, published: false }] });
      if (resp.success) {
        blog.published = false;
        blog.publishedDate = null;
      } else {
        enqueueSnackbar('Ha ocurrido un error', {
          variant: 'error',
        });
      }
    } else {
      const resp = await Request.postBlogPublish({ blogs: [{ id: blog.id, published: true }] });
      if (resp.success) {
        blog.published = true;
        blog.publishedDate = new Date();
      } else {
        enqueueSnackbar('Ha ocurrido un error', {
          variant: 'error',
        });
      }
    }
    setCurrentRows(newBlogs);
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectedRows.length > 0;
  const selectedSomeRows = selectedRows.length > 0 && selectedRows.length < currentRows.length;
  const selectedAllRows = selectedRows.length === currentRows.length;
  const account = useSelector((state) => state.account);

  const publishBlog = async () => {
    if (account.user.role.name !== SUPER_ADMIN) {
      enqueueSnackbar('Debe ser Administrador!', {
        variant: 'error',
      });
    } else {
      const blogs = selectedRows.map((sr) => ({ id: sr, published: true }));
      const resp = await Request.postBlogPublish({ blogs });
      if (resp.success) {
        enqueueSnackbar('Blogs publicados!', {
          variant: 'success',
        });
        const newBlogs = [...currentRows];
        selectedRows.forEach((sr) => {
          const publishedBlog = newBlogs.find((nb) => nb.id === sr);
          if (publishedBlog) {
            publishedBlog.published = true;
            publishedBlog.publishedDate = new Date();
          }
        });
        setCurrentRows(newBlogs);
        setSelectedRows([]);
      } else {
        enqueueSnackbar('No se pudo publicar', {
          variant: 'error',
        });
      }
    }
  };

  const unpublishBlog = async () => {
    if (account.user.role.name !== SUPER_ADMIN) {
      enqueueSnackbar('Debe ser Administrador!', {
        variant: 'error',
      });
    } else {
      const blogs = selectedRows.map((sr) => ({ id: sr, published: false }));
      const resp = await Request.postBlogPublish({ blogs });
      if (resp.success) {
        enqueueSnackbar('Blogs despublicados!', {
          variant: 'success',
        });
        const newBlogs = [...currentRows];
        selectedRows.forEach((sr) => {
          const publishedBlog = newBlogs.find((nb) => nb.id === sr);
          if (publishedBlog) {
            publishedBlog.published = false;
            publishedBlog.publishedDate = null;
          }
        });
        setCurrentRows(newBlogs);
        setSelectedRows([]);
        setSelectedRows([]);
      } else {
        enqueueSnackbar('No se pudo despublicar', {
          variant: 'error',
        });
      }
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Buscar blog por título o tags"
          value={searchValue}
          variant="outlined"
        />
        <Box flexGrow={1} />
        <TextField
          label="Orden"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllRows}
              indeterminate={selectedSomeRows}
              onChange={handleSelectAllRows}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => publishBlog()}
            >
              Publicar
            </Button>
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => unpublishBlog()}
            >
              Despublicar
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllRows}
                    indeterminate={selectedSomeRows}
                    onChange={handleSelectAllRows}
                  />
                </TableCell>
                <TableCell>
                  Título
                </TableCell>
                <TableCell>
                  Autor
                </TableCell>
                <TableCell>
                  Publicado
                </TableCell>
                <TableCell>
                  Fecha publicación
                </TableCell>
                <TableCell align="right">
                  Acción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentRows.map((row) => {
                const isRowSelected = selectedRows.includes(row.id);
                return (
                  <TableRow
                    hover
                    key={row.id}
                    selected={isRowSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isRowSelected}
                        onChange={(event) => handleSelectOneRow(event, row.id)}
                        value={isRowSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {row.title}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {`${row.user.name} ${row.user.lastname}`}
                    </TableCell>
                    {account.user.role.name === SUPER_ADMIN
                      ? (
                        <TableCell>
                          <Switch
                            checked={row.published}
                            onChange={() => handlePublishedChange(row.id)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell>
                          <Switch
                            disabled
                            checked={row.published}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                      )}
                    <TableCell>
                      {row.publishedDate
                        ? moment(row.publishedDate).format('DD/MM/YYYY HH:mm:ss')
                        : 'Sin publicar'}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/administracion/blog/${row.id}/editar`}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  count: PropTypes.number,
};

Results.defaultProps = {
  rows: [],
};

export default Results;
