import React, {
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Button,
  SvgIcon,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  PlusCircle as PlusCircleIcon,
} from 'react-feather';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ActionsResults from './ActionsResults';
import CommentsResults from './CommentsResults';
import Request from '../../../requests/api/objectives';
import instanceRequest from '../../../requests/api/performance_cycle_instances';
import ObjectiveDetailsResult from './ObjectiveDetails';
import SplashScreen from '../../../components/Loading/SplashScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function ObjectivesDetailView() {
  const classes = useStyles();
  const history = useHistory();
  const { objectiveId } = useParams();

  const [comments, setComments] = useState([]);
  const [actions, setActions] = useState([]);
  const [pageOfActionsTable, setPageOfActionsTable] = useState(0);
  const [pageOfCommentsTable, setPageOfCommentsTable] = useState(0);
  const [actionsLimit, setActionsLimit] = useState(10);
  const [commentsLimit, setCommentsLimit] = useState(10);
  const [commentsSearchValue, setCommentsSearchValue] = useState('');
  const performanceCycleInstanceId = window.location.pathname.split('/')[3] || '';
  const [totalComments, setTotalComments] = useState(0);
  const [totalActions, setTotalActions] = useState(0);
  const [instance, setInstance] = useState(null);
  const [reload, setReload] = useState(false);
  const [objectiveDetails, setObjectiveDetails] = useState(null);

  useEffect(() => {
    const getInstanceDetail = async () => {
      const response = await instanceRequest
        .getPerformanceCycleInstanceById(performanceCycleInstanceId);
      if (response.success) setInstance(response.data.data.performance_cycle_instance);
    };
    getInstanceDetail();
  }, [reload]);

  useEffect(() => {
    const getObjective = async () => {
      const response = await Request.getObjectiveById(objectiveId);
      if (response.success) {
        const data = await response.data.data.objective;
        setObjectiveDetails(data);
      }
    };
    getObjective();
  }, [objectiveId]);

  useEffect(() => {
    const getComments = async () => {
      const response = await Request.getObjectiveCommentsPerPage(
        objectiveId,
        pageOfCommentsTable,
        commentsLimit,
        commentsSearchValue,
      );
      if (response.success) {
        const data = await response.data.data.objectiveComments;
        setComments(data);
        setTotalComments(response.data.data.totalObjectiveComments);
      }
    };
    getComments();
  }, [pageOfCommentsTable, commentsLimit, commentsSearchValue]);

  useEffect(() => {
    const getActions = async () => {
      const response = await Request.getObjectiveActionsPerPage(
        objectiveId,
        pageOfActionsTable,
        actionsLimit,
      );
      if (response.success) {
        const data = await response.data.data.objectiveActions;
        setActions(data);
        setTotalActions(response.data.data.totalObjectiveActions);
      }
    };
    getActions();
  }, [pageOfActionsTable, actionsLimit]);

  if (!instance) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="TubeOps | Detalles objetivo"
    >
      <Container maxWidth={false}>
        <Header
          instance={instance}
          setReload={setReload}
        />
        <Box mt={2}>
          <ObjectiveDetailsResult objectiveDetails={objectiveDetails} />
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Box
            width='48%'
          >
            <Box
              mt={3} mb={2}
              display='flex'
              justifyContent='space-between'
            >
              <Typography variant='h4'>
                Comentarios
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                className={classes.action}
                onClick={() => history.push(`/administracion/performance-cycle-instances/${performanceCycleInstanceId}/objectives/${objectiveId}/comments/crear`)}
              >
                <SvgIcon
                  fontSize="small"
                  className={classes.actionIcon}
                >
                  <PlusCircleIcon />
                </SvgIcon>
                Agregar comentario
              </Button>
            </Box>
            <CommentsResults
              comments={comments}
              pageOfTable={pageOfCommentsTable}
              setPageOfTable={setPageOfCommentsTable}
              limit={commentsLimit}
              setLimit={setCommentsLimit}
              searchValue={commentsSearchValue}
              setSearchValue={setCommentsSearchValue}
              totalComments={totalComments}
            />
          </Box>
          <Box
            width='48%'
          >
            <Box
              mt={3} mb={2}
              display='flex'
              justifyContent='space-between'
            >
              <Typography variant='h4'>
                Acciones
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                className={classes.action}
                onClick={() => history.push(`/administracion/performance-cycle-instances/${performanceCycleInstanceId}/objectives/${objectiveId}/actions/crear`)}
              >
                <SvgIcon
                  fontSize="small"
                  className={classes.actionIcon}
                >
                  <PlusCircleIcon />
                </SvgIcon>
                Agregar acción
              </Button>
            </Box>
            <ActionsResults
              actions={actions}
              pageOfTable={pageOfActionsTable}
              setPageOfTable={setPageOfActionsTable}
              limit={actionsLimit}
              setLimit={setActionsLimit}
              totalActions={totalActions}
            />
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

export default ObjectivesDetailView;
