/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useStyles from './styles';
import pesoChileno from '../../../utils/moneyFormat';
import UpdateBillModal from './UpdateBillModal';
import { formatDayMonthAndYearMoment } from '../../../utils/date';

function Results({
  className,
  bills,
  totalBills,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  setRefresh,
  ...rest
}) {
  const classes = useStyles();
  const [currentBills, setCurrentBills] = useState(bills);
  const [open, setOpen] = useState(false);
  const [selectBill, setSelectBill] = useState(null);

  useEffect(() => {
    setCurrentBills(bills);
  }, [bills]);

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleUpdateBill = (bill) => {
    setOpen(true);
    setSelectBill(bill);
  };

  return (
    <Card className={clsx(classes.cardMargin, className)} {...rest}>
      {selectBill ? (
        <UpdateBillModal open={open} bill={selectBill} setOpen={setOpen} setRefresh={setRefresh}/>
      ) : null}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Nombre Cliente</TableCell>
                <TableCell align="center">Numero factura</TableCell>
                <TableCell align="center">Fecha emisión</TableCell>
                <TableCell align="center">Fecha vencimiento</TableCell>
                <TableCell align="center">Monto facturado</TableCell>
                <TableCell align="center">Fecha último pago</TableCell>
                <TableCell align="center">Monto pagado</TableCell>
                <TableCell align="center">Moneda</TableCell>
                <TableCell align="center">Días vencimiento</TableCell>
                <TableCell align="center">Conciliar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentBills.length
                ? currentBills.map((element) => {
                  const dueDays = Math.floor(
                    (new Date(element.dueDate) - new Date())
                        / (1000 * 60 * 60 * 24),
                  );

                  const issueDate = element.issueDate
                    ? formatDayMonthAndYearMoment(element.issueDate)
                    : '';
                  const dueDate = element.dueDate
                    ? formatDayMonthAndYearMoment(element.dueDate)
                    : '';
                  const paymentDate = element.paymentDate
                    ? formatDayMonthAndYearMoment(element.paymentDate)
                    : '';

                  return (
                      <TableRow hover key={element.id}>
                        <TableCell align="center">
                          {element.client.name}
                        </TableCell>
                        <TableCell align="center">{element.number}</TableCell>
                        <TableCell align="center">{issueDate}</TableCell>
                        <TableCell align="center">{dueDate}</TableCell>
                        <TableCell align="center">
                          {pesoChileno(element.amount)}
                        </TableCell>
                        <TableCell align="center">{paymentDate}</TableCell>
                        <TableCell align="center">{pesoChileno(element.paid)}</TableCell>
                        <TableCell align="center">{element.currency}</TableCell>
                        <TableCell
                          align="center"
                          className={clsx({
                            [classes.red]: dueDays <= 0,
                            [classes.yellow]: dueDays < 7,
                            [classes.green]: dueDays >= 7,
                          })}
                        >
                          {dueDays}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton onClick={() => handleUpdateBill(element)}>
                            <CheckCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                  );
                })
                : null}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalBills}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  bills: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  totalBills: PropTypes.number,
  setRefresh: PropTypes.func,
};

Results.defaultProps = {};

export default Results;
