import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import vacationRequest from '../../../requests/api/vacationRequest';
import developerRequest from '../../../requests/api/developer';
import usersRequest from '../../../requests/api/user';
import vacationDayRequest from '../../../requests/api/vacationDay';
import Tabs from './Tabs';
import DevelopersList from './DevelopersList';
import RequestsList from './RequestsList';
import { SUPER_ADMIN, ADMINISTRATION } from '../../../utils/const/user_types';
import { APPROVED, PENDING } from '../../../utils/const/vacationRequestStatus';
import RequestVacationsModal from '../RequestVacationsModal';
import vacationsCalculator from '../../../utils/vacationDaysCalculator';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function SuperVisorVacationRequestList() {
  const classes = useStyles();

  const requestUser = useSelector((s) => s.account.user);

  const [seeRequests, setSeeRequests] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [developersWithVacations, setDevelopersWithVacations] = useState([]);
  const [totalDevelopers, setTotalDevelopers] = useState(0);
  const [vacationRequests, setVacationRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [reloadRequests, setReloadRequests] = useState(null);
  const [totalVacationRequests, setTotalVacationRequests] = useState(0);

  const [developerPage, setDeveloperPage] = useState(0);
  const [developerLimit, setDeveloperLimit] = useState(10);
  const [allVacationRequests, setAllVacationRequests] = useState([]);

  const [supervisors, setSupervisors] = useState([]);

  const [devHasPendingRequest, setDevHasPendingRequest] = useState(false);
  const [devHasNotSignedCertificate, setDevHasNotSignedCertificate] = useState(false);
  const [selectedDeveloper, setSelectedDeveloper] = useState(
    [ADMINISTRATION, SUPER_ADMIN].includes(requestUser.role.name) ? {
      id: null,
      name: null,
      lastname: null,
    } : {
      id: requestUser.developer.id,
      name: requestUser.name,
      lastname: requestUser.lastname,
    },
  );
  const [availableDays, setAvailableDays] = useState(0);
  const [availableCreditDays, setAvailableCreditDays] = useState(0);
  const [lastVacationDeposit, setLastVacationDeposit] = useState({});

  const getSupervisors = async () => {
    const response = await usersRequest.getUsersByRole(SUPER_ADMIN);
    if (response.success) {
      const data = response.data.data.users.map((u) => ({
        id: u.id,
        name: u.name,
        lastname: u.lastname,
        devId: u.developer.id,
      }));
      setSupervisors(data);
    }
  };

  const getAdministrator = async () => {
    const response = await usersRequest.getUsersByRole(ADMINISTRATION);
    if (response.success) {
      const data = response.data.data.users.map((u) => ({
        id: u.id,
        name: u.name,
        lastname: u.lastname,
        devId: u.developer.id,
      }));
      setSupervisors((prev) => [...prev, ...data]);
    }
  };

  const getDevelopersWithVacations = async () => {
    const developerSearchValue = '';
    const response = await developerRequest
      .getDevelopersPerPageWithVacationDays(developerLimit, developerPage, developerSearchValue);
    if (response.success) {
      const data = response.data.data.developers;
      setDevelopersWithVacations(data);
      setTotalDevelopers(response.data.data.totalDevelopers);
    }
  };

  const getVacationRequests = async () => {
    const devId = selectedDeveloper.id || '';
    const response = await vacationRequest.getVacationRequestsPerPage(
      page, limit, devId, searchValue,
    );
    if (response.success) {
      const data = response.data.data.vacationRequests;
      const pendingVacationRequest = data.filter((el) => el.status === PENDING);
      const notSignedCertificateRequest = data.filter((el) => (el.status === APPROVED
        && !el.certificateSignDate));
      if (!searchValue && pendingVacationRequest.length > 0 && devId) setDevHasPendingRequest(true);
      else setDevHasPendingRequest(false);
      if (!searchValue
        && notSignedCertificateRequest.length > 0
        && devId) setDevHasNotSignedCertificate(true);
      else setDevHasNotSignedCertificate(false);
      if (!openModal && !openPreviewModal) {
        setVacationRequests(data);
        setTotalVacationRequests(response.data.data.totalVacationRequests);
      }
    }
  };

  const getAllVacationRequests = async () => {
    const response = await vacationRequest.getAllVacationRequests();
    if (response.success) {
      const data = response.data.data.vacationRequests;
      setAllVacationRequests(data);
    }
  };

  const dayLimit = '';
  const dayPage = '';
  const getVacationDays = async () => {
    const response = await vacationDayRequest
      .getVacationDaysPerPage(dayLimit, dayPage, selectedDeveloper.id);
    if (response.success) {
      const data = response.data.data.vacationDays;
      if (data.length > 0) {
        const {
          twoYearsAgoDeposit: deposit,
          twoYearsAgoCharge: charge,
          creditDaysAvailable: credit,
        } = vacationsCalculator(data);

        const availableVacationDays = deposit - charge;
        setAvailableDays(availableVacationDays);
        setAvailableCreditDays(credit);
        setLastVacationDeposit(data[0]);
      }
    }
  };

  useEffect(() => {
    getSupervisors();
    getAdministrator();
    // data for developers list.
    getAllVacationRequests();
  }, []);

  useEffect(() => {
    // this brings the data for developers list.
    getDevelopersWithVacations();
  }, [developerLimit, developerPage]);

  useEffect(() => {
    getVacationDays();
  }, [selectedDeveloper]);

  useEffect(() => {
    getVacationRequests();
  }, [page, limit, searchValue, reloadRequests, selectedDeveloper]);

  if (![SUPER_ADMIN, ADMINISTRATION].includes(requestUser.role.name)) return <Redirect to='/' />;

  return (
    <Page
      className={classes.root}
      title="TubeOps | Vacaciones"
    >
      <Container maxWidth={false}>
        <Header />
        <Tabs
          seeRequests={seeRequests}
          setSeeRequests={setSeeRequests}
        />
        <Box>
          {!seeRequests && (
            <DevelopersList
              developersWithVacations={developersWithVacations}
              totalDevelopers={totalDevelopers}
              developerPage={developerPage}
              developerLimit={developerLimit}
              setDeveloperPage={setDeveloperPage}
              setDeveloperLimit={setDeveloperLimit}
              vacationRequests={allVacationRequests}
            />
          )}
          {seeRequests && (
            <RequestsList
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              setSearchValue={setSearchValue}
              vacationRequests={vacationRequests}
              totalVacationRequests={totalVacationRequests}
              setReloadRequests={setReloadRequests}
              developers={developersWithVacations}
              setSelectedDeveloper={setSelectedDeveloper}
            />
          )}
          <RequestVacationsModal
            supervisors={supervisors}
            availableDays={availableDays}
            availableCreditDays={availableCreditDays}
            devHasPendingRequest={devHasPendingRequest}
            lastVacationDeposit={lastVacationDeposit}
            selectedDeveloper={selectedDeveloper}
            setSelectedDeveloper={setSelectedDeveloper}
            setDevHasPendingRequest={setDevHasPendingRequest}
            openModal={openModal}
            setOpenModal={setOpenModal}
            openPreviewModal={openPreviewModal}
            setOpenPreviewModal={setOpenPreviewModal}
            devHasNotSignedCertificate={devHasNotSignedCertificate}
            setDevHasNotSignedCertificate={setDevHasNotSignedCertificate}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default SuperVisorVacationRequestList;
