import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Button } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useStyles from './styles';

function SearchMonth({
  className, nextMonth, setNextMonth, ...rest
}) {
  if (!nextMonth) {
    return null;
  }
  const classes = useStyles();

  const handlePrevMonth = () => {
    const prevMonth = new Date(nextMonth);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setNextMonth(prevMonth.toISOString());
  };

  const handleNextMonth = () => {
    const nextMes = new Date(nextMonth);
    nextMes.setMonth(nextMes.getMonth() + 1);
    setNextMonth(nextMes.toISOString());
  };

  return (
    <Grid container justifyContent="flex-end" spacing={1} {...rest}>
      <Grid item lg={8} xl={6}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={handlePrevMonth}
              color="primary"
              className={classes.dateButtons}
            >
              <NavigateBeforeIcon />
            </Button>
            <DatePicker
              inputFormat="MM-yyyy"
              views={['year', 'month']}
              label="Mes y Año"
              minDate={new Date('2020-02-01')}
              maxDate={new Date('2030-01-01')}
              value={nextMonth}
              onChange={(value) => setNextMonth(value.toISOString())}
              renderInput={(params) => (
                <TextField {...params} fullWidth variant="outlined" />
              )}
            />
            <Button
              onClick={handleNextMonth}
              color="primary"
              className={classes.dateButtons}
            >
              <NavigateNextIcon />
            </Button>
          </div>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

SearchMonth.propTypes = {
  className: PropTypes.string,
  nextMonth: PropTypes.any,
  setNextMonth: PropTypes.func,
};

export default SearchMonth;
