import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import useStyles from './styles';
import Page from '../../../components/Layout/Page';
import InfoCard from './InfoCard';
import billRequests from '../../../requests/api/bill';
import Filters from './Filters';
import clientsRequest from '../../../requests/api/client';
import billsFiltersActions from '../../../actions/billActions';
import Results from './Results';
import { ISSUED } from '../../../utils/const/billStatus';

function BillReceivables() {
  const classes = useStyles();
  const [bills, setBills] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalBills, setTotalBills] = useState(0);
  const [clients, setClients] = useState([]);
  const [filters, setFilters] = useState({
    client: '',
    search: '',
  });
  const [cardsData, setCardsData] = useState({});
  const [refresh, setRefresh] = useState(false);

  const billsRedux = useSelector((state) => state.bill);
  const [reduxFilters, setReduxFilters] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const setReduxInfo = async () => {
      if (Object.keys(billsRedux.billsReceivablesFilters).length > 0) {
        setFilters({ ...filters, ...billsRedux.billsReceivablesFilters });
      }
      setReduxFilters(true);
    };
    setReduxInfo();
  }, []);

  useEffect(() => {
    const getBills = async () => {
      const response = await billRequests.getBillsPerPage({
        page: pageOfTable,
        limit,
        searchValue: filters.search || '',
        searchStartMonth: '',
        searchEndMonth: '',
        searchClient: filters.client.name || '',
        searchStatus: ISSUED,
        searchOrder: 'ASC',
      });
      if (response.success) {
        const data = await response.data.data.bills;
        const arrayData = Object.values(data);
        setBills(arrayData);
        setTotalBills(response.data.data.totalBills);
        setCardsData(response.data.data.issuedBillsData);
      }
    };

    const setBillsFilters = async () => {
      dispatch(billsFiltersActions.setBillsReceivablesFilters(filters));
    };

    if (clients.length > 0 && reduxFilters) {
      setBillsFilters();
      getBills();
    }
  }, [filters, clients, pageOfTable, limit, refresh]);

  useEffect(() => {
    const getClients = async () => {
      const response = await clientsRequest.getAllClients();
      if (response.success) {
        const data = await response.data.data.clients;
        const arrayData = Object.values(data);
        setClients(arrayData);
      }
    };
    getClients();
  }, []);

  return (
    <Page className={classes.root} title="TubeOps | Cuentas por cobrar">
      <Container maxWidth={false}>
        <div className={classes.headerDiv}>
          <Header />
        </div>
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <InfoCard data={cardsData} card={0} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InfoCard data={cardsData} card={1} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InfoCard data={cardsData} card={2} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InfoCard data={cardsData} card={3} />
          </Grid>
        </Grid>
        <div className={classes.chartDiv}>
          <Filters
            filters={filters}
            setFilters={setFilters}
            clients={clients}
          />
          <Results
            bills={bills}
            totalBills={totalBills}
            pageOfTable={pageOfTable}
            setPageOfTable={setPageOfTable}
            limit={limit}
            setLimit={setLimit}
            setRefresh={setRefresh}
          />
        </div>
      </Container>
    </Page>
  );
}

export default BillReceivables;
