import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Request from '../../../requests/api/companies';
import CompanyEditForm from './CompanyEditForm';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function CompanyEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [company, setCompany] = useState();

  useEffect(() => {
    const getCompany = async () => {
      const response = await Request.getCompanyById(props.match.params.id);
      if (isMountedRef.current) {
        setCompany(response.data.data.company);
      }
    };

    getCompany();
    // eslint-disable-next-line
  }, [props]);

  if (!company) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Template Title | Editar Compañía"
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
        <CompanyEditForm company={company} />
        </Box>
      </Container>
    </Page>
  );
}

CompanyEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(CompanyEditView);
