import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';

function SearchClientBar({ setProjectId, projectId, projects }) {
  if (projects.length === 0) {
    return null;
  }

  const handleSearchProjectChange = (event, value) => {
    if (value) {
      setProjectId(value.id);
    }
  };

  return (
    // buscar por cliente - proyecto y que se seleccione el projectId
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          disablePortal
          options={projects}
          value={projects.find((project) => project.id === projectId)}
          id="combo-box-demo"
          getOptionLabel={(option) => `${option.client.name} - ${option.name}`}
          isOptionEqualToValue={(option) => option.id === projectId}
          onChange={handleSearchProjectChange}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Nombre Proyecto" />
          )}
        />
      </Grid>
    </Grid>
  );
}

SearchClientBar.propTypes = {
  className: PropTypes.string,
  setProjectId: PropTypes.func,
  projects: PropTypes.array,
  projectId: PropTypes.number,
};

export default SearchClientBar;
