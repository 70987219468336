/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import projectRequest from '../../../requests/api/project';
import useStyles from './styles';
import { compareDateMonths, firstDayOfCurrentMonth } from '../../../utils/date';
import developerGraphicActions from '../../../actions/developerGraphicActions';
import { DEVELOPER } from '../../../utils/const/user_types';

function ProjectGraphicView() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [month, setMonth] = useState(null);
  const [projectId, setProjectId] = useState();
  const [projects, setProjects] = useState([]);
  const [autocompleteDefaultValue, setAutocompleteDefaultValue] = useState([]);
  const [ids, setIds] = useState([]);
  const [developerIndex, setDeveloperIndex] = useState(-1);
  const history = useHistory();
  const projectGraphicReduxInfo = useSelector((state) => state.projectGraphic);
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();

  useEffect(() => {
    const setReduxInfo = async () => {
      if (
        projectGraphicReduxInfo.projectGraphic.month
        && projectGraphicReduxInfo.projectGraphic.projectId
      ) {
        setMonth(projectGraphicReduxInfo.projectGraphic.month);
      } else {
        setMonth(firstDayOfCurrentMonth(new Date()));
      }
      if (projectGraphicReduxInfo.projectGraphic.projectId) {
        setProjectId(projectGraphicReduxInfo.projectGraphic.projectId);
      }
    };
    setReduxInfo();
  }, []);

  useEffect(() => {
    const getDeveloperProjectsPerMonth = async () => {
      const response = await projectRequest.getProjectGraphic({
        id: projectId,
        month,
      });
      if (response.success) {
        const projectData = await response.data.data.totalProjectDevelopers;
        if (projectData.assignedHours) {
          const dataArray = [];
          dataArray.push({
            name: 'Horas Asignadas',
            data: projectData.assignedHours,
          });

          dataArray.push({
            name: 'Horas Proyectadas',
            data: projectData.projectedHours,
          });
          dataArray.push({
            name: 'Horas Reales',
            data: projectData.realHours,
          });

          setIds(projectData.developersIds);
          setData(dataArray);
          setCategories(projectData.developersName);
        } else {
          setIds([]);
          setData([]);
          setCategories([]);
        }
      }
    };

    if (projectId && month) getDeveloperProjectsPerMonth();
  }, [month, projectId]);

  useEffect(() => {
    const getProjects = async () => {
      const response = await projectRequest.getProjectsPerPage({
        page: 0,
        limit: 200,
        searchClient: '',
        searchProject: '',
        month,
        status: 'Activo',
      });
      if (response.success) {
        const projectsData = await response.data.data.projects;
        const arrayData = Object.values(projectsData);
        const firstElement = arrayData[0];

        let project = null;
        project = projectId || firstElement.id;

        if (account.user.role.name === DEVELOPER) {
          let hours = 0;
          if (project === firstElement.id) {
            for (let i = 0; i < response.data.data.projects.length; i += 1) {
              const projectCells = response.data.data.projects[
                i
              ].project_cells.filter((pc) => pc.project_developers.length > 0);

              let projectDevelopers = [];
              if (projectCells.length > 0) {
                projectDevelopers = projectCells[0].project_developers;
              }
              for (let j = 0; j < projectDevelopers.length; j += 1) {
                if (compareDateMonths(projectDevelopers[j].assignation.month, month)
                  && projectDevelopers[j].assignation.developer.id
                    === account.user.developer.id
                  && projectDevelopers[j].assignedHours >= hours
                ) {
                  project = response.data.data.projects[i].id;
                  hours = projectDevelopers[j].assignedHours;
                }
              }
            }
          }
          setProjectId(project);
        }
        setAutocompleteDefaultValue(arrayData.filter((e) => e.id === project));
        setProjects(arrayData);
      }
    };
    if (month) getProjects();
  }, [month]);

  useEffect(() => {
    const redirectDeveloperGraphicView = async () => {
      if (developerIndex >= 0) {
        const id = ids[developerIndex];
        dispatch(
          developerGraphicActions.setDeveloperGraphic({
            developerId: id,
            month,
          }),
        );
        history.push('/administracion/developers/grafico');
      }
    };
    redirectDeveloperGraphicView();
  }, [developerIndex]);

  return (
    <Page className={classes.root} title="TubeOps | Gráfico Proyectos">
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          {autocompleteDefaultValue.length ? (
            <Results
              categories={categories}
              data={data}
              month={month}
              setMonth={setMonth}
              projects={projects}
              projectId={projectId}
              setProjectId={setProjectId}
              autocompleteDefaultValue={autocompleteDefaultValue}
              setDeveloperIndex={setDeveloperIndex}
            />
          ) : null}
        </Box>
      </Container>
    </Page>
  );
}

ProjectGraphicView.propTypes = {
  match: PropTypes.any,
};

export default ProjectGraphicView;
