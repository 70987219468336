const UNDEFINED = 'Indefinido';
const HONORARIUM = 'Honorarios';
const FIXED_TERM = 'Plazo fijo';

const contractTypesList = [
  UNDEFINED,
  HONORARIUM,
  FIXED_TERM,
];

module.exports = {
  UNDEFINED,
  HONORARIUM,
  FIXED_TERM,
  contractTypesList,
};
