import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import vacationRequest from '../../../requests/api/vacationRequest';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    maxWidth: '600px',
    marginTop: '8%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

export default function UploadCertificateUrlModal({
  setOpenAddUrlModal, openAddUrlModal, request,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [certificateUrl, setCertificateUrl] = useState('');
  const [certificateSignDate, setCertificateSignDate] = useState('');

  const handleUploadLink = async () => {
    try {
      const emailStartDate = request.startDate.split('-').reverse().join('/');
      const emailEndDate = request.endDate.split('-').reverse().join('/');
      const response = await vacationRequest.putVacationRequestById({
        id: request.id,
        developerId: request.developerId,
        developerEmail: `${request.developer.user.email}`,
        developerName: `${request.developer.user.name} ${request.developer.user.lastname}`,
        supervisorId: request.supervisorId,
        status: request.status,
        daysRequested: request.daysRequested,
        startDate: request.startDate,
        endDate: request.endDate,
        certificateUrl,
        certificateSignDate,
        emailStartDate,
        emailEndDate,
      });
      if (response.success) {
        enqueueSnackbar('Url agregada exitosamente', { variant: 'success' });
        window.location.reload();
      }
    } catch (err) {
      enqueueSnackbar(err, { variant: 'error' });
    }
  };

  return (
    <Box
      mt={2}
      display='flex'
      flexDirection='column'
      gridGap={10}
    >
      {openAddUrlModal && (
        <Modal
          open={openAddUrlModal}
          onClose={() => setOpenAddUrlModal(false)}
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='75%'
          >
            <Card className={classes.modal}>
              <CardContent className={classes.modalContent}>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    flex={1}
                  >
                    <Box
                      display='flex'
                      mb={2}
                    >
                      <Typography
                        variant='h3'
                      >
                        Subir certificado de vacaciones firmado
                      </Typography>
                    </Box>
                    <Divider />
                    <Box mx='auto' my={3}>
                      <Box m='auto'>
                        <Box m='auto'>
                          <Typography
                            variant='h4'
                            align='center'
                          >
                            Ingresa el link del certificado de vacaciones firmado
                            para concretar la solicitud.
                          </Typography>
                        </Box>
                      </Box>
                      <Box m='auto' width='70%'>
                        <Box mt={2}>
                          <Typography variant='h5'>
                            Url del certificado:
                          </Typography>
                        </Box>
                        <Box mt={1}>
                          <TextField
                            fullWidth
                            name="certificateUrl"
                            value={certificateUrl}
                            onChange={(e) => setCertificateUrl(e.target.value)}
                            required
                            placeholder='Url de certificado'
                            variant="outlined"
                          />
                        </Box>
                        <Box mt={2}>
                          <Typography variant='h5'>
                            Fecha en la que el documento fue firmado:
                          </Typography>
                        </Box>
                        <Box mt={1}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={esLocale}
                          >
                            <DesktopDatePicker
                              inputFormat="dd-MM-yyyy"
                              views={['day']}
                              label="Fecha de firma"
                              value={certificateSignDate || null}
                              onChange={(e) => setCertificateSignDate(e)}
                              renderInput={(params) => <TextField
                                {...params}
                                variant='outlined'
                                fullWidth
                              />}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                      <Box m='auto' width='95%'>
                        <Box mt={2}>
                          <Typography
                            variant='h4'
                            align='center'
                          >
                            Una vez que el certificado haya sido ingresado a la plataforma,
                            la solicitud no podrá ser rechazada ni cancelada.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display='flex'
                  padding='10px 24px'
                >
                  <Box width={'49%'} mr={2}>
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={() => setOpenAddUrlModal(false)}
                      variant='contained'
                    >
                      Volver
                    </Button>
                  </Box>
                  <Box width={'49%'}>
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={handleUploadLink}
                      variant='contained'
                    >
                      Confirmar
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Modal>
      )}
    </Box>
  );
}

UploadCertificateUrlModal.propTypes = {
  request: PropTypes.object,
  openAddUrlModal: PropTypes.boolean,
  setOpenAddUrlModal: PropTypes.func,
};
