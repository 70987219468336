import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import PerformanceCycleCreateForm from './PerformanceCycleCreateForm';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function PerformanceCycleCreateView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="TubeOps | Crear Ciclo de Performance"
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <PerformanceCycleCreateForm />
        </Box>
      </Container>
    </Page>
  );
}

PerformanceCycleCreateView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(PerformanceCycleCreateView);
