import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import Request from '../../../requests/api/developer';
import useStyles from './styles';

function DevelopersListView() {
  const classes = useStyles();
  const [developers, setDevelopers] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [totalDevelopers, setTotalDevelopers] = useState(0);

  useEffect(() => {
    const getDeveloper = async () => {
      const response = await Request.getDevelopersPerPage(pageOfTable, limit, searchValue);
      if (response.success) {
        const data = await response.data.data.developers;
        const arrayData = Object.values(data);
        setDevelopers(arrayData);
        setTotalDevelopers(response.data.data.totalDevelopers);
      }
    };
    getDeveloper();
  }, [pageOfTable, limit, searchValue]);

  return (
      <Page
        className={classes.root}
        title="TubeOps | Developers"
      >
        <Container maxWidth={false}>
          <Header />
          {developers && (
            <Box mt={3}>
              <Results
                developers={developers}
                pageOfTable={pageOfTable}
                setPageOfTable={setPageOfTable}
                limit={limit}
                setLimit={setLimit}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                totalDevelopers={totalDevelopers}
              />
            </Box>
          )}
        </Container>
      </Page>
  );
}

export default DevelopersListView;
