import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Typography } from '@material-ui/core';
import clsx from 'clsx';
import pesoChileno from '../../../utils/moneyFormat';
import useStyles from './styles';

function InfoCard({ data, card }) {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      if (card === 0) {
        setTitle('Total');
        setAmount(data.Total);
      }
      if (card === 1) {
        setTitle('Vencido');
        setAmount(data.Vencido);
      }
      if (card === 2) {
        setTitle('Por vencer');
        setAmount(data['Por vencer']);
      }
      if (card === 3) {
        setTitle('Vigente');
        setAmount(data.Vigente);
      }
    }
  }, [data]);

  if (Object.keys(data).length === 0) {
    return null;
  }

  return (
    <div>
      <Card className={clsx(classes.infoCard)}>
        <Typography className={classes.infoCardtitle}>{title}</Typography>
        <Typography
          className={clsx(classes.infoCardNumber, {
            [classes.blue]: card === 0,
            [classes.red]: card === 1,
            [classes.yellow]: card === 2,
            [classes.green]: card === 3,
          })}
        >{`${pesoChileno(amount)}`}</Typography>
      </Card>
    </div>
  );
}

InfoCard.propTypes = {
  data: PropTypes.object,
  card: PropTypes.number.isRequired,
};

export default InfoCard;
