import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
}));

function Header({
  className, ...rest
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box>
      <Grid
        className={clsx(classes.root, className)}
        container
        justifyContent="space-between"
        spacing={3}
        {...rest}
      >
        <Grid item>
          <Typography
            variant="h3"
            color="textPrimary"
          >
            Detalles objetivo
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => history.goBack()}>
            Atrás
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
