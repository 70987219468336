import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';
import axios from '../../utils/axios';

const postCreateObjective = async ({
  performanceCycleInstanceId,
  type,
  description,
  expectedResults,
  monthlyLoad,
  bonusPercentage,
}) => {
  try {
    const response = await axios.post('/api/objectives', {
      performanceCycleInstanceId,
      type,
      description,
      expectedResults,
      monthlyLoad,
      bonusPercentage,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postCreateObjectiveAction = async ({
  objectiveId,
  type,
  description,
  approved,
  expectedResults,
  completed,
}) => {
  try {
    const response = await axios.post(`/api/objectives/${objectiveId}/actions`, {
      type,
      description,
      approved,
      expectedResults,
      completed,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postCreateObjectiveComment = async ({
  objectiveId,
  commentingDeveloperId,
  isEvaluationComment,
  comment,
}) => {
  try {
    const response = await axios.post(`/api/objectives/${objectiveId}/comments`, {
      commentingDeveloperId,
      isEvaluationComment,
      comment,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getObjectivesPerPage = async (instanceId, page, limit, searchValue, objectivesSort) => {
  try {
    const { data } = await axios.get(`/api/performance-cycles/instances/${instanceId}/objectives?page=${page}&limit=${limit}&searchValue=${searchValue}&objectivesSort=${objectivesSort}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getObjectiveActionsPerPage = async (objectiveId, page, limit) => {
  try {
    const { data } = await axios.get(`/api/objectives/${objectiveId}/actions?page=${page}&limit=${limit}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getObjectiveCommentsPerPage = async (objectiveId, page, limit, searchValue) => {
  try {
    const { data } = await axios.get(`/api/objectives/${objectiveId}/comments?page=${page}&limit=${limit}&searchValue=${searchValue}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getObjectiveActionById = async (actionId) => {
  try {
    const { data } = await axios.get(`/api/objectives/actions/${actionId}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getObjectiveById = async (objectiveId) => {
  try {
    const { data } = await axios.get(`/api/objectives/${objectiveId}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putObjectiveActionById = async (
  {
    actionId,
    approved,
    completed,
  },
) => {
  try {
    const response = await axios.put(`/api/objectives/actions/${actionId}`, {
      approved,
      completed,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putObjectiveById = async (
  {
    objectiveId,
    type,
    description,
    expectedResults,
    monthlyLoad,
    bonusPercentage,
    active,
    evaluation,
    approved,
  },
) => {
  try {
    const response = await axios.put(`/api/objectives/${objectiveId}`, {
      type,
      description,
      expectedResults,
      monthlyLoad,
      bonusPercentage,
      active,
      evaluation,
      approved,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteObjectiveActionById = async (actionId) => {
  try {
    const { data } = await axios.delete(`/api/objectives/actions/${actionId}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteObjectiveCommentById = async (commentId) => {
  try {
    const { data } = await axios.delete(`/api/objectives/comments/${commentId}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteObjectiveById = async (objectiveId) => {
  try {
    const { data } = await axios.delete(`/api/objectives/${objectiveId}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAllObjectivesPerPage = async (page, limit, searchValue, cycleId, objectivesSort) => {
  try {
    const { data } = await axios.get(`/api/objectives?page=${page}&limit=${limit}&searchValue=${searchValue}&cycleId=${cycleId}&objectivesSort=${objectivesSort}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  postCreateObjective,
  postCreateObjectiveComment,
  postCreateObjectiveAction,
  getObjectivesPerPage,
  getObjectiveActionsPerPage,
  getObjectiveActionById,
  getObjectiveById,
  getObjectiveCommentsPerPage,
  putObjectiveById,
  putObjectiveActionById,
  deleteObjectiveActionById,
  deleteObjectiveCommentById,
  deleteObjectiveById,
  getAllObjectivesPerPage,
};
