import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';
import axios from '../../utils/axios';

const getInstancesPerPage = async (page, limit, name) => {
  try {
    const { data } = await axios.get(`/api/performance-cycle/instances?page=${page}&limit=${limit}&name=${name}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postCreatePerformanceCycleInstance = async ({
  supervisorDeveloperId,
  performanceCycleId,
  projectsEvaluationAvg,
  objectivesEvaluationAvg,
  hoursEvaluation,
  objectiveMonthlyHours,
  projectsPerformanceWeight,
  objectivesWeight,
  hourCompletionWeight,
}) => {
  try {
    const response = await axios.post('/api/performance-cycle/instances', {
      supervisorDeveloperId,
      performanceCycleId,
      projectsEvaluationAvg,
      objectivesEvaluationAvg,
      hoursEvaluation,
      objectiveMonthlyHours,
      projectsPerformanceWeight,
      objectivesWeight,
      hourCompletionWeight,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putPerformanceCycleInstanceById = async (
  {
    id,
    supervisorDeveloperId,
    projectsEvaluationAvg,
    objectivesEvaluationAvg,
    hoursEvaluation,
    objectiveMonthlyHours,
    projectsPerformanceWeight,
    objectivesWeight,
    hourCompletionWeight,
    cycleReportUrl,
  },
) => {
  try {
    const response = await axios.put(`/api/performance-cycles/instances/${id}`, {
      supervisorDeveloperId,
      projectsEvaluationAvg,
      objectivesEvaluationAvg,
      hoursEvaluation,
      objectiveMonthlyHours,
      projectsPerformanceWeight,
      objectivesWeight,
      hourCompletionWeight,
      cycleReportUrl,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getPerformanceCycleInstanceById = async (id) => {
  try {
    const { data } = await axios.get(`/api/performance-cycles/instances/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deletePerformanceCycleInstanceById = async (id) => {
  try {
    const { data } = await axios.delete(`/api/performance-cycle/instances/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getPerformanceCycleInstancesPerPage = async (
  performanceCycleId, page, limit, searchValue,
) => {
  try {
    const { data } = await axios.get(`/api/performance-cycles/${performanceCycleId}/instances?page=${page}&limit=${limit}&searchValue=${searchValue}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getPerformanceCycleInstanceByDeveloperId = async (id, cycleId) => {
  try {
    const { data } = await axios.get(`/api/performance-cycles-instances/${id}/${cycleId}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  getInstancesPerPage,
  postCreatePerformanceCycleInstance,
  putPerformanceCycleInstanceById,
  getPerformanceCycleInstanceById,
  deletePerformanceCycleInstanceById,
  getPerformanceCycleInstancesPerPage,
  getPerformanceCycleInstanceByDeveloperId,
};
