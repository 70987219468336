/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Edit as EditIcon, Search as SearchIcon } from 'react-feather';
import { useSnackbar } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import Request from '../../../requests/api/demands';
import useStyles from './styles';
import getInitials from '../../../utils/getInitials';
import { SUPER_ADMIN, SYSTEM_ADMIN } from '../../../utils/const/user_types';

function Results({
  className,
  demand,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  totalDemands,
  month,
  setMonth,
  filters,
  setFilters,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectDemand, setSelectDemand] = useState([]);
  const [currentDemand, setCurrentDemand] = useState(demand);

  useEffect(() => {
    setCurrentDemand(demand);
  }, [demand]);

  const handleFiltersChange = (name, event) => {
    event.persist();
    let value = null;
    value = event.target.value;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectAllDemands = (event) => {
    setSelectDemand(event.target.checked ? currentDemand.map((e) => e.id) : []);
  };

  const handleSelectOneDemand = (event, demandId) => {
    if (!selectDemand.includes(demandId)) {
      setSelectDemand((prevSelected) => [...prevSelected, demandId]);
    } else {
      setSelectDemand((prevSelected) => prevSelected.filter((id) => id !== demandId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const getMonth = (date) => {
    const dateFormat = new Date(date);
    const Month = dateFormat.toLocaleString('es-es', { month: 'long' });
    return Month;
  };

  const handleMonthOnChange = (date, name) => {
    if (date && date.getFullYear() > 2020) {
      const Month = date.getMonth();
      const Year = date.getFullYear();
      const firstDayOfTheMonth = new Date(Year, Month, 1);
      const valid = Date.parse(firstDayOfTheMonth);
      if (!Number.isNaN(valid)) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: firstDayOfTheMonth.toISOString(),
        }));
      }
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: '',
      }));
    }
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectDemand.length > 0;
  const selectSomeDemands = selectDemand.length > 0 && selectDemand.length < currentDemand.length;
  const selectedAllDemands = selectDemand.length === currentDemand.length;
  const account = useSelector((state) => state.account);

  const deleteDemands = async () => {
    if (account.user.role.name !== SUPER_ADMIN && account.user.role.name !== SYSTEM_ADMIN) {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectDemand.length; a += 1) {
        Request.deleteDemand(selectDemand[a]);
        enqueueSnackbar('Demandas eliminadas!', {
          variant: 'success',
        });
      }
      setCurrentDemand(
        currentDemand.filter((e) => !selectDemand.includes(e.id)),
      );
      setSelectDemand([]);
    }
  };

  const handleBlockedDemand = (e) => {
    e.preventDefault();
    enqueueSnackbar('Demanda bloqueada!', {
      variant: 'error',
    });
  };

  return (
    <Card className={clsx(classes.emptyRoot, className)} {...rest}>
      <Grid
        container
        spacing={3}
        className={classes.filtersContainer}
        display="flex"
        alignItems="center"
        flexwrap="wrap"
      >
        <Grid item md={4} xs={12}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleFiltersChange('client', e)}
            placeholder="Buscar cliente"
            value={filters.client}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleFiltersChange('project', e)}
            placeholder="Buscar proyecto"
            value={filters.project}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DatePicker
              inputFormat="MM-yyyy"
              views={['year', 'month']}
              label="Mes y Año"
              minDate={new Date('2022-02-01')}
              maxDate={new Date('2024-01-01')}
              value={filters.month}
              onChange={(e) => handleMonthOnChange(e, 'month')}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" error={false} />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllDemands}
              indeterminate={selectSomeDemands}
              onChange={handleSelectAllDemands}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteDemands()}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllDemands}
                    indeterminate={selectSomeDemands}
                    onChange={handleSelectAllDemands}
                  />
                </TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Projecto</TableCell>
                <TableCell>Mes</TableCell>
                <TableCell>Horas Mínimas</TableCell>
                <TableCell>Horas Máximas</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentDemand.map((element) => {
                const isDemandSelected = selectDemand.includes(element.id);
                return (
                  <TableRow hover key={element.id} selected={isDemandSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isDemandSelected}
                        onChange={(event) => handleSelectOneDemand(event, element.id)
                        }
                        value={isDemandSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatar} src={element.avatar}>
                          {getInitials(element.project.client.name)}
                        </Avatar>
                        <div>
                          <Typography color="inherit" variant="h6">
                            {element.project.client.name}
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>{element.project.name}</TableCell>
                    <TableCell>{getMonth(element.month)}</TableCell>
                    <TableCell>{element.minHours}</TableCell>
                    <TableCell>{element.maxHours}</TableCell>
                    {element.blocked ? (
                      <TableCell align="right">
                        <IconButton
                          component={RouterLink}
                          // disabled
                          onClick={handleBlockedDemand}
                        >
                          <SvgIcon fontSize="small">
                            <EditIcon style={{ color: '#8c1111' }} />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    ) : (
                      <TableCell align="right">
                        <IconButton
                          component={RouterLink}
                          to={`/administracion/demand/${element.id}/editar`}
                        >
                          <SvgIcon fontSize="small" fill="red">
                            <EditIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalDemands}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  demand: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  totalDemands: PropTypes.number,
  month: PropTypes.string,
  setMonth: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default Results;
