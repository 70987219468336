const CLIENT = 'client';
const SUPER_ADMIN = 'superAdmin';
const SYSTEM_ADMIN = 'systemAdmin';
const USER = 'user';
const DEVELOPER = 'developer';
const ADMINISTRATION = 'administrator';

module.exports = {
  CLIENT,
  DEVELOPER,
  SUPER_ADMIN,
  USER,
  SYSTEM_ADMIN,
  ADMINISTRATION,
};
