import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Edit as EditIcon, Search as SearchIcon } from 'react-feather';
import { useSnackbar } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import Request from '../../../requests/api/availability';
import useStyles from './styles';
import getInitials from '../../../utils/getInitials';
import { SUPER_ADMIN, SYSTEM_ADMIN } from '../../../utils/const/user_types';

const sortOptions = [
  {
    value: 'updated_at|desc',
    label: 'Más nuevo primero',
  },
  {
    value: 'updated_at|asc',
    label: 'Más antiguo primero',
  },
];

function Results({
  className,
  availability,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  searchValue,
  setSearchValue,
  totalAvailabilities,
  month,
  setMonth,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectAvailability, setSelectAvailability] = useState([]);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [currentAvailability, setCurrentAvailability] = useState(availability);

  useEffect(() => {
    setCurrentAvailability(availability);
  }, [availability]);

  const handleQueryChange = (event) => {
    event.persist();
    setSearchValue(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllAvailabilities = (event) => {
    setSelectAvailability(
      event.target.checked ? currentAvailability.map((e) => e.id) : [],
    );
  };

  const handleSelectOneAvailability = (event, availabilityId) => {
    if (!selectAvailability.includes(availabilityId)) {
      setSelectAvailability((prevSelected) => [
        ...prevSelected,
        availabilityId,
      ]);
    } else {
      setSelectAvailability((prevSelected) => prevSelected.filter((id) => id !== availabilityId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const getMonth = (date) => {
    const dateFormat = new Date(date);
    const Month = dateFormat.toLocaleString('es-es', { month: 'long' });
    return Month;
  };

  const handleMonthOnChange = (date) => {
    if (date && (date.getFullYear() > 2020)) {
      const Month = date.getMonth();
      const Year = date.getFullYear();
      const firstDayOfTheMonth = new Date(Year, Month, 1);
      const valid = Date.parse(firstDayOfTheMonth);
      if (!Number.isNaN(valid)) {
        setMonth(firstDayOfTheMonth.toISOString());
      }
    } else {
      setMonth('');
    }
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectAvailability.length > 0;
  const selectSomeAvailabilities = selectAvailability.length > 0
    && selectAvailability.length < currentAvailability.length;
  const selectedAllAvailabilites = selectAvailability.length === currentAvailability.length;
  const account = useSelector((state) => state.account);

  const deleteAvailabilities = async () => {
    if (account.user.role.name !== SUPER_ADMIN && account.user.role.name !== SYSTEM_ADMIN) {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectAvailability.length; a += 1) {
        Request.deleteAvailability(selectAvailability[a]);
        enqueueSnackbar('Disponibilidades eliminadas!', {
          variant: 'success',
        });
      }
      setCurrentAvailability(
        currentAvailability.filter((e) => !selectAvailability.includes(e.id)),
      );
      setSelectAvailability([]);
    }
  };

  const handleBlockedAvailability = (e) => {
    e.preventDefault();
    enqueueSnackbar('Disponibilidad bloqueada!', {
      variant: 'error',
    });
  };

  return (
    <Card className={clsx(classes.emptyRoot, className)} {...rest}>
      <Divider />
      <Box p={2} minHeight={56} display="flex" alignItems="center">
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Buscar disponibilidad"
          value={searchValue}
          variant="outlined"
        />
        <Box flexGrow={0.1} />
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <DatePicker
            inputFormat="MM-yyyy"
            views={['year', 'month']}
            label="Mes y Año"
            minDate={new Date('2022-02-01')}
            maxDate={new Date('2024-01-01')}
            value={month}
            onChange={(e) => handleMonthOnChange(e)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={false}
              />
            )}
          />
        </LocalizationProvider>

        <Box flexGrow={1} />
        <TextField
          label="Orden"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllAvailabilites}
              indeterminate={selectSomeAvailabilities}
              onChange={handleSelectAllAvailabilities}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteAvailabilities()}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllAvailabilites}
                    indeterminate={selectSomeAvailabilities}
                    onChange={handleSelectAllAvailabilities}
                  />
                </TableCell>
                <TableCell>Desarrollador</TableCell>
                <TableCell>Mes</TableCell>
                <TableCell>Horas Mínimas</TableCell>
                <TableCell>Horas Maximas</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentAvailability.map((element) => {
                const isUserSelected = selectAvailability.includes(element.id);
                return (
                  <TableRow hover key={element.id} selected={isUserSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isUserSelected}
                        onChange={(event) => handleSelectOneAvailability(event, element.id)
                        }
                        value={isUserSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatar} src={element.avatar}>
                          {getInitials(element.developer.user.name)}
                        </Avatar>
                        <div>
                          <Typography color="inherit" variant="h6">
                            {element.developer.user.name}{' '}
                            {element.developer.user.lastname}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {element.developer.user.email}
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>{getMonth(element.month)}</TableCell>
                    <TableCell>{element.minHours}</TableCell>
                    <TableCell>{element.maxHours}</TableCell>
                    {element.blocked
                      ? <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        // disabled
                        onClick={handleBlockedAvailability}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon style={{ color: '#8c1111' }}/>
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                      : <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/administracion/availability/${element.id}/editar`}
                      >
                        <SvgIcon fontSize="small" fill="red">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalAvailabilities}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  availability: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  totalAvailabilities: PropTypes.number,
  month: PropTypes.string,
  setMonth: PropTypes.func,
};

Results.defaultProps = {
  users: [],
};

export default Results;
