/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box, Card, Divider, Button,
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import Autocomplete from '@mui/material/Autocomplete';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DataGraph from './Graphic';
import { firstDayOfCurrentMonth } from '../../../utils/date';
import useStyles from './styles';
import controlPanelGraphicActions from '../../../actions/controlPanelGraphicActions';
import { DEVELOPER } from '../../../utils/const/user_types';
import projectCellActions from '../../../actions/projectCellActions';
import developerGraphicActions from '../../../actions/developerGraphicActions';

function Results({
  className,
  data,
  categories,
  month,
  setMonth,
  projects,
  projectId,
  setProjectId,
  autocompleteDefaultValue,
  setDeveloperIndex,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  const handleMonthOnChange = (date) => {
    if (date && date.getFullYear() > 2020) {
      const Month = date.getMonth();
      const Year = date.getFullYear();
      const firstDayOfTheMonth = new Date(Year, Month, 1);
      const valid = Date.parse(firstDayOfTheMonth);
      if (!Number.isNaN(valid)) {
        setMonth(firstDayOfTheMonth.toISOString());
      }
    } else {
      setMonth(firstDayOfCurrentMonth(new Date()));
    }
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(month);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setMonth(prevMonth.toISOString());
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(month);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setMonth(nextMonth.toISOString());
  };

  const handleButtonOnClick = async () => {
    dispatch(
      controlPanelGraphicActions.setControlPanelGraphic({
        name: 'project',
        month,
      }),
    );
    history.push('/administracion/panel-de-control');
  };

  const handleIndividualAssignmentOnClick = async () => {
    dispatch(
      developerGraphicActions.setDeveloperGraphic({
        developerId: account.user.developer.id,
        month,
      }),
    );

    history.push(
      '/administracion/developers/grafico',
    );
  };

  const handleRedirectToProjectCellButton = async () => {
    dispatch(
      projectCellActions.setProjectCell({
        projectId,
        month,
      }),
    );
    history.push('/administracion/celulas-de-proyecto/panel');
  };

  return (
    <Card className={clsx(classes.rootResults, className)} {...rest}>
      <Divider />
      <Box p={2} minHeight={56} display="flex" alignItems="center" spacing={2}>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12}>
            <Autocomplete
              fullWidth
              disablePortal
              disableClearable
              options={projects}
              defaultValue={autocompleteDefaultValue[0]}
              id="combo-box-demo"
              getOptionLabel={(option) => `${option.client.name} - ${option.name}`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, value) => setProjectId(value.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Cliente-Proyecto"
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esLocale}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={handlePrevMonth}
                    color="primary"
                    className={classes.dateButtons}

                  >
                    <NavigateBeforeIcon />
                  </Button>
                  <DesktopDatePicker
                    inputFormat="MM-yyyy"
                    views={['year', 'month']}
                    label="Mes y Año"
                    minDate={new Date('2022-01-02')}
                    maxDate={new Date('2024-01-01')}
                    value={month}
                    onChange={(e) => handleMonthOnChange(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Button
                    onClick={handleNextMonth}
                    color="primary"
                    className={classes.dateButtons}
                  >
                    <NavigateNextIcon />
                  </Button>
                </div>
              </LocalizationProvider>
            </Grid>
          <Grid item md={2} xs={12} align="right">
            {account.user.role.name !== DEVELOPER ? (
              <div className={classes.buttonsDiv}>
                <Button
                  color="secondary"
                  className={classes.action}
                  variant="contained"
                  onClick={() => handleRedirectToProjectCellButton()}
                >
                  Asignaciones
                </Button>
                <Button
                  color="secondary"
                  className={classes.action}
                  variant="contained"
                  onClick={() => handleButtonOnClick()}
                >
                  Volver a panel de control
                </Button>
              </div>
            ) : (
              <Button
                color="secondary"
                className={classes.action}
                variant="contained"
                onClick={() => handleIndividualAssignmentOnClick()}
              >
                Volver a asignación individual
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <DataGraph
        categories={categories}
        data={data}
        setDeveloperIndex={setDeveloperIndex}
        role={account.user.role.name}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  month: PropTypes.any,
  startDate: PropTypes.number,
  setMonth: PropTypes.func,
  categories: PropTypes.array,
  projects: PropTypes.array,
  projectId: PropTypes.number,
  setProjectId: PropTypes.func,
  autocompleteDefaultValue: PropTypes.array,
  setDeveloperIndex: PropTypes.func,
};

export default Results;
