/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  List, ListItem, ListItemText, Grid,
} from '@mui/material';
import clsx from 'clsx';
import useStyles from './styles';
import BarChart from './BarChart';
import { formatMonthAndYearMoment } from '../../../utils/date';

function DevelopersBar({
  developers, month, developerId, setDeveloperId,
}) {
  const classes = useStyles();
  const [chartsData, setChartsData] = useState({});

  const handleOnClick = (id) => {
    setDeveloperId(id);
  };

  useEffect(() => {
    const setVariables = async () => {
      for (let i = 0; i < developers.length; i += 1) {
        const developer = developers[i];
        const developerAvailability = developer.availabilities?.find(
          (availability) => formatMonthAndYearMoment(availability.month) === formatMonthAndYearMoment(month),
        ) || [];
        const developerAssignment = developer.assignations?.find(
          (assignment) => formatMonthAndYearMoment(assignment.month) === formatMonthAndYearMoment(month),
        ) || [];
        const projectDevelopers = developerAssignment.project_developers || [];
        const developerAssignedHours = projectDevelopers.reduce(
          (acc, projectDeveloper) => acc + projectDeveloper.assignedHours,
          0,
        ) || 0;
        const developerMinAvailability = Math.max(developerAvailability.minHours - developerAvailability.vacationHours, 0) || 0;
        const developerMaxAvailability = Math.max(developerAvailability.maxHours - developerAvailability.vacationHours, 0) || 0;
        const chartData1 = [
          {
            name: 'Disponibilidad Máxima',
            data: [developerMaxAvailability - developerMinAvailability],
            color: '#BDBDBD',
            stack: 'stack1',
          },
          {
            name: ' Disponibilidad horas',
            data: [developerMinAvailability],
            color: '#24335E',
            stack: 'stack1',
          },
        ];
        const chartData2 = [
          {
            name: 'Horas asignadas',
            data: [developerAssignedHours],
            color: '#00D293',
          },
        ];
        let max = 0;
        if (developerAssignedHours >= developerMaxAvailability) {
          max = developerAssignedHours;
        } else {
          max = developerMaxAvailability;
        }
        setChartsData((prevObject) => ({
          ...prevObject,
          [developer.id]: {
            chartData1,
            chartData2,
            max,
          },
        }));
      }
    };
    if (developers.length > 0) {
      setVariables();
    }
  }, [developers, month]);

  if (Object.keys(chartsData).length === 0) {
    return null;
  }

  return (
    <div className={classes.clientProjectsSquare}>
      <List>
        {developers.map((developer) => (
          <React.Fragment key={developer.id}>
            <ListItem
              className={clsx(classes.developerListItemBlue, {
                [classes.developerListItemWhite]: developer.id === developerId,
                [classes.developerListHover]: developer.id !== developerId,
              })}
              onClick={() => handleOnClick(developer.id)}
            >
              <ListItemText
                disableTypography
                primary={
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      {`${developer.user.name} ${developer.user.lastname}`}
                    </Grid>
                    <Grid item xs={12}>
                      <BarChart
                        title={''}
                        data={chartsData[developer.id].chartData1}
                        max={chartsData[developer.id].max}
                        chart={0}
                      />

                      <BarChart
                        title={''}
                        data={chartsData[developer.id].chartData2}
                        max={chartsData[developer.id].max}
                        chart={1}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

DevelopersBar.propTypes = {
  className: PropTypes.string,
  developers: PropTypes.array,
  month: PropTypes.string,
  developerId: PropTypes.number,
  setDeveloperId: PropTypes.func,
};

export default DevelopersBar;
