import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import usersRequest from '../../../requests/api/user';
import instanceRequest from '../../../requests/api/performance_cycle_instances';
import { SUPER_ADMIN } from '../../../utils/const/user_types';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    maxWidth: '775px',
    marginTop: '8%',
    height: '75%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

export default function EditSupervisorModal({
  instance,
  openSupervisorModal,
  setOpenSupervisorModal,
  setReload,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedSupervisor, setSelectedSupervisor] = useState({});
  const [supervisors, setSupervisors] = useState([]);

  useEffect(() => {
    const getSupervisors = async () => {
      const response = await usersRequest.getUsersByRole(SUPER_ADMIN);
      if (response.success) {
        const data = response.data.data.users.map((u) => ({
          id: u.id,
          name: u.name,
          lastname: u.lastname,
          devId: u.developer.id,
        }));
        setSupervisors(data);
      }
    };
    getSupervisors();
    if (instance.supervisor) {
      setSelectedSupervisor({
        id: instance.supervisor.user.id,
        name: instance.supervisor.user.name,
        lastname: instance.supervisor.user.lastname,
        devId: instance.supervisor.id,
      });
    }
  }, [instance]);

  const handleEditInstanceSupervisor = async () => {
    const response = await instanceRequest.putPerformanceCycleInstanceById({
      id: instance.id,
      supervisorDeveloperId: selectedSupervisor.devId,
    });
    if (response.success) {
      enqueueSnackbar('Cambios guardados', {
        variant: 'success',
      });
    }
    setOpenSupervisorModal(false);
    setReload((prev) => !prev);
  };

  const handleCloseModal = () => {
    setOpenSupervisorModal(false);
    setReload((prev) => !prev);
  };

  if (!instance) return <></>;

  return (
    <Box
      mt={2}
      display='flex'
      flexDirection='column'
      gridGap={10}
    >
      <Modal
        open={openSupervisorModal}
        onClose={() => setOpenSupervisorModal(false)}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='55%'
        >
          <Card className={classes.modal}>
            <CardContent className={classes.modalContent}>
              <Box display='flex' flexDirection='column' flex={1}>
                <Box display='flex' flexDirection='column' flex={1}>
                  <Box display='flex' mb={2}>
                    <Typography variant='h3'>
                      Seleccionar supervisor
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    mt='3%'
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-between'
                    height='50%'
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                    >
                      <Typography variant='h5'>
                        Nombre developer:
                      </Typography>
                      <Typography variant='h4'>
                        {instance.collaborator ? `${instance.collaborator.user.name} ${instance.collaborator.user.lastname}` : ''}
                      </Typography>
                    </Box>
                    <Box>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        options={supervisors}
                        value={selectedSupervisor}
                        getOptionLabel={(option) => `${option.name} ${option.lastname}`}
                        onChange={(e, value) => {
                          if (value === null) return;
                          setSelectedSupervisor(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Selecciona un supervisor"
                          />
                        )} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                display='flex'
                padding='10px 24px'
              >
                <Box width={'49%'} mr={2}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={handleCloseModal}
                    variant='contained'
                  >
                    Cancelar
                  </Button>
                </Box>
                <Box width={'49%'}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={handleEditInstanceSupervisor}
                    variant='contained'
                  >
                    Confirmar
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
}

EditSupervisorModal.propTypes = {
  instance: PropTypes.object,
  openSupervisorModal: PropTypes.bool,
  setOpenSupervisorModal: PropTypes.func,
  setReload: PropTypes.func,
};
