import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';

function SearchDeveloperBar({
  developers,
  developerId,
  setDeveloperId,
}) {
  if (developers.length === 0) {
    return null;
  }

  const handleSearchDeveloperChange = (event, value) => {
    if (value) {
      setDeveloperId(value.id);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          disablePortal
          id="combo-box-demo"
          options={developers}
          getOptionLabel={(option) => `${option.user.name} ${option.user.lastname}`}
          value={developers.find((option) => option.id === developerId)}
          onChange={handleSearchDeveloperChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Nombre Developer"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

SearchDeveloperBar.propTypes = {
  className: PropTypes.string,
  developers: PropTypes.array,
  developerId: PropTypes.number,
  setDeveloperId: PropTypes.func,
};

export default SearchDeveloperBar;
