import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
  IconButton,
  makeStyles,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import EditSupervisorModal from './EditSupervisorModal';
import { SUPER_ADMIN } from '../../../utils/const/user_types';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 8,
  },
  title: {
    paddingRight: '15px',
  },
  tooltip: {
    backgroundColor: 'white',
    '& .MuiTooltip-arrow': {
      color: '#006E90',
    },
    color: '#006E90',
    border: '1px solid',
    borderColor: '#006E90',
    fontSize: '17px',
  },
}));

function InstanceDetails({ instance, objectives, setReload }) {
  const classes = useStyles();
  const user = useSelector((s) => s.account.user);

  const [openSupervisorModal, setOpenSupervisorModal] = useState(false);

  const totalObjectivesMonthlyLoad = objectives.reduce((acc, curr) => acc + curr.monthlyLoad, 0);

  return (
    <Box mt={2}>
      <Box
        display='flex'
      >
        <Typography
          className={classes.title}
          variant="h5"
          color="textPrimary"
        >
          Detalle instancia de ciclo de performance
        </Typography>
      </Box>
      <Card className={classes.root}>
        <Box
          display='flex'
          height={130}
        >
          <Box
            width='33.3%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='h6'>
              Supervisor:
            </Typography>
            <Box display='flex'>
              <Typography variant='h2'>
                {instance.supervisor ? `${instance.supervisor.user.name} ${instance.supervisor.user.lastname}` : '-'}
              </Typography>
              {user.role.name === SUPER_ADMIN && <IconButton
                onClick={() => setOpenSupervisorModal(true)}
              >
                <SvgIcon fontSize='small'>
                  <EditIcon />
                </SvgIcon>
              </IconButton>}
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            width='33.3%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='h6'>
              Carga total de objetivos
            </Typography>
            <Typography variant='h2'>
              {totalObjectivesMonthlyLoad}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            width='33.3%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='h6'>
              Cargo del developer
            </Typography>
            <Typography variant='h2'>
              {instance.collaborator.jobTitle}
            </Typography>
          </Box>
        </Box>
      </Card>
      <EditSupervisorModal
        instance={instance}
        openSupervisorModal={openSupervisorModal}
        setOpenSupervisorModal={setOpenSupervisorModal}
        setReload={setReload}
      />
    </Box>
  );
}

InstanceDetails.propTypes = {
  instance: PropTypes.object,
  objectives: PropTypes.array,
  setReload: PropTypes.func,
};

export default InstanceDetails;
