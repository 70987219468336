import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Box,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import StarRateIcon from '@material-ui/icons/StarRate';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import WarningIcon from '@material-ui/icons/Warning';
import CommentIcon from '@material-ui/icons/Comment';

// Single evaluation with stars and tooltip
const Evaluation = ({
  score, descriptions, comment, aspect,
}) => {
  const hasComment = comment && comment.trim() !== '';
  const backgroundColor = 'rgba(217, 217, 217, 0.20)';
  const commentIconColor = hasComment ? 'primary' : 'disabled';

  return (
    <div style={{
      display: 'flex', alignItems: 'center', backgroundColor, padding: '8px', margin: '0 0', paddingLeft: '13.5%',
    }}>
      <div style={{ width: '60%', marginRight: '8px' }}> {/* Set a fixed width */}
        <Typography variant="body2">
          {aspect}
        </Typography>
      </div>
      <div style={{ width: '30%', display: 'flex' }}> {/* Adjust width accordingly */}
        {[...Array(4)].map((_, i) => (
          <Tooltip key={i} title={descriptions[i] || ''} placement="top">
            <IconButton>
              <StarRateIcon color={(score > i) ? 'primary' : 'disabled'} />
            </IconButton>
          </Tooltip>
        ))}
      </div>
      <div style={{ width: '10%', textAlign: 'right' }}> {/* Set a fixed width */}
        <Tooltip title={hasComment ? comment : 'Sin comentario'}>
          <IconButton>
            <CommentIcon color={commentIconColor} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

Evaluation.propTypes = {
  score: PropTypes.number,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  comment: PropTypes.string,
  aspect: PropTypes.string.isRequired,
};

// A single user row in the performance evaluations table
const UserRow = ({ user }) => {
  const [open, setOpen] = useState(false);
  const missingEvaluations = user.evaluations.some((e) => e.evaluation === null);

  const averageScore = user.evaluations.reduce(
    (acc, curr) => acc + (curr.evaluation || 0), 0,
  ) / user.evaluations.length;

  // Round to two decimal places
  const roundedAverageScore = averageScore.toFixed(2);

  return (
    <>
      <TableRow>
        <TableCell style={{ width: '20px' }} component="th" scope="row">
          {missingEvaluations && <WarningIcon />}
        </TableCell>
        <TableCell
          onClick={() => setOpen(!open)}
          style={{ width: '50px' }}
        >
          <IconButton size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {user.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {user.projects.join(', ')}
        </TableCell>
        <TableCell align="right">
          {/* Render the average score with stars */}
          <Typography component="span" variant="body2">
            {roundedAverageScore} <StarRateIcon />
          </Typography>
        </TableCell>
      </TableRow>
      {/* Collapsible section for evaluations */}
      <TableRow>
        <TableCell style={{
          paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0, width: '100%',
        }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}>
              {user.evaluations.map((evaluation) => (
                <div key={`${evaluation.performance_aspect.id}${evaluation.projectId}${evaluation.evaluatorDeveloperId}`}>
                  <Evaluation
                    score={evaluation.evaluation}
                    descriptions={[
                      evaluation.performance_aspect.oneStarDescription,
                      evaluation.performance_aspect.twoStarDescription,
                      evaluation.performance_aspect.threeStarDescription,
                      evaluation.performance_aspect.fourStarDescription,
                    ]}
                    comment={evaluation.comment}
                    aspect={evaluation.performance_aspect.name}
                  />
                </div>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
};

// The main table component
const MyEvaluationsTable = ({ usersWithEvaluations }) => (
  <TableContainer component={Paper}>
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell>Rol / Aspecto</TableCell>
          <TableCell>Proyectos</TableCell>
          <TableCell align="right">Average Evaluation</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {usersWithEvaluations.map((user) => (
          <UserRow key={user.name} user={user} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

MyEvaluationsTable.propTypes = {
  usersWithEvaluations: PropTypes.array.isRequired,
};

export default MyEvaluationsTable;
