import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { Autocomplete } from '@mui/material';
import clientRequest from '../../../requests/api/client';
import countryList from '../../../utils/const/countries';
import { budgetTypes } from '../../../utils/const/budgetTypes';
import { companyTypeslist } from '../../../utils/const/companyTypes';
import { communucationChannelList } from '../../../utils/const/communicationChannelTypes';
import useStyles from './styles';

function ClientEditForm({ className, client, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/clientes" />;
  }

  return (
    <Formik
      initialValues={{
        name: client.name,
        country: client.country,
        budget: client.budget,
        channel: client.channel,
        type: client.type,
        contactDate: client.contactDate,
        dealDate: client.dealDate || '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        country: Yup.string().max(255).required('Debe ingresar un país'),
        budget: Yup.string().max(255).required('Debe ingresar un presupuesto'),
        channel: Yup.string()
          .max(255)
          .required('Debe ingresar la red social o medio de contacto'),
        type: Yup.string()
          .max(255)
          .required('Debe ingresar el tipo de empresa'),
        contactDate: Yup.date().required(
          'Debe ingresar la fecha del primer contacto',
        ),
        dealDate: Yup.date().required('Debe ingresar la fecha del contrato'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Make API request
          const data = {
            id: client.id,
            name: values.name,
            country: values.country,
            budget: values.budget,
            channel: values.channel,
            type: values.type,
            contactDate: values.contactDate,
            dealDate: values.dealDate,
          };
          const response = await clientRequest.putClientById(data);
          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: (
                <Button href="/administracion/usuarios">Volver a admins</Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldValue,
        values,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={countryList}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.country}
                    onChange={(event, value) => {
                      setFieldValue('country', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Pais"
                        name="country"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={companyTypeslist}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.type}
                    onChange={(event, value) => {
                      setFieldValue('type', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.type && errors.type)}
                        helperText={touched.type && errors.type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Tipo de empresa"
                        name="type"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={budgetTypes}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.budget}
                    onChange={(event, value) => {
                      setFieldValue('budget', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.budget && errors.budget)}
                        helperText={touched.budget && errors.budget}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Presupuesto"
                        name="budget"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={communucationChannelList}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.channel}
                    onChange={(event, value) => {
                      setFieldValue('channel', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.channel && errors.channel)}
                        helperText={touched.channel && errors.channel}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Canal de comunicación"
                        name="channel"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      views={['year', 'month', 'day']}
                      label="Fecha de primer contacto"
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      name="contactDate"
                      id="contactDate"
                      value={values.contactDate}
                      onChange={(value) => setFieldValue('contactDate', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            touched.contactDate && errors.contactDate,
                          )}
                          fullWidth
                          helperText={touched.contactDate && errors.contactDate}
                          // onBlur={handleBlur}
                          variant="outlined"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      views={['year', 'month', 'day']}
                      label="Fecha de inicio"
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      name="dealDate"
                      id="dealDate"
                      value={values.dealDate}
                      onChange={(value) => setFieldValue('dealDate', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.dealDate && errors.dealDate)}
                          fullWidth
                          helperText={touched.dealDate && errors.dealDate}
                          // onBlur={handleBlur}
                          variant="outlined"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ClientEditForm.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
};

export default ClientEditForm;
