import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Redirect } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import Autocomplete from '@mui/material/Autocomplete';
import projectRequest from '../../../requests/api/project';
import { projectStatusList } from '../../../utils/const/projectStatus';
import useStyles from './styles';

function ProjectEditForm({
  className, project, ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [checked, setChecked] = useState(project.fixedBudget);

  const handleChangeCheck = (e) => {
    setChecked(e.target.checked);
  };

  if (submitSuccess) {
    return <Redirect to="/administracion/proyecto" />;
  }

  return (
    <Formik
      initialValues={{
        name: project.name || '',
        description: project.description || '',
        estimatedHours: project.estimatedHours || '',
        fixedBudget: project.fixedBudget || checked,
        status: project.status || '',
        kickOffDate: project.kickOffDate || '',
        closeDate: project.closeDate || null,
        notionDatabaseId: project.notionDatabaseId || null,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Debes ingresar un nombre'),
        description: Yup.string().required('Debe ingresar una descripción'),
        estimatedHours: Yup.string().required(
          'Debe ingresar un tiempo estimado',
        ),
        status: Yup.string().required('Debe ingresar el estado del proyecto'),
        kickOffDate: Yup.date().required(
          'Debe ingresar una fecha de comienzo',
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Make API request
          const data = {
            id: project.id,
            name: values.name,
            description: values.description,
            estimatedHours: values.estimatedHours,
            fixedBudget: checked,
            status: values.status,
            kickOffDate: values.kickOffDate,
            closeDate: values.closeDate,
            dimensions: values.dimensions,
            notionDatabaseId: values.notionDatabaseId,
          };

          const response = await projectRequest.putProjectById(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: (
                <Button href="/administracion/proyecto">
                  Volver a proyectos
                </Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    label="Descripción"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.description}
                    variant="outlined"
                    type="string"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(
                      touched.estimatedHours && errors.estimatedHours,
                    )}
                    fullWidth
                    helperText={touched.estimatedHours && errors.estimatedHours}
                    label="Horas estimadas"
                    name="estimatedHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.estimatedHours}
                    variant="outlined"
                    type="string"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                <Autocomplete
                    options={projectStatusList}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.status}
                    onChange={(event, value) => {
                      setFieldValue('status', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.status && errors.status)}
                        helperText={touched.status && errors.status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Estado del proyecto"
                        name="status"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                      <DatePicker
                        inputFormat="dd-MM-yyyy"
                        views={['year', 'month', 'day']}
                        label="Fecha de comienzo"
                        minDate={new Date('2020-02-01')}
                        maxDate={new Date('2030-01-01')}
                        value={values.kickOffDate}
                        onChange={(value) => setFieldValue('kickOffDate', value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            error={Boolean(touched.kickOffDate && errors.kickOffDate)}
                            helperText={touched.kickOffDate && errors.kickOffDate}
                            onBlur={handleBlur}
                          />
                        )}
                      />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                      <DatePicker
                        inputFormat="dd-MM-yyyy"
                        views={['year', 'month', 'day']}
                        label="Fecha de cierre"
                        minDate={new Date('2020-02-01')}
                        maxDate={new Date('2030-01-01')}
                        value={values.closeDate}
                        onChange={(value) => setFieldValue('closeDate', value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            error={Boolean(touched.closeDate && errors.closeDate)}
                            helperText={touched.closeDate && errors.closeDate}
                            onBlur={handleBlur}
                          />
                        )}
                      />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.notionDatabaseId && errors.notionDatabaseId)}
                    fullWidth
                    helperText={touched.notionDatabaseId && errors.notionDatabaseId}
                    label="ID tabla Notion"
                    name="notionDatabaseId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.notionDatabaseId}
                    variant="outlined"
                  />
                </Grid>
                <Grid item />
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                  control={<Checkbox
                  checked={checked}
                  onChange={handleChangeCheck}
                  />}
                  label="Presupuesto Fijo" />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ProjectEditForm.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired,
};

export default ProjectEditForm;
