import produce from 'immer';
import projectGraphicActions from '../actions/projectGraphicActions';

const initialState = {
  projectGraphic: {},
};

const projectGraphicReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectGraphicActions.SET_PROJECT_GRAPHIC: {
      const { projectGraphic } = action.payload;

      return produce(state, (draft) => {
        draft.projectGraphic = projectGraphic;
      });
    }

    default: {
      return state;
    }
  }
};

export const resetState = () => initialState;

export default projectGraphicReducer;
