const nonBillableProjects = {
  Tubesoft: [
    'TuBooster',
    'inTube Space',
    'Templates',
    'Comercial',
    'Onboarding',
    'Project life cycle',
    'UX/UI',
    'Tubesoft',
    'Web',
  ],
  'Tubesoft Systems': ['TubeOps', 'Tubito', 'Tuboleta', 'Template Ecommerce'],
  'inTube Space': ['Startup Chile 2022', 'Plane', 'Piloto Circular'],
};

module.exports = {
  nonBillableProjects,
};
