import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import BillCreateForm from './BillCreateForm';
import clientRequest from '../../../requests/api/client';
import useStyles from './styles';

function BillCreateView() {
  const classes = useStyles();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const getAllClients = async () => {
      const response = await clientRequest.getAllClients();
      if (response.success) {
        const data = await response.data.data.clients;
        const arrayData = Object.values(data);
        setClients(arrayData);
      }
    };
    getAllClients();
  }, []);

  return (
    <Page className={classes.root} title="Agregar Factura">
      <Container maxWidth={false}>
        <Header />
        <BillCreateForm clients={clients}/>
      </Container>
    </Page>
  );
}

export default BillCreateView;
