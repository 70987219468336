const EXENTA = 'Exenta';
const AFECTA = 'Afecta';

const billTypesList = [
  EXENTA,
  AFECTA,
];

module.exports = {
  EXENTA,
  AFECTA,
  billTypesList,
};
