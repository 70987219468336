import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '& .MuiTableCell-head': {
      fontWeight: 700,
    },
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  ResultRoot: {},
  headerRoot: {},
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  cardMargin: {
    marginBottom: '10px',
  },
  cardTitle: {
    margin: '10px 10px',
  },
  cardHeigth: {
    maxHeight: '300px',
  },
  barChartCard: {
    margin: '20px 10px',
  },
  costHeaders: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '10px',
    height: '90px',
  },
  buttonsDisplay: {
    display: 'block',
  },
  deleteButton: {
    marginRight: '10px',
    verticalAlign: 'top',
  },
  barChartHeaders: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
  scrollBar: {
    height: '30%',
    overflowY: 'scroll',
    overflowX: 'scroll',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  tableContainer: {
    minHeight: '600px',
    overflowY: 'auto',
  },
  projectBarName: {
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
      color: 'black',
    },
  },

  headerDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  titleTypography: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    color: '#24335E',
  },
  mainSquare: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    marginTop: '20px',
    marginBottom: '20px',
  },
  leftColumn: {
    width: '40%',
    height: '550px',
    marginRight: '30px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '550px',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  topRow: {
    marginBottom: '20px',
    backgroundColor: '#FFFFFF',
    padding: '10px',
  },
  subTitleTypography: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    color: '#24335E',
    marginBottom: '10px',
  },
  cost: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    color: '#24335E',
  },
  bottomSquare: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: '100px',
    },
  },
  cell: {
    [theme.breakpoints.down('xl')]: {
      padding: '2px',
    },
    height: '50px',
    textAlign: 'center',
  },
  clientProjectsSquare: {
    overflowY: 'scroll', // Añade un scroll vertical
    backgroundColor: '#FFFFFF', // Color de fondo
    '&::-webkit-scrollbar': {
      width: '6px', // Ancho de la barra de desplazamiento
    },
  },
  projectListItemBlue: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 700,
    fontSize: '16px',
    color: '#24335E',
    height: '100px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #BDBDBD',

  },
  projectListItemWhite: {
    color: '#FFFFFF',
    backgroundColor: '#24335E',
  },
  projectListHover: {
    '&:hover': { cursor: 'pointer', backgroundColor: '#BDBDBD' },
  },
  addAssignationButton: {
    height: '50px',
  },
  dateButtons: {
    backgroundColor: 'transparent', // Fondo transparente en todo momento
    '&:hover': {
      backgroundColor: 'transparent', // Fondo transparente en hover
    },
  },
}));

export default useStyles;
