import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import highchartsMore from 'highcharts/highcharts-more';

highchartsMore(Highcharts);

function DataGraphic({ categories, data, setProjectIndex }) {
  const onClickEvent = (index) => {
    setProjectIndex(index);
  };
  const options = {
    chart: {
      type: 'column',
      zoomType: 'x',
    },
    title: {
      text: 'Análisis de horas por proyecto',
    },
    xAxis: {
      categories,
      labels: {
        events: {
          click() {
            onClickEvent(this.chart.series[0].points[this.pos].index);
          },
        },
        style: {
          cursor: 'pointer',
        },
      },
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: 'Horas',
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        fontSize: '12px',
      },
    },
    series: data,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

DataGraphic.propTypes = {
  data: PropTypes.array,
  categories: PropTypes.array,
  setProjectIndex: PropTypes.func,
};

export default DataGraphic;
