import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { TextField, Button } from '@material-ui/core';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useStyles from './styles';

const DateFilters = ({
  startMonth, setStartMonth, endMonth, setEndMonth,
}) => {
  const classes = useStyles();

  const handleStartDateChange = (date) => {
    setStartMonth(date.toISOString());
  };

  const handlePrevStartDate = () => {
    const prevMonth = new Date(startMonth);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setStartMonth(prevMonth.toISOString());
  };

  const handleNextStartDate = () => {
    const nextMonth = new Date(startMonth);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setStartMonth(nextMonth.toISOString());
  };

  const handlePrevEndDate = () => {
    const prevMonth = new Date(endMonth);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setEndMonth(prevMonth.toISOString());
  };

  const handleNextEndDate = () => {
    const nextMonth = new Date(endMonth);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setEndMonth(nextMonth.toISOString());
  };

  const handleEndDateChange = (date) => {
    setEndMonth(date.toISOString());
  };

  return (
    <div className={classes.datePickerContainer}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={esLocale}
      >
        <Button
          onClick={handlePrevStartDate}
          color="primary"
          className={classes.dateButtons}
        >
          <NavigateBeforeIcon />
        </Button>
        <DatePicker
          inputFormat="MM-yyyy"
          views={['year', 'month']}
          label="Mes y Año"
          minDate={new Date('2020-02-01')}
          maxDate={new Date('2030-01-01')}
          value={startMonth}
          onChange={(value) => handleStartDateChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              className={classes.datePicker}
            />
          )}
        />
      </LocalizationProvider>
      <Button
        onClick={handleNextStartDate}
        color="primary"
        className={classes.dateButtons}
      >
        <NavigateNextIcon />
      </Button>
      <Button
          onClick={handlePrevEndDate}
          color="primary"
          className={classes.dateButtons}
        >
          <NavigateBeforeIcon />
        </Button>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={esLocale}
      >
        <DatePicker
          inputFormat="MM-yyyy"
          views={['year', 'month']}
          label="Mes y Año"
          minDate={new Date('2020-02-01')}
          maxDate={new Date('2030-01-01')}
          value={endMonth}
          onChange={(value) => handleEndDateChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              className={classes.datePicker}
            />
          )}
        />
      </LocalizationProvider>
      <Button
        onClick={handleNextEndDate}
        color="primary"
        className={classes.dateButtons}
      >
        <NavigateNextIcon />
      </Button>
    </div>
  );
};

DateFilters.propTypes = {
  startMonth: PropTypes.string,
  endMonth: PropTypes.string,
  setStartMonth: PropTypes.func.isRequired,
  setEndMonth: PropTypes.func.isRequired,
};

export default DateFilters;
