const SET_PROJECT_CELL = '@account/set-project-cell';
const REFRESH = '@account/refresh';

const setProjectCell = (projectCell) => (dispatch) => dispatch({
  type: SET_PROJECT_CELL,
  payload: {
    projectCell,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setProjectCell,
  SET_PROJECT_CELL,
  refresh,
  REFRESH,
};
