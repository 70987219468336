import axios from '../../utils/axios';
import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';

const getAvailabilitiesPerPage = async (page, limit, searchValue, month) => {
  try {
    const { data } = await axios.get(
      `/api/availabilities?page=${page}&limit=${limit}&searchValue=${searchValue}&month=${month}`,
    );

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postCreateAvailability = async (
  {
    developerId,
    month,
    minHours,
    maxHours,
  },
) => {
  try {
    const response = await axios.post('/api/availability/create', {
      developerId,
      month,
      minHours,
      maxHours,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAvailabilityById = async (id) => {
  try {
    const { data } = await axios.get(`/api/availability/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putAvailabilityById = async (
  {
    id, month, minHours, maxHours, blocked, manuallyEdited,
  },
) => {
  try {
    const response = await axios.put('/api/availability/edit', {
      id, month, minHours, maxHours, blocked, manuallyEdited,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteAvailability = async (id) => {
  try {
    const { data } = await axios.delete(`/api/availability/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAllAvailabilities = async () => {
  try {
    const { data } = await axios.get('/api/all/availabilities');

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAvailabilitiesPerPageFilter = async ({
  page, limit, searchStartMonth, searchEndMonth,
}) => {
  try {
    const { data } = await axios.get(
      `/api/filter/availabilities?page=${page}&limit=${limit}&searchStartMonth=${searchStartMonth}&searchEndMonth=${searchEndMonth}`,
    );

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAvailabilitiesPerPageDeveloperId = async (page, developerId) => {
  try {
    const { data } = await axios.get(`/api/availabilitiesDeveloperId?limit=${10}&page=${page}&developerId=${developerId}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAvailabilitiesGraphic = async ({ developerId, startMonth, endMonth }) => {
  try {
    const { data } = await axios.get(
      `/api/availability-graphic?developerId=${developerId}&startMonth=${startMonth}&endMonth=${endMonth}`,
    );

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const get12AvailabilitiesPerDev = async ({ developerId, onlyFuture }) => {
  try {
    let response;
    if (onlyFuture) {
      response = await axios.get(`/api/availabilities/${developerId}?onlyFuture=${onlyFuture}`);
    } else {
      response = await axios.get(`/api/availabilities/${developerId}`);
    }

    return {
      success: response.data.success,
      status: response.data.status,
      message: response.data.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putAvailabilitiesByDeveloper = async ({
  availabilitiesToUpdateIds, minHours, maxHours, developerId,
}) => {
  try {
    const response = await axios.put('/api/availabilities/edit', {
      availabilitiesToUpdateIds, minHours, maxHours, developerId,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  getAvailabilitiesPerPage,
  postCreateAvailability,
  getAvailabilityById,
  putAvailabilityById,
  deleteAvailability,
  getAllAvailabilities,
  getAvailabilitiesPerPageFilter,
  getAvailabilitiesPerPageDeveloperId,
  getAvailabilitiesGraphic,
  get12AvailabilitiesPerDev,
  putAvailabilitiesByDeveloper,
};
