import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import ProjectRoleEditForm from './ProjectRoleEditForm';
import Header from './Header';
import projectRoleRequest from '../../../requests/api/project_role';
import useStyles from './styles';

function ProjectRoleEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [projectRole, setProjectRole] = useState();

  useEffect(() => {
    const getProjectRole = async () => {
      const res = await projectRoleRequest.getProjectRoleById(props.match.params.id);
      const response = res.data;
      if (isMountedRef.current) {
        setProjectRole(response.data.projectRole);
      }
    };

    getProjectRole();
    // eslint-disable-next-line
  }, [props]);

  if (!projectRole) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Editar Talento"
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <ProjectRoleEditForm projectRole={projectRole}/>
        </Box>
      </Container>
    </Page>
  );
}

ProjectRoleEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ProjectRoleEditView);
