/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  Edit as EditIcon,
  Eye as EyeIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import Request from '../../../requests/api/performance_cycles';
import { DEVELOPER, SUPER_ADMIN, SYSTEM_ADMIN } from '../../../utils/const/user_types';

const sortOptions = [
  {
    value: 'updated_at|desc',
    label: 'Más nuevo primero',
  },
  {
    value: 'updated_at|asc',
    label: 'Más antiguo primero',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
}));

function Results({
  className,
  cycles,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  totalCycles,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedCycles, setSelectedCycles] = useState([]);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [currentCycles, setCurrentCycles] = useState(cycles);

  useEffect(() => { setCurrentCycles(cycles); }, [cycles]);

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllCycles = (event) => {
    setSelectedCycles(event.target.checked
      ? currentCycles.map((cycle) => cycle.id)
      : []);
  };

  const handleSelectOneCycle = (event, cycleId) => {
    if (!selectedCycles.includes(cycleId)) {
      setSelectedCycles((prevSelected) => [...prevSelected, cycleId]);
    } else {
      setSelectedCycles((prevSelected) => prevSelected.filter((id) => id !== cycleId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectedCycles.length > 0;
  const selectedSomeCycles = selectedCycles.length > 0 && selectedCycles.length < currentCycles.length;
  const selectedAllCycles = selectedCycles.length === currentCycles.length;
  const account = useSelector((state) => state.account);

  const deleteCycles = async () => {
    if (account.user.role.name !== SUPER_ADMIN && account.user.role.name !== SYSTEM_ADMIN) {
      enqueueSnackbar('Debe ser Administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectedCycles.length; a += 1) {
        Request.deletePerformanceCycle(selectedCycles[a]);
        enqueueSnackbar('Ciclos eliminados!', {
          variant: 'success',
        });
      }
      setCurrentCycles(currentCycles.filter((e) => (!selectedCycles.includes(e.id))));
      setSelectedCycles([]);
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <Box flexGrow={1} />
        <TextField
          label="Orden"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      {account.user.role.name !== DEVELOPER ? (
        enableBulkOperations && (
          <div className={classes.bulkOperations}>
            <div className={classes.bulkActions}>
              <Checkbox
                checked={selectedAllCycles}
                indeterminate={selectedSomeCycles}
                onChange={handleSelectAllCycles}
              />
              <Button
                variant="outlined"
                className={classes.bulkAction}
                onClick={() => deleteCycles()}
              >
                Delete
              </Button>
            </div>
          </div>
        )) : null}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllCycles}
                    indeterminate={selectedSomeCycles}
                    onChange={handleSelectAllCycles}
                  />
                </TableCell>
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell>
                  Fecha inicio
                </TableCell>
                <TableCell>
                  Fecha fin
                </TableCell>
                <TableCell align="right">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentCycles.map((cycle) => {
                const isCycleSelected = selectedCycles.includes(cycle.id);

                return (
                  <TableRow
                    hover
                    key={cycle.id}
                    selected={isCycleSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isCycleSelected}
                        onChange={(event) => handleSelectOneCycle(event, cycle.id)}
                        value={isCycleSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {cycle.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {cycle.startDate.slice(0, 10).split('-').reverse().join('-')}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {cycle.endDate.slice(0, 10).split('-').reverse().join('-')}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {account.user.role.name !== DEVELOPER ? (
                        <IconButton
                          component={RouterLink}
                          to={`/administracion/performance-cycles/${cycle.id}/editar`}
                        >
                          <SvgIcon fontSize="small">
                            <EditIcon />
                          </SvgIcon>
                        </IconButton>
                      ) : null}
                      <IconButton
                        component={RouterLink}
                        to={`/administracion/performance-cycles/${cycle.id}/instances`}
                      >
                        <SvgIcon fontSize="small">
                          <EyeIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalCycles}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  cycles: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  totalCycles: PropTypes.number,
};

Results.defaultProps = {
  cycles: [],
};

export default Results;
