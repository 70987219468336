import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter, useParams } from 'react-router-dom';
import {
  Box,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import DemandEditForm from './DemandEditForm';
import Header from './Header';
import DemandRequest from '../../../requests/api/demands';
import useStyles from './styles';

function DemandsEditView(props) {
  const classes = useStyles();
  const { id } = useParams();
  const isMountedRef = useIsMountedRef();
  const [demand, setDemand] = useState();

  useEffect(() => {
    const getDemand = async () => {
      const res = await DemandRequest.getDemandById(id);
      const response = res.data;
      if (isMountedRef.current) {
        setDemand(response.data.demand);
      }
    };

    getDemand();
    // eslint-disable-next-line
    }, [props]);

  if (!demand) {
    return null;
  }

  return (
      <Page
        className={classes.root}
        title="Editar Demanda"
      >
        <Container maxWidth="lg">
          <Header />
          <Box mt={3}>
            <DemandEditForm demand={demand}/>
          </Box>
        </Container>
      </Page>
  );
}

DemandsEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(DemandsEditView);
