/* eslint-disable no-undef */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Autocomplete from '@mui/material/Autocomplete';
import { Redirect } from 'react-router-dom';
import projectRequest from '../../../requests/api/project';
import useStyles from './styles';

function ProjectDimensionEditForm({
  className, projectId, categories, categoriesDimensions, projectDimensions, ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  // const [dimensionsDefaultValue, setDimensionsDefaultVaue] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const DimensionsDefaultValue = () => {
    const array = [];
    for (let i = 0; i < categories.length; i += 1) {
      array[i] = projectDimensions.filter((e) => e.category_id === categories[i].id);
    }
    return array;
  };

  if (submitSuccess) {
    return <Redirect to="/administracion/proyecto" />;
  }
  return (
    <Formik
      initialValues={{
        dimensions: (DimensionsDefaultValue()) || '',
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Make API request
          const data = {
            projectId: parseInt(projectId, 10),
            dimensions: values.dimensions,
          };

          const response = await projectRequest.updateProjectDimensions(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: (
                <Button href="/administracion/proyecto">
                  Volver a proyectos
                </Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        // values,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
            <div className={classes.cardTitle}>
                  <Typography variant="h3" color="textPrimary" align="center">
                    Dimensiones
                  </Typography>
                </div>
                <Box width="100%" />
                {categories.map((element, index) => (
                  <Grid key={element.id} container spacing={3} className={classes.dimensionsMargin}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        disabled={true}
                        label="Categoría"
                        name="category"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        defaultValue={element.name}
                        variant="outlined"
                        type="string"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={categoriesDimensions.filter((e) => e.category_id === element.id)}
                        defaultValue={projectDimensions.filter((e) => e.category_id === element.id)}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.dimension_name}
                        // eslint-disable-next-line max-len
                        isOptionEqualToValue={(option, value) => (value.dimension_id === option.dimension_id)}
                        onChange={(e, value) => setFieldValue(`dimensions[${index}]`, value)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 6 }}
                              checked={selected}
                            />
                            {option.dimension_name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched.dimensions && errors.dimensions,
                            )}
                            helperText={touched.dimensions && errors.dimensions}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Dimensiones"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                ))}
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ProjectDimensionEditForm.propTypes = {
  className: PropTypes.string,
  projectId: PropTypes.string,
  projectDimensions: PropTypes.array.isRequired,
  categories: PropTypes.array,
  categoriesDimensions: PropTypes.array,
};

export default ProjectDimensionEditForm;
