import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Divider,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import availabilityRequest from '../../../requests/api/availability';
import useStyles from './styles';

function AvailabilitiesList({
  className,
  availabilities,
  totalAvailabilities,
  setReloadAvailabilities,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [currentAvailabilities, setCurrentAvailabilities] = useState(availabilities);
  const [enableEdit, setEnableEdit] = useState(false);
  const [values, setValues] = useState({
    minHours: '',
    maxHours: '',
  });

  useEffect(() => setCurrentAvailabilities(availabilities), [availabilities]);

  const getMonth = (date) => {
    const dateFormat = new Date(date);
    const Month = dateFormat.toLocaleString('es-es', { month: 'long' });
    return Month;
  };

  const handleEnableEdit = (availability, index) => {
    setValues({
      minHours: availability.minHours,
      maxHours: availability.maxHours,
    });
    setEnableEdit({ [index]: true });
  };

  const handleEditAvailability = async (availability, index) => {
    const response = await availabilityRequest.putAvailabilityById({
      id: availability.id,
      minHours: values.minHours,
      maxHours: values.maxHours,
      month: availability.month,
      blocked: availability.blocked,
      manuallyEdited: true,
    });
    if (response.success) {
      enqueueSnackbar('Cambios en las horas por defecto guardados', {
        variant: 'success',
      });
      setReloadAvailabilities(true);
      setEnableEdit({ [index]: false });
    }
  };

  return (
    <Card className={clsx(classes.emptyRoot, className)} {...rest}>
      <Divider />
      <Box p={2} minHeight={56} display="flex" alignItems="center">
        <Box flexGrow={0.1} />
        <Box flexGrow={1} />
      </Box>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Mes</TableCell>
                <TableCell align='center'>Horas Mínimas</TableCell>
                <TableCell align='center'>Horas Maximas</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {currentAvailabilities.map((element, index) => (
                <TableRow hover key={element.id}>
                  <TableCell align='center'>
                    {getMonth(element.month)}
                  </TableCell>
                  <TableCell align='center'>
                    {enableEdit[index]
                      ? <TextField
                        onChange={(e) => setValues((prev) => ({
                          ...prev,
                          minHours: e.target.value,
                        }))}
                        value={values.minHours}
                        variant="outlined"
                      />
                      : element.minHours - element.vacationHours}
                  </TableCell>
                  <TableCell align='center'>
                    {enableEdit[index]
                      ? <TextField
                        onChange={(e) => setValues((prev) => ({
                          ...prev,
                          maxHours: e.target.value,
                        }))}
                        value={values.maxHours}
                        variant="outlined"
                      />
                      : element.maxHours - element.vacationHours}
                  </TableCell>
                  <TableCell>
                    <Box
                      display='flex'
                    >
                      <Tooltip title='La disponibilidad de este mes fue editada.'>
                        <SvgIcon
                          fontSize="small"
                        >
                          {element.manuallyEdited && <EditIcon />}
                        </SvgIcon>
                      </Tooltip>
                      {enableEdit[index]
                        ? <Box
                          width='100%'
                          display='flex'
                          justifyContent='space-between'
                        >
                          <Button
                            variant='contained'
                            fullWidth
                            style={{
                              marginLeft: '5%',
                            }}
                            onClick={() => handleEditAvailability(element, index)}
                          >
                            Confirmar
                          </Button>
                          <IconButton
                            onClick={() => setEnableEdit((prev) => ({ ...prev, [index]: false }))}
                          >
                            <SvgIcon>
                              <CancelIcon/>
                            </SvgIcon>
                          </IconButton>
                        </Box>
                        : <Button
                          variant='contained'
                          fullWidth
                          style={{
                            marginLeft: '5%',
                          }}
                          onClick={() => handleEnableEdit(element, index)}
                        >
                          Editar
                        </Button>
                      }
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalAvailabilities}
        onPageChange={() => { }}
        onRowsPerPageChange={() => { }}
        page={0}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

AvailabilitiesList.propTypes = {
  className: PropTypes.string,
  availabilities: PropTypes.array,
  page: PropTypes.number,
  setPage: PropTypes.func,
  totalAvailabilities: PropTypes.number,
  setReloadAvailabilities: PropTypes.func,
};

AvailabilitiesList.defaultProps = {
  users: [],
};

export default AvailabilitiesList;
