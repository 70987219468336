import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import objectiveRequest from '../../../requests/api/objectives';
import cycleRequest from '../../../requests/api/performance_cycles';
import Objectives from '../../PerformanceCycleInstances/PerformanceCycleInstancesDetailView/Objectives';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function ObjectivesListView() {
  const classes = useStyles();
  const [objectives, setObjectives] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [page, setPage] = useState(0);
  const [reload, setReload] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [limit, setLimit] = useState(10);
  const [cycles, setCycles] = useState([]);
  const [totalObjectives, setTotalObjectives] = useState(0);
  const [selectedCycle, setSelectedCycle] = useState({ id: 0, name: '' });
  const [objectivesSort, setObjectivesSort] = useState('');

  useEffect(() => {
    const getCycles = async () => {
      const cyclePage = '';
      const cycleLimit = '';
      const response = await cycleRequest.getPerformanceCyclesPerPage(cyclePage, cycleLimit);
      if (response.success) {
        const data = await response.data.data.performanceCycles;
        setCycles(data);
        setSelectedCycle(data[0]);
      }
    };
    getCycles();
  }, []);

  useEffect(() => {
    if (reload) setReload(false);
    if (!isMountedRef.current) {
      return;
    }
    const getAllObjectives = async () => {
      const response = await objectiveRequest.getAllObjectivesPerPage(
        page,
        limit,
        searchValue,
        selectedCycle.id,
        objectivesSort,
      );
      if (response.success) {
        const data = await response.data.data.objectives;
        setObjectives(data);
        setTotalObjectives(response.data.data.totalObjectives);
      }
    };
    if (selectedCycle.id) getAllObjectives();
  }, [page, limit, searchValue, selectedCycle, reload, objectivesSort]);

  return (
    <Page
      className={classes.root}
      title="TubeOps | Objetivos"
    >
      <Container maxWidth={false}>
        <Header
          cycles={cycles}
          selectedCycle={selectedCycle}
          setSelectedCycle={setSelectedCycle}
        />
        <Box mt={3}>
          <Typography
            variant='h4'
          >
            Todos los objetivos
          </Typography>
        </Box>
        <Box mt={1}>
          <Objectives
            objectives={objectives}
            totalObjectives={totalObjectives}
            pageOfTable={page}
            setPageOfTable={setPage}
            limit={limit}
            setLimit={setLimit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setReload={setReload}
            allObjectives={true}
            setObjectivesSort={setObjectivesSort}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default ObjectivesListView;
