import produce from 'immer';
import projectCellActions from '../actions/projectCellActions';

const initialState = {
  projectCell: {},
};

const projectCellReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectCellActions.SET_PROJECT_CELL: {
      const { projectCell } = action.payload;

      return produce(state, (draft) => {
        draft.projectCell = projectCell;
      });
    }

    default: {
      return state;
    }
  }
};

export const resetState = () => initialState;

export default projectCellReducer;
