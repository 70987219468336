import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  Icon,
  Typography,
} from '@material-ui/core';
import Calendar from '@mui/icons-material/CalendarMonth';
import useStyles from './styles';

function Header({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.emptyRoot, className)}
      container
      justifyContent="flex-start"
      spacing={3}
      {...rest}
    >
      <Grid item>
      <Icon>
          <Calendar sx={{ fontSize: 40 }}/>
        </Icon>
        </Grid>

        <Grid item>
        <Typography
          variant="h3"
          className={classes.titleTypography}
        >
          Gráfico de Facturas
        </Typography>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
