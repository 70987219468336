import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Box,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import StarRateIcon from '@material-ui/icons/StarRate';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import WarningIcon from '@material-ui/icons/Warning';
import CommentIcon from '@material-ui/icons/Comment';

// Single evaluation with stars and tooltip
const Evaluation = ({
  score, descriptions, roleName, comment, aspect, onStarClick,
}) => {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const hasComment = comment && comment.trim() !== '';
  const backgroundColor = 'rgba(217, 217, 217, 0.20)';
  const commentIconColor = hasComment ? 'primary' : 'disabled';

  const handleStarHover = (index) => {
    setHoverIndex(index);
  };

  const handleStarClick = (newScore) => {
    if (newScore !== score) {
      onStarClick(newScore);
    }
  };

  const handleMouseLeave = () => {
    setHoverIndex(-1);
  };

  return (
    <div style={{
      display: 'flex', alignItems: 'center', backgroundColor, padding: '8px', margin: '0 0', paddingLeft: '134px', width: '100%',
    }}>
      <div style={{ width: '30%', marginRight: '8px' }}> {/* Set a fixed width */}
        <Typography variant="body2">
          {roleName}
        </Typography>
      </div>
      <div style={{ width: '30%', marginRight: '8px' }}> {/* Set a fixed width */}
        <Typography variant="body2">
          {aspect}
        </Typography>
      </div>
      <div style={{ width: '30%', display: 'flex' }}> {/* Adjust width accordingly */}
        {[...Array(4)].map((_, i) => (
          <Tooltip key={i} title={descriptions[i] || ''} placement="top">
            <IconButton
              onMouseEnter={() => handleStarHover(i)}
              onClick={() => handleStarClick(i + 1)}
              onMouseLeave={handleMouseLeave}
              style={{ cursor: 'pointer', padding: 0 }}
            >
              <StarRateIcon color={(hoverIndex !== -1 ? i <= hoverIndex : score > i) ? 'primary' : 'disabled'} />
            </IconButton>
          </Tooltip>
        ))}
      </div>
      <div style={{ width: '10%', textAlign: 'right' }}> {/* Set a fixed width */}
        <Tooltip title={hasComment ? comment : 'Sin comentario'}>
          <IconButton>
            <CommentIcon color={commentIconColor} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

Evaluation.propTypes = {
  score: PropTypes.number,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  roleName: PropTypes.string.isRequired,
  comment: PropTypes.string,
  aspect: PropTypes.string.isRequired,
  onStarClick: PropTypes.func,
};

// A single user row in the performance evaluations table
const UserRow = ({ user, handleOpenModal }) => {
  const [open, setOpen] = useState(false);
  const missingEvaluations = user.evaluations.some((e) => e.evaluation === null);

  const averageScore = user.evaluations.reduce(
    (acc, curr) => acc + (curr.evaluation || 0), 0,
  ) / user.evaluations.length;

  // Round to two decimal places
  const roundedAverageScore = averageScore.toFixed(2);

  return (
    <>
      <TableRow>
        <TableCell style={{ width: '20px' }} component="th" scope="row">
          {missingEvaluations && <WarningIcon />}
        </TableCell>
        <TableCell
          onClick={() => setOpen(!open)}
          style={{ width: '50px' }}
        >
          <IconButton size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {user.name}
        </TableCell>
        <TableCell align="right">
          {/* Render the average score with stars */}
          <Typography component="span" variant="body2">
            {roundedAverageScore} <StarRateIcon />
          </Typography>
        </TableCell>
      </TableRow>
      {/* Collapsible section for evaluations */}
      <TableRow>
        <TableCell style={{
          paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0,
        }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}>
              {user.evaluations.map((evaluation) => (
                <div key={evaluation.performance_aspect.id}>
                  <Evaluation
                    score={evaluation.evaluation}
                    descriptions={[
                      evaluation.performance_aspect.oneStarDescri,
                      evaluation.performance_aspect.twoStarDescri,
                      evaluation.performance_aspect.threeStarDesc,
                      evaluation.performance_aspect.fourStarDescr,
                    ]}
                    roleName={evaluation.performance_aspect.evaluatedProj}
                    comment={evaluation.comment}
                    aspect={evaluation.performance_aspect.name}
                    onStarClick={(score) => handleOpenModal(evaluation, user, score)}
                  />
                </div>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

// The main table component
const PerformanceEvaluationsTable = ({ usersWithEvaluations, handleOpenModal }) => (
  <TableContainer component={Paper}>
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell>Name</TableCell>
          <TableCell align="right">Average Evaluation</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {usersWithEvaluations.map((user) => (
          <UserRow key={user.name} user={user} handleOpenModal={handleOpenModal} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

PerformanceEvaluationsTable.propTypes = {
  usersWithEvaluations: PropTypes.array.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default PerformanceEvaluationsTable;
