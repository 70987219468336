const PENDING = 'Solicitada';
const APPROVED = 'Aprobada';
const REJECTED = 'Rechazada';
const CANCELLED = 'Cancelada';

module.exports = {
  PENDING,
  APPROVED,
  REJECTED,
  CANCELLED,
};
