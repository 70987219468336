import React, { useEffect, useState } from 'react';
import {
  List, ListItem, ListItemText, Grid, IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import WarningIcon from '@mui/icons-material/Warning';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import CloseProjectModal from './CloseProjectModal';
import useStyles from './styles';
import demandRequest from '../../../requests/api/demands';
import { CLOSED } from '../../../utils/const/projectStatus';
import projectRequest from '../../../requests/api/project';
import { compareDateMonths } from '../../../utils/date';

function ClientProjects({
  clientProjectsInfo,
  month,
  projectId,
  setProjectId,
  setRefreshCurrentAssignment,
}) {
  const [expanded, setExpanded] = useState({});
  const [anchorEl, setAnchorEl] = useState({});
  const [openCloseProjectModal, setOpenCloseProjectModal] = useState(false);
  const [open, setOpen] = useState({});
  const [projectToClose, setProjectToClose] = useState(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleExpansion = (clientId, bool) => {
    if (bool) {
      setExpanded((prevExpanded) => ({
        ...prevExpanded,
        [clientId]: !prevExpanded[clientId],
      }));
    } else {
      setExpanded((prevExpanded) => ({
        ...prevExpanded,
        [clientId]: true,
      }));
    }
  };

  const handleClickMenu = (event, selectProjectId) => {
    setAnchorEl({ ...anchorEl, [selectProjectId]: event.currentTarget });
    setOpen({ ...open, [selectProjectId]: true });
  };
  const handleCloseMenu = (selectProjectId) => {
    setAnchorEl({ ...anchorEl, [selectProjectId]: null });
    setOpen({ ...open, [selectProjectId]: false });
  };

  const handleSelectProject = (id) => {
    setProjectId(id);
  };

  const handleOpenCloseProjectModal = (project) => {
    setProjectToClose(project);
    setOpenCloseProjectModal(true);
    handleCloseMenu(project.id);
  };

  const handleCloseProject = async () => {
    const currentDate = new Date();
    const data = { ...projectToClose, status: CLOSED, closeDate: currentDate };

    try {
      const response = await projectRequest.putProjectById(data);

      const res = response.data;
      if (res.success) {
        enqueueSnackbar('Proyecto Cerrado', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setRefreshCurrentAssignment((prevState) => !prevState);
      } else {
        enqueueSnackbar(res.data.errorMessage, {
          variant: 'warning',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar('Error al cerrar el proyecto', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    setOpenCloseProjectModal(false);
  };

  const handleConfirmProject = async (project, monthDemand) => {
    const data = { ...monthDemand, blocked: !monthDemand.blocked };

    try {
      const response = await demandRequest.putDemandById(data);

      if (response.success) {
        enqueueSnackbar('Cambio guardado', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setRefreshCurrentAssignment((prevState) => !prevState);
      } else {
        enqueueSnackbar(response.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar('Error al confirmar la demanda', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    handleCloseMenu(project.id);
  };

  useEffect(() => {
    if (clientProjectsInfo.length !== 0 && projectId) {
      for (let i = 0; i < clientProjectsInfo.length; i += 1) {
        const client = clientProjectsInfo[i];
        const clientProjects = clientProjectsInfo[i].projects;
        for (let j = 0; j < clientProjects.length; j += 1) {
          const project = clientProjects[j];
          if (project.id === projectId) {
            handleExpansion(client.id, false);
            return;
          }
        }
      }
    }
  }, [projectId, clientProjectsInfo]);

  if (!clientProjectsInfo || !projectId) {
    return null;
  }

  return (
    <div className={classes.clientProjectsSquare}>
      {openCloseProjectModal ? (
        <CloseProjectModal
          open={openCloseProjectModal}
          setOpen={setOpenCloseProjectModal}
          handleCloseProject={handleCloseProject}
          project={projectToClose}
        />
      ) : null}
      <List>
        {clientProjectsInfo.map((client) => (
          <React.Fragment key={client.id}>
            <ListItem
              className={clsx(classes.clientListItemBlue, {
                [classes.clientListItemWhite]: expanded[client.id],
              })}
              onClick={() => handleExpansion(client.id, true)}
            >
              <ListItemText
                disableTypography
                primary={
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={1}>
                      {!client.blocked ? (
                        <IconButton
                          sx={{
                            color: expanded[client.id] ? '#FFFFFF' : '#24335E',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          <WarningIcon />
                        </IconButton>
                      ) : null}
                    </Grid>

                    <Grid item xs={4}>
                      {client.name}
                    </Grid>
                    <Grid item xs={3}>
                      {`${client.minDemand} - ${client.maxDemand}`}
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        sx={{
                          color: expanded[client.id] ? '#FFFFFF' : '#24335E',
                        }}
                      >
                        {expanded[client.id] ? (
                          <ArrowDropUpIcon sx={{ fontSize: 40 }} />
                        ) : (
                          <ArrowDropDownIcon sx={{ fontSize: 40 }} />
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
            {expanded[client.id]
              && client.projects.map((project) => {
                const projectMinDemand = project.demands.find(
                  (demand) => compareDateMonths(demand.month, month),
                )
                  ?.minHours || 0;
                const projectMaxDemand = project.demands.find(
                  (demand) => compareDateMonths(demand.month, month),
                )
                  ?.maxHours || 0;

                const projectSelected = project.id === projectId;

                const findMonthDemand = project.demands.find(
                  (demand) => compareDateMonths(demand.month, month),
                );

                return (
                  <ListItem
                    key={project.id}
                    className={clsx(classes.projectListItemGrey, {
                      [classes.projectListItemBlue]: projectSelected,
                    })}
                    onClick={() => handleSelectProject(project.id)}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={1}>
                            {!project.blocked ? (
                              <IconButton
                                sx={{
                                  color: projectSelected
                                    ? '#FFFFFF'
                                    : '#24335E',
                                  '&:hover': {
                                    backgroundColor: 'transparent',
                                  },
                                }}
                              >
                                <WarningIcon />
                              </IconButton>
                            ) : null}
                          </Grid>

                          <Grid item xs={4}>
                            {project.name}
                          </Grid>
                          <Grid item xs={3}>
                            {`${projectMinDemand} - ${projectMaxDemand}`}
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              sx={{
                                color: projectSelected ? '#FFFFFF' : '#24335E',
                              }}
                              onClick={(e) => handleClickMenu(e, project.id)}
                            >
                              <MoreHorizIcon sx={{ fontSize: 40 }} />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl[project.id] || null}
                              open={open[project.id] || false}
                              onClose={() => handleCloseMenu(project.id)}
                              className={classes.marginTop}
                            >
                              {findMonthDemand ? (
                                <MenuItem
                                  onClick={() => handleConfirmProject(
                                    project,
                                    findMonthDemand,
                                  )
                                  }
                                >
                                  {findMonthDemand.blocked
                                    ? 'Desconfirmar demanda'
                                    : 'Confirmar demanda'}
                                </MenuItem>
                              ) : null}
                              <MenuItem
                                onClick={() => handleOpenCloseProjectModal(project)
                                }
                              >
                                Cerrar proyecto
                              </MenuItem>
                            </Menu>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                );
              })}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

ClientProjects.propTypes = {
  clientProjectsInfo: PropTypes.array,
  month: PropTypes.any,
  projectId: PropTypes.number,
  setProjectId: PropTypes.func,
  setRefreshCurrentAssignment: PropTypes.func,
};

export default ClientProjects;
