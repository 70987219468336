import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import DimensionEditForm from './DimensionEditForm';
import Header from './Header';
import dimensionRequest from '../../../requests/api/dimension';
import useStyles from './styles';

function DimensionEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [dimension, setDimension] = useState();

  useEffect(() => {
    const getDimension = async () => {
      const res = await dimensionRequest.getDimensionById(props.match.params.id);
      const response = res.data;
      if (isMountedRef.current) {
        setDimension(response.data.dimension);
      }
    };

    getDimension();
    // eslint-disable-next-line
  }, [props]);

  if (!dimension) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Editar Dimensión"
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <DimensionEditForm dimension={dimension}/>
        </Box>
      </Container>
    </Page>
  );
}

DimensionEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(DimensionEditView);
