import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Autocomplete } from '@mui/material';
import clsx from 'clsx';
import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  emptyRoot: {},
}));

function Header({
  className,
  selectedCycle,
  setSelectedCycle,
  cycles,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();

  const handleGoToDevelopersObjectives = () => {
    history.push('/administracion/performance-cycle-instances/developer');
  };

  return (
    <Grid
      className={clsx(classes.emptyRoot, className)}
      container
      justifyContent="space-between"
      {...rest}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Objetivos
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item md={5}>
            <Autocomplete
              fullWidth
              disablePortal
              id="combo-box-demo"
              options={cycles}
              value={selectedCycle}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                if (value === null) return;
                setSelectedCycle(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Ciclo"
                />
              )} />
          </Grid>
          <Grid item md={7}>
            <Button
              style={{ height: '50px' }}
              color="secondary"
              variant="contained"
              className={classes.action}
              onClick={() => handleGoToDevelopersObjectives()}
            >
              Ir a objetivos por developer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  cycles: PropTypes.array,
  selectedCycle: PropTypes.object,
  setSelectedCycle: PropTypes.func,
};

export default Header;
