import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Typography, Grid, Button } from '@material-ui/core';
import useStyles from './styles';

function Header({
  className, step, setStep, ...rest
}) {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.emptyRoot, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Crear Célula
        </Typography>
      </Grid>
      <Grid item>
        <Button
          component={RouterLink}
          to="/administracion/celulas-de-proyecto"
        >
          Cancelar
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  step: PropTypes.number,
  setStep: PropTypes.func,
};

export default Header;
