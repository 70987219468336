import produce from 'immer';
import controlPanelGraphicActions from '../actions/controlPanelGraphicActions';

const initialState = {
  controlPanelGraphic: {},
};

const controlPanelGraphicReducer = (state = initialState, action) => {
  switch (action.type) {
    case controlPanelGraphicActions.SET_CONTROL_PANEL_GRAPHIC: {
      const { controlPanelGraphic } = action.payload;

      return produce(state, (draft) => {
        draft.controlPanelGraphic = controlPanelGraphic;
      });
    }

    default: {
      return state;
    }
  }
};

export const resetState = () => initialState;

export default controlPanelGraphicReducer;
