import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import GraphicHeader from './GraphicHeader';
import Request from '../../../requests/api/demands';
import projectRequest from '../../../requests/api/project';
import useStyles from './styles';

const getSearchCache = () => {
  const searchProject = '';
  const searchClient = '';
  const searchMonth = '';

  return {
    searchProject,
    searchClient,
    searchMonth,
  };
};

function DemandsListView() {
  const classes = useStyles();
  const [demand, setDemand] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalDemands, setTotalDemands] = useState(0);
  const [view, setView] = useState(1);
  const [graphicStartMonth, setGraphicStartMonth] = useState('');
  const [graphicEndMonth, setGraphicEndMonth] = useState('');
  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState(0);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);

  const {
    searchProject, searchClient, searchMonth,
  } = getSearchCache();
  const [filters, setFilters] = useState({
    project: searchProject,
    client: searchClient,
    month: searchMonth,
  });
  useEffect(() => {
    const getDemands = async () => {
      const response = await Request.getDemandsPerPage({
        page: pageOfTable,
        limit,
        searchProject: filters.project,
        searchClient: filters.client,
        searchMonth: filters.month,
      });
      if (response.success) {
        const demandsResponse = await response.data.data.Demands;
        setDemand(demandsResponse);
        setTotalDemands(response.data.data.totalDemands);
      }
    };
    getDemands();
  }, [pageOfTable, limit, filters]);

  useEffect(() => {
    const getDemandsGraphic = async () => {
      const response = await Request.getDemandsGraphic({
        projectId,
        startMonth: graphicStartMonth,
        endMonth: graphicEndMonth,
      });
      if (response.success) {
        const demandsGraphicResponse = await response.data.data.totalDemands;
        const dataArray = [];
        dataArray.push({
          name: 'Demanda',
          data: demandsGraphicResponse.demand,
        });
        dataArray.push({
          name: 'Asignación',
          data: demandsGraphicResponse.assignedHours,
        });
        dataArray.push({
          name: 'Efectivas',
          data: demandsGraphicResponse.realHours,
        });
        setData(dataArray);
        setCategories(demandsGraphicResponse.month);
      }
    };
    const getProjects = async () => {
      const response = await projectRequest.getProjectsPerPage({
        page: 0,
        limit: 100,
        searchClient: '',
        searchProject: '',
        month: '',
      });
      if (response.success) {
        const projectsData = await response.data.data.projects;
        const arrayData = Object.values(projectsData);
        setProjects(arrayData);
      }
    };
    getProjects();
    getDemandsGraphic();
  }, [graphicStartMonth, projectId, graphicEndMonth]);

  return (
    <Page className={classes.root} title="TubeOps | Demanda">
      <Container maxWidth={false}>
        <Header setView={setView}/>
        {demand && (
          <Box mt={3}>
            {!view
              ? <Results
              demand={demand}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              totalDemands={totalDemands}
            /> : <GraphicHeader
            data={data}
            categories={categories}
            projects={projects}
            graphicStartMonth={graphicStartMonth}
            setGraphicStartMonth={setGraphicStartMonth}
            graphicEndMonth={graphicEndMonth}
            setGraphicEndMonth={setGraphicEndMonth}
            setProjectId={setProjectId} />}
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default DemandsListView;
