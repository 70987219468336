import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Button } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import developerGraphicActions from '../../../actions/developerGraphicActions';
import useStyles from './styles';

function SearchMonth({
  className, month, setMonth, developerId, ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  if (!month) {
    return null;
  }
  const handlePrevMonth = () => {
    const prevMonth = new Date(month);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setMonth(prevMonth.toISOString());
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(month);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setMonth(nextMonth.toISOString());
  };

  const handleGoToProjectGraphic = async () => {
    dispatch(
      developerGraphicActions.setDeveloperGraphic({ developerId, month }),
    );
    history.push('/administracion/developers/grafico');
  };

  return (
    <Grid container justifyContent="flex-end" spacing={1} {...rest}>
      <Grid item md={4} xs={8}>
        <Button
          color="secondary"
          className={classes.action}
          variant="contained"
          onClick={() => handleGoToProjectGraphic()}
        >
          Gráfico
        </Button>
      </Grid>
      <Grid item lg={8} xl={6}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={handlePrevMonth}
              color="primary"
              className={classes.dateButtons}
            >
              <NavigateBeforeIcon />
            </Button>
            <DatePicker
              inputFormat="MM-yyyy"
              views={['year', 'month']}
              label="Mes y Año"
              minDate={new Date('2020-02-01')}
              maxDate={new Date('2030-01-01')}
              value={month}
              onChange={(value) => setMonth(value.toISOString())}
              renderInput={(params) => (
                <TextField {...params} fullWidth variant="outlined" />
              )}
            />
            <Button
              onClick={handleNextMonth}
              color="primary"
              className={classes.dateButtons}
            >
              <NavigateNextIcon />
            </Button>
          </div>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

SearchMonth.propTypes = {
  className: PropTypes.string,
  month: PropTypes.any,
  setMonth: PropTypes.func,
  developerId: PropTypes.number,
};

export default SearchMonth;
