/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box, Card, Divider,
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import Autocomplete from '@mui/material/Autocomplete';
import DataGraph from './Graphic';
import useStyles from './styles';

function Results({
  className,
  data,
  categories,
  graphicStartMonth,
  graphicEndMonth,
  setGraphicStartMonth,
  setGraphicEndMonth,
  projects,
  setProjectId,
  ...rest
}) {
  const classes = useStyles();

  const handleMonthOnChange = (date, name) => {
    if (date && (date.getFullYear() > 2020)) {
      const Month = date.getMonth();
      const Year = date.getFullYear();
      const firstDayOfTheMonth = new Date(Year, Month, 1);
      const valid = Date.parse(firstDayOfTheMonth);
      if (!Number.isNaN(valid)) {
        if (name === 'start') {
          setGraphicStartMonth(firstDayOfTheMonth.toISOString());
        }
        if (name === 'end') {
          setGraphicEndMonth(firstDayOfTheMonth.toISOString());
        }
      }
    } else {
      if (name === 'start') {
        setGraphicStartMonth('');
      }
      if (name === 'end') {
        setGraphicEndMonth('');
      }
    }
  };

  return (
    <Card className={clsx(classes.rootResults, className)} {...rest}>
      <Divider />
      <Box p={2} minHeight={56} display="flex" alignItems="center" spacing={2}>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12}>
            <Autocomplete
              fullWidth
              disablePortal
              options={projects}
              id="combo-box-demo"
              getOptionLabel={(option) => `${option.client.name} - ${option.name}`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, value) => (value ? setProjectId(value.id) : setProjectId(0))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Cliente-Proyecto"
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DesktopDatePicker
                inputFormat="MM-yyyy"
                views={['year', 'month']}
                label="Mes inicio"
                minDate={new Date('2022-01-02')}
                maxDate={new Date('2024-01-01')}
                value={graphicStartMonth || null}
                onChange={(e) => handleMonthOnChange(e, 'start')}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={4} xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DesktopDatePicker
                inputFormat="MM-yyyy"
                views={['year', 'month']}
                label="Mes termino"
                minDate={new Date('2022-01-02')}
                maxDate={new Date('2024-01-01')}
                value={graphicEndMonth || null}
                onChange={(e) => handleMonthOnChange(e, 'end')}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <DataGraph
        categories={categories}
        data={data}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  graphicStartMonth: PropTypes.any,
  setGraphicStartMonth: PropTypes.func,
  graphicEndMonth: PropTypes.any,
  setGraphicEndMonth: PropTypes.func,
  categories: PropTypes.array,
  projects: PropTypes.array,
  setProjectId: PropTypes.func,

};

export default Results;
