import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import demandRequest from '../../../requests/api/demands';
import useStyles from './styles';

function ProjectDemandsCreateForm({
  className,
  setRefreshCurrentAssignment,
  selectProjectInfo,
  nextMonth,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [minHours, setMinHours] = useState(0);
  const [maxHours, setMaxHours] = useState(0);
  const [formMonth, setFormMonth] = useState(nextMonth);

  useEffect(() => {
    if ((Object.keys(selectProjectInfo).length !== 0)) {
      setMinHours(selectProjectInfo.demands[0]?.minHours || 0);
      setMaxHours(selectProjectInfo.demands[0]?.maxHours || 0);
      setFormMonth(nextMonth);
    }
  }, [selectProjectInfo]);

  if (Object.keys(selectProjectInfo).length === 0) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        project: '',
        client: '',
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            projectId: selectProjectInfo.id,
            month: formMonth,
            minHours,
            maxHours,
          };

          const response = await demandRequest.postCreateDemand(data);

          const res = response.data;
          if (res.success) {
            // setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(true);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
            setRefreshCurrentAssignment((prev) => !prev);
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.errorMessage });
            enqueueSnackbar(res.errorMessage, {
              variant: 'warning',
              action: (
                <Button href="/administracion/demanda-proyectos">Volver a Demanda</Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        // values,
        // setFieldValue,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Typography className={classes.agregarDemanda}>
            Agregar Demanda
          </Typography>
          <Card>
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item md={2} xs={12}>
                  <TextField
                    disabled={true}
                    label="Cliente"
                    name="client"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={selectProjectInfo.client.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    disabled={true}
                    label="Proyecto"
                    name="project"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={selectProjectInfo.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="MM-yyyy"
                      views={['year', 'month']}
                      label="Mes y Año"
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      value={formMonth}
                      onChange={(e) => {
                        setFormMonth(e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={Boolean(touched.month && errors.month)}
                          helperText={touched.month && errors.month}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    error={Boolean(touched.minHours && errors.minHours)}
                    helperText={touched.minHours && errors.minHours}
                    label="Horas Mín"
                    name="minHours"
                    onBlur={handleBlur}
                    onChange={(e) => setMinHours(e.target.value)}
                    value={minHours}
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="start">hr</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    error={Boolean(touched.maxHours && errors.maxHours)}
                    helperText={touched.maxHours && errors.maxHours}
                    label="Horas Máx"
                    name="maxHours"
                    onBlur={handleBlur}
                    onChange={(e) => setMaxHours(e.target.value)}
                    value={maxHours}
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="start">hr</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Agregar Demanda
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ProjectDemandsCreateForm.propTypes = {
  className: PropTypes.string,
  clients: PropTypes.array,
  selectProjectInfo: PropTypes.object,
  setRefreshCurrentAssignment: PropTypes.func,
  nextMonth: PropTypes.any,
};

export default ProjectDemandsCreateForm;
