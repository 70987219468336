import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import Autocomplete from '@mui/material/Autocomplete';
import Request from '../../../requests/api/developer';
import { contractTypesList } from '../../../utils/const/contractTypes';

import useStyles from './styles';

function DeveloperEditForm({
  className,
  developer,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/developers" />;
  }

  return (
    <Formik
      initialValues={{
        jobTitle: developer.jobTitle || '',
        contractType: developer.contractType || '',
        clockifyApiKey: developer.clockifyApiKey || '',
        github: developer.github,
        linkedin: developer.linkedin || '',
        costPerHour: developer.costPerHour,
        contractHours: developer.contractHours || 120,
        contractStartDate: developer.contractStartDate,
      }}
      validationSchema={Yup.object().shape({
        contractType: Yup.string().max(255).required('Debe ingresar un nombre'),
        clockifyApiKey: Yup.string().max(255).required('Debe ingresar un apellido'),
        jobTitle: Yup.string().required('Debe ingresar el cargo'),
        github: Yup.string().max(255).required('Debe ingresar el usuario de guthub'),
        linkedin: Yup.string().max(255).required('Debe ingresar el linkedin'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            id: developer.id,
            contractType: values.contractType,
            clockifyApiKey: values.clockifyApiKey,
            jobTitle: values.jobTitle,
            github: values.github,
            linkedin: values.linkedin,
            costPerHour: values.costPerHour || null,
            contractHours: values.contractHours,
            contractStartDate: values.contractStartDate,
            minDefaultHours: developer.minDefaultHours,
            maxDefaultHours: developer.maxDefaultHours,
            maxConcurrentProjects: developer.maxConcurrentProjects,
          };

          const response = await Request.putDeveloperById(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: <Button href="/administracion/developers">Volver a admins</Button>,
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
            <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Autocomplete
                    options={contractTypesList}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.contractType}
                    onChange={(event, value) => {
                      setFieldValue('contractType', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(
                          touched.contractType && errors.contractType,
                        )}
                        helperText={touched.contractType && errors.contractType}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Tipo de contrato"
                        name="contractType"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.clockifyApiKey && errors.clockifyApiKey)}
                    fullWidth
                    helperText={touched.clockifyApiKey && errors.clockifyApiKey}
                    label="Api Key de Clockify"
                    name="clockifyApiKey"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.clockifyApiKey}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.jobTitle && errors.jobTitle)}
                    fullWidth
                    helperText={touched.jobTitle && errors.jobTitle}
                    label="Cargo"
                    name="jobTitle"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.jobTitle}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.github && errors.github)}
                    fullWidth
                    helperText={touched.github && errors.github}
                    label="Usuario de Github"
                    name="github"
                    type="github"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.github}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.linkedin && errors.linkedin)}
                    fullWidth
                    helperText={touched.linkedin && errors.linkedin}
                    label="Linkedin"
                    name="linkedin"
                    type="linkedin"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.linkedin}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.costPerHour && errors.costPerHour)}
                    fullWidth
                    helperText={touched.costPerHour && errors.costPerHour}
                    label="Costo HH"
                    name="costPerHour"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.costPerHour}
                    variant="outlined"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.contractHours && errors.contractHours)}
                    fullWidth
                    helperText={touched.contractHours && errors.contractHours}
                    label="Horas por contrato"
                    name="contractHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.contractHours}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DesktopDatePicker
                      inputFormat="yyyy-MM-dd"
                      views={['year', 'month', 'day']}
                      label="Fecha de comienzo contrato"
                      value={values.contractStartDate}
                      onChange={(value) => setFieldValue('contractStartDate', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          error={Boolean(touched.contractStartDate && errors.contractStartDate)}
                          helperText={touched.contractStartDate && errors.contractStartDate}
                          onBlur={handleBlur}
                        />)}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

DeveloperEditForm.propTypes = {
  className: PropTypes.string,
  developer: PropTypes.object.isRequired,
};

export default DeveloperEditForm;
