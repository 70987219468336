import React, { useState } from 'react';
import {
  Modal, Box, Typography, IconButton, Button, Grid, TextField,
} from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate'; // Changed import source
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import performanceAspectsRequest from '../../../requests/api/performance_aspect_evaluations';

function EvaluationModal({
  evaluation, user, isOpen, onClose, selectedScore, update, setUpdate,
}) {
  const [comment, setComment] = useState(evaluation.comment || '');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Styling objects for various components
  const titleStyle = {
    mb: 2, // Add margin bottom to title
    borderBottom: '1px solid #e0e0e0', // Add bottom border to title section
    pb: 2, // Padding bottom to title section
  };

  const buttonBoxStyle = {
    mt: 2, // Margin top for buttons
    display: 'flex',
    justifyContent: 'space-between',
  };

  const buttonStyle = {
    width: '48%',
    backgroundColor: '#24335E',
    color: '#FFFFFF',
    cursor: 'pointer',
  };

  const evaluationGridStyle = {
    marginBottom: '15px',
  };

  const evaluationTextStyle = {
    fontWeight: 'bold',
  };

  const textFieldStyle = {
    mt: 4, // Margin top for TextField
    mb: 2, // Margin bottom for TextField
  };

  const getScoreDescription = (score) => {
    switch (parseInt(score, 10)) {
      case 1: return evaluation.performance_aspect.oneStarDescri;
      case 2: return evaluation.performance_aspect.twoStarDescri;
      case 3: return evaluation.performance_aspect.threeStarDesc;
      default: return evaluation.performance_aspect.fourStarDescr;
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value); // Update the comment state when the text changes
  };

  // Add a function to handle the confirm and evaluate action
  const handleConfirmAndEvaluate = async () => {
    const evaluationResponse = await performanceAspectsRequest.editEvaluation({
      evaluationId: evaluation.id,
      evaluation: selectedScore,
      comment,
    });

    if (evaluationResponse.data.success) {
      enqueueSnackbar('Cambios guardados', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(evaluationResponse.message, {
        variant: 'warning',
      });
    }

    setComment('');
    setUpdate(!update);
    onClose();
  };

  return (
    evaluation && (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="evaluation-modal-title"
        aria-describedby="evaluation-modal-description"
      >
        <Box className={classes.modalContainer}>
          <IconButton onClick={onClose} sx={{ position: 'absolute', right: 16, top: 16 }}>
            <CloseIcon />
          </IconButton>
          <Typography id="evaluation-modal-title" variant="h6" component="h2" sx={titleStyle}>
            Evaluar Performance
          </Typography>
          <Grid sx={evaluationGridStyle} container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Nombre developer</Typography>
              <Typography sx={evaluationTextStyle}>{user.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Rol</Typography>
              <Typography sx={evaluationTextStyle}>
                {evaluation.performance_aspect.evaluatedProj}
              </Typography>
            </Grid>
          </Grid>
          <Grid sx={evaluationGridStyle} container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Nota</Typography>
              {[...Array(4)].map((_, i) => (
                  <IconButton key={i}>
                    <StarRateIcon style={{ color: (selectedScore > i) ? '#00D293' : 'disabled' }} />
                  </IconButton>
              ))}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Aspecto</Typography>
              <Typography sx={evaluationTextStyle}>{evaluation.performance_aspect.name}</Typography>
            </Grid>
          </Grid>
          <Typography variant="subtitle1">Descripción de nota seleccionada</Typography>
          <Typography sx={evaluationTextStyle}>{getScoreDescription(selectedScore)}</Typography>
          <TextField
            id="comment"
            label="Comentario"
            multiline
            fullWidth
            rows={4}
            value={comment}
            onChange={handleCommentChange}
            margin="normal"
            variant="outlined"
            sx={textFieldStyle}
          />
          <Box sx={buttonBoxStyle}>
            <Button sx={buttonStyle} variant="outlined" onClick={onClose}>
              CANCELAR
            </Button>
            <Button sx={buttonStyle} variant="contained" onClick={handleConfirmAndEvaluate}>
              CONFIRMAR Y EVALUAR
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  );
}

EvaluationModal.propTypes = {
  evaluation: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  selectedScore: PropTypes.number.isRequired,
  update: PropTypes.bool.isRequired,
  setUpdate: PropTypes.func.isRequired,
};

export default EvaluationModal;
