const budgetTypes = [
  'UF 250 a UF 500',
  'UF 500 a UF 1000',
  'UF 1000 a UF 3000',
  'UF 3000+',
  '10.000 USD a 20.000 USD',
  '10MM a 50MM',
];

module.exports = {
  budgetTypes,
};
