import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  emptyRoot: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
    height: '100%',
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  buttonsDiv: {
    display: 'flex',
    width: '400px',
  },
  scrollTable: {
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
  },
  buttonsDisplay: {
    display: 'flex',
    justifyContent: 'center',
  },
  deleteButton: {
    marginRight: '20px',
    verticalAlign: 'top',
  },
  titleTypography: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    color: '#24335E',
  },
  iconButton: {
    fontSize: '40px',
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerIcon: {
    marginRight: '5px',
  },
  headerDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mainSquare: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  leftColumn: {
    width: '40%',
    height: '542px',
    marginRight: '30px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
    border: '1px solid #BDBDBD',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  topRow: {
    height: '161px',
    marginBottom: '20px',
  },
  bottomRow: {
    height: '381px',
    backgroundColor: '#FFFFFF',
  },
  agregarDemanda: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 400,
    fontSize: '22px',
    marginBottom: '20px',
  },
  createDemandDiv: {
    padding: '30px 0 30px 0',
  },
  clientProjectsSquare: {
    flexGrow: 1,
    overflowY: 'scroll', // Añade un scroll vertical
    backgroundColor: '#FFFFFF', // Color de fondo
    '&::-webkit-scrollbar': {
      width: '6px', // Ancho de la barra de desplazamiento
    },
  },
  // Modal
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600, // Matched to the image
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '30px',
    overflow: 'auto',
    borderRadius: 2, // Add rounded corners to the modal
  },
  // End modal
  clientListItemBlue: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 700,
    fontSize: '16px',
    color: '#24335E',
    backgroundColor: '#FFFFFF',
    height: '64px',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.4,
    },
  },
  clientListItemWhite: {
    color: '#FFFFFF',
    backgroundColor: '#24335E',
  },
  projectListItemGrey: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 700,
    fontSize: '16px',
    color: '#24335E',
    backgroundColor: '#BDBDBD4D',
  },
  projectListItemBlue: {
    backgroundColor: '#00D293',
    color: '#FFFFFF',
  },
  check: {
    color: '#00D293',
  },
  progressBarDisplay: {
    display: 'flex',
    alignItems: 'center',
  },
  progressBarText: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 400,
    fontSize: '16px',
    color: '#24335E',
    marginRight: '20px',
    paddingLeft: '5px',
  },
  marginTop: {
    marginTop: '40px',
  },
  buttonsContainer: {
    padding: '30px',
    background: '#FFFFFF',
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 0px 5px 5px',
  },
  modalButton: {
    width: '300px',
    height: '50px',
    borderRadius: '5px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
  },
  modalBodyContainer: {
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    textAlign: 'left',
    fontSize: 24,
  },
  boldTypography: {
    fontWeight: 700,
    marginLeft: '5px',
  },
  warningIcon: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dateButtons: {
    backgroundColor: 'transparent', // Fondo transparente en todo momento
    '&:hover': {
      backgroundColor: 'transparent', // Fondo transparente en hover
    },
  },
}));

export default useStyles;
