import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';
import axios from '../../utils/axios';

const getVacationRequestsPerPage = async (page, limit, developerId, searchValue) => {
  try {
    const { data } = await axios.get(`/api/vacation-requests?page=${page}&limit=${limit}&developerId=${developerId}&searchValue=${searchValue}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postCreateVacationRequest = async ({
  developerId,
  developerName,
  supervisorId,
  supervisorName,
  daysRequested,
  startDate,
  endDate,
  emailStartDate,
  emailEndDate,
}) => {
  try {
    const response = await axios.post('/api/vacation-request/create', {
      developerId,
      developerName,
      supervisorId,
      supervisorName,
      daysRequested,
      startDate,
      endDate,
      emailStartDate,
      emailEndDate,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const errorStatus = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      errorStatus,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putVacationRequestById = async (
  {
    id,
    developerId,
    developerEmail,
    developerName,
    supervisorId,
    status,
    daysRequested,
    startDate,
    endDate,
    emailStartDate,
    emailEndDate,
    certificateUrl,
    certificateSignDate,
  },
) => {
  try {
    const response = await axios.put('/api/vacation-request/edit', {
      id,
      developerId,
      developerEmail,
      developerName,
      supervisorId,
      status,
      daysRequested,
      startDate,
      endDate,
      emailStartDate,
      emailEndDate,
      certificateUrl,
      certificateSignDate,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const errorStatus = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      errorStatus,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getVacationRequestById = async (id) => {
  try {
    const { data } = await axios.get(`/api/vacation-request/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteVacationRequest = async (id) => {
  try {
    const { data } = await axios.delete(`/api/vacation-request/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAllVacationRequests = async () => {
  try {
    const { data } = await axios.get('/api/all-vacation-requests');
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  getVacationRequestsPerPage,
  postCreateVacationRequest,
  putVacationRequestById,
  getVacationRequestById,
  deleteVacationRequest,
  getAllVacationRequests,
};
