/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { NumericFormat } from 'react-number-format';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import useStyles from './styles';
import pesoChileno from '../../../utils/moneyFormat';
import billRequest from '../../../requests/api/bill';
import { PAID } from '../../../utils/const/billStatus';

const UploadBillsModal = ({
  open, bill, setOpen, setRefresh,
}) => {
  const classes = useStyles();
  const [paymentDate, setPaymentDate] = useState(null);
  const [alreadyPaid, setAlreadyPaid] = useState(0);
  const [toPay, setToPay] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [comment, setComment] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPaymentDate(bill.paymentDate ? moment(bill.paymentDate).utc() : null);
    setAlreadyPaid(bill.paid);
    setToPay(bill.amount - bill.paid);
    setTotalAmount(bill.amount);
    setComment(bill.comments || '');
  }, [bill]);

  const handleChange = (ev) => {
    setToPay(ev.floatValue);
    setRemainingAmount(totalAmount - alreadyPaid - ev.floatValue);
  };

  const materialUiTextFieldProps = {
    fullWidth: true,
    label: 'Monto a Pagar',
    InputProps: {
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    },
  };

  const handleClose = () => {
    setOpen(false);
    setToPay(0);
    setComment('');
    setPaymentDate(null);
    setRemainingAmount(0);
  };

  const handleSaveChanges = async () => {
    try {
      const editBill = {
        ...bill,
        paymentDate,
        paid: toPay + alreadyPaid,
        comments: comment,
      };

      if (paymentDate === null) {
        enqueueSnackbar('Debe ingresar una fecha de pago', {
          variant: 'error',
        });
        return;
      }
      if (remainingAmount === 0) {
        editBill.status = PAID;
      }

      if (remainingAmount < 0) {
        enqueueSnackbar(
          'El monto pagado no puede ser mayor al monto restante',
          {
            variant: 'warning',
          },
        );
        return;
      }
      if (isNaN(toPay)) {
        enqueueSnackbar('El monto a pagar tiene que se mayor o igual a 0', {
          variant: 'warning',
        });
        return;
      }

      const response = await billRequest.putBillById(editBill);

      const res = response.data;
      if (res.success) {
        enqueueSnackbar('Pago guardado', {
          variant: 'success',
        });
        handleClose();
        setRefresh((prev) => !prev);
      } else {
        enqueueSnackbar(res.errorMessage, {
          variant: 'warning',
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'warning',
      });
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modalContainer}>
      <div>
        <div className={classes.modalContent}>
          <div className={classes.modalHeaderContainer}>
            <div className={classes.modalTitleContainer}>
              <Typography className={classes.modalTitle}>
                Factura N° {bill.number} - {bill.client.name}
              </Typography>
              <Typography className={classes.modalTitle}>
                Total {pesoChileno(totalAmount)}
              </Typography>
              <Typography className={classes.modalTitle}>
                Pagado {pesoChileno(alreadyPaid)}
              </Typography>
            </div>
            <CloseIcon
              className={classes.closeIcon}
              onClick={handleClose}
              sx={{ fontSize: 40 }}
            />
          </div>
          <hr className={classes.modalLine} />
          <div className={classes.modalBodyContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    label="Fecha de Pago"
                    minDate={new Date('2020-02-01')}
                    maxDate={new Date('2030-01-01')}
                    value={paymentDate}
                    onChange={(value) => setPaymentDate(value || null)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        required
                        error={paymentDate === null}
                        helperText={paymentDate === null ? 'Campo requerido' : ''}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <NumericFormat
                  value={toPay}
                  customInput={TextField}
                  onValueChange={handleChange}
                  thousandSeparator="."
                  decimalSeparator=","
                  variant="outlined"
                  {...materialUiTextFieldProps}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Monto restante"
                  variant="outlined"
                  fullWidth
                  value={pesoChileno(remainingAmount)}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comentario"
                  name="search"
                  variant="outlined"
                  fullWidth
                  multiline
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.saveChangesContainer}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.modalButton}
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.modalButton}
                onClick={() => handleSaveChanges()}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

UploadBillsModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  bill: PropTypes.object.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default UploadBillsModal;
