const ACTIONS_TYPES = [
  {
    id: 1,
    label: 'Auspicio de curso',
  },
  {
    id: 2,
    label: 'Asignación',
  },
  {
    id: 3,
    label: 'Apoyo',
  },
  {
    id: 4,
    label: 'Otro',
  },
];

export default ACTIONS_TYPES;
