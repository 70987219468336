const PYME = 'Pyme';
const ENTERPRISE = 'Enterprise';
const STARTUP = 'Startup';

const companyTypeslist = [PYME, ENTERPRISE, STARTUP];

module.exports = {
  PYME,
  ENTERPRISE,
  STARTUP,
  companyTypeslist,
};
