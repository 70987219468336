import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import instanceRequest from '../../../requests/api/performance_cycle_instances';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    maxWidth: '700px',
    marginTop: '8%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

export default function ReportModal({
  instance,
  openReportModal,
  setOpenReportModal,
  setReload,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [newReportUrl, setNewReportUrl] = useState();

  useEffect(() => setNewReportUrl(instance.cycleReportUrl || ''), [instance]);

  const handleSubmit = async () => {
    const response = await instanceRequest.putPerformanceCycleInstanceById({
      id: instance.id,
      cycleReportUrl: newReportUrl || '',
    });
    if (response.success) {
      setReload((prev) => !prev);
      enqueueSnackbar('Cambios guardados', {
        variant: 'success',
      });
    }
    setOpenReportModal(false);
  };

  const handleCloseModal = () => {
    setOpenReportModal(false);
  };

  if (!instance) return <></>;

  return (
    <Box
      mt={2}
      display='flex'
      flexDirection='column'
      gridGap={10}
    >
      <Modal
        open={openReportModal}
        onClose={() => setOpenReportModal(false)}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Card className={classes.modal}>
            <CardContent className={classes.modalContent}>
              <Box mb={4} display='flex' flexDirection='column' flex={1}>
                <Box display='flex' flexDirection='column' flex={1}>
                  <Box display='flex' mb={2}>
                    <Typography variant='h3'>
                      {instance.cycleReportUrl ? 'Editar reporte' : 'Agregar reporte'}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    mt='3%'
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-between'
                    height='50%'
                  >
                    <Box>
                      <Box>
                        <Box mt={1}>
                          <Typography variant='h5'>
                            Nombre developer
                          </Typography>
                        </Box>
                        <Box mt={1}>
                          <Typography variant='h3'>
                            {`${instance.collaborator.user.name} ${instance.collaborator.user.lastname}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box mt={3}>
                      {!instance.cycleReportUrl && <Box>
                        <Typography variant='h5'>
                          Url del reporte
                        </Typography>
                      </Box>}
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          name="cycleReportUrl"
                          onChange={(e) => setNewReportUrl(e.target.value)}
                          required
                          label={!instance.cycleReportUrl ? '' : 'Url del reporte'}
                          value={newReportUrl}
                          variant="outlined"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                display='flex'
                padding='10px 24px'
              >
                <Box width={'49%'} mr={2}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={handleCloseModal}
                    variant='contained'
                  >
                    Cancelar
                  </Button>
                </Box>
                <Box width={'49%'}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={handleSubmit}
                    variant='contained'
                  >
                    Confirmar
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
}

ReportModal.propTypes = {
  instance: PropTypes.object,
  openReportModal: PropTypes.bool,
  setOpenReportModal: PropTypes.func,
  setReload: PropTypes.func,
};
