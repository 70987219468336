import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import dimensionRequest from '../../../requests/api/dimension';
import useStyles from './styles';

function DimensionCreateForm({ className, categories, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [clear, setClear] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/dimensiones" />;
  }

  const initialValues = {
    category: '',
    subCategory: '',
    dimension: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        category: Yup.object().required('Debes ingresar un tipo de categoría'),
        subCategory: Yup.object().required(
          'Debes ingresar un tipo de sub-categoría',
        ),
        dimension: Yup.string().required('Debes ingresar una dimensión'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            subCategoryId: values.subCategory.id,
            name: values.dimension,
          };
          const response = await dimensionRequest.postCreateDimension(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: (
                <Button href="/administracion/dimensiones">
                  Volver a Proyectos
                </Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="category"
                    name="category"
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    classes={{ input: classes.textFieldInput }}
                    onChange={(e, value) => {
                      if (value === null) {
                        // eslint-disable-next-line no-param-reassign
                        value = '';
                      }
                      setFieldValue(
                        'category',
                        value.name !== null ? value : initialValues.category,
                      );
                      setFieldValue(
                        'subCategory', initialValues.subCategory,
                      );
                      setClear((prevState) => !prevState);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(touched.category && errors.category)}
                        helperText={touched.category && errors.category}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        label="Categoría"
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="subCategory"
                    key={clear}
                    name="subCategory"
                    options={values.category !== '' ? values.category.sub_categories : []}
                    getOptionLabel={(option) => option.name}
                    classes={{ input: classes.textFieldInput }}
                    onChange={(e, value) => {
                      if (value === null) {
                        // eslint-disable-next-line no-param-reassign
                        value = '';
                      }
                      setFieldValue(
                        'subCategory',
                        value.name !== null ? value : initialValues.subCategory,
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(touched.subCategory && errors.subCategory)}
                        helperText={touched.subCategory && errors.subCategory}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        label="Sub-categoría"
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.dimension && errors.dimension)}
                    fullWidth
                    helperText={touched.dimension && errors.dimension}
                    label="Dimensión"
                    name="dimension"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.dimension}
                    variant="outlined"
                  />
                </Grid>
                <Grid item />
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

DimensionCreateForm.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array,
};

export default DimensionCreateForm;
