import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import highchartsMore from 'highcharts/highcharts-more';

highchartsMore(Highcharts);

function DataGraphic({ categories, data }) {
  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories,
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: 'Horas',
      },
      stackLabels: {
        enabled: false,
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      enabled: true,
      backgroundColor: '#D9D9D9',
      style: {
        color: '#24335E',
        fontFamily: 'Inter, sans-serif',
      },
      formatter() {
        const { series } = this.series.chart;
        const seriesIndex = this.series.index;
        const label = series[seriesIndex].name;
        const categoryIndex = this.point.index;
        const minAvailability = Math.round(series[1].yData[categoryIndex]);
        const maxAvailability = Math.round(minAvailability + series[0].yData[categoryIndex]);
        const assignation = Math.round(series[2].yData[categoryIndex]);
        const real = Math.round(series[3].yData[categoryIndex]);
        if (['Disponibilidad máxima', 'Disponibilidad mínima'].includes(label)) {
          return `<div style="text-align: center;"><b style="font-weight: 700">Disponibilidad</b><br/>Disponibilidad Mínima: ${minAvailability} horas<br/>Disponibilidad Máxima: ${maxAvailability} horas </div>`;
        }
        if (label === 'Asignación') {
          return `<div style="text-align: center;"><b style="font-weight: 700">Asignación</b><br/>Asignación: ${assignation} horas</div>`;
        }
        if (label === 'Real') {
          return `<div style="text-align: center;"><b style="font-weight: 700">Real</b><br/>Real: ${real} horas</div>`;
        }
        return null;
      },
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        fontSize: '12px',
      },
    },
    series: data,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

DataGraphic.propTypes = {
  data: PropTypes.array,
  categories: PropTypes.array,
  setProjectIndex: PropTypes.func,
};

export default DataGraphic;
