/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Grid,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Edit as EditIcon, Search as SearchIcon } from 'react-feather';
import { useSnackbar } from 'notistack';
import developerDimensionRequest from '../../../requests/api/developer_dimension';
import useStyles from './styles';

function Results({
  className,
  projectDimensions,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  filters,
  setFilters,
  totalProjectDimensions,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectDeveloperDimension, setSelectDeveloperDimension] = useState([]);
  const [currentDeveloperDimension, setCurrentDeveloperDimension] = useState(projectDimensions);
  useEffect(() => {
    setCurrentDeveloperDimension(projectDimensions);
  }, [projectDimensions]);

  const handleFiltersChange = (name, event) => {
    event.persist();
    let value = null;
    value = event.target.value;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectAllDeveloperDimension = (event) => {
    setSelectDeveloperDimension(
      event.target.checked ? currentDeveloperDimension.map((e) => e.id) : [],
    );
  };

  const handleSelectOneDeveloperDimension = (event, developerDimensionId) => {
    if (!selectDeveloperDimension.includes(developerDimensionId)) {
      setSelectDeveloperDimension((prevSelected) => [...prevSelected, developerDimensionId]);
    } else {
      setSelectDeveloperDimension((prevSelected) => prevSelected.filter((id) => id !== developerDimensionId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectDeveloperDimension.length > 0;
  const selectSomeDimensions = selectDeveloperDimension.length > 0
    && selectDeveloperDimension.length < currentDeveloperDimension.length;
  const selectedAllDeveloperDimensions = selectDeveloperDimension.length === currentDeveloperDimension.length;
  const account = useSelector((state) => state.account);

  const deleteDeveloperDimensions = async () => {
    if (account.user.role.name.toLowerCase() !== 'superadmin') {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectDeveloperDimension.length; a += 1) {
        developerDimensionRequest.deleteDeveloperDimension(selectDeveloperDimension[a]);
        enqueueSnackbar('Dimension eliminadas!', {
          variant: 'success',
        });
      }
      setCurrentDeveloperDimension(
        currentDeveloperDimension.filter((e) => !selectDeveloperDimension.includes(e.id)),
      );
      setSelectDeveloperDimension([]);
    }
  };

  return (
    <Card className={clsx(classes.cardMargin, className)} {...rest}>
      <Divider />
      <Grid
          container
          spacing={3}
          className={classes.filtersContainer}
          display="flex"
          alignItems="center"
          flexwrap="wrap"
        >
        <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFiltersChange('project', e)}
              placeholder="Buscar Proyecto"
              value={filters.project}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFiltersChange('client', e)}
              placeholder="Buscar Cliente"
              value={filters.client}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFiltersChange('dimension', e)}
              placeholder="Buscar dimensión"
              value={filters.dimension}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFiltersChange('category', e)}
              placeholder="Buscar categoría"
              value={filters.category}
              variant="outlined"
              fullWidth
            />
          </Grid>
          </Grid>

      {/* {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllDeveloperDimensions}
              indeterminate={selectSomeDimensions}
              onChange={handleSelectAllDeveloperDimension}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteDeveloperDimensions()}
            >
              Delete
            </Button>
          </div>
        </div>
      )} */}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllDeveloperDimensions}
                    indeterminate={selectSomeDimensions}
                    onChange={handleSelectAllDeveloperDimension}
                  />
                </TableCell> */}
                <TableCell>Proyecto</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Dimensión</TableCell>
                <TableCell>Categoría</TableCell>
                {/* <TableCell align="right">Acciones</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentDeveloperDimension.length ? currentDeveloperDimension.map((element) => {
                const { project } = element;
                const { dimension } = element;
                const { client } = project;
                const { category } = dimension.sub_category;
                const isDeveloperDimensionSelected = selectDeveloperDimension.includes(
                  element.id,
                );
                return (
                  <TableRow
                    hover
                    key={element.id}
                    selected={isDeveloperDimensionSelected}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isDeveloperDimensionSelected}
                        onChange={(event) => handleSelectOneDeveloperDimension(event, element.id)
                        }
                        value={isDeveloperDimensionSelected}
                      />
                    </TableCell> */}
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <div>
                          <Typography color="inherit" variant="h6">
                            {project.name}
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>{client.name}</TableCell>
                    <TableCell>{dimension.name}</TableCell>
                    <TableCell>{category.name}</TableCell>

                    {/* <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/administracion/talento/${element.id}/editar`}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              }) : null}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalProjectDimensions}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  projectDimensions: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  totalProjectDimensions: PropTypes.number,
};

Results.defaultProps = {};

export default Results;
