import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import useStyles from './styles';

const BottomButton = ({ selectChart }) => {
  const classes = useStyles();
  const history = useHistory();

  let buttonText = '';

  if (selectChart === 0) {
    buttonText = 'PROYECCIONES DEMANDA';
  } else if (selectChart === 1) {
    buttonText = 'ASIGNACION DEVELOPERS';
  } else if (selectChart === 2) {
    buttonText = 'ASIGNACION PROYECTOS';
  }

  const handleClick = () => {
    if (selectChart === 0) {
      history.push('/administracion/demanda-proyectos');
    }
    if (selectChart === 1) {
      history.push('/administracion/asignaciones/panel');
    }
    if (selectChart === 2) {
      history.push('/administracion/celulas-de-proyecto/panel');
    }
  };

  return (
    <div className={classes.bottomButtonContiner}>
      <Button
        variant="contained"
        color="secondary"
        type="submit"
        onClick={handleClick}
      >{`${buttonText}`}</Button>
    </div>
  );
};

BottomButton.propTypes = {
  selectChart: PropTypes.number.isRequired,
};

export default BottomButton;
