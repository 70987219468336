import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box, Card, Divider, Button,
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import Autocomplete from '@mui/material/Autocomplete';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DataGraph from './Graphic';
import { firstDayOfCurrentMonth } from '../../../utils/date';
import useStyles from './styles';
import controlPanelGraphicActions from '../../../actions/controlPanelGraphicActions';
import assignationActions from '../../../actions/assignationActions';

function Results({
  className,
  data,
  categories,
  month,
  setMonth,
  developers,
  developerId,
  setDeveloperId,
  autocompleteDefaultValue,
  setProjectIndex,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  const handleMonthOnChange = (date) => {
    if (date && date.getFullYear() > 2020) {
      const Month = date.getMonth();
      const Year = date.getFullYear();
      const firstDayOfTheMonth = new Date(Year, Month, 1);
      const valid = Date.parse(firstDayOfTheMonth);
      if (!Number.isNaN(valid)) {
        setMonth(firstDayOfTheMonth.toISOString());
      }
    } else {
      setMonth(firstDayOfCurrentMonth(new Date()));
    }
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(month);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setMonth(prevMonth.toISOString());
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(month);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setMonth(nextMonth.toISOString());
  };

  const handleButtonOnClick = async () => {
    dispatch(
      controlPanelGraphicActions.setControlPanelGraphic({
        name: 'developer',
        month,
      }),
    );
    history.push('/administracion/panel-de-control');
  };

  const handleRedirectToAssignationButton = async () => {
    dispatch(
      assignationActions.setAssignation({
        developerId,
        month,
      }),
    );
    history.push('/administracion/asignaciones/panel');
  };

  return (
    <Card className={clsx(classes.rootResults, className)} {...rest}>
      <Divider />
      {!(account.user.role.name === 'developer') ? (
        <Box
          p={2}
          minHeight={56}
          display="flex"
          alignItems="center"
          spacing={2}
        >
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                disableClearable
                options={developers}
                defaultValue={autocompleteDefaultValue[0]}
                id="combo-box-demo"
                getOptionLabel={(option) => `${option.user.name} ${option.user.lastname}`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => setDeveloperId(value.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Nombre desarrollador"
                  />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esLocale}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={handlePrevMonth}
                    color="primary"
                    className={classes.dateButtons}
                  >
                    <NavigateBeforeIcon />
                  </Button>
                  <DesktopDatePicker
                    inputFormat="MM-yyyy"
                    views={['year', 'month']}
                    label="Mes y Año"
                    minDate={new Date('2022-01-02')}
                    maxDate={new Date('2024-01-01')}
                    value={month}
                    onChange={(e) => handleMonthOnChange(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Button
                    onClick={handleNextMonth}
                    color="primary"
                    className={classes.dateButtons}
                  >
                    <NavigateNextIcon />
                  </Button>
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item md={2} xs={12} align="right">
              <Button
                color="secondary"
                className={classes.action}
                variant="contained"
                onClick={() => handleRedirectToAssignationButton()}
              >
                Asignaciones
              </Button>
            </Grid>
            <Grid item md={2} xs={12} align="right">
              <Button
                color="secondary"
                className={classes.action}
                variant="contained"
                onClick={() => handleButtonOnClick()}
              >
                Volver a panel de control
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          p={2}
          minHeight={56}
          display="flex"
          alignItems="center"
          spacing={2}
        >
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esLocale}
              >
                <DesktopDatePicker
                  inputFormat="MM-yyyy"
                  views={['year', 'month']}
                  label="Mes y Año"
                  minDate={new Date('2022-01-02')}
                  maxDate={new Date('2024-01-01')}
                  value={month}
                  onChange={(e) => handleMonthOnChange(e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
      )}
      <DataGraph
        categories={categories}
        data={data}
        setProjectIndex={setProjectIndex}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  month: PropTypes.any,
  startDate: PropTypes.number,
  setMonth: PropTypes.func,
  categories: PropTypes.array,
  developers: PropTypes.array,
  setDeveloperId: PropTypes.func,
  developerId: PropTypes.number,
  autocompleteDefaultValue: PropTypes.array,
  setProjectIndex: PropTypes.func,
};

export default Results;
