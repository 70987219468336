import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import developerDimensionRequest from '../../../requests/api/developer_dimension';
import useStyles from './styles';

const getSearchCache = () => {
  const searchDimension = '';
  const searchCategory = '';
  const searchDeveloper = '';
  const searchMinExperience = '';
  const searchMaxExperience = '';

  return {
    searchDimension,
    searchCategory,
    searchDeveloper,
    searchMinExperience,
    searchMaxExperience,
  };
};

function DeveloperDimensionListView() {
  const classes = useStyles();
  const [developerDimensions, setDeveloperDimensions] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalDeveloperDimensions, setTotalDeveloperDimensions] = useState(0);
  const {
    searchDimension, searchCategory, searchDeveloper, searchMinExperience, searchMaxExperience,
  } = getSearchCache();
  const [filters, setFilters] = useState({
    dimension: searchDimension,
    category: searchCategory,
    developer: searchDeveloper,
    minExperience: searchMinExperience,
    maxExperience: searchMaxExperience,
  });
  useEffect(() => {
    const getDeveloperDimension = async () => {
      const response = await developerDimensionRequest.getDeveloperDimensionPerPage({
        page: pageOfTable,
        limit,
        searchDimension: filters.dimension,
        searchCategory: filters.category,
        searchDeveloper: filters.developer,
        minExperience: filters.minExperience,
        maxExperience: filters.maxExperience,
      });
      if (response.success) {
        const data = await response.data.data.developer_dimensions;
        const arrayData = Object.values(data);
        setDeveloperDimensions(arrayData);
        setTotalDeveloperDimensions(response.data.data.totalDeveloperDimensions);
      }
    };
    getDeveloperDimension();
  }, [pageOfTable, limit, filters]);

  return (
    <Page className={classes.root} title="TubeOps | Talento">
      <Container maxWidth={false}>
        <Header />
        {developerDimensions && (
          <Box mt={3}>
            <Results
              developerDimensions={developerDimensions}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              totalDeveloperDimensions={totalDeveloperDimensions}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default DeveloperDimensionListView;
