import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import vacationRequest from '../../requests/api/vacationRequest';
import developerRequest from '../../requests/api/developer';
import vacationDaysRequest from '../../requests/api/vacationDay';
import { THEMES } from '../../constants';
import { formatDate } from '../../utils/date';
import {
  SUPER_ADMIN,
  ADMINISTRATION,
} from '../../utils/const/user_types';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    ...(theme.name === THEMES.LIGHT
      ? {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:disabled': {
          color: 'white',
          backgroundColor: '#9e9e9e',
        },
      }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      }
      : {}),
    height: 60,
  },
  modal: {
    maxWidth: '775px',
    marginTop: '8%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

export default function RequestVacationsModal({
  supervisors,
  selectedDeveloper,
  setSelectedDeveloper,
  lastVacationDeposit,
  availableDays,
  availableCreditDays,
  devHasPendingRequest,
  setDevHasPendingRequest,
  openModal,
  setOpenModal,
  openPreviewModal,
  setOpenPreviewModal,
  devHasNotSignedCertificate,
  setDevHasNotSignedCertificate,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const requestUser = useSelector((s) => s.account.user);
  const [loading, setLoading] = useState(false);
  const [selectedSupervisor, setSelectedSupervisor] = useState({
    id: 0,
    name: '',
    lastname: '',
  });

  const [developers, setDevelopers] = useState([
    {
      id: 0,
      name: '',
      lastname: '',
      contractStartDate: null,
    },
  ]);

  const [vacationDays, setVacationDays] = useState({
    startDate: null,
    endDate: null,
  });
  const [vacations, setVacations] = useState({
    requested: null,
    available: null,
  });
  const [minPickerDate, setMinPickerDate] = useState(null);
  const [errors, setErrors] = useState({
    unavailableDays: false,
    date: false,
  });

  // add 1 day to the last deposit date to make sure to get the right month
  const lastVacationsDepositDate = lastVacationDeposit && moment(lastVacationDeposit.createdAt).add(1, 'd');

  const lastVacationsDepositMonth = lastVacationsDepositDate.month();
  const lastVacationsDepositYear = lastVacationsDepositDate.year();
  const currentDate = moment().utc();
  const oneMonthFurther = currentDate.clone();
  oneMonthFurther.add(1, 'months');

  const calculateMinPickerDate = () => {
    // calculate a date that is at least 2 week away from now and not in the current month
    // to be the minimun selecteable date form a vacation request.
    const targetDate = currentDate.clone();
    targetDate.add(2, 'weeks');
    // Check if the target date is still in the current month
    if (targetDate.month() === currentDate.month()) {
      targetDate.add(1, 'months');
      targetDate.startOf('month').add(1, 'd');
      // Move to the next month
    }

    setMinPickerDate(targetDate);
  };

  const getDevelopers = async () => {
    const page = '';
    const limit = '';
    const devSearchValue = '';
    const response = await developerRequest.getDevelopersPerPage(
      page,
      limit,
      devSearchValue,
    );
    if (response.success) {
      const data = await response.data.data.developers;
      const devs = data.map((d) => ({
        id: d.id,
        name: d.user.name,
        lastname: d.user.lastname,
        contractStartDate: d.contractStartDate,
      }));
      setDevelopers(devs);
    }
  };

  const getWorkDays = async (startDate, endDate) => {
    const response = await vacationDaysRequest.getWorkDays(startDate, endDate);
    const workDaysRequested = response.data.data.workDays;
    // set vacation days requested
    setVacations((prevVal) => ({ ...prevVal, requested: workDaysRequested }));
    if (![SUPER_ADMIN, ADMINISTRATION].includes(requestUser.role.name)) {
      // check if requested days are 5 or more work days.
      // If so, startDate can't be earlier than 1 month further (unless user is superAdmin).
      if (startDate < oneMonthFurther && workDaysRequested >= 5) {
        setErrors((prev) => ({ ...prev, date: true }));
        setMinPickerDate(oneMonthFurther);
      } else {
        setErrors((prev) => ({ ...prev, date: false }));
        calculateMinPickerDate();
      }
    }
  };

  useEffect(() => {
    if ([SUPER_ADMIN, ADMINISTRATION].includes(requestUser.role.name)) {
      getDevelopers();
    }
    calculateMinPickerDate();
  }, [requestUser]);

  useEffect(() => {
    // reset the available days every time the start or the end date changes.
    if (availableDays > 0) {
      setVacations((prevVal) => ({
        ...prevVal,
        available: availableDays + availableCreditDays,
      }));
    } else {
      setVacations((prevVal) => ({
        ...prevVal,
        available: availableCreditDays,
      }));
    }
    // check that start and end date are selected.
    if (vacationDays.startDate !== null && vacationDays.endDate !== null) {
      const start = moment(vacationDays.startDate);
      const end = moment(vacationDays.endDate);

      const endMonth = end.month();
      const endYear = end.year();
      getWorkDays(start, end);

      // check if the month/year of the last deposit made is before the month/year
      // of the end of the vacation selected. This is to simulate correctly
      // the future monthly deposit and not duplicate any entry.
      if (
        lastVacationsDepositYear <= endYear
        && lastVacationsDepositMonth < endMonth
      ) {
        // get the months difference between last deposit date and the vacations end,
        const monthsDifference = endMonth
          - lastVacationsDepositMonth
          + 12 * (endYear - lastVacationsDepositYear);
        // if appropriate sum to available days 1.25 vacation days per month difference.
        if (monthsDifference > 0) {
          setVacations((prevVal) => ({
            ...prevVal,
            available: prevVal.available + monthsDifference * 1.25,
          }));
        }
      }
      if (
        vacations.available - vacations.requested < 0
        && ![SUPER_ADMIN, ADMINISTRATION].includes(requestUser.role.name)
      ) {
        setErrors((prev) => ({ ...prev, unavailableDays: true }));
      } else setErrors((prev) => ({ ...prev, unavailableDays: false }));
    }
  }, [vacationDays, selectedDeveloper]);

  const handleCloseModal = () => {
    setVacationDays({ startDate: null, endDate: null });
    setVacations({ requested: null, available: null });
    setSelectedSupervisor({ id: 0, name: '', lastname: '' });
    setSelectedDeveloper(
      [SUPER_ADMIN, ADMINISTRATION].includes(requestUser.role.name)
        ? {
          id: null,
          name: null,
          lastname: null,
          contractStartDate: null,
        }
        : {
          id: requestUser.developer.id,
          name: requestUser.name,
          lastname: requestUser.lastname,
          contractStartDate: requestUser.developer.contractStartDate,
        },
    );
    if ([SUPER_ADMIN, ADMINISTRATION].includes(requestUser.role.name)) {
      setDevHasPendingRequest(false);
      setDevHasNotSignedCertificate(false);
    }
    setOpenModal(false);
    setOpenPreviewModal(false);
  };

  const handleOpenPreviewModal = () => {
    setOpenModal(false);
    setOpenPreviewModal(true);
  };

  const handleSendVacationRequest = async () => {
    setLoading(true);
    try {
      const originalStartDate = moment(vacationDays.startDate);
      const originalEndDate = moment(vacationDays.endDate);
      const startDay = originalStartDate.date();
      const startMonth = originalStartDate.month() + 1;
      const startYear = originalStartDate.year();
      const endDay = originalEndDate.date();
      const endMonth = originalEndDate.month() + 1;
      const endYear = originalEndDate.year();
      const emailStartDate = `${startDay
        .toString()
        .padStart(2, '0')}/${startMonth
        .toString()
        .padStart(2, '0')}/${startYear.toString()}`;
      const emailEndDate = `${endDay.toString().padStart(2, '0')}/${endMonth
        .toString()
        .padStart(2, '0')}/${endYear.toString()}`;

      const data = {
        developerId: selectedDeveloper.id,
        developerName: `${selectedDeveloper.name} ${selectedDeveloper.lastname}`,
        supervisorId: selectedSupervisor.devId,
        supervisorName: `${selectedSupervisor.name} ${selectedSupervisor.lastname}`,
        daysRequested: vacations.requested,
        startDate: vacationDays.startDate,
        endDate: vacationDays.endDate,
        emailStartDate,
        emailEndDate,
      };
      const response = await vacationRequest.postCreateVacationRequest(data);
      const res = response.data;
      if (res.success) {
        enqueueSnackbar('Solicitud enviada', { variant: 'success' });
        window.location.reload();
      }
    } catch (err) {
      enqueueSnackbar(err, { variant: 'error' });
    }
  };

  return (
    <Box mt={2} display="flex" flexDirection="column" gridGap={10}>
      <Button
        className={classes.button}
        fullWidth
        onClick={() => setOpenModal(true)}
      >
        Crear solicitud
      </Button>
      {openModal && (
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="75%"
          >
            <Card className={classes.modal}>
              <CardContent className={classes.modalContent}>
                <Box display="flex" flexDirection="column" flex={1}>
                  <Box display="flex" flexDirection="column" flex={1}>
                    <Box display="flex" mb={2}>
                      <Typography variant="h3">
                        Crear solicitud de vacaciones
                      </Typography>
                    </Box>
                    <Divider />
                    {[SUPER_ADMIN, ADMINISTRATION].includes(
                      requestUser.role.name,
                    ) && (
                      <Box mt={2} mb={2}>
                        <Autocomplete
                          isOptionEqualToValue={(option, value) => option.id === value.id
                          }
                          disablePortal
                          id="supervisor-autocomplete"
                          defaultValue={
                            openPreviewModal
                              ? selectedDeveloper
                              : {
                                id: 0,
                                name: '',
                                lastname: '',
                                contractStartDate: null,
                              }
                          }
                          options={developers.length > 0 ? developers : []}
                          getOptionLabel={(option) => `${option.name} ${option.lastname}`
                          }
                          onChange={(e, value) => {
                            setSelectedDeveloper(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="outlined"
                              label="Seleccione un developer"
                              name="developer"
                            />
                          )}
                        />
                      </Box>
                    )}
                    <Box mb={2}>
                      <Autocomplete
                        isOptionEqualToValue={(option, value) => option.id === value.id
                        }
                        disablePortal
                        id="supervisor-autocomplete"
                        defaultValue={
                          openPreviewModal
                            ? selectedSupervisor
                            : { id: 0, name: '', lastname: '' }
                        }
                        options={supervisors}
                        getOptionLabel={(option) => `${option.name} ${option.lastname}`
                        }
                        onChange={(e, value) => {
                          setSelectedSupervisor(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            label="Seleccione un supervisor"
                            name="supervisor"
                          />
                        )}
                      />
                    </Box>
                    {((!devHasPendingRequest && !devHasNotSignedCertificate)
                      || [SUPER_ADMIN, ADMINISTRATION].includes(requestUser.role.name))
                      && (
                        <Box display="flex" flexDirection="row">
                          <Box mr={2} width={'48%'}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={esLocale}
                            >
                              <DatePicker
                                inputFormat="dd-MM-yyyy"
                                views={['year', 'month', 'day']}
                                label="Desde"
                                minDate={
                                  ![SUPER_ADMIN, ADMINISTRATION].includes(
                                    requestUser.role.name,
                                  ) && minPickerDate
                                }
                                maxDate={moment('2030-01-01')}
                                value={vacationDays.startDate}
                                onChange={(value) => setVacationDays((prevVal) => ({
                                  ...prevVal,
                                  startDate: value,
                                }))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>
                          <Box width={'50%'}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={esLocale}
                            >
                              <DatePicker
                                inputFormat="dd-MM-yyyy"
                                views={['year', 'month', 'day']}
                                label="Hasta"
                                minDate={moment(vacationDays.startDate)}
                                maxDate={moment('2030-01-01')}
                                value={vacationDays.endDate}
                                onChange={(value) => setVacationDays((prevVal) => ({
                                  ...prevVal,
                                  endDate: value,
                                }))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>
                        </Box>
                      )}
                    {devHasPendingRequest && ![SUPER_ADMIN, ADMINISTRATION].includes(
                      requestUser.role.name,
                    ) && (
                      <Box display="flex" justifyContent="center">
                        <Card id="pending-request">
                          <CardContent>
                            <Box>
                              {[SUPER_ADMIN, ADMINISTRATION].includes(
                                requestUser.role.name,
                              ) ? (
                                <Typography variant="h4">
                                  El/la developer seleccionado/a tiene una
                                  solicitud pendiente. Por favor, apruebe la
                                  solicitud para generar una nueva.
                                </Typography>
                                ) : (
                                <Typography variant="h4">
                                  Tienes una solicitud pendiente. Por favor,
                                  espera a que la aprueben para solicitar nuevas
                                  vacaciones.
                                </Typography>
                                )}
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    )}
                    {(devHasNotSignedCertificate
                      && ![SUPER_ADMIN, ADMINISTRATION].includes(requestUser.role.name))
                      && (
                        <Box display="flex" justifyContent="center">
                          <Card id="pending-request">
                            <CardContent>
                              <Box>
                                {
                                  <Typography variant="h4">
                                    Tienes un certificado de vacaciones pendiente de firmar.
                                    Por favor, fírmalo lo antes posible para poder
                                    realizar una nueva solicitud.
                                  </Typography>
                                }
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      )}
                    <Box display="flex" flexDirection="column" mt="8%" ml="35%">
                      <Box display="flex">
                        <Typography>Vacaciones solicitadas:</Typography>
                        <Typography
                          style={{ paddingLeft: '5px', fontWeight: 'bold' }}
                        >
                          {vacations.requested && `${vacations.requested} días`}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography>Vacaciones restantes:</Typography>
                        <Typography
                          color={errors.unavailableDays ? 'error' : 'inherit'}
                          style={{ paddingLeft: '5px', fontWeight: 'bold' }}
                        >
                          {vacations.requested
                            ? `${vacations.available - vacations.requested
                            } días`
                            : ''}
                        </Typography>
                      </Box>
                    </Box>
                    {errors.date && (
                      <Box ml="25%" mt="8%">
                        <Typography variant="h5" color="error">
                          Estás solicitando 5 o más días hábiles.
                        </Typography>
                        <Typography variant="h5" color="error">
                          Por favor, avisa con un mes de anticipación como
                          mínimo.
                        </Typography>
                      </Box>
                    )}
                    {errors.unavailableDays && (
                      <Box ml="20%" mt="8%">
                        <Typography variant="h5" color="error">
                          Los días solicitados superan el total de vacaciones
                          disponibles.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" padding="10px 24px">
                  <Box width={'49%'} mr={2}>
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={handleCloseModal}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                  </Box>
                  <Box width={'49%'}>
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={handleOpenPreviewModal}
                      variant="contained"
                      disabled={
                        selectedSupervisor.name === null
                        || vacationDays.startDate === null
                        || vacationDays.endDate === null
                        || Object.values(errors).includes(true)
                      }
                    >
                      Siguiente
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Modal>
      )}
      {openPreviewModal && (
        <Modal
          open={openPreviewModal}
          onClose={() => setOpenPreviewModal(false)}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="75%"
          >
            <Card className={classes.modal}>
              <CardContent className={classes.modalContent}>
                <Box display="flex" flexDirection="column" flex={1}>
                  <Box display="flex" flexDirection="column" flex={1}>
                    <Box display="flex" mb={2}>
                      <Typography variant="h3">
                        Solicitar usar días de vacaciones
                      </Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="column">
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="start"
                        ml={'5%'}
                        mt={'5%'}
                      >
                        <Box width={'49%'}>
                          <Typography variant="h5">Nombre developer</Typography>
                          <Typography variant="h4">
                            {`${selectedDeveloper.name} ${selectedDeveloper.lastname}`}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h5">Supervisor</Typography>
                          <Typography variant="h4">
                            {`${selectedSupervisor.name} ${selectedSupervisor.lastname}`}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="start"
                        ml={'5%'}
                        mt={'5%'}
                      >
                        <Box width={'49%'}>
                          <Typography variant="h5">Fecha inicio</Typography>
                          <Typography variant="h4">
                            {`${formatDate(vacationDays.startDate)}`}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h5">Fecha fin</Typography>
                          <Typography variant="h4">
                            {`${formatDate(vacationDays.endDate)}`}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="start"
                        ml={'5%'}
                        mt={'5%'}
                      >
                        <Box width={'49%'}>
                          <Typography variant="h5">Días solicitados</Typography>
                          <Typography variant="h4">
                            {`${vacations.requested}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" padding="10px 24px">
                  <Box width={'49%'} mr={2}>
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={() => setOpenModal(true)}
                      variant="contained"
                    >
                      Atrás
                    </Button>
                  </Box>
                  <Box width={'49%'}>
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={handleSendVacationRequest}
                      disabled={loading}
                      variant="contained"
                    >
                      Finalizar
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Modal>
      )}
    </Box>
  );
}

RequestVacationsModal.propTypes = {
  developer: PropTypes.object,
  supervisors: PropTypes.array,
  availableDays: PropTypes.number,
  availableCreditDays: PropTypes.number,
  pendingRequest: PropTypes.bool,
  lastVacationDeposit: PropTypes.object,
  selectedDeveloper: PropTypes.object,
  setSelectedDeveloper: PropTypes.func,
  devHasPendingRequest: PropTypes.bool,
  setDevHasPendingRequest: PropTypes.func,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  openPreviewModal: PropTypes.bool,
  setOpenPreviewModal: PropTypes.func,
  devHasNotSignedCertificate: PropTypes.bool,
  setDevHasNotSignedCertificate: PropTypes.func,
};
