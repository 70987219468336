import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

function HorizontalBarChart({
  title,
  data,
}) {
  const series = (data.length !== 0 && data[1].value !== 0) ? [
    {
      name: data[0].name,
      color: '#BDBDBD',
      data: [data[0].value - data[1].value],
      stack: 'stack1',
    },
    {
      name: data[1].name,
      color: '#24335E',
      data: [data[1].value],
      stack: 'stack1',
    },

  ] : [];

  const options = {
    chart: {
      type: 'bar',
      height: '80px',
      backgroundColor: 'transparent',
      spacingTop: 1,
      spacingBottom: 1,
    },
    title: {
      text: title,
      align: 'left',
      style: {
        fontFamily: 'Inter, sans-serif',
        fontWeight: 700,
        fontSize: '16px',
        marginTop: '-100px',
      },
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      stackLabels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          y: 25,
          formatter() {
            if (this.point.color === '#24335E') {
              return 'Min';
            }
            return 'Max';
          },
          style: {
            fontFamily: 'Inter, sans-serif',
            color: 'black',
          },
        },
      },
    },
    tooltip: {
      backgroundColor: '#D9D9D9',
      style: {
        color: '#24335E',
        fontFamily: 'Inter, sans-serif',
      },
      formatter() {
        const minDemand = this.series.chart.series[1].yData[0];
        const maxDemand = this.series.chart.series[0].yData[0] + minDemand;
        const minLabel = `Mínima: ${minDemand} horas`;
        const maxLabel = `Máxima: ${maxDemand} horas`;
        return `
      <div>
        <span style="font-weight: bold;">${title}</span><br/>
        <span>${minLabel}</span><br/>
        <span>${maxLabel}</span>
      </div>
    `;
      },
    },

    series,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

HorizontalBarChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
};
export default HorizontalBarChart;
