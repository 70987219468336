import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Request from '../../../requests/api/performance_cycle_instances';

const useStyles = makeStyles(() => ({
  root: {},
}));

function PerformanceCycleInstanceEditForm({
  className,
  performanceCycleInstance,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/performance-cycle-instances/all" />;
  }

  return (
    <Formik
      initialValues={{
        developerName: performanceCycleInstance.collaborator.user.name,
        supervisorName: performanceCycleInstance.supervisor?.user.name || 'Sin supervisor',
        startDate: performanceCycleInstance.performance_cycle.startDate,
        endDate: performanceCycleInstance.performance_cycle.endDate,
        objectivesWeight: performanceCycleInstance.objectivesWeight || 0,
        hourCompletionWeight: performanceCycleInstance.hourCompletionWeight || 0,
        projectsPerformanceWeight: performanceCycleInstance.projectsPerformanceWeight || 0,
        monthlyHourTarget: performanceCycleInstance.objectiveMonthlyHours || 120,
        hoursEvaluation: performanceCycleInstance.hoursEvaluation || 0,
        projectsEvaluationAvg: performanceCycleInstance.projectsEvaluationAvg || 0,
        objectivesEvaluationAvg: performanceCycleInstance.objectivesEvaluationAvg || 0,
      }}
      validationSchema={Yup.object().shape({
        objectivesWeight: Yup.number().required('El peso de objetivos es requerido'),
        hourCompletionWeight: Yup.number().required('El peso de horas es requerido'),
        projectsPerformanceWeight: Yup.number().required('El peso de proyectos es requerido'),
        monthlyHourTarget: Yup.number().required('El objetivo de horas es requerido'),
        projectsEvaluationAvg: Yup.number(),
        hoursEvaluation: Yup.number(),
        objectivesEvaluationAvg: Yup.number(),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            id: performanceCycleInstance.id,
            supervisorDeveloperId: performanceCycleInstance.supervisor.id,
            developerId: performanceCycleInstance.collaborator.id,
            objectivesWeight: values.objectivesWeight,
            hourCompletionWeight: values.hourCompletionWeight,
            projectsPerformanceWeight: values.projectsPerformanceWeight,
            objectiveMonthlyHours: values.monthlyHourTarget,
            hoursEvaluation: values.hoursEvaluation,
            projectsEvaluationAvg: values.projectsEvaluationAvg,
            objectivesEvaluationAvg: values.objectivesEvaluationAvg,
          };
          const response = await Request.putPerformanceCycleInstanceById(data);
          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: <Button href={`/administracion/performance-cycle-instances/${performanceCycleInstance.id}`}>Volver atrás</Button>,
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.developerName && errors.developerName)}
                    fullWidth
                    helperText={touched.developerName && errors.developerName}
                    label="Nombre developer"
                    disabled
                    name="developerName"
                    onBlur={handleBlur}
                    required
                    value={values.developerName}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.supervisorName && errors.supervisorName)}
                    fullWidth
                    helperText={touched.supervisorName && errors.supervisorName}
                    label="Nombre supervisor"
                    name="supervisorName"
                    onBlur={handleBlur}
                    disabled
                    required
                    value={values.supervisorName}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.startDate && errors.startDate)}
                    fullWidth
                    helperText={touched.startDate && errors.startDate}
                    label="Fecha inicio ciclo"
                    name="startDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    required
                    value={values.startDate}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.endDate && errors.endDate)}
                    fullWidth
                    helperText={touched.endDate && errors.endDate}
                    label="Fecha término ciclo"
                    name="endDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    required
                    value={values.endDate}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.objectivesWeight && errors.objectivesWeight)}
                    fullWidth
                    helperText={touched.objectivesWeight && errors.objectivesWeight}
                    label="Peso objetivos"
                    name="objectivesWeight"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.objectivesWeight}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.projectsPerformanceWeight
                      && errors.projectsPerformanceWeight)}
                    fullWidth
                    helperText={touched.projectsPerformanceWeight
                      && errors.projectsPerformanceWeight}
                    label="Peso proyectos"
                    name="projectsPerformanceWeight"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.projectsPerformanceWeight}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.hourCompletionWeight && errors.hourCompletionWeight)}
                    fullWidth
                    helperText={touched.hourCompletionWeight && errors.hourCompletionWeight}
                    label="Peso horas"
                    name="hourCompletionWeight"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.hourCompletionWeight}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.hoursEvaluation && errors.hoursEvaluation)}
                    fullWidth
                    helperText={touched.hoursEvaluation && errors.hoursEvaluation}
                    label="Nota horas"
                    name="hoursEvaluation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.hoursEvaluation}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.projectsEvaluationAvg && errors.projectsEvaluationAvg)}
                    fullWidth
                    helperText={touched.projectsEvaluationAvg && errors.projectsEvaluationAvg}
                    label="Nota promedio proyectos"
                    name="projectsEvaluationAvg"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.projectsEvaluationAvg}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.objectivesEvaluationAvg
                      && errors.objectivesEvaluationAvg)}
                    fullWidth
                    helperText={touched.objectivesEvaluationAvg && errors.objectivesEvaluationAvg}
                    label="Nota promedio objetivos"
                    name="objectivesEvaluationAvg"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.objectivesEvaluationAvg}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

PerformanceCycleInstanceEditForm.propTypes = {
  className: PropTypes.string,
  performanceCycleInstance: PropTypes.object.isRequired,
};

export default PerformanceCycleInstanceEditForm;
