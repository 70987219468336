import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import AssignmentCreateForm from './AssignationCreateForm';
import developerRequest from '../../../requests/api/developer';
import useStyles from './styles';

function AssignmentCreateView() {
  const classes = useStyles();

  const [developers, setDevelopers] = useState([]);

  useEffect(() => {
    const getDevelopers = async () => {
      const response = await developerRequest.getDevelopersPerPage(0, 0, '');
      if (response.success) {
        const data = await response.data.data.developers;
        const arrayData = Object.values(data);
        setDevelopers(arrayData);
      }
    };
    getDevelopers();
  }, []);

  return (
    <Page className={classes.root} title="Agregar Asignación">
      <Container maxWidth={false}>
        <Header/>
            <AssignmentCreateForm
              developers={developers}
            />
      </Container>
    </Page>
  );
}

export default AssignmentCreateView;
