import axios from '../../utils/axios';
import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';

const postCreateProjectCell = async ({
  projectId,
  month,
}) => {
  try {
    const response = await axios.post('/api/project_cell/create', {
      projectId,
      month,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage
      : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProjectCellsPerPage = async ({
  pageOfTable,
  limit,
  searchProject,
  searchClient,
  searchStartMonth,
  searchEndMonth,
}) => {
  try {
    const { data } = await axios.get(
      `/api/project_cells?page=${pageOfTable}&limit=${limit}&searchProject=${searchProject}&searchClient=${searchClient}&searchStartMonth=${searchStartMonth}&searchEndMonth=${searchEndMonth}`,
    );

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProjectCellById = async (id) => {
  try {
    const { data } = await axios.get(`/api/project_cell/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putAssignationById = async (
  {
    id, estSatisfaction, realSatisfaction,
  },
) => {
  try {
    const response = await axios.put('/api/assignation/edit', {
      id, estSatisfaction, realSatisfaction,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteProjectCell = async (id) => {
  try {
    const { data } = await axios.delete(`/api/project_cell/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.data.data.errorMessage
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProjectCellByProjectIdAndMonth = async ({
  projectId,
  month,
}) => {
  try {
    const { data } = await axios.get(
      `/api/project_cell?projectId=${projectId}&month=${month}`,
    );

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  postCreateProjectCell,
  getProjectCellsPerPage,
  getProjectCellById,
  putAssignationById,
  deleteProjectCell,
  getProjectCellByProjectIdAndMonth,
};
