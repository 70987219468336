import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import projectDeveloperRequest from '../../../requests/api/project_developer';
import useStyles from './styles';

function CreateProjectDeveloper({
  className,
  assignationId,
  month,
  projects,
  setProjects,
  setRefreshCurrentAssignment,
  allProjectRoles,
  developerId,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  if (projects.length === 0) {
    return null;
  }

  return (
    <div>
      <Formik
        initialValues={{
          projectId: '',
          assignedHours: '',
          projectRoles: [],
        }}
        validationSchema={Yup.object().shape({
          projectId: Yup.number().required('Debe ingresar un proyecto'),
          projectRoles: Yup.array()
            .min(1, 'Debe haber almenos un rol')
            .required('Este campo es obligatorio'),

          assignedHours: Yup.number().required('Debe asignar horas de trabajo'),
        })}
        onSubmit={async (
          values,
          {
            setErrors, setStatus, setSubmitting, resetForm,
          },
        ) => {
          try {
            const data = {
              developerId,
              projectId: values.projectId,
              assignationId: assignationId || 0,
              projectCellId: 0,
              projectRoles: values.projectRoles,
              assignedHours: values.assignedHours,
              month,
            };

            const response = await projectDeveloperRequest.postCreateProjectDeveloper(data);

            if (response.success) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(true);
              enqueueSnackbar('Cambios guardados', {
                variant: 'success',
              });
              setRefreshCurrentAssignment((prevState) => !prevState);
              setProjects([]);
            } else {
              setStatus({ success: false });
              setErrors({ submit: response.data.errorMessage });
              enqueueSnackbar(response.message, {
                variant: 'error',
                action: (
                  <Button href="/administracion/asignaciones">
                    Volver a asignaciones
                  </Button>
                ),
              });
            }
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form
            className={clsx(classes.emptyRoot, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <div className={classes.cardTitle}>
              <Typography variant="h3" color="textPrimary" align="left">
                Agregar Asignación
              </Typography>
            </div>
            <Card className={classes.cardMargin}>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={projects.filter((project) => project.status === 'Activo')}
                      getOptionLabel={(option) => (option ? `${option.name} - ${option.client.name} ` : '')
                      }
                      onChange={(e, value) => {
                        if (value) {
                          setFieldValue('projectId', value.id);
                        } else {
                          setFieldValue('projectId', '');
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={Boolean(touched.projectId && errors.projectId)}
                          helperText={touched.projectId && errors.projectId}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          label="Proyecto - Cliente"
                          name="project"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={allProjectRoles}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox checked={selected} />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rol"
                          variant="outlined"
                          error={Boolean(touched.projectRoles && errors.projectRoles)}
                          helperText={touched.projectRoles && errors.projectRoles}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      )}
                      name="projectRoles"
                      onChange={(e, value) => {
                        setFieldValue('projectRoles', value);
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.assignedHours && errors.assignedHours,
                      )}
                      fullWidth
                      helperText={touched.assignedHours && errors.assignedHours}
                      label="Horas Asignadas"
                      name="assignedHours"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.assignedHours}
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 200 },
                        endAdornment: (
                          <InputAdornment position="start">hr</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Box alignContent="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Agregar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
}

CreateProjectDeveloper.propTypes = {
  className: PropTypes.string,
  assignationId: PropTypes.number,
  projects: PropTypes.array,
  allProjectRoles: PropTypes.array,
  setRefreshCurrentAssignment: PropTypes.func,
  setProjects: PropTypes.func,
  month: PropTypes.string,
  developerId: PropTypes.number,
};

export default CreateProjectDeveloper;
