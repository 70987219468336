const pesoChileno = (number) => {
  const pesoFormat = number.toLocaleString('es-CL', {
    style: 'currency',
    currency: 'CLP',
  });

  return pesoFormat;
};

export default pesoChileno;
