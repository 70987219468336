import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer, { resetState as resetAccountReducer } from './accountReducer';
import developerGraphicReducer, { resetState as resetDeveloperGraphicReducer } from './developerGraphicReducer';
import projectGraphicReducer, { resetState as resetProjectGraphicReducer } from './projectGraphicReducer';
import controlPanelGraphicReducer, { resetState as resetControlPanelGraphicReducer } from './controlPanelGraphicReducer';
import projectDemandGraphicReducer, { resetState as resetProjectDemandGraphicReducer } from './projectDemandGraphicReducer';
import assignationReducer, { resetState as resetAssignationReducer } from './assignationReducer';
import projectCellReducer, { resetState as resetProjectCellReducer } from './projectCellReducer';
import billReducer, { resetState as resetBillReducer } from './billReducer';
import instanceReducer, { resetState as resetInstanceReducer } from './instanceReducer';
import { LOGOUT } from '../actions/accountActions';

const rootReducer = combineReducers({
  account: accountReducer,
  form: formReducer,
  developerGraphic: developerGraphicReducer,
  projectGraphic: projectGraphicReducer,
  projectDemandGraphic: projectDemandGraphicReducer,
  controlPanelGraphic: controlPanelGraphicReducer,
  assignation: assignationReducer,
  projectCell: projectCellReducer,
  bill: billReducer,
  instance: instanceReducer,
});

const appReducer = (state, action) => {
  if (action.type === LOGOUT) {
    // Resetea los reducers al estado inicial
    return {
      account: resetAccountReducer(),
      form: formReducer(undefined, {}),
      developerGraphic: resetDeveloperGraphicReducer(),
      projectGraphic: resetProjectGraphicReducer(),
      projectDemandGraphic: resetProjectDemandGraphicReducer(),
      controlPanelGraphic: resetControlPanelGraphicReducer(),
      assignation: resetAssignationReducer(),
      projectCell: resetProjectCellReducer(),
      bill: resetBillReducer(),
      instance: resetInstanceReducer(),
    };
  }

  return rootReducer(state, action);
};

export default appReducer;
