import produce from 'immer';
import instanceActions from '../actions/instanceActions';

const initialState = {
  instance: {},
};

const instanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case instanceActions.SET_INSTANCE: {
      const { instance } = action.payload;

      return produce(state, (draft) => {
        draft.instance = instance;
      });
    }

    default: {
      return state;
    }
  }
};

export const resetState = () => initialState;

export default instanceReducer;
