/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BarChart from './BarChart';
import useStyles from './styles';
import { formatMonthAndYearMoment } from '../../../utils/date';

function AvailabilityAndAssignationBarChart({
  developers,
  developerId,
  month,
}) {
  const classes = useStyles();
  const [dataAvailabilityChart, setDataAvailabilityChart] = useState([]);
  const [dataAssignationChart, setDataAssignationChart] = useState([]);
  const [chartMax, setChartMax] = useState(0);

  useEffect(() => {
    const setVariables = async () => {
      const developer = developers.find((d) => d.id === developerId);
      const developerAvailability = developer?.availabilities.find(
        (availability) => formatMonthAndYearMoment(availability.month)
            === formatMonthAndYearMoment(month),
      ) || [];
      const developerAssignment = developer?.assignations.find(
        (assignment) => formatMonthAndYearMoment(assignment.month)
            === formatMonthAndYearMoment(month),
      ) || [];
      const projectDevelopers = developerAssignment.project_developers || [];
      const developerAssignedHours = projectDevelopers.reduce(
        (acc, projectDeveloper) => acc + projectDeveloper.assignedHours,
        0,
      ) || 0;
      const developerMinAvailability = Math.max(developerAvailability.minHours - developerAvailability.vacationHours, 0)
        || 0;
      const developerMaxAvailability = Math.max(developerAvailability.maxHours - developerAvailability.vacationHours, 0)
        || 0;
      const chartData1 = [
        {
          name: 'Disponibilidad Máxima',
          data: [developerMaxAvailability - developerMinAvailability],
          color: '#BDBDBD',
          stack: 'stack1',
        },
        {
          name: ' Disponibilidad horas',
          data: [developerMinAvailability],
          color: '#24335E',
          stack: 'stack1',
        },
      ];
      const chartData2 = [
        {
          name: 'Horas asignadas',
          data: [developerAssignedHours],
          color: '#00D293',
        },
      ];
      let max = 0;
      if (developerAssignedHours >= developerMaxAvailability) {
        max = developerAssignedHours;
      } else {
        max = developerMaxAvailability;
      }

      setDataAvailabilityChart(chartData1);
      setDataAssignationChart(chartData2);
      setChartMax(max);
    };
    if (developers.length && developerId > 0) {
      setVariables();
    }
  }, [developers, month, developerId]);

  return (
    <div>
      <div className={classes.topRow}>
        <BarChart
          title={'Disponibilidad mínima y máxima'}
          data={dataAvailabilityChart}
          max={chartMax}
          chart={0}
        />
        <BarChart
          title={'Horas Asignadas'}
          data={dataAssignationChart}
          max={chartMax}
          chart={1}
        />
      </div>
    </div>
  );
}

AvailabilityAndAssignationBarChart.propTypes = {
  className: PropTypes.string,
  developers: PropTypes.array.isRequired,
  developerId: PropTypes.number.isRequired,
  month: PropTypes.string.isRequired,
};

export default AvailabilityAndAssignationBarChart;
