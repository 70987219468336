const REFERRED = 'Referido';
const DIRECT_CONTACT = 'Contacto directo';
const TEAM = 'Equipo';
const INTERNAL = 'Interno';

const communucationChannelList = [REFERRED, DIRECT_CONTACT, TEAM, INTERNAL];

module.exports = {
  REFERRED,
  DIRECT_CONTACT,
  TEAM,
  INTERNAL,
  communucationChannelList,
};
