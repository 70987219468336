import React from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ClientCreateForm from './ClientCreateForm';
import useStyles from './styles';

function ClientCreateView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Agregar Cliente"
    >
      <Container maxWidth={false}>
        <Header />
        <ClientCreateForm />
      </Container>
    </Page>
  );
}

export default ClientCreateView;
