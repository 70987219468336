import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  emptyRoot: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
    height: '100%',
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  buttonsDiv: {
    display: 'flex',
    width: '400px',
  },
  scrollTable: {
    overflowY: 'scroll',
  },
  buttonsDisplay: {
    display: 'flex',
    justifyContent: 'center',
  },
  deleteButton: {
    marginRight: '20px',
    verticalAlign: 'top',
  },
  titleTypography: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    color: '#24335E',
  },
  iconButton: {
    fontSize: '40px',
  },
  headerDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  infoCard: {
    width: '100%',
    height: '150px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    borderRadius: '0px',
    border: '1px solid #EAEAEA',
  },
  infoCardtitle: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily: theme.typography.Inter.fontFamily,
    color: '#5F696B',
  },
  infoCardNumber: {
    fontSize: '34px',
    fontWeight: 700,
    fontFamily: theme.typography.Inter.fontFamily,
  },
  blue: {
    color: '#24335E',
  },
  yellow: {
    color: '#FFC300',
  },
  green: {
    color: '#00D293',
  },
  red: {
    color: '#D42F2F',
  },
  chartDiv: {
    width: '100%',
    marginTop: '30px',
  },
  chartHeader: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
  },
  searchBar: {
    width: '300px',
  },
  bottomButtonContiner: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#FFFFFF',
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
  },
  modalContent: {
    height: 'auto',
    backgroundColor: '#fff',
    [theme.breakpoints.up('sm')]: {
      width: '800px',
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 40px 20px 40px',
  },
  modalHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modalTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  modalTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    textAlign: 'left',
    fontSize: 24,
    marginRight: 'auto',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  modalLine: {
    borderTop: '1px solid #222A35',
    margin: '20px 0',
  },
  modalBodyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  saveChangesContainer: {
    padding: '30px',
    background: '#FFFFFF',
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 0px 5px 5px',
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  selectedFileName: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  modalButton: {
    width: '300px',
    height: '50px',
    borderRadius: '5px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
  },
  headerGrid: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  downloadButton: {
    width: '150px',
    height: '50px',
  },
}));

export default useStyles;
