import React, { useEffect, useState } from 'react';
import {
  Box, Container, Tab, Tabs,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import performanceAspectsRequest from '../../../requests/api/performance_aspect_evaluations';
import performanceCyclesRequest from '../../../requests/api/performance_cycles';
import useStyles from './styles';
import ProjectSideBar from './ProjectSideBar';
import PerformanceEvaluationsTable from './PerformanceEvaluationsTable';
import EvaluationModal from './EvaluationModal'; // Import the modal component
import Evaluations from './MyEvaluations/Summary';
import MyEvaluationsTable from './MyEvaluations/MyEvaluationsTable';
import InstancesRequest from '../../../requests/api/performance_cycle_instances';

function DeveloperPerformanceView() {
  const classes = useStyles();
  const [allProjects, setAllProjects] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [projectId, setProjectId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentEvaluation, setCurrentEvaluation] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedScore, setSelectedScore] = useState(null);
  const [cycle, setCycle] = useState({ id: 1 });
  const [cycles, setCycles] = useState([]);
  const [update, setUpdate] = useState(false);
  const [value, setValue] = React.useState(0);
  const [userEvaluations, setUserEvaluations] = useState([]);
  const [userEvaluationAvg, setUserEvaluationAvg] = useState([]);
  const [instance, setInstance] = useState({
    projectsEvaluationAvg: 0,
  });

  const account = useSelector((state) => state.account);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getProjects = async () => {
      const response = await performanceAspectsRequest.getProjectsWithEvaluations({
        searchValue,
        userId: account.user.id,
        cycleId: cycle.id,
      });
      if (response.success) {
        const data = await response.data.data.projects;
        const arrayData = Object.values(data);
        if (arrayData.length > 0 && !projectId) {
          setProjectId(arrayData[0].id);
        }
        setAllProjects(arrayData);
      }
    };
    getProjects();
  }, [searchValue, cycle, update]);

  useEffect(() => {
    const getUserEvaluations = async () => {
      const response = await performanceAspectsRequest.getUserEvaluations({
        userId: account.user.id,
        cycleId: cycle.id,
      });
      if (response.success) {
        const {
          projectsScoresWithAverage,
          evaluationsPerRole,
        } = await response.data.data;
        setUserEvaluationAvg(projectsScoresWithAverage);
        setUserEvaluations(evaluationsPerRole);
      }
    };
    getUserEvaluations();
  }, [searchValue, cycle, update]);

  useEffect(() => {
    const getInstance = async () => {
      const response = await InstancesRequest
        .getPerformanceCycleInstanceByDeveloperId(account.user.developer.id, cycle.id);

      if (response.success) {
        const devInstance = response.data.data.performance_cycle_instance;
        setInstance(devInstance);
      }
    };

    if (cycle.id) getInstance();
  }, [cycle]);

  useEffect(() => {
    const getCycles = async () => {
      const response = await performanceCyclesRequest.getPerformanceCyclesPerPage(
        0,
        100,
      );

      if (response.data.success) {
        setCycles(response.data.data.performanceCycles);
        if (response.data.data.performanceCycles.length) {
          setCycle(response.data.data.performanceCycles[0]);
        }
      }
    };

    getCycles();
  }, []);

  const handleOpenModal = (evaluation, user, score) => {
    setCurrentEvaluation(evaluation); // Set the evaluation object to state
    setCurrentUser(user);
    setSelectedScore(score);
    setModalIsOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setModalIsOpen(false); // Close the modal
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Page className={classes.root} title="TubeOps | Performance proyectos">
      <Container maxWidth={false}>
        <Header cycle={cycle} setCycle={setCycle} cycles={cycles} />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="MIS EVALUACIONES" onClick={() => handleChange(0)} {...a11yProps(0)} />
            <Tab label="EVALUAR" onClick={() => handleChange(1)} {...a11yProps(1)} />
          </Tabs>
        </Box>
        {value === 1 && <Grid>
          {allProjects.length ? <div className={classes.mainSquare}>
            {/* projects sidebar */}
            <ProjectSideBar
              projects={allProjects}
              projectId={projectId}
              setProjectId={setProjectId}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            {/* project evaluations */}
            {allProjects.length && projectId
              && <PerformanceEvaluationsTable
                usersWithEvaluations={
                  allProjects.find((item) => item.id === projectId)
                    ? allProjects.find((item) => item.id === projectId).users
                    : []
                }
                handleOpenModal={handleOpenModal}
              />
            }
          </div> : <Grid style={{
            marginTop: '50px',
          }}>
            <Typography
              variant="h4"
              color="textPrimary"
            >
              No hay evaluaciones para este ciclo
            </Typography>
          </Grid>}
          {/* Render the EvaluationModal here */}
          {currentEvaluation && selectedScore && currentUser && <EvaluationModal
            evaluation={currentEvaluation}
            isOpen={modalIsOpen}
            onClose={handleCloseModal}
            user={currentUser}
            selectedScore={selectedScore}
            update={update}
            setUpdate={setUpdate}
          />}
        </Grid>}
        {value === 0 && <Grid>
          <Evaluations
            evaluationAvgs={userEvaluationAvg}
            avgEvaluation={instance.projectsEvaluationAvg || 0}
          />
          <Box sx={{ marginTop: '25px' }} />
          {userEvaluations?.length !== 0 && <MyEvaluationsTable
            usersWithEvaluations={userEvaluations}
          />}
        </Grid>}
      </Container>
    </Page>
  );
}

export default DeveloperPerformanceView;
