/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import pesoChileno from '../../../utils/moneyFormat';

function StackedBarChart({ className, currentMonthAssignment, ...rest }) {
  const classes = useStyles();

  const [monthlyCost, setMonthlyCost] = useState(0);

  useEffect(() => {
    const setVariables = async () => {
      const [projectCell] = currentMonthAssignment;
      const projectDevelopers = projectCell.project_developers;
      let cost = 0;
      for (let i = 0; i < projectDevelopers.length; i += 1) {
        cost
          += projectDevelopers[i].assignation.developer.costPerHour
          * projectDevelopers[i].assignedHours;
        setMonthlyCost(cost);
      }
    };
    if (currentMonthAssignment.length > 0) setVariables();
  }, [currentMonthAssignment]);

  return (
    <div>
      <Typography className={classes.subTitleTypography}>
        Costo mensual
      </Typography>
      <Card className={clsx(classes.cardMargin, className)} {...rest}>
        <div className={classes.costHeaders}>
          <Typography className={classes.cost}>
            {pesoChileno(monthlyCost)}
          </Typography>
        </div>
      </Card>
    </div>
  );
}

StackedBarChart.propTypes = {
  className: PropTypes.string,
  currentMonthAssignment: PropTypes.array,
};

export default StackedBarChart;
