/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import projectDeveloperRequest from '../../../requests/api/project_developer';

function LastMonthAssignment({
  className,
  lastMonthAssignments,
  month,
  setRefreshCurrentAssignment,
  setDevelopers,
  projectId,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [projectDevelopers, setProjectDevelopers] = useState([]);

  useEffect(() => {
    const setVariables = () => {
      setProjectDevelopers(lastMonthAssignments[0]?.project_developers || []);
    };
    setVariables();
  }, [lastMonthAssignments]);

  const getLastMonth = (date) => {
    const dateFormat = new Date(date);
    const Month = new Date(dateFormat.setMonth(dateFormat.getMonth() - 1));

    const a = Month.toLocaleString('es-es', { month: 'long' });
    return a;
  };

  const createProjectDeveloper = async (projectDeveloper) => {
    try {
      const data = {
        developerId: projectDeveloper.assignation.developer.id,
        projectId,
        assignationId: 0,
        projectCellId: 0,
        projectRoles: projectDeveloper.project_roles,
        assignedHours: projectDeveloper.assignedHours,
        month,
      };

      const response = await projectDeveloperRequest.postCreateProjectDeveloper(
        data,
      );

      if (response.success) {
        enqueueSnackbar('Cambios guardados', {
          variant: 'success',
        });
        setDevelopers([]);
        setRefreshCurrentAssignment((prevState) => !prevState);
      } else {
        enqueueSnackbar(response.message, {
          variant: 'error',
          action: (
            <Button href="/administracion/asignaciones">
              Volver a asignaciones
            </Button>
          ),
        });
      }
    } catch (error) {
      enqueueSnackbar('error', {
        variant: 'error',
        action: (
          <Button href="/administracion/asignaciones">
            Volver a asignaciones
          </Button>
        ),
      });
    }
  };

  return (
    <div>
      <Typography className={classes.subTitleTypography}>
        Célula {getLastMonth(month)}
      </Typography>
      <Card
        className={clsx(classes.cardMargin, classes.cardHeigth, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>Desarrollador</TableCell>
                  <TableCell className={classes.cell}>Role</TableCell>
                  <TableCell className={classes.cell}>
                    Horas Asignadas
                  </TableCell>
                  <TableCell className={classes.cell}>Horas Reales</TableCell>
                  <TableCell className={classes.cell}> Acciones </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectDevelopers.map((e) => (
                  <TableRow hover key={e.id}>
                    <TableCell className={classes.cell}>
                      {e.assignation.developer.user.name}{' '}
                      {e.assignation.developer.user.lastname}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {e.project_roles.map((rol) => `${rol.name}`).join(' & ')}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {e.assignedHours}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {e.realHours.toFixed(1)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Button
                        color="secondary"
                        variant="contained"
                        className={classes.action}
                        onClick={() => createProjectDeveloper(e)}
                      >
                        Replicar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </Card>
    </div>
  );
}

LastMonthAssignment.propTypes = {
  className: PropTypes.string,
  lastMonthAssignments: PropTypes.array,
  month: PropTypes.string,
  setRefreshCurrentAssignment: PropTypes.func,
  setDevelopers: PropTypes.func,
  projectId: PropTypes.number,
};

export default LastMonthAssignment;
