import React, { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Page from '../../../../components/Layout/Page';
import Header from './Header';
import AdminCreateForm from './UserCreateForm';
import RequestRole from '../../../../requests/api/role';
import { SYSTEM_ADMIN } from '../../../../utils/const/user_types';
import RequestCompany from '../../../../requests/api/companies';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function CustomerCreateView() {
  const classes = useStyles();
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const account = useSelector((state) => state.account);

  useEffect(() => {
    const getRoles = async () => {
      const response = await RequestRole.getRolesPerPage(0, 1000, '');
      if (response.success) {
        const data = await response.data.data.roles;
        setRoles(data);
      }
    };

    const getCompanies = async () => {
      const response = await RequestCompany.getCompaniesPerPage(0, 10000, '');
      if (response.success) {
        const data = await response.data.data.companies;
        setCompanies(data);
      }
    };
    if (account.user.role.name === SYSTEM_ADMIN) {
      getCompanies();
    }
    getRoles();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Example Template | Agregar Admin"
    >
      <Container maxWidth={false}>
        <Header />
        {
          (roles.length)
          && (
          <AdminCreateForm roles={roles} companies={companies} roleName={account.user.role.name}/>
          )
        }
      </Container>
    </Page>
  );
}

export default CustomerCreateView;
