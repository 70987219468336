import produce from 'immer';
import projectDemandGraphicActions from '../actions/projectDemandGraphicActions';

const initialState = {
  projectDemandGraphic: {},
};

const projectDemandGraphicReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectDemandGraphicActions.SET_PROJECT_DEMAND_GRAPHIC: {
      const { projectDemandGraphic } = action.payload;

      return produce(state, (draft) => {
        draft.projectDemandGraphic = projectDemandGraphic;
      });
    }

    default: {
      return state;
    }
  }
};

export const resetState = () => initialState;

export default projectDemandGraphicReducer;
