import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import vacationRequest from '../../../requests/api/vacationRequest';
import usersRequest from '../../../requests/api/user';
import vacationDay from '../../../requests/api/vacationDay';
import VacationDaysCount from './VacationDaysCount';
import RequestVacationsModal from '../RequestVacationsModal';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import { APPROVED, PENDING } from '../../../utils/const/vacationRequestStatus';
import vacationsCalculator from '../../../utils/vacationDaysCalculator';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));
function DeveloperVacationRequestList() {
  const classes = useStyles();
  const requestUser = useSelector((s) => s.account.user);
  const [vacationRequests, setVacationRequests] = useState([]);
  const [devHasPendingRequest, setDevHasPendingRequest] = useState(false);
  const [devHasNotSignedCertificate, setDevHasNotSignedCertificate] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalVacationRequests, setTotalVacationRequests] = useState(0);
  const [supervisors, setSupervisors] = useState([]);
  const [availableDays, setAvailableDays] = useState(0);
  const [availableCreditDays, setAvailableCreditDays] = useState(0);
  const [lastVacationDeposit, setLastVacationDeposit] = useState({});
  const [selectedDeveloper, setSelectedDeveloper] = useState(
    requestUser.role.name === SUPER_ADMIN ? {
      id: null,
      name: null,
      lastname: null,
      contractStartDate: null,
    } : {
      id: requestUser.developer.id,
      name: requestUser.name,
      lastname: requestUser.lastname,
      contractStartDate: requestUser.developer.contractStartDate,
    },
  );

  const [creditDaysExpiration, setCreditDaysExpiration] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  useEffect(() => {
    const searchValue = '';
    const getVacationRequests = async () => {
      const response = await vacationRequest
        .getVacationRequestsPerPage(page, limit, selectedDeveloper.id, searchValue);
      if (response.success) {
        const data = response.data.data.vacationRequests;
        const pendingVacationRequest = data.filter((el) => el.status === PENDING);
        const notSignedCertificateRequest = data.filter((el) => (el.status === APPROVED
          && !el.certificateSignDate));
        if (notSignedCertificateRequest.length > 0) setDevHasNotSignedCertificate(true);
        else setDevHasNotSignedCertificate(false);
        if (pendingVacationRequest.length > 0) setDevHasPendingRequest(true);
        setVacationRequests(data);
        setTotalVacationRequests(response.data.data.totalVacationRequests);
      }
    };
    const dayLimit = '';
    const dayPage = '';
    const getVacationDays = async () => {
      const response = await vacationDay
        .getVacationDaysPerPage(dayLimit, dayPage, selectedDeveloper.id);
      if (response.success) {
        const data = response.data.data.vacationDays;
        if (data.length > 0) {
          const {
            twoYearsAgoDeposit: deposit,
            twoYearsAgoCharge: charge,
            creditDaysAvailable: credit,
          } = vacationsCalculator(data);

          const availableVacationDays = deposit - charge;
          setAvailableDays(availableVacationDays);
          setAvailableCreditDays(credit);
          setLastVacationDeposit(data[0]);
        }
      }
    };
    const getSupervisors = async () => {
      const response = await usersRequest.getUsersByRole(SUPER_ADMIN);
      if (response.success) {
        const data = response.data.data.users.map((u) => ({
          id: u.id,
          name: u.name,
          lastname: u.lastname,
          devId: u.developer.id,
        }));
        setSupervisors(data);
      }
    };
    getVacationRequests();
    getVacationDays();
    getSupervisors();
    if (selectedDeveloper.id) {
      const fixContractStartDate = moment(selectedDeveloper.contractStartDate).utc();
      const expirationDate = fixContractStartDate.clone();
      // check how many years have passed since the start of the contract,
      // and set expiration date for the next anniversary.
      const workedYears = moment().utc().diff(fixContractStartDate, 'years');
      expirationDate.year(fixContractStartDate.year() + (workedYears + 1));
      setCreditDaysExpiration(expirationDate);
    }
  }, [limit, page, selectedDeveloper]);

  return (
    <Page
      className={classes.root}
      title="TubeOps | Vacaciones"
    >
      <Container maxWidth={false}>
        <Header />
        <VacationDaysCount
          availableDays={availableDays}
          availableCreditDays={availableCreditDays}
          creditDaysExpiration={creditDaysExpiration}
        />
        <RequestVacationsModal
          supervisors={supervisors}
          availableDays={availableDays}
          availableCreditDays={availableCreditDays}
          devHasPendingRequest={devHasPendingRequest}
          lastVacationDeposit={lastVacationDeposit}
          selectedDeveloper={selectedDeveloper}
          setSelectedDeveloper={setSelectedDeveloper}
          openModal={openModal}
          setOpenModal={setOpenModal}
          openPreviewModal={openPreviewModal}
          setOpenPreviewModal={setOpenPreviewModal}
          devHasNotSignedCertificate={devHasNotSignedCertificate}
          setDevHasNotSignedCertificate={setDevHasNotSignedCertificate}
        />
        <Box mt={3}>
          <Results
            vacationRequests={vacationRequests}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            requestUser={requestUser}
            totalVacationRequests={totalVacationRequests}
          />
        </Box>
      </Container>
    </Page>
  );
}
export default DeveloperVacationRequestList;
