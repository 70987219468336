import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  emptyRoot: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
    height: '100%',
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  buttonsDiv: {
    display: 'flex',
    width: '400px',
  },
  scrollTable: {
    overflowY: 'scroll',
  },
  buttonsDisplay: {
    display: 'flex',
    justifyContent: 'center',
  },
  deleteButton: {
    marginRight: '20px',
    verticalAlign: 'top',
  },
  titleTypography: {
    fontFamily: theme.typography.Inter.fontFamily,
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    color: '#24335E',
  },
  iconButton: {
    fontSize: '40px',
  },
  headerDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  infoCardsDiv: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  infoCard: {
    backgroundColor: '#D9D9D9',
    color: '#24335E',
    width: '90%',
    height: '150px',
    marginTop: '20px',
  },
  infoCardHover: {
    '&:hover': {
      backgroundColor: '#C1C5D1',
    },
  },
  infoCardSelected: {
    backgroundColor: '#24335E',
    color: '#FFFFFF',
  },
  cardFirstPart: {
    height: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardSecondPart: {
    height: '85px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoCardtitle: {
    fontSize: '22px',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily: theme.typography.Inter.fontFamily,
  },
  infoCardDivider: {
    backgroundColor: '#24335E',
  },
  infoCardDividerSelected: {
    backgroundColor: '#FFFFFF',
  },
  infoCardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
  },
  infoCardText: {
    fontSize: '12px',
    fontFamily: theme.typography.Inter.fontFamily,
  },
  infoCardNumber: {
    fontSize: '27px',
    fontWeight: 700,
    fontFamily: theme.typography.Inter.fontFamily,
  },
  infoCardSecondPartLeftRight: {
    width: '50%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  chartDiv: {
    width: '100%',
    marginTop: '30px',
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
    backgroundColor: '#FFFFFF',
  },
  datePicker: {
    width: '200px',
  },
  bottomButtonContiner: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#FFFFFF',
  },
  dateButtons: {
    backgroundColor: 'transparent', // Fondo transparente en todo momento
    '&:hover': {
      backgroundColor: 'transparent', // Fondo transparente en hover
    },
  },
}));

export default useStyles;
