import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Icon, Typography } from '@material-ui/core';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import useStyles from './styles';

function Header({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.emptyRoot, className)}
      container
      justifyContent="flex-start"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Icon>
          <RequestQuoteIcon sx={{ fontSize: 40 }} />
        </Icon>
      </Grid>

      <Grid item>
        <Typography variant="h3" className={classes.titleTypography}>
          Cuentas por cobrar
        </Typography>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
