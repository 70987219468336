import produce from 'immer';
import developerGraphicActions from '../actions/developerGraphicActions';

const initialState = {
  developerGraphic: {},
};

const developerGraphicReducer = (state = initialState, action) => {
  switch (action.type) {
    case developerGraphicActions.SET_DEVELOPER_GRAPHIC: {
      const { developerGraphic } = action.payload;

      return produce(state, (draft) => {
        draft.developerGraphic = developerGraphic;
      });
    }

    default: {
      return state;
    }
  }
};

export const resetState = () => initialState;

export default developerGraphicReducer;
