import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsCustomEvents from 'highcharts-custom-events';
import highchartsMore from 'highcharts/highcharts-more';
import { DEVELOPER } from '../../../utils/const/user_types';

HighchartsCustomEvents(Highcharts);

highchartsMore(Highcharts);

function DataGraphic({
  categories, data, setDeveloperIndex, role,
}) {
  const onClickEvent = (index) => {
    setDeveloperIndex(index);
  };

  let labels = {};
  if (role !== DEVELOPER) {
    labels = {
      events: {
        click() {
          onClickEvent(this.chart.series[0].points[this.pos].index);
        },
      },
      style: {
        cursor: 'pointer',
      },
    };
  }

  const options = {
    chart: {
      type: 'column',
      zoomType: 'x',
    },
    title: {
      text: 'Análisis de horas por developer',
    },
    xAxis: {
      categories,
      labels,
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: 'Horas',
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        fontSize: '12px',
      },
    },
    series: data,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

DataGraphic.propTypes = {
  data: PropTypes.array,
  categories: PropTypes.array,
  setDeveloperIndex: PropTypes.func,
  role: PropTypes.string,
};

export default DataGraphic;
