/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  TableContainer,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from './styles';
import projectDeveloperRequest from '../../../requests/api/project_developer';
import assignationActions from '../../../actions/assignationActions';
import { compareDateMonths } from '../../../utils/date';

function CurrentAssignments({
  className,
  currentMonthAssignment,
  setRefreshCurrentAssignment,
  allProjectRoles,
  developers,
  month,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [projectDevelopers, setProjectDevelopers] = useState([]);
  const [editId, setEditId] = useState(0);
  const [assignedHours, setAssignedHours] = useState(0);
  const [projectRoles, setProjectRoles] = useState([]);
  const [resetProjectRoles, setResetProjectRoles] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const getMonth = (date) => {
    const dateFormat = new Date(date);
    const Month = dateFormat.toLocaleString('es-es', { month: 'long' });
    return Month;
  };

  useEffect(() => {
    const setVariables = () => {
      const arrayProjectRoles = currentMonthAssignment[0]?.project_developers.map((e) => e.project_roles);
      setProjectRoles(arrayProjectRoles || []);
      setProjectDevelopers(currentMonthAssignment[0]?.project_developers || []);
    };
    setVariables();
  }, [currentMonthAssignment, resetProjectRoles]);

  if (developers.length === 0) {
    return null;
  }

  const editProjectDeveloper = async (index) => {
    try {
      const data = {
        id: editId,
        projectRoles: projectRoles[index],
        assignedHours,
      };
      const response = await projectDeveloperRequest.putEditProjectDeveloper(
        data,
      );

      if (response.success) {
        enqueueSnackbar('Cambios guardados', {
          variant: 'success',
        });
        setRefreshCurrentAssignment((prevState) => !prevState);
        setEditId(0);
      } else {
        enqueueSnackbar(response.message, {
          variant: 'error',
          action: (
            <Button href="/administracion/celulas-de-proyecto">
              Volver a Células de proyecto
            </Button>
          ),
        });
      }
    } catch (error) {
      enqueueSnackbar('error', {
        variant: 'error',
        action: (
          <Button href="/administracion/celulas-de-proyecto">
            Volver a Célula de proyecto
          </Button>
        ),
      });
    }
  };

  const deleteProjectDeveloper = async (id) => {
    try {
      const data = {
        id,
      };
      const response = await projectDeveloperRequest.deleteProjectDeveloper(
        data,
      );
      if (response.success) {
        enqueueSnackbar('Asignacion eliminadas!', {
          variant: 'success',
        });
        setRefreshCurrentAssignment((prevState) => !prevState);
        setEditId(0);
      } else {
        enqueueSnackbar(response.message, {
          variant: 'error',
          action: (
            <Button href="/administracion/celulas-de-proyecto">
              Células de proyecto
            </Button>
          ),
        });
      }
    } catch (error) {
      enqueueSnackbar('error', {
        variant: 'error',
        action: (
          <Button href="/administracion/celulas-de-proyecto">
            Células de proyecto
          </Button>
        ),
      });
    }
  };

  const handleProjectDeveloperEdit = (projectDeveloper) => {
    setEditId(projectDeveloper.id);
    setAssignedHours(projectDeveloper.assignedHours);
  };

  const handleSelectProjectRoles = (index, newSelectedValues) => {
    const newProjectRoles = [...projectRoles];
    newProjectRoles[index] = newSelectedValues;
    setProjectRoles(newProjectRoles);
  };

  const handleClickDeveloperName = (projectDeveloper) => {
    dispatch(
      assignationActions.setAssignation({
        developerId: projectDeveloper.assignation.developerId,
        month,
      }),
    );
    history.push('/administracion/asignaciones/panel');
  };

  return (
    <div>
      <Typography className={classes.subTitleTypography}>
        Célula {getMonth(month)}
      </Typography>
      <Card
        className={clsx(classes.cardMargin, classes.cardHeigth, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>Desarrollador</TableCell>
                  <TableCell className={classes.cell}>Role</TableCell>
                  <TableCell className={classes.cell}>
                    Horas Asignadas
                  </TableCell>
                  <TableCell className={classes.cell}>
                    Disponibilidad Mínima %
                  </TableCell>
                  <TableCell className={classes.cell}>
                    Disponibilidad Máxima %
                  </TableCell>
                  <TableCell className={classes.cell}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectDevelopers.map((e, index) => {
                  if (editId === e.id) {
                    return (
                      <TableRow hover key={e.id}>
                        <TableCell className={classes.cell}>
                          {e.assignation.developer.user.name}{' '}
                          {e.assignation.developer.user.lastname}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Autocomplete
                            key={index}
                            multiple
                            id="checkboxes-tags-demo"
                            options={allProjectRoles}
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) => option.id === value.id
                            }
                            getOptionLabel={(option) => option.name}
                            style={{ minWidth: 200 }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox checked={selected} />
                                {option.name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Roles"
                                variant="outlined"
                              />
                            )}
                            value={projectRoles[index]}
                            onChange={(event, newSelectedValues) => handleSelectProjectRoles(index, newSelectedValues)
                            }
                          />
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <TextField
                            required
                            label="Horas Asignadas"
                            name="assignedHours"
                            variant="outlined"
                            value={assignedHours}
                            onChange={(event) => {
                              setAssignedHours(event.target.value);
                            }}
                          />
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell align="right" className={classes.cell}>
                          <div className={classes.buttonsDisplay}>
                            <DeleteIcon
                              sx={{
                                backgroundColor: 'red',
                                color: 'white',
                                fontSize: 37,
                              }}
                              className={classes.deleteButton}
                              onClick={() => deleteProjectDeveloper(e.id)}
                            />
                            <Button
                              color="secondary"
                              variant="contained"
                              className={classes.action}
                              onClick={() => editProjectDeveloper(index)}
                            >
                              Guardar
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              className={classes.action}
                              onClick={() => {
                                setEditId(0);
                                setResetProjectRoles((prevState) => !prevState);
                              }}
                            >
                              Cancelar
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  const developer = developers.find(
                    (dev) => dev.id === e.assignation.developer.id,
                  );
                  const availability = developer?.availabilities.find(
                    (a) => compareDateMonths(a.month, month),
                  ) || [];

                  const availalityMinHours = Math.max(availability.minHours - availability.vacationHours, 0) || 0;
                  const availalityMaxHours = Math.max(availability.maxHours - availability.vacationHours, 0) || 0;

                  const assignation = developer?.assignations.find(
                    (a) => compareDateMonths(a.month, month),
                  ) || [];
                  const totalAssignedHours = assignation?.project_developers?.reduce(
                    (acc, cur) => acc + cur.assignedHours,
                    0,
                  ) || 0;
                  const projectAssignedHours = e.assignedHours;

                  const minAvailabilityPercentage = parseInt(
                    (totalAssignedHours / availalityMinHours) * 100,
                    10,
                  ) || 0;
                  const maxAvailabilityPercentage = parseInt(
                    (totalAssignedHours / availalityMaxHours) * 100,
                    10,
                  ) || 0;

                  return (
                    <TableRow hover key={e.id}>
                      <TableCell
                        className={clsx(classes.cell, classes.projectBarName)}
                        onClick={() => handleClickDeveloperName(e)}
                      >
                        {e.assignation.developer.user.name}{' '}
                        {e.assignation.developer.user.lastname}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {e.project_roles
                          .map((rol) => `${rol.name}`)
                          .join(' & ')}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {projectAssignedHours}
                      </TableCell>
                      <TableCell
                        className={classes.cell}
                      >{`${minAvailabilityPercentage}%`}</TableCell>
                      <TableCell
                        className={classes.cell}
                      >{`${maxAvailabilityPercentage}%`}</TableCell>
                      <TableCell className={classes.cell}
>
                        <Button
                          color="secondary"
                          variant="contained"
                          className={classes.action}
                          onClick={() => {
                            handleProjectDeveloperEdit(e);
                            setResetProjectRoles((prevState) => !prevState);
                          }}
                        >
                          Editar
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </Card>
    </div>
  );
}

CurrentAssignments.propTypes = {
  className: PropTypes.string,
  currentMonthAssignment: PropTypes.array,
  setRefreshCurrentAssignment: PropTypes.func,
  developers: PropTypes.array,
  allProjectRoles: PropTypes.array,
  month: PropTypes.string,
};

export default CurrentAssignments;
