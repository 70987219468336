import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import ClientEditForm from './ClientEditForm';
import Header from './Header';
import Request from '../../../requests/api/client';
import useStyles from './styles';

function ClientEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [client, setClient] = useState();

  useEffect(() => {
    const getClient = async () => {
      const res = await Request.getClientById(props.match.params.id);
      const response = res.data;
      if (isMountedRef.current) {
        setClient(response.data.client);
      }
    };

    getClient();
    // eslint-disable-next-line
  }, [props]);

  if (!client) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Editar Cliente"
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <ClientEditForm client={client} />
        </Box>
      </Container>
    </Page>
  );
}

ClientEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ClientEditView);
