import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import projectRoleRequest from '../../../requests/api/project_role';
import useStyles from './styles';

const getSearchCache = () => {
  const searchRole = '';

  return {
    searchRole,
  };
};

function ProjectRoleListView() {
  const classes = useStyles();
  const [projectRoles, setProjectRoles] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalProjectRoles, setTotalProjectRoles] = useState(0);
  const {
    searchRole,
  } = getSearchCache();
  const [filters, setFilters] = useState({
    role: searchRole,
  });
  useEffect(() => {
    const getProjectRoles = async () => {
      const response = await projectRoleRequest.getProjectRolesPerPage({
        page: pageOfTable,
        limit,
        searchValue: filters.role,
      });
      if (response.success) {
        const data = await response.data.data.projectRoles;
        const arrayData = Object.values(data);
        setProjectRoles(arrayData);
        setTotalProjectRoles(response.data.data.totalProjectRoles);
      }
    };
    getProjectRoles();
  }, [pageOfTable, limit, filters]);

  return (
    <Page className={classes.root} title="TubeOps | Roles de Proyecto">
      <Container maxWidth={false}>
        <Header />
        {projectRoles && (
          <Box mt={3}>
            <Results
              projectRoles={projectRoles}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              totalProjectRoles={totalProjectRoles}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default ProjectRoleListView;
