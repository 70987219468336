import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, Button } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import useStyles from './styles';
import billRequests from '../../../requests/api/bill';
import { ISSUED } from '../../../utils/const/billStatus';

const Filters = ({ filters, setFilters, clients }) => {
  const classes = useStyles();

  const handleFiltersChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleExport = async () => {
    const searchStatus = ISSUED;
    const { data } = await billRequests.exportBills({ searchStatus });
    if (data.data.files.length) {
      data.data.files.forEach((file) => {
        const uri = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file.file}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = uri;
        downloadLink.download = file.fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    }
  };

  if (clients.length === 0) {
    return null;
  }
  return (
    <div className={classes.chartHeader}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item />
        <Grid
          item
          container
          spacing={2}
          className={classes.headerGrid}
        >
          <Grid item>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={clients}
              defaultValue={null}
              getOptionLabel={(option) => (option ? `${option.name}` : '')}
              isOptionEqualToValue={(option, value) => option.name === value.name
              }
              onChange={(e, value) => handleFiltersChange('client', value || '')
              }
              value={filters.client}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Buscar Cliente"
                  variant="outlined"
                  className={classes.searchBar}
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Número Factura"
              name="search"
              onChange={(e) => handleFiltersChange('search', e.target.value || '')
              }
              value={filters.search}
              variant="outlined"
              className={classes.searchBar}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
              className={classes.downloadButton}
            >
              Descargar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Filters.propTypes = {
  clients: PropTypes.array,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default Filters;
