const SET_DEVELOPER_GRAPHIC = '@account/set-developer-graphic';
const REFRESH = '@account/refresh';

const setDeveloperGraphic = (developerGraphic) => (dispatch) => dispatch({
  type: SET_DEVELOPER_GRAPHIC,
  payload: {
    developerGraphic,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setDeveloperGraphic,
  SET_DEVELOPER_GRAPHIC,
  refresh,
  REFRESH,
};
