/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import developerDimensionRequest from '../../../requests/api/developer_dimension';

import useStyles from './styles';

function DeveloperDimensionEditForm({ className, developerDimension, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/talento" />;
  }
  return (
    <Formik
      initialValues={{
        name: `${developerDimension.developer.user.name} ${developerDimension.developer.user.lastname}` || '',
        dimension: `${developerDimension.dimension.name}` || '',
        experience: developerDimension.experience || null,
        interest: developerDimension.interest || null,

      }}
      validationSchema={Yup.object().shape({
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Make API request
          const data = {
            id: developerDimension.id,
            experience: values.experience || null,
            interest: values.interest || null,
          };

          const response = await developerDimensionRequest.putDeveloperDimensionById(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: (
                <Button href="/administracion/talento">
                  Volver a talento
                </Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                  <TextField
                    disabled={true}
                    fullWidth
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled={true}
                    fullWidth
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.dimension}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.experience && errors.experience)}
                    fullWidth
                    helperText={touched.experience && errors.experience}
                    label="Experiencia"
                    name="experience"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.experience}
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 200 },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.interest && errors.interest)}
                    fullWidth
                    helperText={touched.interest && errors.interest}
                    label="Interés"
                    name="interest"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.interest}
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 200 },
                    }}
                  />
                </Grid>
                <Grid item />
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

DeveloperDimensionEditForm.propTypes = {
  className: PropTypes.string,
  developerDimension: PropTypes.object.isRequired,
};

export default DeveloperDimensionEditForm;
