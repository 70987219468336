import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { Redirect } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import Request from '../../../requests/api/demands';
import useStyles from './styles';

function DemandsCreateForm({ className, clients, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [projects, setProjects] = useState([]);

  if (submitSuccess) {
    return <Redirect to="/administracion/demands" />;
  }

  return (
    <Formik
      initialValues={{
        project: null,
        client: null,
        month: '',
        minHours: '',
        maxHours: '',
      }}
      validationSchema={Yup.object().shape({
        client: Yup.object().nullable().required('Debe ingresar un cliente'),
        project: Yup.object().nullable().required('Debe ingresar un proyecto'),
        month: Yup.date().required('Debe ingresar un mes y un año'),
        minHours: Yup.number().required('Debe ingresar la cantidad mínima de horas'),
        maxHours: Yup.number().required('Debe ingresar la cantidad máxima de horas'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // const month = values.date.toLocaleString('es-es', { month: 'long' });
          const month = values.month.getMonth();
          const year = values.month.getFullYear();
          const firstDayOfTheMonth = new Date(year, month, 1);
          const data = {
            projectId: values.project.id,
            month: firstDayOfTheMonth,
            minHours: values.minHours,
            maxHours: values.maxHours,
          };
          const response = await Request.postCreateDemand(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: (
                <Button href="/administracion/demands">
                  Volver a Demanda
                </Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    id="combo-box-demo"
                    options={clients}
                    getOptionLabel={(option) => `${option.name}`
                    }
                    value={values.client}
                    onChange={(e, value) => {
                      setFieldValue('client', value);
                      if (value) {
                        setProjects(value.projects);
                      } else {
                        setProjects([]);
                        setFieldValue('project', null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.client && errors.client)}
                        helperText={touched.client && errors.client}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Nombre cliente"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    id="combo-box-demo"
                    options={projects}
                    getOptionLabel={(option) => `${option.name}`
                    }
                    onChange={(e, value) => {
                      setFieldValue('project', value);
                    }}
                    value={values.project}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.project && errors.project)}
                        helperText={touched.project && errors.project}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Nombre proyecto"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                      <DatePicker
                        inputFormat="MM-yyyy"
                        views={['year', 'month']}
                        label="Mes y Año"
                        minDate={new Date('2020-02-01')}
                        maxDate={new Date('2030-01-01')}
                        value={values.month}
                        onChange={(value) => setFieldValue('month', value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            error={Boolean(touched.month && errors.month)}
                            helperText={touched.month && errors.month}
                            onBlur={handleBlur}
                          />
                        )}
                      />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.minHours && errors.minHours)}
                    fullWidth
                    helperText={touched.minHours && errors.minHours}
                    label="Horas Mínimas"
                    name="minHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.minHours}
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="start">hr</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                <TextField
                    error={Boolean(touched.maxHours && errors.maxHours)}
                    fullWidth
                    helperText={touched.maxHours && errors.maxHours}
                    label="Horas Máximas"
                    name="maxHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.maxHours}
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="start">hr</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item />
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

DemandsCreateForm.propTypes = {
  className: PropTypes.string,
  clients: PropTypes.array,
};

export default DemandsCreateForm;
