import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import developerRequest from '../../../requests/api/developer';
import availabilityRequest from '../../../requests/api/availability';
import GraphicHeader from './GraphicHeader';
import useStyles from './styles';

function AvailabilitiesListView() {
  const classes = useStyles();
  const [availability, setAvailability] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [totalAvailabilities, setTotalAvailabilities] = useState(0);
  const [view, setView] = useState(1);
  const [month, setMonth] = useState('');
  const [graphicStartMonth, setGraphicStartMonth] = useState('');
  const [graphicEndMonth, setGraphicEndMonth] = useState('');
  const [developers, setDevelopers] = useState([]);
  const [developerId, setDeveloperId] = useState(0);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getAvailabilities = async () => {
      const response = await availabilityRequest.getAvailabilitiesPerPage(
        pageOfTable,
        limit,
        searchValue,
        month,
      );
      if (response.success) {
        const availabilitiesResponse = await response.data.data.availabilities;
        const arrayData = Object.values(availabilitiesResponse);
        setAvailability(arrayData);
        setTotalAvailabilities(response.data.data.totalAvailabilities);
      }
    };
    getAvailabilities();
  }, [pageOfTable, limit, searchValue, month]);

  useEffect(() => {
    const getAvailabilitiesGraphic = async () => {
      const response = await availabilityRequest.getAvailabilitiesGraphic({
        developerId,
        startMonth: graphicStartMonth,
        endMonth: graphicEndMonth,
      });
      if (response.success) {
        const availabilitiesGraphicResponse = await response.data.data.totalAvailabilities;
        const dataArray = [];
        dataArray.push({
          name: 'Disponibilidad',
          data: availabilitiesGraphicResponse.availability,
        });
        dataArray.push({
          name: 'Asignación',
          data: availabilitiesGraphicResponse.assignedHours,
        });
        setData(dataArray);
        setCategories(availabilitiesGraphicResponse.month);
      }
    };
    const getDevelopers = async () => {
      const response = await developerRequest.getDevelopersPerPage(0, 0, '');
      if (response.success) {
        const developersData = await response.data.data.developers;
        const arrayData = Object.values(developersData);
        setDevelopers(arrayData);
      }
    };
    getDevelopers();
    getAvailabilitiesGraphic();
  }, [graphicStartMonth, developerId, graphicEndMonth]);

  return (
    <Page className={classes.root} title="TubeOps | Disponibilidad">
      <Container maxWidth={false}>
        <Header setView={setView} />
        {availability && (
          <Box mt={3}>
            {!view ? (
              <Results
                availability={availability}
                pageOfTable={pageOfTable}
                setPageOfTable={setPageOfTable}
                limit={limit}
                setLimit={setLimit}
                month={month}
                setMonth={setMonth}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                totalAvailabilities={totalAvailabilities}
              />
            ) : (
              <GraphicHeader
                data={data}
                categories={categories}
                developers={developers}
                graphicStartMonth={graphicStartMonth}
                setGraphicStartMonth={setGraphicStartMonth}
                graphicEndMonth={graphicEndMonth}
                setGraphicEndMonth={setGraphicEndMonth}
                setDeveloperId={setDeveloperId}
              />
            )}
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default AvailabilitiesListView;
