import axios from '../../utils/axios';
import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';

const postCreateAssignation = async ({
  developerId,
  month,
  estSatisfaction,
  realSatisfaction,
}) => {
  try {
    const response = await axios.post('/api/assignation/create', {
      developerId,
      month,
      estSatisfaction,
      realSatisfaction,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage
      : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAssignationsPerPage = async ({
  pageOfTable,
  limit,
  searchDeveloper,
  searchStartMonth,
  searchEndMonth,
}) => {
  try {
    const { data } = await axios.get(
      `/api/assignations?page=${pageOfTable}&limit=${limit}&searchDeveloper=${searchDeveloper}&searchStartMonth=${searchStartMonth}&searchEndMonth=${searchEndMonth}`,
    );

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAssignationById = async (id) => {
  try {
    const { data } = await axios.get(`/api/assignation/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putAssignationById = async (
  {
    id, estSatisfaction, realSatisfaction,
  },
) => {
  try {
    const response = await axios.put('/api/assignation/edit', {
      id, estSatisfaction, realSatisfaction,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteAssignation = async (id) => {
  try {
    const { data } = await axios.delete(`/api/assignation/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.data.data.errorMessage
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAssignationByDeveloperIdAndMonth = async ({
  developerId,
  month,
}) => {
  try {
    const { data } = await axios.get(
      `/api/assignation?developerId=${developerId}&month=${month}`,
    );

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const get12AssignationsByDev = async ({ developerId }) => {
  try {
    const { data } = await axios.get(`/api/assignations/${developerId}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  postCreateAssignation,
  getAssignationsPerPage,
  getAssignationById,
  putAssignationById,
  deleteAssignation,
  getAssignationByDeveloperIdAndMonth,
  get12AssignationsByDev,
};
