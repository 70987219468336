import React, { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import projectRequest from '../../requests/api/project';
import developerRequest from '../../requests/api/developer';
import useStyles from './styles';
import { firstDayOfCurrentMonth } from '../../utils/date';
import developerGraphicActions from '../../actions/developerGraphicActions';
import projectGraphicActions from '../../actions/projectGraphicActions';
import controlPanelGraphicActions from '../../actions/controlPanelGraphicActions';
import InfoCard from './InfoCard';
import { nonBillableProjects } from '../../utils/const/nonBillableProjects';

function ControlPanelView() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [month, setMonth] = useState(null);
  const [projectIndex, setProjectIndex] = useState(-1);
  const [developerIndex, setDeveloperIndex] = useState(-1);
  const [graphic, setGraphic] = useState(1);
  const history = useHistory();
  const controlPanelGraphicReduxInfo = useSelector(
    (state) => state.controlPanelGraphic,
  );
  const [totalAssignedHours, setTotalAssignedHours] = useState(0);
  const [totalProjectedHours, setTotalProjectedHours] = useState(0);
  const [totalRealHours, setTotalRealHours] = useState(0);
  const [totalBillableAssignedHours, setTotalBillableAssignedHours] = useState(0);
  const [totalBillableProjectedHours, setTotalBillableProjectedHours] = useState(0);
  const [totalBillableRealHours, setTotalBillableRealHours] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const setReduxInfo = async () => {
      if (controlPanelGraphicReduxInfo.controlPanelGraphic.month) {
        if (
          controlPanelGraphicReduxInfo.controlPanelGraphic.name === 'project'
        ) {
          setGraphic(1);
        }
        if (
          controlPanelGraphicReduxInfo.controlPanelGraphic.name === 'developer'
        ) {
          setGraphic(0);
        }
        setMonth(controlPanelGraphicReduxInfo.controlPanelGraphic.month);
        dispatch(controlPanelGraphicActions.setControlPanelGraphic({}));
      } else {
        setMonth(firstDayOfCurrentMonth(new Date()));
      }
    };
    setReduxInfo();
  }, []);

  useEffect(() => {
    const setGraphicVariables = async () => {
      if (graphic) {
        const response = await projectRequest.getAllProjectsGraphic({
          month,
        });
        if (
          response.success
          && Object.keys(response.data.data.totalProjects).length !== 0
        ) {
          const projectsData = await response.data.data.totalProjects;
          const dataArray = [];
          dataArray.push({
            name: 'Horas Asignadas',
            data: projectsData.assignedHours,
          });
          dataArray.push({
            name: 'Horas Proyectadas',
            data: projectsData.projectedHours,
          });
          dataArray.push({
            name: 'Horas Reales',
            data: projectsData.realHours,
          });
          setIds(projectsData.ids);
          setData(dataArray);
          setCategories(projectsData.projectsName);

          const assignedHours = projectsData.assignedHours.reduce(
            (a, b) => a + b,
            0,
          );

          const projectedHours = projectsData.projectedHours.reduce(
            (a, b) => a + b,
            0,
          );

          const realHours = projectsData.realHours.reduce((a, b) => a + b, 0);

          const billablePositions = [];

          const { projectsName } = projectsData;

          for (let i = 0; i < projectsName.length; i += 1) {
            const [client, project] = projectsName[i].split(' - ');
            if (
              nonBillableProjects[client] === undefined
              || !nonBillableProjects[client].includes(project)
            ) {
              billablePositions.push(i);
            }
          }

          const billableAssignedHours = billablePositions.reduce(
            (a, b) => a + projectsData.assignedHours[b],
            0,
          );

          const billableProjectedHours = billablePositions.reduce(
            (a, b) => a + projectsData.projectedHours[b],
            0,
          );

          const billableRealHours = billablePositions.reduce(
            (a, b) => a + projectsData.realHours[b],
            0,
          );
          setTotalAssignedHours(assignedHours);
          setTotalProjectedHours(projectedHours);
          setTotalRealHours(realHours);
          setTotalBillableAssignedHours(billableAssignedHours);
          setTotalBillableProjectedHours(billableProjectedHours);
          setTotalBillableRealHours(billableRealHours);
        } else {
          setIds([]);
          setData([]);
          setCategories([]);
        }
      } else {
        const response = await developerRequest.getAllDevelopersGraphic({
          month,
        });
        if (
          response.success
          && Object.keys(response.data.data.totalDevelopers).length !== 0
        ) {
          const developersData = await response.data.data.totalDevelopers;
          const dataArray = [];
          dataArray.push({
            name: 'Horas Asignadas',
            data: developersData.assignedHours,
          });
          dataArray.push({
            name: 'Horas Proyectadas',
            data: developersData.projectedHours,
          });
          dataArray.push({
            name: 'Horas Reales',
            data: developersData.realHours,
          });
          setIds(developersData.ids);
          setData(dataArray);
          setCategories(developersData.developersName);
        } else {
          setIds([]);
          setData([]);
          setCategories([]);
        }
      }
    };

    if (month) setGraphicVariables();
  }, [month, graphic]);

  useEffect(() => {
    const redirectProjectGraphicView = async () => {
      if (projectIndex >= 0) {
        const id = ids[projectIndex];
        dispatch(
          projectGraphicActions.setProjectGraphic({ projectId: id, month }),
        );
        history.push('/administracion/proyecto/grafico');
      }
    };
    redirectProjectGraphicView();
  }, [projectIndex]);

  useEffect(() => {
    const redirectDeveloperGraphicView = async () => {
      if (developerIndex >= 0) {
        const id = ids[developerIndex];
        dispatch(
          developerGraphicActions.setDeveloperGraphic({
            developerId: id,
            month,
          }),
        );
        history.push('/administracion/developers/grafico');
      }
    };
    redirectDeveloperGraphicView();
  }, [developerIndex]);

  return (
    <Page className={classes.root} title="TubeOps | Panel de Control">
      <Container maxWidth={false}>
        <div className={classes.headerDiv}>
          <Header />
        </div>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              totalHours={totalAssignedHours}
              totalBillableHours={totalBillableAssignedHours}
              card={0}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              totalHours={totalProjectedHours}
              totalBillableHours={totalBillableProjectedHours}
              card={1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              totalHours={totalRealHours}
              totalBillableHours={totalBillableRealHours}
              card={2}
            />
          </Grid>
        </Grid>
        <Box mt={3}>
          <Results
            categories={categories}
            data={data}
            month={month}
            setMonth={setMonth}
            setProjectIndex={setProjectIndex}
            setDeveloperIndex={setDeveloperIndex}
            graphic={graphic}
            setGraphic={setGraphic}
          />
        </Box>
      </Container>
    </Page>
  );
}

ControlPanelView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ControlPanelView);
