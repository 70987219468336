import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import DemandsCreateForm from './DemandsCreateForm';
import useStyles from './styles';
import Request from '../../../requests/api/client';

function DemandsCreateView() {
  const classes = useStyles();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const getClient = async () => {
      const response = await Request.getClientsPerPage(0, 100, '');
      if (response.success) {
        const data = await response.data.data.clients;
        const arrayData = Object.values(data);
        setClients(arrayData);
      }
    };
    getClient();
  }, []);

  return (
    <Page className={classes.root} title="Agregar Demanda">
      <Container maxWidth={false}>
        <Header />
        <DemandsCreateForm clients={clients} />
      </Container>
    </Page>
  );
}

export default DemandsCreateView;
