import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';
import axios from '../../utils/axios';

const getBillsPerPage = async ({
  page,
  limit,
  searchValue,
  searchStartMonth,
  searchEndMonth,
  searchClient,
  searchStatus,
  searchOrder,
}) => {
  try {
    const { data } = await axios.get(
      `/api/bills?page=${page}&limit=${limit}&searchStartMonth=${searchStartMonth}&searchEndMonth=${searchEndMonth}&searchClient=${searchClient}&searchStatus=${searchStatus}&searchValue=${searchValue}&searchOrder=${searchOrder}`,
    );
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postCreateBill = async ({
  clientId,
  type,
  number,
  issueDate,
  dueDate,
  amount,
  currency,
  paymentDate,
  billUrl,
  creditNoteUrl,
  comments,
  status,
  serviceMonth,
}) => {
  try {
    const response = await axios.post('/api/bill/create', {
      clientId,
      type,
      number,
      issueDate,
      dueDate,
      amount,
      currency,
      paymentDate,
      billUrl,
      creditNoteUrl,
      comments,
      status,
      serviceMonth,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const errStatus = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage
      : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status: errStatus,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putBillById = async ({
  id,
  clientId,
  type,
  number,
  issueDate,
  dueDate,
  amount,
  currency,
  paymentDate,
  billUrl,
  creditNoteUrl,
  comments,
  status,
  serviceMonth,
  paid,
}) => {
  try {
    const response = await axios.put('/api/bill/edit', {
      id,
      clientId,
      type,
      number,
      issueDate,
      dueDate,
      amount,
      currency,
      paymentDate,
      billUrl,
      creditNoteUrl,
      comments,
      status,
      serviceMonth,
      paid,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const errStatus = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage
      : INTERNAL_SERVER_ERROR.message;

    return {
      success: false,
      status: errStatus,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getBillById = async (id) => {
  try {
    const { data } = await axios.get(`/api/bill/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteBill = async (id) => {
  try {
    const { data } = await axios.delete(`/api/bill/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const exportBills = async ({ searchStatus }) => {
  try {
    const { data } = await axios.get(
      `/api/bill/export?searchStatus=${searchStatus}`,
    );
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  getBillsPerPage,
  postCreateBill,
  putBillById,
  getBillById,
  deleteBill,
  exportBills,
};
