import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { Redirect } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import Request from '../../../requests/api/project';
import { projectStatusList } from '../../../utils/const/projectStatus';
import useStyles from './styles';

function ProjectCreateForm({
  className,
  clients,
  categories,
  categoriesDimensions,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [checked, setChecked] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChangeCheck = (e) => {
    setChecked(e.target.checked);
  };

  if (submitSuccess) {
    return <Redirect to="/administracion/proyecto" />;
  }

  return (
    <Formik
      initialValues={{
        client: '',
        name: '',
        description: '',
        estimatedHours: '',
        fixedBudget: checked,
        status: '',
        kickOffDate: '',
        closeDate: null,
        dimensions: [],
        notionDatabaseId: null,
      }}
      validationSchema={Yup.object().shape({
        client: Yup.object().required('Debe ingresar un cliente'),
        name: Yup.string().required('Debe ingresar un nombre'),
        description: Yup.string().required('Debe ingresar una descripción'),
        estimatedHours: Yup.string().required(
          'Debe ingresar un tiempo estimado',
        ),
        status: Yup.string().required('Debe ingresar el estado del proyecto'),
        kickOffDate: Yup.date().required('Debe ingresar una fecha de inicio'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            clientId: values.client.id,
            name: values.name,
            description: values.description,
            estimatedHours: values.estimatedHours,
            fixedBudget: checked,
            status: values.status,
            kickOffDate: values.kickOffDate,
            closeDate: values.closeDate,
            dimensions: values.dimensions,
            notionDatabaseId: values.notionDatabaseId,
          };
          const response = await Request.postCreateProject(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: response.message });
            enqueueSnackbar(response.message, {
              variant: 'error',
              action: (
                <Button href="/administracion/proyecto">
                  Volver a Proyectos
                </Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    id="combo-box-demo"
                    options={clients}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(e, value) => {
                      if (value === null) {
                        // eslint-disable-next-line no-param-reassign
                        value = '';
                      }
                      setFieldValue('client', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.client && errors.client)}
                        helperText={touched.client && errors.client}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // required
                        variant="outlined"
                        label="Nombre cliente"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    multiline
                    helperText={touched.description && errors.description}
                    label="Descripción"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.description}
                    variant="outlined"
                    type="string"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(
                      touched.estimatedHours && errors.estimatedHours,
                    )}
                    fullWidth
                    helperText={touched.estimatedHours && errors.estimatedHours}
                    label="Horas estimadas"
                    name="estimatedHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.estimatedHours}
                    variant="outlined"
                    type="string"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={projectStatusList}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.status}
                    onChange={(event, value) => {
                      setFieldValue('status', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.status && errors.status)}
                        helperText={touched.status && errors.status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Estado del proyecto"
                        name="status"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      views={['year', 'month', 'day']}
                      label="Fecha de inicio"
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      value={values.kickOffDate}
                      onChange={(value) => setFieldValue('kickOffDate', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          error={Boolean(
                            touched.kickOffDate && errors.kickOffDate,
                          )}
                          helperText={touched.kickOffDate && errors.kickOffDate}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      views={['year', 'month', 'day']}
                      label="Fecha de cierre"
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      value={values.closeDate}
                      onChange={(value) => setFieldValue('closeDate', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          error={Boolean(touched.closeDate && errors.closeDate)}
                          helperText={touched.closeDate && errors.closeDate}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(
                      touched.notionDatabaseId && errors.notionDatabaseId,
                    )}
                    fullWidth
                    helperText={
                      touched.notionDatabaseId && errors.notionDatabaseId
                    }
                    label="ID tabla Notion"
                    name="notionDatabaseId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.notionDatabaseId}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChangeCheck}
                      />
                    }
                    label="Presupuesto Fijo"
                  />
                </Grid>
                <Grid Grid item md={6} xs={12} />
                <div className={classes.cardTitle}>
                  <Typography variant="h3" color="textPrimary" align="center">
                    Dimensiones
                  </Typography>
                </div>
                <Box width="100%" />
                {categories.map((element, index) => (
                  <Grid
                    key={element.id}
                    container
                    spacing={3}
                    className={classes.dimensionsMargin}
                  >
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        disabled={true}
                        label="Categoría"
                        name="category"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        defaultValue={element.name}
                        variant="outlined"
                        type="string"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={categoriesDimensions.filter(
                          (e) => e.category_id === element.id,
                        )}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.dimension_name}
                        onChange={(e, value) => setFieldValue(`dimensions[${index}]`, value)
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 6 }}
                              checked={selected}
                            />
                            {option.dimension_name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched.dimensions && errors.dimensions,
                            )}
                            helperText={touched.dimensions && errors.dimensions}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Dimensiones"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ProjectCreateForm.propTypes = {
  className: PropTypes.string,
  clients: PropTypes.array,
  categoriesDimensions: PropTypes.array,
  categories: PropTypes.array,
};

export default ProjectCreateForm;
