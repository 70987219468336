import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Redirect } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import Request from '../../../requests/api/demands';

import useStyles from './styles';

function DemandEditForm({ className, demand, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [checked, setChecked] = useState(demand.blocked);

  const handleChangeCheck = (e) => {
    setChecked(e.target.checked);
  };

  if (submitSuccess) {
    return <Redirect to="/administracion/demands" />;
  }

  return (
    <Formik
      initialValues={{
        month: demand.month || '',
        minHours: demand.minHours || '',
        maxHours: demand.maxHours || '',
        blocked: demand.blocked || '',
      }}
      validationSchema={Yup.object().shape({
        month: Yup.date().required('Debe ingresar un mes y un año'),
        minHours: Yup.number().required(
          'Debe ingresar la cantidad mínima de horas',
        ),
        maxHours: Yup.number().required(
          'Debe ingresar la cantidad máxima de horas',
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Make API request
          const data = {
            id: demand.id,
            month: values.month,
            minHours: values.minHours,
            maxHours: values.maxHours,
            blocked: checked,
          };

          const response = await Request.putDemandById(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: (
                <Button href="/administracion/demands">
                  Volver a demandas
                </Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="MM-yyyy"
                      views={['year', 'month']}
                      label="Mes y Año"
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      value={values.month}
                      onChange={(value) => setFieldValue('month', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          error={Boolean(touched.month && errors.month)}
                          helperText={touched.month && errors.month}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.minHours && errors.minHours)}
                    fullWidth
                    helperText={touched.minHours && errors.minHours}
                    label="Horas Mínimas"
                    name="minHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // required
                    value={values.minHours}
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="start">hr</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.maxHours && errors.maxHours)}
                    fullWidth
                    helperText={touched.maxHours && errors.maxHours}
                    label="Horas Máximas"
                    name="maxHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // required
                    value={values.maxHours}
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="start">hr</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={<Checkbox
                  checked={checked}
                  onChange={handleChangeCheck}
                  />}
                  label="Demanda bloqueada" />
                </Grid>
                <Grid item />
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

DemandEditForm.propTypes = {
  className: PropTypes.string,
  demand: PropTypes.object.isRequired,
};

export default DemandEditForm;
