import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import Request from '../../../requests/api/performance_cycle_instances';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function PerformanceCycleInstancesListView() {
  const classes = useStyles();
  const [instances, setInstances] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const performanceCycleId = window.location.pathname.split('/')[3] || '';
  const [totalPerformanceCycleInstances, setTotalPerformanceCycleInstances] = useState(0);

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }
    const getInstances = async () => {
      const response = await Request.getPerformanceCycleInstancesPerPage(
        performanceCycleId,
        pageOfTable,
        limit,
        searchValue,
      );
      if (response.success) {
        const data = await response.data.data.performanceCycleInstances;
        setInstances(data);
        setTotalPerformanceCycleInstances(response.data.data.totalPerformanceCycleInstances);
      }
    };
    getInstances();
  }, [pageOfTable, limit, searchValue]);

  return (
    <Page
      className={classes.root}
      title="TubeOps | Ciclos de performance - Instancias"
    >
      <Container maxWidth={false}>
        <Header />
        {instances && (
          <Box mt={3}>
            <Results
              performanceCycleInstances={instances}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              totalPerformanceCycleInstances={totalPerformanceCycleInstances}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default PerformanceCycleInstancesListView;
