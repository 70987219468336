import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import Request from '../../../requests/api/dimension';
import useStyles from './styles';

const getSearchCache = () => {
  const searchDimension = '';
  const searchCategory = '';
  const searchSubCategory = '';

  return {
    searchDimension,
    searchCategory,
    searchSubCategory,
  };
};

function DimensionsListView() {
  const classes = useStyles();
  const [dimensions, setDimensions] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalDimensions, setTotalDimensions] = useState(0);
  const {
    searchDimension, searchCategory, searchSubCategory,
  } = getSearchCache();
  const [filters, setFilters] = useState({
    dimension: searchDimension,
    category: searchCategory,
    subCategory: searchSubCategory,
  });
  useEffect(() => {
    const getDimensions = async () => {
      const response = await Request.getDimensionsPerPage({
        page: pageOfTable,
        limit,
        searchDimension: filters.dimension,
        searchCategory: filters.category,
        searchSubCategory: filters.subCategory,
      });
      if (response.success) {
        const data = await response.data.data.dimensions;
        const arrayData = Object.values(data);
        setDimensions(arrayData);
        setTotalDimensions(response.data.data.totalDimensions);
      }
    };
    getDimensions();
  }, [pageOfTable, limit, filters]);

  return (
    <Page className={classes.root} title="TubeOps | Dimensiones">
      <Container maxWidth={false}>
        <Header />
        {dimensions && (
          <Box mt={3}>
            <Results
              dimensions={dimensions}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              totalDimensions={totalDimensions}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default DimensionsListView;
