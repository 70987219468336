/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Edit as EditIcon, Search as SearchIcon } from 'react-feather';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { useSnackbar } from 'notistack';
import projectRequest from '../../../requests/api/project';
import useStyles from './styles';
import getInitials from '../../../utils/getInitials';
import { formatDayMonthAndYearMoment } from '../../../utils/date';
import { SUPER_ADMIN, SYSTEM_ADMIN } from '../../../utils/const/user_types';
import projectDemandGraphicActions from '../../../actions/projectDemandGraphicActions';
import projectGraphicActions from '../../../actions/projectGraphicActions';

function Results({
  className,
  project,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  searchValue,
  setSearchValue,
  totalProjects,
  filters,
  setFilters,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectProject, setSelectProject] = useState([]);
  const [currentProject, setCurrentProject] = useState(project);
  const [realCost, setRealCost] = useState([]);
  const [assignedCost, setAssignedCost] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const getProjectRealCost = async () => {
      const projectsRealCostArray = [];
      const projectsAssignedCostArray = [];
      for (let k = 0; k < project.length; k += 1) {
        let totalRealCost = 0;
        let totalAssignedCost = 0;
        const projectCells = project[k].project_cells;
        for (let i = 0; i < projectCells.length; i += 1) {
          const projectCell = projectCells[i];
          const projectDevelopers = projectCell.project_developers;
          for (let j = 0; j < projectDevelopers.length; j += 1) {
            const projectDeveloper = projectDevelopers[j];
            const developerCost = (projectDeveloper.assignation.developer.cost_per_hour * projectDeveloper.realHours);
            const projectCost = (projectDeveloper.assignation.developer.cost_per_hour * projectDeveloper.assignedHours);
            totalRealCost += developerCost;
            totalAssignedCost += projectCost;
          }
        }
        projectsRealCostArray[k] = totalRealCost;
        projectsAssignedCostArray[k] = totalAssignedCost;
      }
      setRealCost(projectsRealCostArray);
      setAssignedCost(projectsAssignedCostArray);
    };
    getProjectRealCost();
  }, [project]);

  useEffect(() => {
    setCurrentProject(project);
  }, [project]);

  const handleSelectAllProjects = (event) => {
    setSelectProject(
      event.target.checked ? currentProject.map((e) => e.id) : [],
    );
  };

  const redirectToDemandGraphic = (projectId) => {
    dispatch(projectDemandGraphicActions.setProjectDemandGraphic({ projectId }));
    history.push('/administracion/demanda-proyectos');
  };

  const handleSelectOneProject = (event, projectId) => {
    if (!selectProject.includes(projectId)) {
      setSelectProject((prevSelected) => [
        ...prevSelected,
        projectId,
      ]);
    } else {
      setSelectProject((prevSelected) => prevSelected.filter((id) => id !== projectId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleFiltersChange = (name, event) => {
    event.persist();
    let value = null;
    value = event.target.value;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectProject.length > 0;
  const selectSomeProjects = selectProject.length > 0
    && selectProject.length < currentProject.length;
  const selectedAllProjects = selectProject.length === currentProject.length;
  const account = useSelector((state) => state.account);

  const deleteAvailabilities = async () => {
    if (account.user.role.name !== SUPER_ADMIN && account.user.role.name !== SYSTEM_ADMIN) {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectProject.length; a += 1) {
        projectRequest.deleteProject(selectProject[a]);
        enqueueSnackbar('Proyectos eliminados!', {
          variant: 'success',
        });
      }
      setCurrentProject(
        currentProject.filter((e) => !selectProject.includes(e.id)),
      );
      setSelectProject([]);
    }
  };

  const handleRedirectToGraphicProject = (projectId) => {
    dispatch(projectGraphicActions.setProjectGraphic({ projectId }));
    history.push('/administracion/proyecto/grafico');
  };

  return (
    <Card className={clsx(classes.emptyRoot, className)} {...rest}>
      <Divider />
      <Grid
        container
        spacing={3}
        className={classes.filtersContainer}
        display="flex"
        alignItems="center"
        flexwrap="wrap"
      >      <Grid item md={4} xs={12}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleFiltersChange('client', e)}
            placeholder="Buscar cliente"
            value={filters.client}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleFiltersChange('project', e)}
            placeholder="Buscar proyecto"
            value={filters.project}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllProjects}
              indeterminate={selectSomeProjects}
              onChange={handleSelectAllProjects}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteAvailabilities()}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllProjects}
                    indeterminate={selectSomeProjects}
                    onChange={handleSelectAllProjects}
                  />
                </TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Proyecto</TableCell>
                <TableCell>Horas Estimadas</TableCell>
                <TableCell>Costo Total Estimado </TableCell>
                <TableCell>Costo Total Real</TableCell>
                <TableCell>Fecha de Inicio</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell align="right">
                  Gráfico
                </TableCell>
                <TableCell align="right">
                  Demanda
                </TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProject.map((element, index) => {
                const isProjectSelected = selectProject.includes(element.id);

                return (
                  <TableRow hover key={element.id} selected={isProjectSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isProjectSelected}
                        onChange={(event) => handleSelectOneProject(event, element.id)
                        }
                        value={isProjectSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatar} src={element.avatar}>
                          {getInitials(element.name)}
                        </Avatar>
                        <div>
                          <Typography color="inherit" variant="h6">
                            {element.client.name}
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>{element.name}</TableCell>
                    <TableCell>{element.estimatedHours}</TableCell>
                    <TableCell>{assignedCost[index]}</TableCell>
                    <TableCell>{realCost[index]}</TableCell>
                    <TableCell>{formatDayMonthAndYearMoment(element.kickOffDate)}</TableCell>
                    <TableCell>{element.status}</TableCell>

                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleRedirectToGraphicProject(element.id)}
                      >
                        <SvgIcon fontSize="medium">
                          <EqualizerIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => redirectToDemandGraphic(element.id)}
                      >
                        <SvgIcon fontSize="medium">
                          <InsertChartIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/administracion/proyecto/${element.id}/editar`}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalProjects}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  project: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  totalProjects: PropTypes.number,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

Results.defaultProps = {
  users: [],
};

export default Results;
