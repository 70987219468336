import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ProjectCreateForm from './ProjectCreateForm';
import useStyles from './styles';
import clientsRequest from '../../../requests/api/client';
// import clientRequest from '../../../requests/api/client';
import categoryRequest from '../../../requests/api/category';

function ProjectCreateView() {
  const classes = useStyles();
  const [clients, setClients] = useState([]);
  const [categoriesDimensions, setCategoriesDimensions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pageOfTable] = useState(0);
  const [limit] = useState(100);
  const [searchValue] = useState('');

  useEffect(() => {
    const getClients = async () => {
      const response = await clientsRequest.getClientsPerPage(
        pageOfTable,
        limit,
        searchValue,
      );
      if (response.success) {
        const data = await response.data.data.clients;
        const arrayData = Object.values(data);
        setClients(arrayData);
      }
    };
    getClients();
    const getCategories = async () => {
      const response = await categoryRequest.getCategoriesPerPage({
        page: pageOfTable,
        limit,
        searchValue: '',
      });
      if (response.success) {
        const data = await response.data.data.categories;
        const arrayData = Object.values(data);
        setCategories(arrayData);
      }
    };
    getCategories();
    const getCategoriesDimensions = async () => {
      const response = await categoryRequest.getCategoriesDimensions();
      if (response.success) {
        const data = await response.data.data.categories_dimensions;
        const arrayData = Object.values(data);
        setCategoriesDimensions(arrayData);
      }
    };
    getCategoriesDimensions();
  }, []);

  return (
    <Page className={classes.root} title="Crear Proyecto">
      <Container maxWidth={false}>
        <Header />
        <ProjectCreateForm
          clients={clients}
          categories={categories}
          categoriesDimensions={categoriesDimensions}
        />
      </Container>
    </Page>
  );
}

export default ProjectCreateView;
