import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 8,
  },
  containerWithShadow: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  },
}));

function Tabs({
  seeRequests,
  setSeeRequests,
}) {
  const classes = useStyles();

  const handleChangeTab = (e, value) => {
    setSeeRequests(value);
  };

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      className={classes.containerWithShadow}
      my={2}
    >
      <Box
        width='50%'
        display='flex'
        p={1.5}
        justifyContent='center'
        sx={{
          backgroundColor: seeRequests ? '#ffffff' : '#22335E',
          borderRadius: seeRequests ? null : '8px',
          color: seeRequests ? '#22335E' : '#ffffff',
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={(e) => handleChangeTab(e, false)}
      >
        <Typography>
          Developers
        </Typography>
      </Box>
      <Box
        width='50%'
        display='flex'
        p={1.5}
        justifyContent='center'
        sx={{
          backgroundColor: seeRequests ? '#22335E' : '#ffffff',
          color: seeRequests ? '#ffffff' : '#22335E',
          borderRadius: seeRequests ? '8px' : null,
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={(e) => handleChangeTab(e, true)}
      >
        <Typography>
          Solicitudes
        </Typography>
      </Box>
    </Box>
  );
}

Tabs.propTypes = {
  seeRequests: PropTypes.bool,
  setSeeRequests: PropTypes.func,
};

export default Tabs;
