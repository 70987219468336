import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { Autocomplete } from '@mui/material';
import OBJECTIVES_TYPES from '../../../utils/const/objectivesTypes';
import Request from '../../../requests/api/objectives';

const useStyles = makeStyles(() => ({
  root: {},
}));

function ObjectivesCreateForm({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) history.goBack();

  return (
    <Formik
      initialValues={{
        type: '',
        description: '',
        expectedResults: '',
        monthlyLoad: '',
        bonusPercentage: '',
      }}
      validationSchema={Yup.object().shape({
        type: Yup.string().max(255).required('Debe ingresar un tipo de objetivo'),
        description: Yup.string().required('Debe ingresar una descripción del objetivo'),
        expectedResults: Yup.string().required('Debe ingresar los resultados esperados'),
        monthlyLoad: Yup.number().required('Debe ingresar la carga mensual'),
        bonusPercentage: Yup.number().required('Debe ingresar el porcentaje de bono asociado'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            performanceCycleInstanceId: window.location.pathname.split('/')[3] || '',
            type: values.type,
            description: values.description,
            expectedResults: values.expectedResults,
            monthlyLoad: values.monthlyLoad,
            bonusPercentage: values.bonusPercentage,
          };
          const response = await Request.postCreateObjective(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: <Button onClick={() => history.goBack()}>Volver a instancia</Button>,
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Autocomplete
                    fullWidth
                    disablePortal
                    id="combo-box-demo"
                    options={OBJECTIVES_TYPES}
                    getOptionLabel={(option) => `${option.label}`
                    }
                    onChange={(e, value) => {
                      if (value === null) {
                        setFieldValue('type', '');
                        return;
                      }
                      setFieldValue('type', value.label);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.type && errors.type)}
                        helperText={touched.type && errors.type}
                        required
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Tipo de objetivo"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.monthlyLoad && errors.monthlyLoad)}
                    fullWidth
                    helperText={touched.monthlyLoad && errors.monthlyLoad}
                    label="Carga mensual"
                    name="monthlyLoad"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.monthlyLoad}
                    required
                    variant="outlined"
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    label="Descripción del objetivo"
                    multiline
                    minRows={4}
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.expectedResults && errors.expectedResults)}
                    fullWidth
                    helperText={touched.expectedResults && errors.expectedResults}
                    label="Resultados esperados"
                    multiline
                    minRows={4}
                    name="expectedResults"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.expectedResults}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.bonusPercentage && errors.bonusPercentage)}
                    fullWidth
                    helperText={touched.bonusPercentage && errors.bonusPercentage}
                    label="Porcentaje de bono asociado"
                    name="bonusPercentage"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.bonusPercentage}
                    required
                    variant="outlined"
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ObjectivesCreateForm.propTypes = {
  className: PropTypes.string,
};

export default ObjectivesCreateForm;
