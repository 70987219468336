/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import Header from './Header';
import useStyles from './styles';
import Page from '../../components/Layout/Page';
import InfoCard from './InfoCard';
import demandRequests from '../../requests/api/demands';
import availabilityRequest from '../../requests/api/availability';
import projectDeveloperRequest from '../../requests/api/project_developer';
import Chart from './Chart';
import { formatMonthAndYearMoment } from '../../utils/date';
import DateFilters from './DateFilters';
import BottomButton from './BottomButton';

function Dashboard() {
  const classes = useStyles();
  const [selectChart, setSelectChart] = useState(0);
  const [demands, setDemands] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [projectDevelopers, setProjectDevelopers] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const [startMonth, setStartMonth] = useState(
    new Date(currentYear, currentMonth - 6, 1).toISOString(),
  );
  const [endMonth, setEndMonth] = useState(
    new Date(currentYear, currentMonth + 6, 1).toISOString(),
  );

  useEffect(() => {
    const getDemands = async () => {
      const response = await demandRequests.getDemandsPerPageFilter({
        page: 0,
        limit: 10000,
        searchStartMonth: startMonth,
        searchEndMonth: endMonth,
      });
      if (response.success) {
        const demandsResponse = await response.data.data.demands;
        const arrayData = Object.values(demandsResponse);
        setDemands(arrayData);
      }
    };
    const getAvailavilities = async () => {
      const response = await availabilityRequest.getAvailabilitiesPerPageFilter(
        {
          page: 0,
          limit: 10000,
          searchStartMonth: startMonth,
          searchEndMonth: endMonth,
        },
      );
      if (response.success) {
        const availabilitiesResponse = await response.data.data.availabilities;
        const arrayData = Object.values(availabilitiesResponse);
        setAvailabilities(arrayData);
      }
    };

    const getProjectDevelopers = async () => {
      const response = await projectDeveloperRequest.getProjectDevelopersPerPage({
        page: 0,
        limit: 10000,
        searchProject: '',
        searchClient: '',
        searchDeveloper: '',
        searchStartMonth: startMonth,
        searchEndMonth: endMonth,
      });
      if (response.success) {
        const projectDevelopersResponse = await response.data.data
          .projectDevelopers;
        const arrayData = Object.values(projectDevelopersResponse);
        setProjectDevelopers(arrayData);
      }
    };

    getProjectDevelopers();
    getDemands();
    getAvailavilities();
  }, [startMonth, endMonth]);

  useEffect(() => {
    const getChartData = () => {
      if (
        demands.length > 0
        && availabilities.length > 0
        && projectDevelopers.length > 0
      ) {
        const dataDemands = [];
        const dataAvailabilities = [];
        const dataProjectDevelopers = [];
        const data = {};

        for (let i = 0; i < demands.length; i += 1) {
          const demand = demands[i];
          const month = new Date(demand.month);
          const monthString = formatMonthAndYearMoment(month);
          const find = dataDemands.find((item) => item.month === monthString);
          if (find) {
            find.minDemand += demand.minHours;
            find.maxDemand += demand.maxHours;
          } else {
            dataDemands.push({
              month: monthString,
              minDemand: demand.minHours,
              maxDemand: demand.maxHours,
            });
          }
        }
        for (let j = 0; j < availabilities.length; j += 1) {
          const availability = availabilities[j];
          const month = new Date(availability.month);
          const monthString = formatMonthAndYearMoment(month);
          const find = dataAvailabilities.find(
            (item) => item.month
              === monthString,
          );
          if (find) {
            find.minAvailability
              += Math.max(availability.minHours - availability.vacationHours, 0);
            find.maxAvailability
              += Math.max(availability.maxHours - availability.vacationHours, 0);
          } else {
            dataAvailabilities.push({
              month: monthString,
              minAvailability:
                Math.max(availability.minHours - availability.vacationHours, 0),
              maxAvailability:
                Math.max(availability.maxHours - availability.vacationHours, 0),
            });
          }
        }

        for (let k = 0; k < projectDevelopers.length; k += 1) {
          const projectDeveloper = projectDevelopers[k];
          const { assignation } = projectDeveloper;
          const month = new Date(assignation.month);
          const monthString = formatMonthAndYearMoment(month);
          const find = dataProjectDevelopers.find(
            (item) => item.month === monthString,
          );
          if (find) {
            find.realHours += projectDeveloper.realHours;
            find.assignedHours += projectDeveloper.assignedHours;
          } else {
            dataProjectDevelopers.push({
              month: monthString,
              realHours: projectDeveloper.realHours,
              assignedHours: projectDeveloper.assignedHours,
            });
          }
        }

        data.demands = dataDemands;
        data.availabilities = dataAvailabilities;
        data.projectDevelopers = dataProjectDevelopers;
        setDataChart(data);
      }
    };
    getChartData();
  }, [demands, availabilities, projectDevelopers]);

  return (
    <Page className={classes.root} title="TubeOps | Dashboard">
      <Container maxWidth={false}>
        <div className={classes.headerDiv}>
          <Header />
        </div>
        <Grid container className={classes.infoCardsDiv}>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              chart={0}
              selectChart={selectChart}
              setSelectChart={setSelectChart}
              data={dataChart}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              chart={1}
              selectChart={selectChart}
              setSelectChart={setSelectChart}
              data={dataChart}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              chart={2}
              selectChart={selectChart}
              setSelectChart={setSelectChart}
              data={dataChart}
            />
          </Grid>
        </Grid>
        <div className={classes.chartDiv}>
          <DateFilters
            startMonth={startMonth}
            endMonth={endMonth}
            setStartMonth={setStartMonth}
            setEndMonth={setEndMonth}
          />
          <Chart
            selectChart={selectChart}
            data={dataChart}
            startMonth={startMonth}
            endMonth={endMonth}
          />
          <BottomButton selectChart={selectChart} />
        </div>
      </Container>
    </Page>
  );
}

export default Dashboard;
