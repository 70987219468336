import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import AvailabilityCreateForm from './AvailabilityCreateForm';
import useStyles from './styles';
import Request from '../../../requests/api/developer';

function AvailabilityCreateView() {
  const classes = useStyles();
  const [developers, setDevelopers] = useState([]);

  useEffect(() => {
    const getDeveloper = async () => {
      const response = await Request.getDevelopersPerPage(0, 0, '');
      if (response.success) {
        const data = await response.data.data.developers;
        const arrayData = Object.values(data);
        setDevelopers(arrayData);
      }
    };
    getDeveloper();
  }, []);

  return (
    <Page className={classes.root} title="Agregar Disponibilidad">
      <Container maxWidth={false}>
        <Header />
        <AvailabilityCreateForm developers={developers} />
      </Container>
    </Page>
  );
}

export default AvailabilityCreateView;
