/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  IconButton,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useStyles from './styles';
import getInitials from '../../../utils/getInitials';
import { SUPER_ADMIN, SYSTEM_ADMIN } from '../../../utils/const/user_types';
import demandRequest from '../../../requests/api/demands';
import { compareDateMonths } from '../../../utils/date';

function Results({
  className,
  month,
  selectProjectInfo,
  setRefreshCurrentAssignment,
  nextMonth,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [project, setProject] = useState(selectProjectInfo);
  const [editId, setEditId] = useState(0);
  const [minHours, setMinHours] = useState(0);
  const [maxHours, setMaxHours] = useState(0);

  useEffect(() => {
    setProject(selectProjectInfo);
  }, [selectProjectInfo]);

  const getMonth = (date) => {
    const dateFormat = new Date(date);
    const Month = dateFormat.toLocaleString('es-es', { month: 'long' });
    return Month;
  };

  const account = useSelector((state) => state.account);

  const editDemand = async (demandEdit, blocked, type) => {
    try {
      let data = {};
      if (type === 'checkEdit') {
        data = {
          id: demandEdit.id,
          month: demandEdit.month,
          minHours: demandEdit.minHours,
          maxHours: demandEdit.maxHours,
          blocked: blocked === undefined ? demandEdit.blocked : blocked,
        };
      } else {
        data = {
          id: demandEdit.id,
          month: demandEdit.month,
          minHours,
          maxHours,
          blocked: blocked === undefined ? demandEdit.blocked : blocked,
        };
      }
      const response = await demandRequest.putDemandById(data);
      if (response.success) {
        enqueueSnackbar('Cambios guardados', {
          variant: 'success',
        });
        setRefreshCurrentAssignment((prevState) => !prevState);
        setEditId(0);
      } else {
        enqueueSnackbar(response.message, {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('error', {
        variant: 'error',
      });
    }
  };

  const deleteDemand = async (id) => {
    if (
      account.user.role.name !== SUPER_ADMIN
      && account.user.role.name !== SYSTEM_ADMIN
    ) {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      try {
        const response = await demandRequest.deleteDemand(id);
        if (response.success) {
          enqueueSnackbar('Demanda eliminadas!', {
            variant: 'success',
          });
          setRefreshCurrentAssignment((prevState) => !prevState);
          setEditId(0);
        } else {
          enqueueSnackbar(response.message, {
            variant: 'error',
          });
        }
      } catch (error) {
        enqueueSnackbar('error', {
          variant: 'error',
        });
      }
    }
  };

  const handleDemandEdit = (e) => {
    setEditId(e.id);
    setMinHours(e.minHours);
    setMaxHours(e.maxHours);
  };

  if (!month || !nextMonth || Object.keys(project).length === 0) {
    return null;
  }

  return (
    <Card className={clsx(classes.emptyRoot, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={700} maxHeight={300}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell align="center">Projecto</TableCell>
                <TableCell align="center">Mes</TableCell>
                <TableCell align="center">Horas Mínimas</TableCell>
                <TableCell align="center">Horas Máximas</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.scrollTable}>
              {project.demands.map((element) => {
                if (editId === element.id) {
                  return (
                    <TableRow hover key={element.id}>
                      <TableCell align="center">
                        <Box display="flex" alignItems="center">
                          <Avatar
                            className={classes.avatar}
                            src={element.avatar}
                          >
                            {getInitials(project.client.name)}
                          </Avatar>
                          <div>
                            <Typography color="inherit" variant="h6">
                              {project.client.name}
                            </Typography>
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {project.name}
                      </TableCell>
                      <TableCell align="center">
                        {getMonth(element.month)}
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          required
                          label="Horas Mínimas"
                          name="minHours"
                          value={minHours}
                          variant="outlined"
                          onChange={(event) => {
                            setMinHours(event.target.value);
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          required
                          label="Horas Maximas"
                          name="maxHours"
                          variant="outlined"
                          value={maxHours}
                          onChange={(event) => {
                            setMaxHours(event.target.value);
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <div className={classes.buttonsDisplay}>
                          <DeleteIcon
                            sx={{
                              backgroundColor: 'red',
                              color: 'white',
                              fontSize: 37,
                            }}
                            className={classes.deleteButton}
                            onClick={() => deleteDemand(element.id)}
                          />
                          <Button
                            color="secondary"
                            variant="contained"
                            className={classes.action}
                            onClick={() => editDemand(element)}
                          >
                            Aceptar
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }
                return (
                  <TableRow hover key={element.id}>
                    <TableCell align="center">
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatar} src={element.avatar}>
                          {getInitials(project.client.name)}
                        </Avatar>
                        <div>
                          <Typography color="inherit" variant="h6">
                            {project.client.name}
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell align="center">{project.name}</TableCell>
                    <TableCell align="center">
                      {getMonth(element.month)}
                    </TableCell>
                    <TableCell align="center">{element.minHours}</TableCell>
                    <TableCell align="center">{element.maxHours}</TableCell>
                    <TableCell align="center">
                      {(compareDateMonths(element.month, month) || compareDateMonths(element.month, nextMonth)) ? (
                        <div className={classes.buttonsDisplay}>
                          <IconButton
                            variant="contained"
                            className={classes.action}
                            onClick={() => handleDemandEdit(element)}
                          >
                            <EditIcon/>
                          </IconButton>
                          <IconButton
                            variant="contained"
                            className={clsx(classes.action, {
                              [classes.check]: element.blocked,
                            })}
                            onClick={() => editDemand(element, !element.blocked, 'checkEdit')}
                          >
                            <CheckCircleIcon />
                          </IconButton>
                        </div>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  selectProjectInfo: PropTypes.object,
  setRefreshCurrentAssignment: PropTypes.func,
  month: PropTypes.any,
  nextMonth: PropTypes.any,
};

export default Results;
