import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
  makeStyles,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Info as InfoIcon } from 'react-feather';
import { formatDate } from '../../../utils/date';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 8,
  },
  title: {
    paddingRight: '15px',
  },
  tooltip: {
    backgroundColor: 'white',
    '& .MuiTooltip-arrow': {
      color: '#006E90',
    },
    color: '#006E90',
    border: '1px solid',
    borderColor: '#006E90',
    fontSize: '17px',
  },
}));

function VacationDaysCount({ availableDays, availableCreditDays, creditDaysExpiration }) {
  const classes = useStyles();
  const [expirationInTwoMonths, setExpirationInTwoMonths] = useState(false);

  useEffect(() => {
    const isCreditExpirationSoon = () => {
      const currentDate = new Date();

      const twoMonthsFromNow = new Date();
      twoMonthsFromNow.setMonth(currentDate.getMonth() + 2);

      if (creditDaysExpiration < twoMonthsFromNow) setExpirationInTwoMonths(true);
      else setExpirationInTwoMonths(false);
    };

    isCreditExpirationSoon();
  }, [creditDaysExpiration]);

  return (
    <Box mt={2}>
      <Box
        display='flex'
      >
        <Typography
          className={classes.title}
          variant="h5"
          color="textPrimary"
        >
          Días de vacaciones disponibles
        </Typography>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          placement="right"
          title='Se usarán primero los días legales. En caso de necesitar más, comenzarán a contar los días de crédito. Estos últimos tienen un plazo de vencimiento de 12 meses.'
          arrow
        >
          <SvgIcon>
            <InfoIcon />
          </SvgIcon>
        </Tooltip>
      </Box>
      <Card className={classes.root}>
        <Box
          display='flex'
          height={130}
        >
          <Box
            width='33.3%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Typography
              variant='h2'
            >
              {availableDays}
            </Typography>
            <Typography
              variant='h6'
            >
              Días legales disponibles
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            width='33.3%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Typography
              variant='h2'
            >
              {availableCreditDays}
            </Typography>
            <Typography
              variant='h6'
            >
              Días adicionales disponibles
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            width='33.3%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            sx={{
              color: expirationInTwoMonths ? 'red' : 'inherit',
            }}
          >
            <Typography
              variant='h2'
            >
              {formatDate(creditDaysExpiration) || '-'}
            </Typography>
            <Typography
              variant='h6'
            >
              Vencimiento días adicionales
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

VacationDaysCount.propTypes = {
  availableDays: PropTypes.number,
  availableCreditDays: PropTypes.number,
  creditDaysExpiration: PropTypes.object,
};

export default VacationDaysCount;
