import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ProjectCellCreateForm from './ProjectCellCreateForm';
import projectRequest from '../../../requests/api/project';
import useStyles from './styles';

function ProjectCellCreateView() {
  const classes = useStyles();

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const getProjects = async () => {
      const response = await projectRequest.getProjectsPerPage({
        page: 0,
        limit: 100,
        searchClient: '',
        searchProject: '',
        month: '',
      });
      if (response.success) {
        const data = await response.data.data.projects;
        const arrayData = Object.values(data);
        setProjects(arrayData);
      }
    };
    getProjects();
  }, []);

  return (
    <Page className={classes.root} title="Agregar Célula">
      <Container maxWidth={false}>
        <Header/>
            <ProjectCellCreateForm
              projects={projects}
            />
      </Container>
    </Page>
  );
}

export default ProjectCellCreateView;
