import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import Request from '../../../requests/api/survey';
import useStyles from './styles';

function SurveysListView() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [surveys, setSurvey] = useState([]);
  const [totalSurveys, setTotalSurveys] = useState(0);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('updated_at|DESC');

  useEffect(() => {
    const getSurveys = async () => {
      const response = await Request.getSurveysPerPage(page, limit, query, sort);
      if (response.success) {
        setSurvey(response.data.surveys);
        setTotalSurveys(response.data.totalSurveys);
      }
    };

    getSurveys();
  }, [page, limit, query, sort]);

  return (
    <Page className={classes.root} title="TubeOps | Encuestas">
      <Container maxWidth={false}>
        <Header />
        {surveys && (
          <Box mt={3}>
            <Results
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              searchValue={query}
              setSearchValue={setQuery}
              surveys={surveys}
              sort={sort}
              setSort={setSort}
              totalSurveys={totalSurveys}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default SurveysListView;
