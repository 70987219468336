import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Request from '../../../requests/api/objectives';

const useStyles = makeStyles(() => ({
  root: {},
}));

function CommentsCreateForm({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const user = useSelector((state) => state.account.user);

  if (submitSuccess) history.goBack();

  return (
    <Formik
      initialValues={{
        comment: '',
      }}
      validationSchema={Yup.object().shape({
        comment: Yup.string().required('Debe ingresar un comentario'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            objectiveId: window.location.pathname.split('/')[5] || '',
            commentingDeveloperId: user.developer.id,
            isEvaluationComment: false,
            comment: values.comment,
          };
          const response = await Request.postCreateObjectiveComment(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: <Button onClick={() => history.goBack()}>Volver a objetivo</Button>,
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={12}
                >
                  <TextField
                    error={Boolean(touched.comment && errors.comment)}
                    fullWidth
                    multiline
                    minRows={2}
                    helperText={touched.comment && errors.comment}
                    label="Comentario"
                    name="comment"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.comment}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box mt={2} mx={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )
      }
    </Formik >
  );
}

CommentsCreateForm.propTypes = {
  className: PropTypes.string,
};

export default CommentsCreateForm;
