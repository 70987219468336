import React from 'react';
import {
  List, ListItem, ListItemText, Grid, IconButton, Autocomplete, TextField, InputAdornment,
} from '@mui/material';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import WarningIcon from '@mui/icons-material/Warning';
import ClearIcon from '@mui/icons-material/Clear';
import useStyles from './styles';
import ProgressBar from './EvalProgressBar';

function ProjectSideBar({
  projects,
  projectId,
  setProjectId,
  searchValue,
  setSearchValue,
}) {
  const classes = useStyles();

  const handleSearchChange = (event, value) => {
    const client = value ? value.name : '';
    setSearchValue(client);
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleSelectProject = (id) => {
    setProjectId(id);
  };

  if (!projects.length || !projectId) {
    return null;
  }

  return (
    <div className={classes.leftColumn}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ paddingRight: 0 }}>
          <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={projects}
            getOptionLabel={(option) => `${option.client} - ${option.name}`}
            value={projects.find((option) => `${option.client} - ${option.name}` === searchValue) || null}
            onChange={handleSearchChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Nombre proyecto"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {searchValue && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear search"
                            onClick={clearSearch}
                            edge="end"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <div className={classes.clientProjectsSquare}>
        <List>
          {projects.map((project) => (
            <React.Fragment key={project.id}>
              <ListItem
                className={clsx(classes.clientListItemBlue, {
                  [classes.clientListItemWhite]: projectId === project.id,
                })}
                onClick={() => handleSelectProject(project.id)}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={1}>
                        {!project.evaluated ? (
                          <IconButton
                            sx={{
                              color: projectId === project.id ? '#FFFFFF' : '#24335E',
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <WarningIcon />
                          </IconButton>
                        ) : null}
                      </Grid>

                      <Grid item xs={10}>
                        {`${project.client} - ${project.name}`}
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </div>
      <ProgressBar
        totalProjects={projects.length}
        evaluatedProjectsComplete={
          projects.reduce((total, project) => total + (project.evaluated === true ? 1 : 0), 0)
        }
      />
    </div>
  );
}

ProjectSideBar.propTypes = {
  projects: PropTypes.array,
  projectId: PropTypes.number,
  setProjectId: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
};

export default ProjectSideBar;
