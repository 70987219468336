import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import DashboardLayout from './components/Navigation';
import LoadingScreen from './components/Loading/LoadingScreen';

import Login from './views/Login';
import Verification from './views/Verification';
import PasswordRecovery from './views/PasswordRecovery';
import PostRegister from './views/Register/PostRegister';
import SendEmail from './views/PasswordRecovery/SendEmail';

import AdminListView from './views/Admin/Users/UserListView';
import AdminCreateView from './views/Admin/Users/UserCreateView';
import AdminEditView from './views/Admin/Users/UserEditView';

import ClientListView from './views/Client/ClientListView';
import ClientCreateView from './views/Client/ClientCreateView';
import ClientEditView from './views/Client/ClientEditView';

import DevelopersListView from './views/Developers/DevelopersListView';
import DevelopersCreateView from './views/Developers/DevelopersCreateView';
import DevelopersEditView from './views/Developers/DevelopersEditView';
import DevelopersGraphicView from './views/Developers/DevelopersGraphicView';
import DevelopersAssignationView from './views/Developers/DevelopersAssignationView';

import AvailabilityListView from './views/Availability/AvailabilityListView';
import AvailabilityCreateView from './views/Availability/AvailabilityCreateView';
import AvailabilityEditView from './views/Availability/AvailabilityEditView';
import AvailabilitiesManagementView from './views/Availability/AvailabilityManagement';
import Dashboard from './views/Dashboard';

import AssignationListView from './views/Assignation/AssignationListView';
import AssignationCreateView from './views/Assignation/AssignationCreateView';

import ProjectListView from './views/Project/ProjectListView';
import ProjectCreateView from './views/Project/ProjectCreateView';
import ProjectEditView from './views/Project/ProjectEditView';
import ProjectGraphicView from './views/Project/ProjectGraphicView';
import ProjectDemandGraphicView from './views/Project/ProjectDemandGraphicView';

import DimensionListView from './views/Dimension/DimensionListView';
import DimensionCreateView from './views/Dimension/DimensionCreateView';
import DimensionEditView from './views/Dimension/DimensionEditView';

import DemandsListView from './views/Demands/DemandsListView';
import DemandsCreateView from './views/Demands/DemandsCreateView';
import DemandsEditView from './views/Demands/DemandsEditView';

import SurveyListView from './views/Survey/SurveyListView';
import SurveyCreateView from './views/Survey/SurveyCreateView';
import SurveyEditView from './views/Survey/SurveyEditView';

import ControlPanel from './views/ControlPanel';
import RoleListView from './views/Admin/Roles/RoleListView';
import RoleEditView from './views/Admin/Roles/RoleEditView';
import RoleCreateView from './views/Admin/Roles/RoleCreateView';
import CompaniesListView from './views/Companies/CompaniesListView';
import CompaniesEditView from './views/Companies/CompaniesEditView';
import CompaniesCreateView from './views/Companies/CompaniesCreateView';

import ProjectCellListView from './views/ProjectCells/ProjectCellListView';
import ProjectCellCreateView from './views/ProjectCells/ProjectCellCreateView';
import ProjectAssignationView from './views/Project/ProjectAssignationView';

import DeveloperDimensionListView from './views/DeveloperDimension/DeveloperDimensionListView';
import DeveloperDimensionEditView from './views/DeveloperDimension/DeveloperDimensionEditView';

import ProjectDimensionListView from './views/ProjectDimension/ProjectDimensionListView';

import PerformanceCyclesListView from './views/PerformanceCycles/PerformanceCyclesListView';
import PerformanceCyclesEditView from './views/PerformanceCycles/PerformanceCyclesEditView';
import PerformanceCyclesCreateView from './views/PerformanceCycles/PerformanceCyclesCreateView';

import PerformanceCycleInstancesListView from './views/PerformanceCycleInstances/PerformanceCycleInstancesListView';
import PerformanceCycleInstancesEditView from './views/PerformanceCycleInstances/PerformanceCycleInstancesEditView';
import PerformanceCycleInstancesCreateView from './views/PerformanceCycleInstances/PerformanceCycleInstancesCreateView';
import PerformanceCycleInstancesDetailView from './views/PerformanceCycleInstances/PerformanceCycleInstancesDetailView';

import ObjectivesCreateView from './views/Objectives/ObjectivesCreateView';
import ObjectivesEditView from './views/Objectives/ObjectivesEditView';
import ObjectivesDetailView from './views/Objectives/ObjectivesDetailView';

import CommentsCreateView from './views/Objectives/CommentsCreateView';
import ActionsCreateView from './views/Objectives/ActionsCreateView';
import DeveloperVacationRequestList from './views/VacationRequests/VacationsRequestView';
import SuperVisorVacationRequestList from './views/VacationRequests/SupervisorViews';

import ProjectRoleListView from './views/ProjectRole/ProjectRoleListView';
import ProjectRoleCreateView from './views/ProjectRole/ProjectRoleCreateView';
import ProjectRoleEditView from './views/ProjectRole/ProjectRoleEditView';

import BlogListView from './views/Blog/BlogListView';
import BlogCreateView from './views/Blog/BlogCreateView';
import BlogEditView from './views/Blog/BlogEditView';
import ObjectivesListView from './views/Objectives/ObjectivesListView';

import BillListView from './views/Bills/BillListView';
import BillCreateView from './views/Bills/BillCreateView';
import BillEditView from './views/Bills/BillEditView';
import BillChartView from './views/Bills/BillChartView';
import BillReceivablesView from './views/Bills/BillReceivables';
import DeveloperPerformanceView from './views/PerformanceEvaluations/DeveloperPerformanceView';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Login />,
  },
  {
    id: 2,
    exact: true,
    path: '/user-verification/:token',
    component: () => <Verification />,
  },
  {
    id: 8,
    exact: true,
    path: '/recuperar-contrasena',
    component: () => <SendEmail />,
  },
  {
    id: 3,
    exact: true,
    path: '/reset-password/:token',
    component: () => <PasswordRecovery />,
  },
  {
    id: 4,
    exact: true,
    path: '/reset-password?token=:token',
    component: () => <Redirect to="/reset-password/:token" />,
  },
  {
    id: 5,
    exact: true,
    path: '/postRegister',
    component: () => <PostRegister />,
  },
  {
    id: 6,
    exact: true,
    path: '/login',
    component: () => <Login />,
  },
  {
    path: '/administracion',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        id: 11,
        exact: true,
        path: '/administracion',
        component: () => <Redirect to="/administracion/usuarios" />,
      },
      {
        id: 12,
        exact: true,
        path: '/administracion/usuarios',
        component: () => <AdminListView />,
      },
      {
        id: 13,
        exact: true,
        path: '/administracion/usuarios/crear',
        component: () => <AdminCreateView />,
      },
      {
        id: 14,
        exact: true,
        path: '/administracion/usuarios/:id/editar',
        component: () => <AdminEditView />,
      },
      {
        id: 15,
        exact: true,
        path: '/administracion/developers',
        component: () => <DevelopersListView />,
      },
      {
        id: 16,
        exact: true,
        path: '/administracion/developers/crear',
        component: () => <DevelopersCreateView />,
      },
      {
        id: 17,
        exact: true,
        path: '/administracion/developers/:id/editar',
        component: () => <DevelopersEditView />,
      },
      {
        id: 18,
        exact: true,
        path: '/administracion/availability/',
        component: () => <AvailabilityListView />,
      },
      {
        id: 19,
        exact: true,
        path: '/administracion/availability/crear',
        component: () => <AvailabilityCreateView />,
      },
      {
        id: 20,
        exact: true,
        path: '/administracion/availability/:id/editar',
        component: () => <AvailabilityEditView />,
      },
      {
        id: 21,
        exact: true,
        path: '/administracion/clientes',
        component: () => <ClientListView />,
      },
      {
        id: 22,
        exact: true,
        path: '/administracion/clientes/crear',
        component: () => <ClientCreateView />,
      },
      {
        id: 23,
        exact: true,
        path: '/administracion/clientes/:id/editar',
        component: () => <ClientEditView />,
      },
      {
        id: 24,
        exact: true,
        path: '/administracion/asignaciones',
        component: () => <AssignationListView />,
      },
      {
        id: 25,
        exact: true,
        path: '/administracion/asignaciones/crear',
        component: () => <AssignationCreateView />,
      },
      {
        id: 26,
        exact: true,
        path: '/administracion/asignaciones/panel',
        component: () => <DevelopersAssignationView />,
      },
      {
        id: 27,
        exact: true,
        path: '/administracion/proyecto/',
        component: () => <ProjectListView />,
      },
      {
        id: 28,
        exact: true,
        path: '/administracion/proyecto/crear',
        component: () => <ProjectCreateView />,
      },
      {
        id: 29,
        exact: true,
        path: '/administracion/proyecto/:id/editar',
        component: () => <ProjectEditView />,
      },
      {
        id: 30,
        exact: true,
        path: '/administracion/dimensiones/',
        component: () => <DimensionListView />,
      },
      {
        id: 31,
        exact: true,
        path: '/administracion/dimensiones/crear',
        component: () => <DimensionCreateView />,
      },
      {
        id: 32,
        exact: true,
        path: '/administracion/dimensiones/:id/editar',
        component: () => <DimensionEditView />,
      },
      {
        id: 33,
        exact: true,
        path: '/administracion/demands/',
        component: () => <DemandsListView />,
      },
      {
        id: 34,
        exact: true,
        path: '/administracion/demand/crear',
        component: () => <DemandsCreateView />,
      },
      {
        id: 35,
        exact: true,
        path: '/administracion/demand/:id/editar',
        component: () => <DemandsEditView />,
      },
      {
        id: 36,
        exact: true,
        path: '/administracion/dashboard',
        component: () => <Dashboard />,
      },
      {
        id: 38,
        exact: true,
        path: '/administracion/developers/grafico',
        component: () => <DevelopersGraphicView />,
      },
      {
        id: 39,
        exact: true,
        path: [
          '/administracion/proyecto/grafico',
          '/administracion/proyecto/developers',
        ],
        component: () => <ProjectGraphicView />,
      },
      {
        id: 40,
        exact: true,
        path: '/administracion/panel-de-control',
        component: () => <ControlPanel />,
      },
      {
        id: 41,
        exact: true,
        path: '/administracion/celulas-de-proyecto',
        component: () => <ProjectCellListView />,
      },
      {
        id: 42,
        exact: true,
        path: '/administracion/celulas-de-proyecto/crear',
        component: () => <ProjectCellCreateView />,
      },
      {
        id: 43,
        exact: true,
        path: '/administracion/celulas-de-proyecto/panel',
        component: () => <ProjectAssignationView />,
      },
      {
        id: 44,
        exact: true,
        path: '/administracion/talento',
        component: () => <DeveloperDimensionListView />,
      },
      {
        id: 45,
        exact: true,
        path: '/administracion/talento/:id/editar',
        component: () => <DeveloperDimensionEditView />,
      },
      {
        id: 46,
        exact: true,
        path: '/administracion/caracteristicas',
        component: () => <ProjectDimensionListView />,
      },
      {
        id: 47,
        exact: true,
        path: '/administracion/roles/',
        component: () => <RoleListView />,
      },
      {
        id: 48,
        exact: true,
        path: '/administracion/roles/crear',
        component: () => <RoleCreateView />,
      },
      {
        id: 49,
        exact: true,
        path: '/administracion/roles/:id/editar',
        component: () => <RoleEditView />,
      },
      {
        id: 46,
        exact: true,
        path: '/administracion/companias',
        component: () => <CompaniesListView />,
      },
      {
        id: 47,
        exact: true,
        path: '/administracion/companias/:id/editar',
        component: () => <CompaniesEditView />,
      },
      {
        id: 48,
        exact: true,
        path: '/administracion/companias/crear',
        component: () => <CompaniesCreateView />,
      },
      {
        id: 49,
        exact: true,
        path: '/administracion/encuestas',
        component: () => <SurveyListView />,
      },
      {
        id: 50,
        exact: true,
        path: '/administracion/encuestas/crear',
        component: () => <SurveyCreateView />,
      },
      {
        id: 51,
        exact: true,
        path: '/administracion/encuestas/:id/editar',
        component: () => <SurveyEditView />,
      },
      {
        id: 52,
        exact: true,
        path: '/administracion/performance-cycles',
        component: () => <PerformanceCyclesListView />,
      },
      {
        id: 53,
        exact: true,
        path: '/administracion/performance-cycles/crear',
        component: () => <PerformanceCyclesCreateView />,
      },
      {
        id: 54,
        exact: true,
        path: '/administracion/performance-cycles/:id/editar',
        component: () => <PerformanceCyclesEditView />,
      },
      {
        id: 55,
        exact: true,
        path: '/administracion/performance-cycles/:id/instances',
        component: () => <PerformanceCycleInstancesListView />,
      },
      {
        id: 56,
        exact: true,
        path: '/administracion/performance-cycle-instances/all',
        component: () => <PerformanceCycleInstancesListView />,
      },
      {
        id: 57,
        exact: true,
        path: '/administracion/performance-cycle-instances/crear',
        component: () => <PerformanceCycleInstancesCreateView />,
      },
      {
        id: 58,
        exact: true,
        path: '/administracion/performance-cycle-instances/:id/editar',
        component: () => <PerformanceCycleInstancesEditView />,
      },
      {
        id: 59,
        exact: true,
        path: '/administracion/performance-cycle-instances/:id',
        component: () => <PerformanceCycleInstancesDetailView />,
      },
      {
        id: 60,
        exact: true,
        path: '/administracion/performance-cycle-instances/:id/objectives/crear',
        component: () => <ObjectivesCreateView />,
      },
      {
        id: 64,
        exact: true,
        path: '/administracion/performance-cycle-instances/:id/objectives/:objectiveId/editar',
        component: () => <ObjectivesEditView />,
      },
      {
        id: 61,
        exact: true,
        path: '/administracion/performance-cycle-instances/:instanceId/objectives/:objectiveId',
        component: () => <ObjectivesDetailView />,
      },
      {
        id: 62,
        exact: true,
        path: '/administracion/performance-cycle-instances/:instanceId/objectives/:objectiveId/actions/crear',
        component: () => <ActionsCreateView />,
      },
      {
        id: 63,
        exact: true,
        path: '/administracion/performance-cycle-instances/:instanceId/objectives/:objectiveId/comments/crear',
        component: () => <CommentsCreateView />,
      },
      {
        id: 64,
        exact: true,
        path: '/administracion/vacation-requests/:id',
        component: () => <DeveloperVacationRequestList />,
      },
      {
        id: 65,
        exact: true,
        path: '/administracion/demanda-proyectos',
        component: () => <ProjectDemandGraphicView />,
      },
      {
        id: 66,
        exact: true,
        path: '/administracion/proyecto-roles/crear',
        component: () => <ProjectRoleCreateView />,
      },
      {
        id: 67,
        exact: true,
        path: '/administracion/proyecto-roles/:id/editar',
        component: () => <ProjectRoleEditView />,
      },
      {
        id: 68,
        exact: true,
        path: '/administracion/proyecto-roles',
        component: () => <ProjectRoleListView />,
      },
      {
        id: 69,
        exact: true,
        path: '/administracion/vacations-requests/all',
        component: () => <SuperVisorVacationRequestList />,
      },
      {
        id: 70,
        exact: true,
        path: '/administracion/blog',
        component: () => <BlogListView />,
      },
      {
        id: 71,
        exact: true,
        path: '/administracion/blog/crear',
        component: () => <BlogCreateView />,
      },
      {
        id: 72,
        exact: true,
        path: '/administracion/blog/:id/editar',
        component: () => <BlogEditView />,
      },
      {
        id: 73,
        exact: true,
        path: '/administracion/facturas',
        component: () => <BillListView />,
      },
      {
        id: 74,
        exact: true,
        path: '/administracion/facturas/crear',
        component: () => <BillCreateView />,
      },
      {
        id: 75,
        exact: true,
        path: '/administracion/facturas/:id/editar',
        component: () => <BillEditView />,
      },
      {
        id: 76,
        exact: true,
        path: '/administracion/availability/gestionar',
        component: () => <AvailabilitiesManagementView />,
      },
      {
        id: 77,
        exact: true,
        path: '/administracion/facturas-grafico',
        component: () => <BillChartView />,
      },
      {
        id: 78,
        exact: true,
        path: '/administracion/cuentas-por-cobrar',
        component: () => <BillReceivablesView />,
      },
      {
        id: 79,
        path: '/administracion/objetivos',
        component: () => <ObjectivesListView />,
      },
      {
        id: 80,
        exact: true,
        path: '/administracion/performance-cycle-instances/developer',
        component: () => <PerformanceCycleInstancesDetailView />,
      },
      {
        id: 81,
        exact: true,
        path: '/administracion/performance/evaluaciones',
        component: () => <DeveloperPerformanceView />,
      },
      {
        id: 100,
        component: () => <Redirect to="/administracion/usuarios" />,
      },
    ],
  },
];

const renderRoutes = (routes) => (routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
