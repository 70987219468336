import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import Request from '../../../requests/api/client';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

// Acá se está seteando el estado del componente
function ClientListView() {
  const classes = useStyles();
  const [clients, setClients] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [totalElements, setTotalElements] = useState(0);

  // Esto se corre al montarse el componente y cada vez que haya un cambio en una dependencia
  useEffect(() => {
    const getClients = async () => {
      const response = await Request.getClientsPerPage(pageOfTable, limit, searchValue);
      if (response.success) {
        const data = await response.data.data.clients;
        const arrayData = Object.values(data);
        setClients(arrayData);
        setTotalElements(response.data.data.totalClients);
      }
    };
    getClients();
  }, [pageOfTable, limit, searchValue]);

  return (
    <Page
      className={classes.root}
      title="TubeOps | Clientes"
    >
      <Container maxWidth={false}>
        <Header />
        {clients && (
          <Box mt={3}>
            <Results
              users={clients}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              totalClients={totalElements}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default ClientListView;
