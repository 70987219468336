import React, { useEffect, useState } from 'react';
import {
  List, ListItem, ListItemText, Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BarChart from './BarChart';
import { formatMonthAndYearMoment } from '../../../utils/date';
import useStyles from './styles';

function ClientProjectsBar({
  projects, month, projectId, setProjectId,
}) {
  const classes = useStyles();
  const [chartsData, setChartsData] = useState({});

  const handleOnClick = (id) => {
    setProjectId(id);
  };

  useEffect(() => {
    const setVariables = async () => {
      for (let i = 0; i < projects.length; i += 1) {
        const project = projects[i];
        const projectDemand = project.demands?.find(
          (demand) => formatMonthAndYearMoment(demand.month)
              === formatMonthAndYearMoment(month),
        ) || [];
        const projectProjectCell = project.project_cells?.find(
          (projectCell) => formatMonthAndYearMoment(projectCell.month)
              === formatMonthAndYearMoment(month),
        ) || [];
        const projectDevelopers = projectProjectCell.project_developers || [];
        const projectAssignedHours = projectDevelopers.reduce(
          (acc, projectDeveloper) => acc + projectDeveloper.assignedHours,
          0,
        ) || 0;
        const projectMinDemand = projectDemand.minHours || 0;
        const projectMaxDemand = projectDemand.maxHours || 0;
        const chartData1 = [
          {
            name: 'Demanda Máxima',
            data: [projectMaxDemand - projectMinDemand],
            color: '#BDBDBD',
            stack: 'stack1',
            borderRadiusTopLeft: 5,
            borderRadiusTopRight: 5,
          },
          {
            name: ' Demanda Minima',
            data: [projectMinDemand],
            color: '#24335E',
            stack: 'stack1',
            borderRadiusBottomLeft: 5,
            borderRadiusBottomRight: 5,
          },
        ];
        const chartData2 = [
          {
            name: 'Horas asignadas',
            data: [projectAssignedHours],
            color: '#00D293',
            borderRadius: 5,
          },
        ];
        let max = 0;
        if (projectAssignedHours >= projectMaxDemand) {
          max = projectAssignedHours;
        } else {
          max = projectMaxDemand;
        }
        setChartsData((prevObject) => ({
          ...prevObject,
          [project.id]: {
            chartData1,
            chartData2,
            max,
          },
        }));
      }
    };
    if (projects.length > 0) {
      setVariables();
    }
  }, [projects, month]);

  if (Object.keys(chartsData).length === 0) {
    return null;
  }

  return (
    <div className={classes.clientProjectsSquare}>
      <List>
        {projects.map((project) => (
          <React.Fragment key={project.id}>
            <ListItem
              className={clsx(classes.projectListItemBlue, {
                [classes.projectListItemWhite]: project.id === projectId,
                [classes.projectListHover]: project.id !== projectId,
              })}
              onClick={() => handleOnClick(project.id)}
            >
              <ListItemText
                disableTypography
                primary={
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item>
                      {`${project.client.name} - ${project.name}`}
                    </Grid>
                    <Grid item xs={12}>
                      <BarChart
                        title={''}
                        data={chartsData[project.id].chartData1}
                        max={chartsData[project.id].max}
                        chart={0}
                      />

                      <BarChart
                        title={''}
                        data={chartsData[project.id].chartData2}
                        max={chartsData[project.id].max}
                        chart={1}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

ClientProjectsBar.propTypes = {
  projects: PropTypes.array,
  month: PropTypes.any,
  projectId: PropTypes.number,
  setProjectId: PropTypes.func,
};

export default ClientProjectsBar;
