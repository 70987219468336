import React, { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Request from '../../../requests/api/objectives';
import ObjectivesEditForm from './ObjectivesEditForm';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function ObjectivesEditView() {
  const classes = useStyles();
  const objectiveId = window.location.pathname.split('/')[5] || '';
  const [objective, setObjective] = useState();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    const getObjective = async () => {
      const response = await Request.getObjectiveById(objectiveId);
      if (response.success) {
        const data = await response.data.data.objective;
        if (isMountedRef.current) {
          setObjective(data);
        }
      }
    };
    getObjective();
  }, []);

  return (
    <Page
      className={classes.root}
      title="TubeOps | Editar objetivo"
    >
      <Container maxWidth={false}>
        <Header />
        {objective && <ObjectivesEditForm objective={objective} />}
      </Container>
    </Page>
  );
}

export default ObjectivesEditView;
