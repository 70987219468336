const ACTIVE = 'Activo';
const PAUSED = 'Pausado';
const CLOSED = 'Cerrado';

const projectStatusList = [ACTIVE, PAUSED, CLOSED];

module.exports = {
  ACTIVE,
  PAUSED,
  CLOSED,
  projectStatusList,
};
