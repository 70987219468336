import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  emptyRoot: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  // diplayFlex: {
  //   display: 'inline-block',
  //   flexWrap: 'wrap',
  // },
  // left: {
  //   float: 'left',
  // },
  // right: {
  //   float: 'right',
  // },
  infoCard: {
    width: '100%',
    height: '150px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    borderRadius: '0px',
    border: '1px solid #EAEAEA',
  },
  infoCardtitle: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily: theme.typography.Inter.fontFamily,
    color: '#5F696B',
  },
  infoCardNumber: {
    fontSize: '40px',
    fontWeight: 700,
    fontFamily: theme.typography.Inter.fontFamily,
  },
  infoCardBottomText: {
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: theme.typography.Inter.fontFamily,
  },
  blue: {
    color: '#24335E',
  },
  grey: {
    color: '#5F696B',
  },
  green: {
    color: '#00D293',
  },
  dateButtons: {
    backgroundColor: 'transparent', // Fondo transparente en todo momento
    '&:hover': {
      backgroundColor: 'transparent', // Fondo transparente en hover
    },
  },
}));

export default useStyles;
