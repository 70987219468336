import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import Request from '../../../requests/api/companies';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function CompanyListView() {
  const classes = useStyles();
  const [companies, setCompanies] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [totalCompanies, setTotalCompanies] = useState(0);

  useEffect(() => {
    const getCompanies = async () => {
      const response = await Request.getCompaniesPerPage(pageOfTable, limit, searchValue);
      if (response.success) {
        const data = await response.data.data.companies;
        setCompanies(data);
        setTotalCompanies(response.data.data.totalCompanies);
      }
    };
    getCompanies();
  }, [pageOfTable, limit, searchValue]);

  return (
    <Page
      className={classes.root}
      title="TubeOps | Compañías"
    >
      <Container maxWidth={false}>
        <Header />
        {companies && (
          <Box mt={3}>
            <Results
              companies={companies}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              totalCompanies={totalCompanies}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default CompanyListView;
