/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Grid,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Edit as EditIcon, Search as SearchIcon } from 'react-feather';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import assignmentRequest from '../../../requests/api/assignation';
import useStyles from './styles';
import { SUPER_ADMIN, SYSTEM_ADMIN } from '../../../utils/const/user_types';
import developerGraphicActions from '../../../actions/developerGraphicActions';
import assignationActions from '../../../actions/assignationActions';
import { compareDateMonths } from '../../../utils/date';

function Results({
  className,
  assignments,
  pageOfTable,
  setPageOfTable,
  limit,
  setLimit,
  filters,
  setFilters,
  totalAssignments,
  table,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedAssignment, setSelectedAssignment] = useState([]);
  const [currentAssignment, setCurrentAssignment] = useState(assignments);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentAssignment(assignments);
  }, [assignments]);

  const handleFiltersChange = (name, event) => {
    event.persist();
    let value = null;
    value = event.target.value;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectAllAssignments = (event) => {
    setSelectedAssignment(
      event.target.checked
        ? currentAssignment.map((assignment) => assignment.id)
        : [],
    );
  };

  const handleSelectOneAssignment = (event, assignmentId) => {
    if (!selectedAssignment.includes(assignmentId)) {
      setSelectedAssignment((prevSelected) => [...prevSelected, assignmentId]);
    } else {
      setSelectedAssignment((prevSelected) => prevSelected.filter((id) => id !== assignmentId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPageOfTable(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const enableBulkOperations = selectedAssignment.length > 0;
  const selectedSomeAssignments = selectedAssignment.length > 0
    && selectedAssignment.length < currentAssignment.length;
  const selectedAllAssignments = selectedAssignment.length === currentAssignment.length;
  const account = useSelector((state) => state.account);

  const deleteAssignments = async () => {
    if (account.user.role.name !== SUPER_ADMIN && account.user.role.name !== SYSTEM_ADMIN) {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      for (let a = 0; a < selectedAssignment.length; a += 1) {
        // eslint-disable-next-line no-await-in-loop
        const response = await assignmentRequest.deleteAssignation(selectedAssignment[a]);
        if (response.success) {
          enqueueSnackbar('Asignaciones eliminadas!', {
            variant: 'success',
          });
          setCurrentAssignment(
            currentAssignment.filter((e) => !selectedAssignment.includes(e.id)),
          );
        } else {
          enqueueSnackbar(response.message, {
            variant: 'error',
          });
        }
      }
      setSelectedAssignment([]);
    }
  };

  const getMonth = (date) => {
    const dateFormat = new Date(date);
    const Month = dateFormat.toLocaleString('es-es', { month: 'long' });
    return Month;
  };
  const handleMonthOnChange = (date, name) => {
    if (date && (date.getFullYear() > 2020)) {
      const Month = date.getMonth();
      const Year = date.getFullYear();
      const firstDayOfTheMonth = new Date(Year, Month, 1);
      const valid = Date.parse(firstDayOfTheMonth);
      if (!Number.isNaN(valid)) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: firstDayOfTheMonth.toISOString(),
        }));
      }
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: '',
      }));
    }
  };

  const handleRedirectToGraphicDeveloper = (developerId, month) => {
    dispatch(developerGraphicActions.setDeveloperGraphic({ developerId, month }));
    history.push('/administracion/developers/grafico');
  };

  const handleRedirectToAssignation = (assignationId) => {
    dispatch(assignationActions.setAssignation({ assignationId }));
    history.push('/administracion/asignaciones/panel');
  };

  return (
    <Card className={clsx(classes.cardMargin, className)} {...rest}>
      {table === 1 ? (
        <Grid
          container
          spacing={3}
          className={classes.filtersContainer}
          display="flex"
          alignItems="center"
          flexwrap="wrap"
        >

          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFiltersChange('developer', e)}
              placeholder="Buscar desarrollador"
              value={filters.developer}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DatePicker
                inputFormat="MM-yyyy"
                views={['year', 'month']}
                label="Mes inicio"
                minDate={new Date('2022-02-01')}
                maxDate={new Date('2024-01-01')}
                value={filters.startMonth}
                onChange={(e) => handleMonthOnChange(e, 'startMonth')}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" error={false} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={4} xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DatePicker
                inputFormat="MM-yyyy"
                views={['year', 'month']}
                label="Mes termino"
                minDate={new Date('2022-02-01')}
                maxDate={new Date('2024-01-01')}
                value={filters.endMonth}
                onChange={(e) => handleMonthOnChange(e, 'endMonth')}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" error={false} />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.cardTitle}>
          <Typography variant="h3" color="textPrimary" align="center">
            Este Mes
          </Typography>
        </div>
      )}

      <Divider />
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllAssignments}
              indeterminate={selectedSomeAssignments}
              onChange={handleSelectAllAssignments}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteAssignments()}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllAssignments}
                    indeterminate={selectedSomeAssignments}
                    onChange={handleSelectAllAssignments}
                  />
                </TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Mes</TableCell>
                <TableCell>Numero de Proyectos</TableCell>
                <TableCell>Horas Asignadas</TableCell>
                <TableCell>Horas Min</TableCell>
                <TableCell>Horas Max</TableCell>
                <TableCell>Horas Reales</TableCell>
                <TableCell>Satisfaccion Estimada</TableCell>
                <TableCell>Satisfaccion Real</TableCell>
                <TableCell>Gráfico</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentAssignment.map((assignment) => {
                const isAssignmentSelected = selectedAssignment.includes(
                  assignment.id,
                );
                const assignedHours = assignment.project_developers.map((item) => item.assignedHours).reduce((prev, curr) => prev + curr, 0);
                return (
                  <TableRow
                    hover
                    key={assignment.id}
                    selected={isAssignmentSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isAssignmentSelected}
                        onChange={(event) => handleSelectOneAssignment(event, assignment.id)
                        }
                        value={isAssignmentSelected}
                      />
                    </TableCell>
                    <TableCell>
                      {assignment.developer.user.name}{' '}
                      {assignment.developer.user.lastname}{' '}
                    </TableCell>
                    <TableCell>{getMonth(assignment.month)}</TableCell>
                    <TableCell>{assignment.project_developers.length}</TableCell>
                    <TableCell>{assignedHours}</TableCell>
                    <TableCell>{assignment.developer.availabilities.filter((availability) => compareDateMonths(availability.month, assignment.month))[0].minHours}</TableCell>
                    <TableCell>{assignment.developer.availabilities.filter((availability) => compareDateMonths(availability.month, assignment.month))[0].maxHours}</TableCell>
                    <TableCell>{assignment.project_developers.map((item) => +item.realHours).reduce((prev, curr) => prev + curr, 0).toFixed(1)}</TableCell>
                    <TableCell>{assignment.estSatisfaction}</TableCell>
                    <TableCell>{assignment.realSatisfaction}</TableCell>
                    <TableCell>
                    <IconButton
                        onClick={() => handleRedirectToGraphicDeveloper(assignment.developer.id, assignment.month)}
                      >
                        <SvgIcon fontSize="medium">
                          <EqualizerIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleRedirectToAssignation(assignment.id)}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
                // ));
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalAssignments}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pageOfTable}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  assignments: PropTypes.array,
  pageOfTable: PropTypes.number,
  setPageOfTable: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  totalAssignments: PropTypes.number,
  table: PropTypes.number,
};

Results.defaultProps = {
};

export default Results;
