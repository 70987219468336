import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';

function SearchClientBar({
  clientProjectsInfo,
  setProjectId,
  searchClient,
  setSearchClient,
}) {
  if (!clientProjectsInfo || clientProjectsInfo.length === 0) {
    return null;
  }

  const handleSearchClientChange = (event, value) => {
    const client = value ? value.name : '';
    const firstProject = value ? value.projects[0].id : 0;
    setSearchClient(client);
    if (value) {
      setProjectId(firstProject);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          disablePortal
          id="combo-box-demo"
          options={clientProjectsInfo}
          getOptionLabel={(option) => option.name}
          value={clientProjectsInfo.find((option) => option.name === searchClient) || null}
          onChange={handleSearchClientChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Nombre cliente"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

SearchClientBar.propTypes = {
  className: PropTypes.string,
  clientProjectsInfo: PropTypes.array,
  setProjectId: PropTypes.func,
  setSearchClient: PropTypes.func,
  searchClient: PropTypes.string,
};

export default SearchClientBar;
