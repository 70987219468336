import React from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import SurveyCreateForm from './SurveyCreateForm';
import useStyles from './styles';

function SurveyCreateView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Crear encuesta">
      <Container maxWidth={false}>
        <Header />
        <SurveyCreateForm />
      </Container>
    </Page>
  );
}

export default SurveyCreateView;
