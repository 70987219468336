import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import SplashScreen from '../../../components/Loading/SplashScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-head': {
      fontWeight: 700,
      textAlign: 'center',
    },
  },
  queryField: {
    width: 50,
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
}));

function ObjectiveDetailsResult({
  className,
  objectiveDetails,
  ...rest
}) {
  const classes = useStyles();

  if (!objectiveDetails) return <SplashScreen />;

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Divider />
      <Box
        display="flex"
        alignItems="center"
      >
      </Box>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center' style={{ width: '40%' }}>
                  Resultado esperado
                </TableCell>
                <TableCell align='center' style={{ width: '40%' }}>
                  Descripción
                </TableCell>
                <TableCell align='center' style={{ width: '5%' }}>
                  Carga
                </TableCell>
                <TableCell align='center' style={{ width: '5%' }}>
                  Porcentaje bono
                </TableCell>
                <TableCell align='center' style={{ width: '10%' }}>
                  Tipo de objetivos
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
                key={objectiveDetails.id}
              >
                <TableCell align='center' style={{ width: '40%' }}>
                  <Box display="flex" alignContent='content' justifyContent='center'>
                    <Typography color="inherit" variant="h6">
                      {objectiveDetails.expectedResults}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align='center' style={{ width: '40%' }}>
                  <Box display="flex" alignContent='content' justifyContent='center'>
                    <Typography color="inherit" variant="h6">
                      {objectiveDetails.description}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align='center' style={{ width: '5%' }}>
                  <Box display="flex" alignContent='content' justifyContent='center'>
                    <Typography color="inherit" variant="h6">
                      {objectiveDetails.monthlyLoad}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align='center' style={{ width: '5%' }}>
                  <Box display="flex" alignContent='content' justifyContent='center'>
                    <Typography color="inherit" variant="h6">
                      {`${objectiveDetails.bonusPercentage} %`}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align='center' style={{ width: '10%' }}>
                  <Box display="flex" alignContent='content' justifyContent='center'>
                    <Typography color="inherit" variant="h6">
                      {objectiveDetails.type}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

ObjectiveDetailsResult.propTypes = {
  className: PropTypes.string,
  objectiveDetails: PropTypes.object,
};

ObjectiveDetailsResult.defaultProps = {
  comments: [],
};

export default ObjectiveDetailsResult;
