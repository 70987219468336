import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BarChart from './BarChart';
import { formatMonthAndYearMoment } from '../../../utils/date';
import useStyles from './styles';

function DemandAndAssignationBarChart({ projects, projectId, month }) {
  const classes = useStyles();
  const [dataDemandChart, setDataDemandChart] = useState([]);
  const [dataAssignationChart, setDataAssignationChart] = useState([]);
  const [chartMax, setChartMax] = useState(0);

  useEffect(() => {
    const setVariables = async () => {
      const project = projects.find((p) => p.id === projectId);
      const projectDemand = project?.demands.find(
        (demand) => formatMonthAndYearMoment(demand.month)
            === formatMonthAndYearMoment(month),
      ) || [];

      const projectAssignment = project?.project_cells.find(
        (projectCell) => formatMonthAndYearMoment(projectCell.month)
            === formatMonthAndYearMoment(month),
      ) || [];

      const projectDevelopers = projectAssignment.project_developers || [];

      const projectAssignedHours = projectDevelopers.reduce(
        (acc, projectDeveloper) => acc + projectDeveloper.assignedHours,
        0,
      ) || 0;

      const projectMinDemand = projectDemand.minHours || 0;
      const projectMaxDemand = projectDemand.maxHours || 0;

      const chartData1 = [
        {
          name: 'Demanda Máxima',
          data: [projectMaxDemand - projectMinDemand],
          color: '#BDBDBD',
          stack: 'stack1',
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
        },
        {
          name: 'Demanda Mínima',
          data: [projectMinDemand],
          color: '#24335E',
          stack: 'stack1',
          borderRadiusBottomLeft: 5,
          borderRadiusBottomRight: 5,
        },
      ];
      const chartData2 = [
        {
          name: 'Horas Asignadas',
          data: [projectAssignedHours],
          color: '#00D293',
          borderRadius: 5,
        },
      ];

      let max = 0;
      if (projectAssignedHours >= projectMaxDemand) {
        max = projectAssignedHours;
      } else {
        max = projectMaxDemand;
      }

      setDataDemandChart(chartData1);
      setDataAssignationChart(chartData2);
      setChartMax(max);
    };
    if (projects.length && projectId > 0) {
      setVariables();
    }
  }, [projects, month, projectId]);

  return (
    <div>
      <div className={classes.topRow}>
        <BarChart
          title={'Demanda mínima y máxima'}
          data={dataDemandChart}
          max={chartMax}
          chart={0}
        />
        <BarChart
          title={'Horas Asignadas'}
          data={dataAssignationChart}
          max={chartMax}
          chart={1}
        />
      </div>
    </div>
  );
}

DemandAndAssignationBarChart.propTypes = {
  className: PropTypes.string,
  projects: PropTypes.array,
  projectId: PropTypes.number,
  month: PropTypes.string,

};

export default DemandAndAssignationBarChart;
