import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import borderRadius from 'highcharts-border-radius';
import PropTypes from 'prop-types';

borderRadius(Highcharts);

function HorizontalBarChart({
  title, data, max, chart,
}) {
  if (data.length === 0) {
    return null;
  }

  const seriesData = data;

  const options = {
    chart: {
      type: 'bar',
      height: title ? '90px' : '35px',
      backgroundColor: 'transparent',
      spacingTop: 1,
      spacingBottom: 1,
    },
    title: {
      text: title,
      align: 'left',
      style: {
        fontFamily: 'Inter, sans-serif',
        fontWeight: 700,
        fontSize: '16px',
        marginTop: '-100px',
      },
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      max,
      stackLabels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: 'Inter, sans-serif',
            color: 'black',
          },
        },
      },
    },
    tooltip: {
      enabled: false,
      backgroundColor: '#D9D9D9',
      style: {
        color: '#24335E',
        fontFamily: 'Inter, sans-serif',
      },
      formatter() {
        const { series } = this.series.chart;
        const seriesIndex = this.series.index;
        const label = series[seriesIndex].name;
        const categoryIndex = this.point.index;
        let minDemand;
        let maxDemand;
        let assignedHours;

        if (chart === 0) {
          minDemand = series[1].yData[categoryIndex];
          maxDemand = minDemand + series[0].yData[categoryIndex];
        } else if (chart === 1) {
          assignedHours = series[0].yData[categoryIndex];
        }
        if (label === 'Demanda Mínima' || label === 'Demanda Máxima') {
          return `<div style="text-align: center;"><b style="font-weight: 700">Demanda</b><br/>Demanda Mínima: ${minDemand} horas<br/>Demanda Máxima: ${maxDemand} horas </div>`;
        }
        if (label === 'Horas Asignadas') {
          return `<div style="text-align: center;"><b style="font-weight: 700">Horas Asignadas</b><br/>Horas Asignadas: ${assignedHours} horas</div>`;
        }
        return null;
      },
    },

    series: seriesData,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

HorizontalBarChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  max: PropTypes.number.isRequired,
  chart: PropTypes.number.isRequired,
};
export default HorizontalBarChart;
