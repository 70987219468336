import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import userRequest from '../../../requests/api/user';
import DeveloperCreateForm from './DeveloperCreateForm';
import useStyles from './styles';

function DeveloperCreateView() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getAllUsers = async () => {
      const response = await userRequest.getAllUsers();
      if (response.success) {
        const data = response.data.data.users;
        const userOptions = data.map((user) => {
          if (!user.developer) {
            return user.email;
          }
          return 0;
        }).filter((u) => u !== 0);
        setUsers(userOptions);
      }
    };
    getAllUsers();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Agregar Developer"
    >
      <Container maxWidth={false}>
        <Header />
        <DeveloperCreateForm
          users={users}
        />
      </Container>
    </Page>
  );
}

export default DeveloperCreateView;
