import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../../components/Layout/Page';
import projectCellRequest from '../../../requests/api/project_cell';
import developerRequest from '../../../requests/api/developer';
import LastMonthAssignments from './LastMonthAssignments';
import CurrentAssignments from './CurrentAssignments';
import CreateProjectDeveloper from './CreateProjectDeveloper';
import projectsRequest from '../../../requests/api/project';
import useStyles from './styles';
import DemandAndAssignationChart from './DemandAndAssignationChart';
import CostCard from './CostCard';
import projectCellActions from '../../../actions/projectCellActions';
import projectRolesRequest from '../../../requests/api/project_role';
import Header from './Header';
import { firstDayOfCurrentMonth, lastMonth, getNextMonth } from '../../../utils/date';
import SearchMonth from './SearchMonth';
import SearchBar from './SearchBar';
import ProjectsBar from './ProjectsBar';
import { ACTIVE } from '../../../utils/const/projectStatus';

function ProjectAssignationView() {
  const classes = useStyles();
  const [projectCellId, setProjectCellId] = useState(0);
  const [projectId, setProjectId] = useState(0);
  const [month, setMonth] = useState('');
  const [limit] = useState(100);
  const [lastMonthAssignments, setLastMonthAssignments] = useState([]);
  const [currentMonthAssignment, setCurrentMonthAssignment] = useState([]);
  const [refreshCurrentAssignment, setRefreshCurrentAssignment] = useState(false);
  const [projects, setProjects] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [projectRoles, setProjectRoles] = useState([]);

  const dispatch = useDispatch();

  const projectCellReduxInfo = useSelector((state) => state.projectCell);

  useEffect(() => {
    const getProjects = async ({ boolean }) => {
      const response = await projectsRequest.getProjectsPerPage({
        page: 0,
        limit,
        searchClient: '',
        searchProject: '',
        status: ACTIVE,
        month: '',
      });
      if (response.success) {
        const data = await response.data.data.projects;
        const arrayData = Object.values(data);
        if (!boolean) {
          for (let i = 0; i < arrayData.length; i += 1) {
            if (arrayData[i].project_cells.length > 0) {
              setProjectId(arrayData[0].id);
              const currentDate = new Date();
              const currentDay = currentDate.getDate();
              const firstDayOfMonth = firstDayOfCurrentMonth(currentDate);
              const firstDayOfNextMonth = getNextMonth(firstDayOfMonth);

              const date = currentDay >= 15
                ? firstDayOfNextMonth
                : firstDayOfMonth;
              setMonth(date);
              break;
            }
          }
        }
        setProjects(arrayData);
      }
    };

    if (projectCellReduxInfo.projectCell.projectId) {
      setProjectId(projectCellReduxInfo.projectCell.projectId);
      setMonth(projectCellReduxInfo.projectCell.month);
      getProjects({ boolean: true });
    } else {
      getProjects({ boolean: false });
    }
  }, [projectCellReduxInfo]);

  useEffect(() => {
    const getLastMonthAssignment = async () => {
      const monthBefore = lastMonth(month);
      const projectCellResponse = await projectCellRequest.getProjectCellByProjectIdAndMonth({
        projectId,
        month: monthBefore,
      });
      if (projectCellResponse.success) {
        const data = await projectCellResponse.data.data.project_cell;
        const arrayData = [data];
        setLastMonthAssignments(arrayData);
      } else {
        setLastMonthAssignments([]);
      }
    };
    const getCurrentMonthAssignment = async () => {
      const projectCellResponse = await projectCellRequest.getProjectCellByProjectIdAndMonth({
        projectId,
        month,
      });
      if (projectCellResponse.success) {
        const data = await projectCellResponse.data.data.project_cell;
        const arrayData = [data];
        setCurrentMonthAssignment(arrayData);
      } else {
        setProjectCellId(0);
        setCurrentMonthAssignment([]);
      }
    };

    if (projectId && month) {
      getLastMonthAssignment();
      getCurrentMonthAssignment();
      dispatch(
        projectCellActions.setProjectCell({
          projectId,
          month,
        }),
      );
    }
  }, [projectId, month, refreshCurrentAssignment]);

  useEffect(() => {
    const getDevelopers = async () => {
      const response = await developerRequest.getDevelopersPerPage(0, 0, '', month);
      if (response.success) {
        const developersData = await response.data.data.developers;
        const arrayData = Object.values(developersData);
        setDevelopers(arrayData);
      }
    };
    if (month) {
      getDevelopers();
    }
  }, [refreshCurrentAssignment, month]);

  useEffect(() => {
    const getProjectRoles = async () => {
      const response = await projectRolesRequest.getProjectRolesPerPage({
        page: 0,
        limit: 100,
        searchValue: '',
      });
      if (response.success) {
        setProjectRoles(response.data.data.projectRoles);
      }
    };
    getProjectRoles();
  }, []);

  return (
    <Page className={classes.root} title="TubeOps | Asignacion de Proyectos">
      <Container maxWidth={false}>
        <div className={classes.headerDiv}>
          <Header />
          <SearchMonth
            month={month}
            setMonth={setMonth}
            projectId={projectId}
          />
        </div>
        {projects.length ? (
          <div>
            <div className={classes.mainSquare}>
              <div className={classes.leftColumn}>
                <SearchBar
                  projectId={projectId}
                  setProjectId={setProjectId}
                  projects={projects}
                />
                <ProjectsBar
                  projects={projects}
                  month={month}
                  projectId={projectId}
                  setProjectId={setProjectId}
                />
              </div>
              <div className={classes.rightColumn}>
                <DemandAndAssignationChart
                  projectId={projectId}
                  month={month}
                  projects={projects}
                />
                <CostCard currentMonthAssignment={currentMonthAssignment} />
                <CreateProjectDeveloper
                  setRefreshCurrentAssignment={setRefreshCurrentAssignment}
                  projectCellId={projectCellId}
                  developers={developers}
                  setDevelopers={setDevelopers}
                  allProjectRoles={projectRoles}
                  month={month}
                  projectId={projectId}
                />
              </div>
            </div>
            <div className={classes.bottomSquare}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <LastMonthAssignments
                    lastMonthAssignments={lastMonthAssignments}
                    month={month}
                    setRefreshCurrentAssignment={setRefreshCurrentAssignment}
                    setDevelopers={setDevelopers}
                    projectId={projectId}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CurrentAssignments
                    setRefreshCurrentAssignment={setRefreshCurrentAssignment}
                    currentMonthAssignment={currentMonthAssignment}
                    allProjectRoles={projectRoles}
                    developers={developers}
                    month={month}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        ) : null}
      </Container>
    </Page>
  );
}

ProjectAssignationView.propTypes = {
  match: PropTypes.any,
};

export default ProjectAssignationView;
