import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Request from '../../../requests/api/performance_cycle_instances';
import PerformanceCycleInstanceEditForm from './PerformanceCycleInstanceEditForm';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function PerformanceCycleInstanceEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [performanceCycleInstance, setPerformanceCycleInstance] = useState();

  useEffect(() => {
    const getPerformanceCycleInstance = async () => {
      const response = await Request.getPerformanceCycleInstanceById(props.match.params.id);
      if (isMountedRef.current) {
        setPerformanceCycleInstance(response.data.data.performance_cycle_instance);
      }
    };

    getPerformanceCycleInstance();
    // eslint-disable-next-line
  }, [props]);

  if (!performanceCycleInstance) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="TubeOps | Editar Instancia de ciclo de performance"
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          {performanceCycleInstance
            && <PerformanceCycleInstanceEditForm performanceCycleInstance={performanceCycleInstance}
            />
          }
        </Box>
      </Container>
    </Page>
  );
}

PerformanceCycleInstanceEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(PerformanceCycleInstanceEditView);
