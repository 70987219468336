import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import useStyles from './styles';

function ProgressBar({
  totalProjects,
  evaluatedProjectsComplete,
}) {
  const classes = useStyles();
  // caso en que min y max son 0

  const series = [
    {
      color: '#BDBDBD',
      data: [totalProjects - evaluatedProjectsComplete],
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      marginBottom: 0,
    },
    {
      color: '#24335E',
      data: [evaluatedProjectsComplete],
      borderRadiusBottomLeft: 5,
      borderRadiusBottomRight: 5,
      marginTop: 0,
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: '100px',
      width: 300,
      // marginRight: 100,
    },
    title: {
      text: '',
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      stackLabels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        minPointLength: 3, // Ensures that even small values have a visible bar
      },
    },
    series,
  };

  return (
    <div className={classes.progressBarDisplay}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <span className={classes.progressBarText}>
        {evaluatedProjectsComplete}/{totalProjects}
      </span>
    </div>
  );
}

ProgressBar.propTypes = {
  totalProjects: PropTypes.number,
  evaluatedProjectsComplete: PropTypes.number,
};
export default ProgressBar;
