import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 8,
  },
  title: {
    paddingRight: '15px',
  },
  tooltip: {
    backgroundColor: 'white',
    '& .MuiTooltip-arrow': {
      color: '#006E90',
    },
    color: '#006E90',
    border: '1px solid',
    borderColor: '#006E90',
    fontSize: '17px',
  },
}));

function Evaluations({ instance }) {
  const classes = useStyles();

  const percentagesSum = instance.objectivesWeight + instance.hourCompletionWeight
    + instance.projectsPerformanceWeight || 100;
  const objectivesEvaluation = ((instance.objectivesEvaluationAvg * 10) / 4) || 0;
  const projectsEvaluation = ((instance.projectsEvaluationAvg * 10) / 4) || 0;
  const finalEvaluation = (((objectivesEvaluation * instance.objectivesWeight)
    + (instance.hoursEvaluation * instance.hourCompletionWeight || 0)
    + (projectsEvaluation * instance.projectsPerformanceWeight))
    / percentagesSum).toFixed(1);

  return (
    <Box mt={2}>
      <Box
        display='flex'
      >
        <Typography
          className={classes.title}
          variant="h5"
          color="textPrimary"
        >
          Resumen
        </Typography>
      </Box>
      <Card className={classes.root}>
        <Box
          display='flex'
          height={130}
        >
          <Box
            width='25%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='h6'>
              Nota objetivos
            </Typography>
            <Box
              width='15%'
              display='flex'
              alignItems='end'
              justifyContent='space-between'
            >
              <Typography variant='h2'>
                {instance.objectivesEvaluationAvg ? instance.objectivesEvaluationAvg.toFixed(1) : 0}
              </Typography>
              <Typography variant='h4'>
                /4
              </Typography>
            </Box>
            <Typography variant='h6'>
              {`${instance.objectivesWeight || 0} %`}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            width='25%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='h6'>
              Nota dedicación
            </Typography>
            <Box
              width='15%'
              display='flex'
              alignItems='end'
              justifyContent='space-between'
            >
              <Typography variant='h2'>
                {instance.hoursEvaluation ? instance.hoursEvaluation.toFixed(1) : 0}
              </Typography>
              <Typography variant='h4'>
                /10
              </Typography>
            </Box>
            <Typography variant='h6'>
              {`${instance.hourCompletionWeight || 0} %`}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            width='25%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='h6'>
              Nota proyectos
            </Typography>
            <Box
              width='15%'
              display='flex'
              alignItems='end'
              justifyContent='space-between'
            >
              <Typography variant='h2'>
                {instance.projectsEvaluationAvg ? instance.projectsEvaluationAvg.toFixed(1) : 0}
              </Typography>
              <Typography variant='h4'>
                /4
              </Typography>
            </Box>
            <Typography variant='h6'>
              {`${instance.projectsPerformanceWeight || 0} %`}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            width='25%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='h6'>
              Nota final
            </Typography>
            <Box
              display='flex'
              alignItems='end'
              justifyContent='space-between'
              style={{ color: '#00D293' }}
            >
              <Typography variant='h2'>
                {finalEvaluation}
              </Typography>
              <Typography variant='h4'>
                /10
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

Evaluations.propTypes = {
  instance: PropTypes.object,
};

export default Evaluations;
