import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';

import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import SurveyEditForm from './SurveyEditForm';
import Header from './Header';
import Request from '../../../requests/api/survey';
import useStyles from './styles';

function SurveyEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [survey, setSurvey] = useState();

  useEffect(() => {
    const getSurvey = async () => {
      const response = await Request.getSurveyById(props.match.params.id);

      if (isMountedRef.current) {
        setSurvey(response.data.survey);
      }
    };

    getSurvey();
  }, [props]);

  if (!survey) {
    return null;
  }

  return (
    <Page className={classes.root} title="Editar encuesta">
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <SurveyEditForm survey={survey} />
        </Box>
      </Container>
    </Page>
  );
}

SurveyEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(SurveyEditView);
