import React from 'react';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button, Grid, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

const CloseProjectModal = ({
  open, setOpen, handleCloseProject, project,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modalContainer}>
      <div>
        <div className={classes.modalContent}>
          <div className={classes.modalHeaderContainer}>
            <div className={classes.modalTitleContainer}>
              <Typography className={classes.modalTitle}>
                ¿Estas seguro que quieres cerrar este proyecto?
              </Typography>
            </div>
            <CloseIcon
              className={classes.closeIcon}
              onClick={handleClose}
              sx={{ fontSize: 40 }}
            />
          </div>
          <hr className={classes.modalLine} />
          <div className={classes.modalBodyContainer}>
            <div className={classes.textContainer}>
              <Typography
                className={clsx(classes.typography, classes.typography)}
              >
                ¿Quieres cerrar el proyecto
              </Typography>
              <Typography
                className={clsx(classes.typography, classes.boldTypography)}
              >
                {`${project.client.name} - ${project.name}?`}
              </Typography>
            </div>
            <Typography
              className={clsx(classes.typography)}
              style={{ marginTop: '20px' }}
            >
              Si confirmas esta acción no podrás arrepentirte después.
            </Typography>
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.modalButton}
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.modalButton}
                onClick={() => handleCloseProject()}
              >
                Cerrar Proyecto
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

CloseProjectModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  handleCloseProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

export default CloseProjectModal;
