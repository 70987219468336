const OBJECTIVES_TYPES = [
  {
    id: 1,
    label: 'Desarrollo profesional',
  },
  {
    id: 2,
    label: 'Generación de valor',
  },
];

export default OBJECTIVES_TYPES;
