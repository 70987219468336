import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Redirect } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';

import Request from '../../../requests/api/survey';
import useStyles from './styles';

function SurveyCreateForm({ className, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChangeCheck = (e) => {
    setChecked(e.target.checked);
  };

  if (submitSuccess) {
    return <Redirect to="/administracion/encuestas" />;
  }

  return (
    <Formik
      initialValues={{
        name: '',
        googleFormId: '',
        link: '',
        expirationDate: '',
        mandatory: checked,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Debe ingresar un nombre'),
        googleFormId: Yup.string().required('Debe ingresar el Google Form ID'),
        link: Yup.string().required('Debe ingresar el link'),
        expirationDate: Yup.date().required('Debe ingresar una fecha de cierre'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            name: values.name,
            googleFormId: values.googleFormId,
            link: values.link,
            expirationDate: `${values.expirationDate}`,
            mandatory: checked,
          };

          const response = await Request.postCreateSurvey(data);

          if (response.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: response.message });
            enqueueSnackbar(response.message, {
              variant: 'error',
              action: (
                <Button href="/administracion/encuestas">
                  Volver a encuestas
                </Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.googleFormId && errors.googleFormId)}
                    fullWidth
                    multiline
                    helperText={touched.googleFormId && errors.googleFormId}
                    label="Google Form ID"
                    name="googleFormId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.googleFormId}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.link && errors.link)}
                    fullWidth
                    helperText={touched.link && errors.link}
                    label="Link"
                    name="link"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.link}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy hh:mm"
                      views={['year', 'month', 'day']}
                      label="Fecha de cierre"
                      minDate={new Date('2022-10-01')}
                      maxDate={new Date('2030-01-01')}
                      value={values.expirationDate}
                      onChange={(value) => setFieldValue('expirationDate', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          error={Boolean(touched.expirationDate && errors.expirationDate)}
                          helperText={touched.expirationDate && errors.expirationDate}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChangeCheck}
                      />
                    }
                    label="Obligatorio"
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

SurveyCreateForm.propTypes = {
  className: PropTypes.string,
};

export default SurveyCreateForm;
