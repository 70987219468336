/* eslint-disable max-len */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import pesoChileno from '../../../utils/moneyFormat';

const Chart = ({
  data,
}) => {
  if (Object.keys(data).length === 0) {
    return null;
  }

  let seriesData = [];
  const { categories } = data;
  const totalFacturado = data['Total Facturado'];
  const totalIngreso = data['Total Ingreso'];
  const totalServicios = data['Total Servicios'];

  seriesData = [
    {
      name: 'Total Facturado',
      data: totalFacturado,
      color: '#24335E',
    },
    {
      name: 'Total Servicios',
      data: totalServicios,
      color: '#5F696B',
    },
    {
      name: 'Total Ingreso',
      data: totalIngreso,
      color: '#00D293',

    },
  ];

  const chartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        style: {
          color: '#24335E',
          fontFamily: 'Inter, sans-serif',
          textTransform: 'capitalize', // Primera letra en mayúscula
          fontSize: '16px',
          fontWeight: 700,
        },
      },
    },
    yAxis: {
      stackLabels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    legend: {
      verticalAlign: 'top',
      enabled: true,
      itemStyle: {
        fontSize: '12px',
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
      },
      column: {
        borderWidth: 0,
        shadow: false,
      },
    },
    tooltip: {
      backgroundColor: '#D9D9D9',
      borderWidth: 1,
      borderRadius: 5,
      useHTML: true,
      style: {
        fontFamily: 'Inter, sans-serif',
        color: '#24335E',
      },

      formatter() {
        const { series } = this.series.chart;
        const seriesIndex = this.series.index;
        const categoryIndex = this.point.index;
        const label = series[seriesIndex].name;
        const value = series[seriesIndex].yData[categoryIndex];

        return `<div style="text-align: center;"><b style="font-weight: 700">${label}</b><br/>${pesoChileno(value)}</div>`;
      },
    },
    series: seriesData,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Chart;
