import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import projectRequest from '../../../requests/api/project';
import useStyles from './styles';

const getSearchCache = () => {
  const searchProject = '';
  const searchClient = '';

  return {
    searchProject,
    searchClient,
  };
};

function ProjectsListView() {
  const classes = useStyles();
  const [project, setProject] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalProjects, setTotalProjects] = useState(0);
  const { searchProject, searchClient } = getSearchCache();
  const [filters, setFilters] = useState({
    project: searchProject,
    client: searchClient,
  });

  useEffect(() => {
    const getProjects = async () => {
      const response = await projectRequest.getProjectsPerPage({
        page: pageOfTable,
        limit,
        searchProject: filters.project,
        searchClient: filters.client,
        month: '',
      });
      if (response.success) {
        const data = await response.data.data.projects;
        const arrayData = Object.values(data);
        setProject(arrayData);
        setTotalProjects(response.data.data.totalProjects);
      }
    };
    getProjects();
  }, [pageOfTable, limit, filters]);

  return (
    <Page className={classes.root} title="TubeOps | Proyectos">
      <Container maxWidth={false}>
        <Header />
        {project && (
          <Box mt={3}>
            <Results
              project={project}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              totalProjects={totalProjects}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default ProjectsListView;
