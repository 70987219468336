import axios from '../../utils/axios';
import { BAD_REQUEST, INTERNAL_SERVER_ERROR, OK } from '../../constants/http';

const getProjectsWithEvaluations = async ({
  searchValue, userId, cycleId,
}) => {
  try {
    const { data } = await axios.get(
      `/api/performance-aspects/projects/${userId}/${cycleId}?&searchValue=${searchValue}`,
    );

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const errStatus = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status: errStatus,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getUserEvaluations = async ({
  userId, cycleId,
}) => {
  try {
    const { data } = await axios.get(
      `/api/performance-aspects/evaluations/${userId}/${cycleId}`,
    );

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const errStatus = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status: errStatus,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const editEvaluation = async ({
  evaluationId, evaluation, comment,
}) => {
  try {
    const response = await axios.put(`/api/performance-aspects/evaluations/${evaluationId}`, {
      evaluation,
      comment,
    });
    if (response.data.success) {
      return {
        success: true,
        status: OK.status,
        message: OK.message,
        data: response.data,
      };
    }

    return {
      success: false,
      status: BAD_REQUEST.status,
      message: response.data.data.errorMessage,
      data: {},
    };
  } catch (err) {
    const errStatus = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status: errStatus,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default {
  getProjectsWithEvaluations,
  getUserEvaluations,
  editEvaluation,
};
